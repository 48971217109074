import config from '@/__config'

const model = {
  modelName: "contact_request",
  label: "聯絡請求",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      labelInLocale: true,
    },
    email: {
      type: "email",
      label: "E-mail",
      labelInLocale: true,
    },
    tel: {
      type: 'text',
      label: '聯絡電話',
      labelInLocale: true,
    },
    remark: {
      type: 'textarea',
      label: '備註',
      labelInLocale: true,
    },
    ip: {
      type: "text",
      label: "IP",
      readonly: true
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      pageMode: true,
      showFields: [
        "created_at", "name", "email"
      ],
      inRowBtnRead: true,
      creatable: false,
      updatable: false,
      deletableScopes: ['contact_request-read'],
    },
    read: {
      leftFields: [
        'name',
        'email',
        'tel',
        'remark',
        'ip',
        'updated_at',
        'created_at',
        'company_name',
        'budget',
        'payload',
      ],
      titleKey: 'mobile',
    },
  }
}

if (config.wsmodule.contact_request) {
  if (config.wsmodule.contact_request.fields) {
    if (config.wsmodule.contact_request.fields.company_name) {
      model.fields.company_name = {
        type: 'text',
        label: '公司'
      }
    }
    if (config.wsmodule.contact_request.fields.budget) {
      model.fields.budget = {
        type: 'text',
        label: '預算'
      }
    }
    if (config.wsmodule.contact_request.fields.payload) {
      model.fields.payload = {
        type: 'payload',
        ...config.wsmodule.contact_request.fields.payload
      }
    }
  }
}

if (config.wsmodule.country_code) {
  model.fields.country_code = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
}

export default model