import moment from 'moment';
import i18n from '@/i18n';
const model = {
  modelName: 'news_banner',
  label: i18n.t('橫幅管理'),
  orderLayerFields: [],
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: `${i18n.t('名稱')} (${i18n.t('後台管理')})`,
      autofocus: true,
      rules: 'required',
    },
    sq: {
      type: 'text',
      label: i18n.t('順序設定'),
    },
    page_settings: {
      type: 'belongsToMany',
      label: i18n.t('位置'),
      textKey: 'name',
      modelName: 'page_setting',
      placeholder: i18n.t('輸入') + '...',
      rules: 'required',
    },
    bg_img_1440: {
      type: 'image',
      label: `${i18n.t('底圖')} 1440(${i18n.t('電腦版圖片')})`,
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    bg_img_768: {
      type: 'image',
      label: `${i18n.t('底圖')} 768`,
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    bg_img_320: {
      type: 'image',
      label: `${i18n.t('底圖')} 320(${i18n.t('手機版圖片')})`,
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    link: {
      type: 'link',
      label: i18n.t('連結'),
    },
    title: {
      type: 'text',
      label: i18n.t('標題'),
    },
    title_color: {
      type: 'color',
      label: i18n.t('標題顏色'),
    },
    des: {
      type: 'text',
      label: i18n.t('說明'),
    },
    des_color: {
      type: 'color',
      label: i18n.t('說明顏色'),
    },
    is_active: {
      type: 'switch',
      label: i18n.t('刊登狀態'),
      activeText: i18n.t('已上架'),
      inactiveText: i18n.t('下架'),
      updatable: {
        getAlertTitle: function() {
          return i18n.t('調整刊登狀態');
        },
        getAlertContent: function(data) {
          if (data.is_active) {
            return i18n.t('確認要刊登嗎？');
          } else {
            return i18n.t('確認要關閉此刊登嗎？');
          }
        },
      },
    },
    start_date: {
      type: 'date',
      label: i18n.t('開始時間'),
      placeholder: i18n.t('選擇起日'),
      col: 3,
      disabledDate: function(data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('結束時間'),
      placeholder: i18n.t('選擇迄日'),
      col: 3,
      disabledDate: function(data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    duration: {
      type: 'radio-input',
      label: i18n.t('活動起迄日期'),
      items: [
        {
          text: i18n.t('時間區間'),
          value: 'interval',
          fields: {
            start_date: {
              type: 'date',
              label: i18n.t('開始時間'),
              placeholder: i18n.t('選擇起日'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.end_date) {
                  if (this.$moment(date).isAfter(data.end_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
            end_date: {
              type: 'date',
              label: i18n.t('結束時間'),
              placeholder: i18n.t('選擇迄日'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.start_date) {
                  if (this.$moment(date).isBefore(data.start_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
          },
        },
        {
          text: i18n.t('永不下架'),
          value: 'infinite',
        },
      ],
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    custom_duration: {
      type: 'custom',
      label: i18n.t('上下架時間'),
      getValue: function(data) {
        if (!data.start_date || !data.end_date) return i18n.t('永不下架');
        let _duration = '-';
        if (data.start_date)
          _duration = `${moment(data.start_date).format('YYYY.MM.DD')} - `;
        if (data.end_date)
          _duration += moment(data.end_date).format('YYYY.MM.DD');
        return _duration;
      },
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        'bg_img_1440',
        'name',
        'title',
        'page_settings',
        'custom_duration',
        'is_active',
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true,
      orderBySq: true,
      filterUniqueSection: true,
    },
    read: {
      leftFields: [
        'name',
        'title',
        'page_setting',
        'is_active',
        'custom_duration',
        'link',
      ],
      rightFields: ['bg_img_1440', 'bg_img_768', 'bg_img_320'],
      titleKey: 'name',
    },
  },
};

export default model;
