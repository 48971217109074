import config from '@/__config';

import i18n from '@/i18n';

export default {
  modelName: "xc_task",
  label: "Task",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: 'Task名稱',
      rules: 'required'
    },
    start_at: {
      type: 'datetime',
      label: '開始時間',
      rules: 'required'
    },
    reviewed_at: {
      type: 'datetime',
      label: '覆核時間',
      readonly: true,
    },
    status: {
      type: 'radio',
      label: '狀態',
      items: [
        {
          label: 'Waiting',
          value: 'waiting',
        },
        {
          label: 'Doing',
          value: 'doing',
        },
        {
          label: 'Done',
          value: 'done',
        },
        {
          label: 'Close',
          value: 'close',
        },
      ]
    },
    hour: {
      type: 'number',
      label: '預計實行時數'
    },
    finish_hour: {
      type: 'number',
      label: '實際執行時數'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    time_review_at: {
      type: 'datetime',
      label: '完成時間評估時間',
      readonly: true
    },
    tags: {
      type: 'tags',
      label: 'Tags',
    },
    xc_work_type: {
      type: 'belongsTo',
      label: '執行類型',
      textKey: "name",
      modelName: "xc_work_type",
      relationPopup: true
    },
    xc_project: {
      type: 'belongsTo',
      label: '專案',
      textKey: "name",
      modelName: "xc_project",
      relationPopup: true
    },
    xc_task_template: {
      type: 'belongsTo',
      label: 'Task 公版',
      textKey: "name",
      modelName: "xc_task_template",
      relationPopup: true
    },
    creator: {
      type: "belongsTo",
      label: "建立人",
      textKey: "name",
      modelName: config.auth.admin_blur ? 'cmser' : 'admin',
      readonly: true,
    },
    taker: {
      type: "belongsTo",
      label: "執行人",
      textKey: "name",
      modelName: config.auth.admin_blur ? 'cmser' : 'admin',
      relationPopup: true
    },
    is_personal: {
      type: 'switch',
      label: '私人Task'
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      pageMode: true,
      showFields: [
        'id',
        'name',
        'status',
        'updated_at',
        'created_at'
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      creatableScopes: ['xc_task-edit'],
      updatableScopes: ['xc_task-edit'],
      deletableScopes: ['xc_task-edit'],
      filterTabs: [

        {
          text: 'Waiting',
          value: 'waiting',
          params: {
            status: 'waiting',
          },
        },
        {
          text: 'Doing',
          value: 'doing',
          params: {
            status: 'doing',
          },
        },
        {
          text: 'Done',
          value: 'done',
          params: {
            status: 'done',
          },
        },
        {
          text: 'Close',
          value: 'close',
          params: {
            status: 'close',
          },
        },
      ],
      filterSelects: {
        taker: {
          type: "belongsTo",
          label: "執行人",
          modelName: config.auth.admin_blur ? 'cmser' : 'admin',
          col: 3,
        }
      }
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'start_at',
                'reviewed_at',
                'status',
                'hour',
                'finish_hour',
                'remark',
                'tags',
                'xc_work_type',
                'xc_project',
                'xc_task_template',
                'taker',
                'is_personal',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('編輯'),
              fields: [
                'name',
                'start_at',
                'reviewed_at',
                'status',
                'hour',
                'finish_hour',
                'remark',
                'tags',
                'xc_work_type',
                'xc_project',
                'xc_task_template',
                'taker',
                'is_personal',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'name',
        'start_at',
        'remark',
        'time_review_at',
        'reviewed_at',
        'hour',
        'finish_hour',
        'tags',
        'xc_work_type',
        'xc_project',
        'xc_task_template',
        'taker',
        'is_personal',
      ],
      rightFields: [
        'id',
        'status',
        'updated_at',
        'created_at'
      ],
      titleKey: 'name',
    },
  }
}