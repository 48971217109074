<template>
  <div>
    <SlickList
      :value="value"
      @input="$_onInput($event)"
      :useDragHandle="useDragHandle"
    >
      <SlickItem
        class="mt-10"
        v-for="(item, itemIndex) in value"
        :key="itemIndex"
        :index="itemIndex"
      >
        <slot
          name="slick-item"
          :item="item"
          :itemIndex="itemIndex"
        >
          <WsCard>{{item[labelKey]}}</WsCard>
          <div class="pl-20">
            <WsStateSort
              v-if="layerFields&&layerFields.length"
              :layerFields="_nextLayerFields"
              :labelKey="_labelKey"
              :value="item[_currentLayerField.key]"
              @input="$_onChildInput($event,itemIndex,_currentLayerField.key)"
            ></WsStateSort>
          </div>
        </slot>
      </SlickItem>
    </SlickList>
  </div>
</template>

<script>
export default {
  methods: {
    $_onInput($event) {
      const _value = [...$event];
      _value.forEach((valueItem, valueIndex) => {
        _value[valueIndex][this._sequenceKey] = String(valueIndex).padStart(
          this.sequenceLength,
          "0"
        );
      });
      this.$emit("input", _value);
    },
    $_onChildInput($event, itemIndex, valueKey) {
      const _value = [...this.value];
      _value[itemIndex][valueKey] = $event;
      this.$emit("input", _value);
    },
    $_sequenceValueSet(){
      const _value = [...this.value]
      _value.forEach((valueItem, valueIndex) => {
        _value[valueIndex][this._sequenceKey] = String(valueIndex).padStart(
          this.sequenceLength,
          "0"
        );
      });
      this.$emit("input", _value);
    },
  },
  mounted() {
    this.$_sequenceValueSet();
  },
  computed: {
    _labelKey() {
      if (this._currentLayerField) {
        if (this._currentLayerField.labelKey) {
          return this._currentLayerField.labelKey;
        } else {
          return this.labelKey;
        }
      } else {
        return null;
      }
    },
    _sequenceKey() {
      if (this._currentLayerField) {
        if (this._currentLayerField.sequenceKey) {
          return this._currentLayerField.sequenceKey;
        } else {
          return this.sequenceKey;
        }
      } else {
        return null;
      }
    },
    _currentLayerField() {
      if (!this.layerFields || !this.layerFields.length) {
        return {};
      } else {
        return this.layerFields[0];
      }
    },
    _nextLayerFields() {
      if (!this.layerFields || !this.layerFields.length) {
        return null;
      } else {
        const _nextLayerFields = [...this.layerFields];
        _nextLayerFields.splice(0, 1);
        return _nextLayerFields;
      }
    },
    _nextLayerField() {
      if (!this._nextLayerFields || !this._nextLayerFields.length) {
        return null;
      } else {
        return this._nextLayerFields[0];
      }
    },
  },
  props: {
    layerFields: {
      type: Array,
    },
    value: {
      type: Array,
    },
    labelKey: {
      type: String,
      default: "label",
    },
    sequenceKey: {
      type: String,
      default: "sq",
    },
    useDragHandle: {
      type: Boolean,
    },
    sequenceLength: {
      type: Number,
      default: 3,
    },
  },
};
</script>

<style>
</style>