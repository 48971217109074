export default {
  modelName: "penguin",
  label: "企鵝",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    birthday: {
      type: 'date',
      label: '生日'
    },
    color: {
      type: 'color',
      label: '顏色'
    },
    gender: {
      type: 'select',
      label: '性別',
      defaultValue: 'girl',
      items: [
        {
          text: '女森',
          value: 'girl'
        },
        {
          text: '男森',
          value: 'boy'
        },
      ]
    },
    foods: {
      type: 'multi-select',
      label: '吃啥',
      items: [
        {
          text: '🐟',
          value: 'fish'
        },
        {
          text: '北極熊',
          value: 'polar_bear'
        },
        {
          text: '大便',
          value: 'poo'
        },
      ]
    },
    avatar: {
      type: 'pocket_image',
      label: '頭貼 (PocketImage)',
      getUploadUrl: '/penguin/image/upload_url',
      signed: true,
    },
    // id_image:{
    //   type:'db_image',
    //   label:'身分證圖 (DbImage)',
    // },
    ice: {
      type: 'belongsTo',
      label: '企鵝的冰塊',
      textKey: "name",
      modelName: "ice",
      width: '100px',
      relationPopup: true
    },
    friends: {
      type: 'belongsToMany',
      label: '朋友企鵝',
      textKey: "name",
      modelName: "penguin",
      relationPopup: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}