import config from '@/__config';
import S_APP_Shop from "@/__vue2stone_cms/service/app/shop";

const model = {
  modelName: 'shop_ship_area_setting',
  label: '配送地區設定',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    ship_ways: {
      type: 'multi-select',
      label: '配送方式',
      items: [
        {
          text: 'Lalamove',
          value: 'lalamove',
        },
        {
          text: '宅配通',
          value: 'home_tong',
        },
        //keep
        // {
        //   text: '黑貓',
        //   value: 'blackcat',
        // },
      ],
    },
    area: {
      type: 'belongsTo',
      label: '地區',
      relationPopup: true,
      textKey: 'name',
      modelName: 'area',
    },
    is_all_area_section: {
      type: 'switch',
      label: '是否為全區',
      activeText: '是',
      inactiveText: '否',
    },
    area_sections: {
      type: 'belongsToMany',
      label: '子地區',
      modelName: 'area_section',
      relationPopup: true,
      getExtendParamsFromValue(value) {
        if (!value) {
          return {};
        } else if (value.area) {
          return {
            area: value.area.id,
          };
        } else {
          return {};
        }
      },
      getDisabledFromValue(value) {
        if (!value) {
          return false;
        } else if (value.area && value.is_all_area_section) {
          return true;
        } else {
          return false;
        }
      },
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    order_type: {
      type: 'select',
      label: '訂單類型',
      rules: 'required',
      items: S_APP_Shop.getOrderTypeItems()
    }
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['order_type', 'area', 'is_all_area_section', 'area_sections', 'ship_ways'],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: false,
      filterSelects: {
        order_type: {
          type: 'select',
          items: S_APP_Shop.getOrderTypeItems()
        },
      },
    },
    create: {
      primary: [
        {
          type: "stateCard",
          floors: [
            {
              fields: [
                "order_type",
                "area",
                "is_all_area_section",
                "area_sections",
                "ship_ways",
              ],
            },
          ],
        },
      ],
    }
  },
};

if (config.wsmodule.shop) {
  if (config.wsmodule.shop.areaToTaiwanCity) {
    model.fields.area.label = '縣市';
    model.fields.area_sections.label = '區域';
  }
}

export default model;
