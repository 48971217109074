<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="`${$t('edit')} ${$t(label)}`"
    :escMetaClose="true"
    @close="$_onDialogClose()"
  >
    <template v-slot:content>
      <WsLoading v-if="loading.fetch"></WsLoading>
      <div v-else>
        <WsErrorMessage
          v-if="pageErrorMessage"
          class="mb-20"
        >{{pageErrorMessage}}</WsErrorMessage>
        <ValidationObserver ref="form">
          <form
            ref="createForm"
            @submit.prevent
          >
            <WsStateForm
              v-model="state"
              :fields="_fields"
              :modelName="modelName"
              :errorMessages="errorMessages"
            ></WsStateForm>
          </form>
        </ValidationObserver>
      </div>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        color="textSecondary"
        outlined
      >{{$t('cancel')}}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :loading="submitLoading"
        color="primary"
      >{{$t('submit')}}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  data: () => ({
    pageErrorMessage: null,
    modelData: null,
    state: {},
    submitLoading: false,
    dialog: false,
    id: null,
    loading: {
      fetch: false,
    },
  }),
  computed: {
    _fields() {
      const _fields = this.updateFields
        ? { ...this.updateFields }
        : { ...this.fields };
      for (let fieldKey in _fields) {
        const _field = _fields[fieldKey];
        if (_field.updateRules !== undefined) {
          _field.rules = _field.updateRules;
        }
        if (_field.type == "hasMany") {
          if (!_field.belongsToKey) {
            _field.belongsToKey = this.modelName;
          }
          _field.belongsToValue = this.modelData.id;
        }
      }
      return _fields;
    },
  },
  methods: {
    $_onDialogClose() {
      this.close();
    },
    stopLoading() {
      this.submitLoading = false;
    },
    startLoading() {
      this.submitLoading = true;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      const postData = S_App_State.getFormatedStates(
        this._fields,
        this.state,
        this.postEncode
      );
      this.$emit("submit", {
        ...postData,
        id: this.id,
      });
    },
    $_onCancel() {
      this.close();
    },
    close() {
      this.reset();
      // window.removeEventListener("keydown", this.$_keydownHandler);
      setTimeout(() => {
        this.dialog = false;
        this.pageErrorMessage = null;
      }, 0);
    },
    reset() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.state = {};
      this.submitLoading = false;
    },
    async open(item) {
      this.dialog = true;
      if (this.fetchOnOpen) {
        try {
          this.loading.fetch = true;
          const res = await this.$axios.get(`${this.modelName}/${item.id}`);
          this.modelData = res.data.data;
        } catch (error) {
          alert("讀取資料錯誤");
        } finally {
          this.loading.fetch = false;
        }
      } else {
        this.modelData = item;
      }
      this.state = {
        ...S_App_State.getValueFromFields(this._fields, this.modelData),
      };
      this.id = item.id ? item.id : null;
      // window.addEventListener("keydown", this.$_keydownHandler);
    },
    // $_keydownHandler($event) {
    // if ($event.metaKey && $event.key == "Enter") {
    //   this.$_onSubmit();
    // }
    // },
  },
  props: {
    fetchOnOpen: {
      type: Boolean,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    errorMessages: {
      type: Object,
      default: null,
    },
    modelName: String,
    label: {
      type: String,
      default: null,
    },
    fields: Object,
    updateFields: {
      type: Object,
    },
  },
};
</script>