import i18n from '@/i18n';

const model = {
  modelName: 'shop_subclass',
  label: '商品子分類',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    uuid: {
      type: 'text',
      label: 'UUID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: '分類名稱',
      autofocus: true,
    },
    icon: {
      type: 'image',
      label: '分類圖片',
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    sq: {
      type: 'text',
      label: '順序設定',
    },
    shop_class: {
      type: 'belongsTo',
      label: '商品分類',
      textKey: 'name',
      modelName: 'shop_class',
      relationPopup: true,
    },
    type: {
      type: 'select',
      label: '分類類型',
      defaultValue: 'current',
      items: [
        {
          text: '現貨商品',
          value: 'current',
        },
        {
          text: '預售商品',
          value: 'pre_order',
        },
      ],
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    is_active: {
      type: 'switch',
      label: i18n.t('是否顯示於前台'),
      activeText: i18n.t('顯示'),
      inactiveText: i18n.t('隱藏'),
      updatable: {
        getAlertContent: function (data) {
          let _isOutstanding = data.is_outstanding
            ? i18n.t('顯示')
            : i18n.t('隱藏');
          return `確認要將此商品“${data.name}”${_isOutstanding}嗎？`;
        },
      },
      width: '140px',
    },
  },
};

export default model;
