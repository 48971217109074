<template>
  <WsDialog
    ref="WsDialog"
    v-model="dialog"
    :title="title"
    :type="type"
    :escMetaClose="true"
  >
    <template v-slot:content>
      <SlickList
        class="ws-sort-item-list"
        v-if="C_value"
        v-model="C_value"
      >
        <SlickItem
          class="ws-sort-item"
          v-for="(stage,stageIndex) in C_value"
          :key="stageIndex"
          :index="stageIndex"
        >
          {{stage[textKey]}}
        </SlickItem>
      </SlickList>
    </template>
    <template v-slot:rightActions>
      <WsBtn
        @click="$_onCancel()"
        :minWidth="100"
        color="textSecondary"
        outlined
      >{{$t('cancel')}}</WsBtn>
      <WsBtn
        @click="$_onSubmit()"
        :minWidth="100"
        color="primary"
      >{{$t('submit')}}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    C_value: null,
  }),

  methods: {
    close() {
      this.dialog = false;
      window.removeEventListener("keydown", this.$_keydownHandler);
    },
    $_onCancel() {
      this.close();
    },
    async $_onSubmit() {
      this.$emit("input", this.C_value);
      this.close();
    },
    $_keydownHandler(event) {
      if (event.metaKey && event.key == "Enter") {
        this.$_onSubmit();
      }
    },
    open() {
      this.$_valueInit();
      this.dialog = true;
      window.addEventListener("keydown", this.$_keydownHandler);
    },
    $_valueInit() {
      this.C_value = this.value ? JSON.parse(JSON.stringify(this.value)) : [];
    },
  },

  props: {
    type: {
      type: String,
      default: "panel-right",
    },
    title: {
      type: String,
      default() {
        return this.$t("adjust_the_order");
      },
    },
    value: {
      type: Array,
      default: null,
    },
    textKey: {
      type: String,
      default: "title",
    },
  },
};
</script>

<style>
</style>