<template>
  <WsLink
    v-if="value"
    class="ws-info-pocket-file mb-10 mt-10"
    :href="_url"
  >{{value.name}}</WsLink>
</template>

<script>
import PocketFileService from "@/__vue2stone_cms/service/api/v1/pocket_file";
export default {
  props: {
    value: {
      type: Object,
    },
  },

  computed: {
    _url() {
      if (!this.value) {
        return null;
      } else {
        return PocketFileService.getSrc(this.value);
      }
    },
  },
};
</script>

<style>
</style>