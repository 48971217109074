// import config from '@/__config'
import H_Model from '@/__vue2stone_cms/helpers/model'

// Admin
import admin from '@/__vue2stone_cms/models/admin';
import admin_role from '@/__vue2stone_cms/models/admin_role';
import admin_group from '@/__vue2stone_cms/models/admin_group';
// User
import user from '@/__vue2stone_cms/models/user';
import user_device_token from '@/__vue2stone_cms/models/user_device_token';
// PinCard
import pin_card from '@/__vue2stone_cms/models/pin_card';
// ServicePlan
import service_plan from '@/__vue2stone_cms/models/service_plan';
import user_service_plan from '@/__vue2stone_cms/models/user_service_plan';
import user_service_plan_item from '@/__vue2stone_cms/models/user_service_plan_item';
import user_service_plan_record from '@/__vue2stone_cms/models/user_service_plan_record';
import service_plan_item from '@/__vue2stone_cms/models/service_plan_item';
// Service Store
import service_store from '@/__vue2stone_cms/models/service_store';
import service_store_close from '@/__vue2stone_cms/models/service_store_close';
import service_store_noti from '@/__vue2stone_cms/models/service_store_noti';
// Appointment
import appointment from '@/__vue2stone_cms/models/appointment';
import ws_blog from '@/__vue2stone_cms/models/ws_blog';
import ws_blog_class from '@/__vue2stone_cms/models/ws_blog_class';
import socialite_google_account from '@/__vue2stone_cms/models/socialite_google_account';
import socialite_facebook_account from '@/__vue2stone_cms/models/socialite_facebook_account';
import socialite_line_account from '@/__vue2stone_cms/models/socialite_line_account';
import tag from '@/__vue2stone_cms/models/tag';
import area from '@/__vue2stone_cms/models/area';
import area_section from '@/__vue2stone_cms/models/area_section';
import system_class from '@/__vue2stone_cms/models/system_class';
import system_subclass from '@/__vue2stone_cms/models/system_subclass';
import contact_request from '@/__vue2stone_cms/models/contact_request';
import shop_product from '@/__vue2stone_cms/models/shop_product';
import shop_product_pre_order from '@/__vue2stone_cms/models/shop_product_pre_order';
import shop_class from '@/__vue2stone_cms/models/shop_class';
import shop_subclass from '@/__vue2stone_cms/models/shop_subclass';
import shop_ship_area_setting from '@/__vue2stone_cms/models/shop_ship_area_setting';
import shop_ship_time_setting from '@/__vue2stone_cms/models/shop_ship_time_setting';
import shop_free_shipping from '@/__vue2stone_cms/models/shop_free_shipping';
import featured_class from '@/__vue2stone_cms/models/featured_class';
import cms_log from '@/__vue2stone_cms/models/cms_log';
import web_log from '@/__vue2stone_cms/models/web_log';
import contact_request_notify_mail from '@/__vue2stone_cms/models/contact_request_notify_mail';
import news_banner from '@/__vue2stone_cms/models/news_banner';
import news from '@/__vue2stone_cms/models/news';
// Acumatica
import acumatica_app from '@/__vue2stone_cms/models/acumatica_app';
import user_device from '@/__vue2stone_cms/models/user_device';
import shop_campaign from '@/__vue2stone_cms/models/shop_campaign';
import shop_product_campaign from '@/__vue2stone_cms/models/shop_product_campaign';
import bonus_point from '@/__vue2stone_cms/models/bonus_point';
import shop_product_cover_frame from '@/__vue2stone_cms/models/shop_product_cover_frame';
import page_cover from '@/__vue2stone_cms/models/page_cover';
import xc_work_type from '@/__vue2stone_cms/models/xc_work_type';
import xc_project from '@/__vue2stone_cms/models/xc_project';
import xc_task from '@/__vue2stone_cms/models/xc_task';
import xc_billing_statement from '@/__vue2stone_cms/models/xc_billing_statement';
import page_setting from '@/__vue2stone_cms/models/page_setting';
import shop_order from '@/__vue2stone_cms/models/shop_order';
import shop_order_shop_product from '@/__vue2stone_cms/models/shop_order_shop_product';
import shop_return_record from '@/__vue2stone_cms/models/shop_return_record';
import download_info from '@/__vue2stone_cms/models/download_info';
import invoice_job from '@/__vue2stone_cms/models/invoice_job';
import showcase from '@/__vue2stone_cms/models/showcase';
import app_developer from '@/__vue2stone_cms/models/app_developer';
import otp from '@/__vue2stone_cms/models/otp';
import shop_cart_product from '@/__vue2stone_cms/models/shop_cart_product';

const stone_models = {
  namespaced: true,
  state: {
    // Model
    admin: admin,
    admin_role,
    admin_group,
    user,
    user_device_token,
    // PinCard
    pin_card,
    // ServicePlan
    service_plan,
    user_service_plan,
    service_plan_item,
    // Service Store
    service_store,
    service_store_close,
    service_store_noti,
    // Appointment
    appointment,
    ws_blog,
    ws_blog_class,
    socialite_google_account,
    socialite_facebook_account,
    socialite_line_account,
    tag,
    area,
    area_section,
    system_class,
    system_subclass,
    contact_request,
    shop_product,
    shop_class,
    shop_subclass,
    shop_product_pre_order,
    shop_ship_area_setting,
    shop_ship_time_setting,
    cms_log,
    web_log,
    user_service_plan_item,
    user_service_plan_record,
    shop_free_shipping,
    featured_class,
    contact_request_notify_mail,
    news_banner,
    news,
    acumatica_app,
    user_device,
    shop_campaign,
    shop_product_campaign,
    bonus_point,
    shop_product_cover_frame,
    page_cover,
    xc_work_type,
    xc_project,
    xc_task,
    xc_billing_statement,
    page_setting,
    shop_order,
    shop_order_shop_product,
    shop_return_record,
    download_info,
    invoice_job,
    showcase,
    app_developer,
    otp,
    shop_cart_product,
  },
  mutations: {},
  actions: {},
};

const moduleModels = H_Model.getModuleModels()
if (moduleModels) {
  stone_models.state = {
    ...stone_models.state,
    ...moduleModels
  }
}

export default stone_models