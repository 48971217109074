export default {
  mode: 'dark',
  bodyOverscroll: false,
  hasDrawer: true,
  hasHeader: true,
  color: {
    primary: '#6f9630',
    black: '#000000',
    white: '#ffffff',
    danger: '#ce3829',
    link: '#14ffb5',
  },
};
