import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

// CMS Stone
import cms from './__vue2stone_cms'

import i18n from './i18n'
Vue.use(cms)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.config.productionTip = false
