import config from '@/__config'

import moment from 'moment';
const model = {
  modelName: 'ws_blog',
  label: '部落格',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    title: {
      type: 'text',
      label: '標題',
      rules: 'required',
    },
    description: {
      type: 'text',
      label: '說明',
    },
    publish_at: {
      type: 'datetime',
      label: '發布時間',
      defaultValue: moment().format('YYYY-MM-DD hh:00:00'),
    },
    cover_image: {
      type: 'pocket_image',
      label: '封面圖片 (建議500k以下)',
      signed: false,
    },
    read_count: {
      type: 'number',
      label: '點擊量',
      readonly: true,
    },
    content: {
      type: 'editor',
      label: '內容',
      signed: false,
    },
    tags: {
      type: 'tags',
      label: '標籤',
    },
    ws_blog_classes: {
      type: 'belongsToMany',
      label: '部落格分類',
      modelName: 'ws_blog_class',
      labelInLocale: true,
    },
  },
};

if (config.wsmodule.ws_blog) {
  if (config.wsmodule.ws_blog.uploadable !== undefined) {
    model.fields.cover_image.uploadable = config.wsmodule.ws_blog.uploadable
  }
  if (config.wsmodule.ws_blog.linkable !== undefined) {
    model.fields.cover_image.linkable = config.wsmodule.ws_blog.linkable
  }
  if (config.wsmodule.ws_blog.pocketable !== undefined) {
    model.fields.cover_image.pocketable = config.wsmodule.ws_blog.pocketable
  }
}

export default model;