const model = {
  modelName: "web_log",
  label: "會員使用紀錄",
  fields: {
    user: {
      type: "belongsTo",
      label: "會員",
      labelInLocale: true,
      relationPopup: true,
      textKey: "name",
      modelName: "user"
    },
    'payload.action': {
      type: 'text',
      label: '行為',
      labelInLocale: true,
      readonly: true
    },
    'payload.target': {
      type: 'text',
      label: '目標',
      labelInLocale: true,
      width: '200px',
      readonly: true
    },
    'payload.target_id': {
      type: 'text',
      label: '目標ID',
      labelInLocale: true,
      readonly: true
    },
    'payload.ip': {
      type: 'text',
      label: 'IP',
      width: '200px',
      readonly: true
    },
    'payload.remark': {
      type: 'text',
      label: '備註',
      width: '200px',
      readonly: true
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      width: '200px'
    },
  },
}

export default model