<template>
  <WsRow class="ws-info-form">
    <WsCol
      v-for="(field, fieldKey) in fields"
      :class="[
        `sm-col-${field.sm?field.sm:12}`,
        `col-${field.sm?field.sm:12}`
      ]"
      :key="fieldKey"
    >
      <WsInfo
        v-bind="field"
        :parent="field.parentKey"
        :value="$_getValue(value,fieldKey,field)"
        :modelData="value"
      />
    </WsCol>
  </WsRow>
</template>

<script>
export default {
  methods: {
    $_getValue(value, fieldKey, field) {
      if (field.type == "custom") {
        return field.getValue(value);
      } else if (field.type == "date-range-or-not") {
        if (value.start_date && value.end_date) {
          return {
            startDate: value.start_date,
            endDate: value.end_date,
          };
        } else {
          return {};
        }
      } else if (!value) {
        return null;
      } else {
        return value[fieldKey];
      }
    },
  },
  props: {
    value: {
      type: Object,
    },
    fields: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style>
</style>