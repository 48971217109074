const model = {
  modelName: "shop_ship_time_setting",
  label: "配送時段設定",
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true
    },
    start_time: {
      type: 'time',
      label: '起始時間'
    },
    end_time: {
      type: 'time',
      label: '結束時間'
    },
    max_count: {
      type: 'number',
      label: '訂單上限'
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        "start_time",
        "end_time",
        "max_count",
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true
    }
  }
}


export default model