import config from '@/__config';
import H_Menu from '@/__vue2stone_cms/helpers/menu'

const menu = {
  wsmodules: {
  },
};

let module_list = [
  'my',
  'admin',
  'user',
  'setting',
  'contact_request',
  'area',
  'acumatica',
  'otp',
  'app_developer',
  'xc_project',
  'xc_work_type',
  'xc_task',
  'xc_billing_statement',
  'shop',
  'news',
  'website',
  'tulpa',
  'tag',
  'system_class',
  'service_plan',
  'pin_card',
  'appointment',
  'locale',
  'download_info',
  'invoice',
  'showcase',
  'ws_blog',
  'service_store',
  'system',
  // 'user_like',
]


if (config.menu.order) {
  module_list = config.menu.order
}

module_list.forEach(module => {
  // if (config.wsmodule[module]) {
  menu.wsmodules = {
    ...menu.wsmodules,
    ...H_Menu.getMenuOption(module),
  }
  // }
});



export default menu