<template>
  <div class="ws-state-image">
    <WsFlex>
      <WsLink
        v-if="_value"
        class="mb-10 mt-10"
        :href="_value.url"
      >{{_value.name}}</WsLink>
      <WsIconBtn
        @click="$_onClear()"
        v-if="_value"
        class="file-remove-btn ml-10"
        name="icon-md-delete"
      />
    </WsFlex>
    <WsPocketFilePicker
      v-model="dialog"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :pocketable="pocketable"
      :uploadable="uploadable"
      :linkable="linkable"
      @submit="$_onPocketFileSubmit($event)"
    />
    <WsBtn @click="$_onPickDialogOpen()">選擇檔案</WsBtn>
  </div>
</template>

<script>
import PocketFileService from "@/__vue2stone_cms/service/api/v1/pocket_file";
export default {
  data: () => ({
    dialog: false,
    fetchedItems: [],
  }),

  computed: {
    _value() {
      if (!this.value) {
        return null;
      } else {
        const _target = this.fetchedItems.find((e) => {
          return e.value == this.value;
        });
        return _target ? _target : null;
      }
    },
  },

  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    $_getUrl(pocketFile) {
      return PocketFileService.getSrc(pocketFile);
    },
    $_onPocketFileSubmit($event) {
      this.$_addItemToFetchedItems($event);
      this.$emit("input", $event.id);
    },
    $_originValueToFetchedItems() {
      if (!this.originValue) {
        return;
      }
      this.$_addItemToFetchedItems(this.originValue);
    },
    $_addItemToFetchedItems(item) {
      const _item = this.fetchedItems.find((e) => {
        return e.value == item.id;
      });
      if (!_item) {
        this.fetchedItems.push({
          value: item.id,
          url: this.$_getUrl(item),
          name: item.name,
        });
      }
    },
  },

  mounted() {
    this.$_originValueToFetchedItems();
  },

  props: {
    originValue: {
      type: Object,
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    pocketable: {
      type: Boolean,
    },
    uploadable: {
      type: Boolean,
    },
    linkable: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>