import app from '@/__vue2stone_cms/store/app'
import auth from '@/__vue2stone_cms/store/auth'
import locale from '@/__vue2stone_cms/store/locale'
import stone_model from '@/__vue2stone_cms/store/model'
import Vue from 'vue'
import Vuex from 'vuex'
import model from "./model";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    app,
    locale,
    model,
    stone_model,
  }
})
