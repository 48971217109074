<template>
  <WsMain>
    <WsCrud
      ref="WsCrud"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :urlModelName="urlModelName"
      :params="_params"
      :createUrl="createUrl"
      :getUpdateUrl="getUpdateUrl"
      :getReadUrl="getReadUrl"
      :orderLayerFields="orderLayerFields"
      @title-bar-custom-btn-click="$emit('title-bar-custom-btn-click',$event)"
      v-bind="crudSetting[bindingKey]"
      @params="$emit('params',$event)"
      @custom-table-action="$emit('custom-table-action',$event)"
      :creatable="_creatable"
      :updatable="_updatable"
      :deletable="_deletable"
      :filterTabs="_filterTabs"
      :getUrl="getUrl"
      :sortOption="sortOption"
      @update:filter-select-form="$emit('update:filter-select-form',$event)"
      @input="$emit('input',$event)"
    ></WsCrud>
  </WsMain>
</template>

<script>
import S_App_Auth from "@/__vue2stone_cms/service/app/auth";
export default {
  metaInfo() {
    return {
      title: this.label,
    };
  },
  methods: {
    fetchData() {
      this.$refs.WsCrud.fetchData();
    },
  },
  computed: {
    _creatable() {
      if (
        this.crudSetting &&
        this.crudSetting[this.bindingKey] &&
        this.crudSetting[this.bindingKey].creatableScopes
      ) {
        if (S_App_Auth.hasScope(this.crudSetting[this.bindingKey].creatableScopes)) {
          return true;
        } else {
          return false;
        }
      } else {
        return this.crudSetting[this.bindingKey].creatable;
      }
    },
    _updatable() {
      if (
        this.crudSetting &&
        this.crudSetting[this.bindingKey] &&
        this.crudSetting[this.bindingKey].updatableScopes
      ) {
        if (S_App_Auth.hasScope(this.crudSetting[this.bindingKey].updatableScopes)) {
          return true;
        } else {
          return false;
        }
      } else {
        return this.crudSetting[this.bindingKey].updatable;
      }
    },
    _deletable() {
      if (
        this.crudSetting &&
        this.crudSetting[this.bindingKey] &&
        this.crudSetting[this.bindingKey].deletableScopes
      ) {
        if (S_App_Auth.hasScope(this.crudSetting[this.bindingKey].deletableScopes)) {
          return true;
        } else {
          return false;
        }
      } else {
        return this.crudSetting[this.bindingKey].deletable;
      }
    },
    _params() {
      let _params = this.crudSetting[this.bindingKey].params;
      if (
        this.crudSetting[this.bindingKey].countryCode &&
        this.$store.state.auth.currentUser.country_code
      ) {
        _params = {
          ...this.crudSetting[this.bindingKey].params,
          country_code: this.$store.state.auth.currentUser.country_code,
        };
      }
      if (this.params) {
        _params = {
          ..._params,
          ...this.params,
        };
      }
      return _params;
    },
    _filterTabs() {
      if (this.crudSetting[this.bindingKey].filterTabs) {
        return this.crudSetting[this.bindingKey].filterTabs;
      } else {
        return this.filterTabs;
      }
    },
  },
  props: {
    bindingKey: {
      type: String,
      default: "index",
    },
    sortOption: {
      type: Object,
    },
    modelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    createUrl: {
      type: String,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    urlModelName: {
      type: String,
    },
    orderLayerFields: {
      type: Array,
    },
    creatableScopes: {
      type: Array,
    },
    updatableScopes: {
      type: Array,
    },
    deletableScopes: {
      type: Array,
    },
    creatable: {
      type: Boolean,
    },
    updatable: {
      type: Boolean,
    },
    deletable: {
      type: Boolean,
    },
    filterTabs: {
      type: Array,
    },
    params: {
      type: Object,
    },
    getUrl: {
      type: String,
    },
  },
};
</script>