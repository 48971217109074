<template>
  <span @click="$_onClick(url,name)">
    <slot></slot>
  </span>
</template>
<script>
export default {
  props: {
    url: {
      type: String,
    },
    name: {
      type: String,
    },
  },
  methods: {
    $_onClick(url, filename) {
      if (!url) {
        return;
      }
      fetch(url).then(function (t) {
        return t.blob().then((b) => {
          let attach = document.createElement("a");
          attach.href = URL.createObjectURL(b);
          attach.setAttribute("download", filename);
          attach.click();
        });
      });
    },
  },
};
</script>