import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'page_cover',
  label: i18n.t('彈跳窗管理'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: i18n.t('標題'),
      placeholder: i18n.t('輸入') + '...',
      rules: 'required',
    },
    page_settings: {
      type: 'belongsToMany',
      label: i18n.t('位置'),
      textKey: 'name',
      modelName: 'page_setting',
      placeholder: i18n.t('輸入') + '...',
      rules: 'required',
    },
    image: {
      type: 'image',
      label: i18n.t('圖框'),
      signed: false,
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    link: {
      type: 'link',
      label: i18n.t('連結'),
      placeholder: i18n.t('請貼上連結網址'),
    },
    start_date: {
      type: 'date',
      label: i18n.t('開始時間'),
      placeholder: i18n.t('選擇起日'),
      disabledDate: function(data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('結束時間'),
      placeholder: i18n.t('選擇迄日'),
      disabledDate: function(data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    is_active: {
      type: 'switch',
      label: i18n.t('刊登狀態'),
      activeText: i18n.t('已上架'),
      inactiveText: i18n.t('下架'),
      updatable: {
        getAlertContent: function(data) {
          let _isActive = data.is_active ? i18n.t('上架') : i18n.t('下架');
          return `確認要${_isActive}此彈跳窗嗎？`;
        },
      },
    },
    duration: {
      type: 'radio-input',
      label: i18n.t('活動起迄日期'),
      items: [
        {
          text: i18n.t('時間區間'),
          value: 'interval',
          fields: {
            start_date: {
              type: 'date',
              label: i18n.t('開始時間'),
              placeholder: i18n.t('選擇起日'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.end_date) {
                  if (this.$moment(date).isAfter(data.end_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
            end_date: {
              type: 'date',
              label: i18n.t('結束時間'),
              placeholder: i18n.t('選擇迄日'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.start_date) {
                  if (this.$moment(date).isBefore(data.start_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
          },
        },
        {
          text: i18n.t('永不下架'),
          value: 'infinite',
        },
      ],
    },
    custom_duration: {
      type: 'custom',
      label: i18n.t('上下架時間'),
      getValue: function(data) {
        let _duration = i18n.t('永不下架');
        if (data.start_date && data.end_date)
          _duration = `${moment(data.start_date).format(
            'YYYY.MM.DD',
          )} - ${moment(data.end_date).format('YYYY.MM.DD')}`;
        return _duration;
      },
    },
  },
  crudSetting: {
    index: {
      showFields: ['image', 'name', 'custom_duration', 'is_active'],
      infiniteScroll: true,
      inRowBtnRead: true,
      inRowBtnUpdate: true,
      inRowBtbDelete: true,
      pageMode: true,
      filterUniqueSection: true,
    },
    read: {
      leftFields: ['name', 'is_active', 'custom_duration', 'link', 'image'],
      rightFields: [],
    },
  },
};

export default model;
