import Rainbow from "rainbowvis.js";
export default {
  getColors(range, start, end) {
    const rainbow = new Rainbow()
    rainbow.setNumberRange(1, range);
    rainbow.setSpectrum(start, end);
    const _colors = []
    for (let i = 1; i <= range; i++) {
      _colors.push(`#${rainbow.colorAt(i)}`)
    }
    return _colors
  },
  getColorFamily(color, range, light, dark) {
    const _lightColors = this.getColors(range, light, color)
    const _darkColors = this.getColors(range, color, dark)
    const _colorFamily = []
    _lightColors.forEach((_lightColor, _lightColorIndex) => {
      if (_lightColorIndex == _lightColors.length - 1) {
        return
      } else {
        _colorFamily.push(_lightColor)
      }
    });
    _darkColors.forEach((_darkColor) => {
      _colorFamily.push(_darkColor)
    });
    return _colorFamily
  }
}