
const model = {
  modelName: "showcase",
  label: "案例展示",
  orderLayerFields: [],
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
      autofocus: true
    },
    description: {
      type: 'textarea',
      rules: 'required',
      label: '說明',
    },
    color: {
      type: 'color',
      label: '顏色',
    },
    cover_image: {
      type: 'image',
      label: '封面圖片',
    },
    main_image: {
      type: 'image',
      label: '主要圖片',
    },
    route_name: {
      type: 'text',
      label: '網址',
      rules: 'required'
    },
    tags: {
      type: 'tags',
      label: 'Tags',
    },
    is_active: {
      type: 'switch',
      label: '顯示',
      activeText: '是',
      inactiveText: '否',
      defaultValue: false,
    },
    content: {
      type: 'wasaintro',
      label: '介紹內容',
    },
    sq: {
      type: 'text',
      label: '排序',
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: [
        'id',
        'name',
        'route_name',
        'is_active',
      ],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      orderBySq: true,
    },
    read: {
      leftFields: [
        'description',
        'main_image',
        'content',
      ],
      rightFields: [
        'id',
        'route_name',
        'name',
        'tags',
        'color',
        'sq',
        'is_active',
        'cover_image',
      ],
      titleKey: 'name'
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'description',
                'color',
                'cover_image',
                'main_image',
                'route_name',
                'tags',
                'is_active',
                'content',
              ],
            },
          ],
        },
      ],
    },
    update: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'description',
                'color',
                'cover_image',
                'main_image',
                'route_name',
                'tags',
                'is_active',
                'content',
              ],
            },
          ],
        },
      ],
      titleKey: 'name'
    },
  }
};

export default model;
