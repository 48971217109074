import service_plan from './service_plan'
import user from './user'

export default {
  modelName: "user_service_plan",
  label: "會員綁定方案",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    service_plan: {
      type: "belongsTo",
      label: "服務方案",
      labelInLocale: true,
      textKey: "name",
      modelName: "service_plan",
      fields: service_plan.fields
    },
    user: {
      type: "belongsTo",
      label: "會員",
      labelInLocale: true,
      textKey: "name",
      modelName: "user",
      fields: user.fields
    },
    "user.email": {
      type: 'text',
      label: '使用者信箱',
      readonly: true
    },
    "user.tel": {
      type: 'text',
      label: '使用者電話',
      readonly: true
    },
    // user_service_plan_items: {
    //   type: 'hasMany',
    //   readonly: true,
    //   label: '會員綁定方案項目',
    //   textKey: "content",
    //   modelName: "user_device_token",
    //   relationPopup: true,
    //   fields: user_device_token.fields,
    //   showFields: [
    //     'id',
    //     'is_active',
    //     'device_token',
    //     'remark',
    //   ]
    // },
    created_at: {
      type: "datetime",
      label: "綁定時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
}
