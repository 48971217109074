import config from '@/__config';

export default {
  getOrderTypeItems() {
    if (!config.wsmodule.shop) {
      return null
    }
    if (!config.wsmodule.shop.order_type) {
      return null
    }
    const getOrderTypeItems = []
    if (config.wsmodule.shop.order_type.general) {
      getOrderTypeItems.push({
        value: config.wsmodule.shop.order_type.general.value ? config.wsmodule.shop.order_type.general.value : 'general',
        text: config.wsmodule.shop.order_type.general.label ? config.wsmodule.shop.order_type.general.label : '商品',
        ...config.wsmodule.shop.order_type.general
      })
    }
    if (config.wsmodule.shop.order_type.pre_order) {
      getOrderTypeItems.push({
        value: config.wsmodule.shop.order_type.general.value ? config.wsmodule.shop.order_type.general.value : 'pre-order',
        text: config.wsmodule.shop.order_type.pre_order.label ? config.wsmodule.shop.order_type.pre_order.label : '預購',
        ...config.wsmodule.shop.order_type.pre_order
      })
    }
    return getOrderTypeItems
  }
}