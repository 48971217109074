<template>
  <WsDialog
    ref="WsDialog"
    :title="_title"
    v-model="dialog"
  >
    <template v-slot:content>
      <slot
        v-if="$_checkSlotHasContent('content')"
        name="content"
        :item="item"
      ></slot>
      <WsLoading v-else-if="loading.fetch"></WsLoading>
      <WsEasyTable
        v-else
        :fields="fields"
        :modelData="item"
      />
    </template>
    <template v-slot:leftActions>
      <slot
        name="leftActions"
        :item="item"
      ></slot>
      <WsBtn
        v-if="deletable"
        @click="$_onDelete()"
        :outlined="false"
        alert
      >{{$t('delete')}}</WsBtn>
    </template>
    <template v-slot:rightActions>
      <slot
        name="rightActions"
        :item="item"
      ></slot>
      <template v-if="copyable">
        <WsBtn
          v-if="pageMode"
          :to="_copyUrl"
        >複製</WsBtn>
        <WsBtn
          v-if="!pageMode"
          @click="$_onCopy()"
        >複製</WsBtn>
      </template>
      <template v-if="updatable">
        <WsBtn
          v-if="pageMode&&!dialogUpdate"
          :to="$_getUpdateUrl(item)"
          @click="$_onUpdate()"
          outlined
        >{{$t('edit')}}</WsBtn>
        <WsBtn
          v-else
          @click="$_onUpdate()"
          outlined
        >{{$t('edit')}}</WsBtn>
      </template>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data: () => ({
    dialog: false,
    item: null,
    itemIndex: null,
    loading: {
      fetch: false,
    },
  }),
  methods: {
    $_checkSlotHasContent(slotName) {
      if (this.$slots[slotName] || this.$scopedSlots[slotName]) {
        return true;
      } else {
        return false;
      }
    },
    $_onCopy() {
      this.$emit("copy", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    $_onUpdate() {
      this.$emit("update", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    $_onDelete() {
      this.$emit("delete", {
        item: this.item,
        itemIndex: this.itemIndex,
      });
    },
    async open(item, itemIndex) {
      this.dialog = true;
      if (this.fetchOnOpen) {
        try {
          this.loading.fetch = true;
          const res = await this.$axios.get(`${this.modelName}/${item.id}`);
          this.item = res.data.data;
        } catch (error) {
          alert("讀取資料錯誤");
        } finally {
          this.loading.fetch = false;
        }
      } else {
        this.item = item;
      }
      this.itemIndex = itemIndex;
    },
    close() {
      this.dialog = false;
    },
    $_getUpdateUrl(item) {
      if (!item) {
        return "";
      }
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}/update`;
      }
    },
  },
  computed: {
    _copyUrl() {
      return `/${this._urlModelName}/create?copyfrom=${this.item.id}`;
    },
    _title() {
      if (!this.item) {
        return null;
      } else {
        return this.item[this.titleKey];
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  props: {
    fetchOnOpen: {
      type: Boolean,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    fields: Object,
    urlModelName: String,
  },
};
</script>

<style>
</style>