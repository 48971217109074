export default {
  modelName: "ws_blog_class",
  label: "部落格分類",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '分類名稱'
    }
  },
}