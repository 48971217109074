<template>
  <div>
    <WsMain>
      <WsLoading v-if="loading.init"></WsLoading>
      <WsStateSort
        v-else
        v-model="state.order"
        :layerFields="orderLayerFields"
        :labelKey="labelKey"
      >
        <template #slick-item="item">
          <slot
            name="slick-item"
            :item="item"
          ></slot>
        </template>
      </WsStateSort>
    </WsMain>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      state: {
        order: [],
      },
      loading: {
        init: true,
        submit: false,
      },
    };
  },

  methods: {
    async $_onSubmit() {
      try {
        this.loading.submit = true;
        this.$axios.patch(this.patchUrl, this.state);
        this.$router.go(-1);
      } catch (error) {
        alert(this.$t("更新順序錯誤"));
      } finally {
        this.loading.submit = false;
      }
    },
    async $_fetchOrder() {
      try {
        this.loading.init = true;
        const res = await this.$axios.get(this.getUrl, { params: this._query });
        this.state.order = res.data.data;
        this.loading.init = false;
      } catch (error) {
        alert(this.$t("取得順序錯誤"));
      }
    },
  },
  computed: {
    _query() {
      if (this.$route.query) {
        return this.$route.query;
      }
      return {};
    },
  },
  mounted() {
    this.$_fetchOrder();
  },
  props: {
    orderLayerFields: {
      type: Array,
      default() {
        return [];
      },
    },
    labelKey: {
      type: String,
      default: "name",
    },
    sequenceKey: {
      type: String,
      default: "sq",
    },
    getUrl: {
      type: String,
      required: true,
    },
    patchUrl: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
</style>