import i18n from '@/i18n';
import config from '@/__config';

const model = {
  modelName: 'shop_cart_product',
  fields: {
    name: {
      label: i18n.t('商品名稱'),
      type: 'text',
    },
    count: {
      label: i18n.t('購買數量'),
      type: 'number',
    },
    price: {
      label: i18n.t('單價'),
      type: 'number',
    },
    discount_price: {
      type: 'number',
      label: i18n.t('優惠價'),
    },
  },
};

if (config.wsmodule.shop) {
  if (config.wsmodule.shop.discount_price) {
    model.fields.discount_price = {
      type: 'number',
      label: i18n.t('優惠價'),
      rules: 'required|min_value:1|integer',
    };
  }
}

export default model;
