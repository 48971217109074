export default {
  modelName: "service_plan_item",
  label: "服務項目",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required'
    },
    type: {
      type: 'select',
      label: '類型',
      rules: 'required',
      items: [
        {
          value: 'custom-items',
          text: '自訂選項',
        },
        {
          value: 'discount',
          text: '折扣-X折',
        },
        {
          value: 'times',
          text: '次數',
        },
        {
          value: 'annual-times',
          text: '年度次數',
        },
        {
          value: 'device-count',
          text: '裝置數量',
        },
        {
          value: 'boolean',
          text: '有無',
        },
      ]
    },
    unit: {
      type: 'text',
      label: '單位',
    },
    items: {
      type: 'list',
      label: '選項',
      fields: {
        value: {
          type: 'text',
          label: '值 (程式辨識使用)',
          autofocus: true
        },
        text: {
          type: 'text',
          label: '文字 (瀏覽使用)'
        },
      }
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
}
