import i18n from '@/i18n';

export default {
  modelName: "xc_project",
  label: "專案",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    // url: {
    //   type: 'link',
    //   label: '專案連結',
    //   displayInText: true,
    //   rules: 'required'
    // },
    name: {
      type: 'text',
      label: '專案名稱',
      rules: 'required'
    },
    slack_webhook_url: {
      label: 'Slack Webhook URL'
    },
    status: {
      type: 'radio',
      label: '狀態',
      items: [
        {
          value: 'prepare',
          label: '準備中'
        },
        {
          value: 'ing',
          label: '進行中'
        },
        {
          value: 'suspend',
          label: '暫停'
        },
        {
          value: 'complete',
          label: '結案'
        },
      ]
    },
    slack_team_id: {
      type: 'text',
      label: 'Slack Team ID'
    },
    slack_channel_id: {
      type: 'text',
      label: 'Slack Channel Id'
    },
    invision: {
      type: 'text',
      label: 'Invision 帳號'
    },
    zeplin: {
      type: 'text',
      label: 'Zeplin 帳號'
    },
    gitlab: {
      type: 'link',
      label: 'Gitlab 連結'
    },
    github: {
      type: 'link',
      label: 'Github 連結'
    },
    google_drive: {
      type: 'link',
      label: 'Google Drive 連結'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    links: {
      type: 'list',
      label: '相關連結',
      fields: {
        name: {
          type: 'text',
          label: '名稱',
          rules: 'required'
        },
        link: {
          type: 'link',
          label: '連結',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '備註',
        },
      },
    },
    infos: {
      type: 'list',
      label: '相關資訊',
      sm: 12,
      fields: {
        name: {
          type: 'text',
          label: '資訊名稱',
          rules: 'required'
        },
        remark: {
          type: 'textarea',
          label: '資訊內容',
        },
      },
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      pageMode: true,
      showFields: [
        'id',
        'name',
        'status',
        'updated_at',
        'created_at'
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      creatableScopes: ['xc_work_type-edit'],
      updatableScopes: ['xc_work_type-edit'],
      deletableScopes: ['xc_work_type-edit'],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'slack_webhook_url',
                'status',
                'slack_team_id',
                'slack_channel_id',
                'invision',
                'zeplin',
                'gitlab',
                'github',
                'google_drive',
                'remark',
                'links',
                'infos',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('編輯'),
              fields: [
                'name',
                'slack_webhook_url',
                'status',
                'slack_team_id',
                'slack_channel_id',
                'invision',
                'zeplin',
                'gitlab',
                'github',
                'google_drive',
                'remark',
                'links',
                'infos',
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'slack_webhook_url',
        'slack_team_id',
        'slack_channel_id',
        'invision',
        'zeplin',
        'gitlab',
        'github',
        'google_drive',
        'remark',
        'links',
        'infos',
      ],
      rightFields: [
        'id',
        'status',
        'name',
        'updated_at',
        'created_at'
      ],
      titleKey: 'name',
    },
  }
}