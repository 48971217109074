<template>
  <ValidationProvider
    :rules="rules"
    v-slot="{ errors }"
    class="ws-state"
    :mode="validationMode"
    :vid="vid"
    :class="[
      arrange,
      {'is-focus':isFocus},
      {'has-content':value},
    ]"
  >
    <div class="ws-state__flex-container">
      <WsText
        v-if="_label"
        class="ws-state__label"
        :size="labelSize"
      >{{_label}} {{labelDes}}<span
          v-if="$_hasRule('required')"
          :style="{color:$color.danger}"
        >*</span></WsText>
      <div class="ws-state__content">
        <WsStateInput
          v-if="type=='text'||type=='password'||type=='email'||type=='link'||type=='number'||type=='currency'"
          ref="WsStateInput"
          @keyup="$_onKeyup"
          :autofocus="autofocus"
          :maxWidth="maxWidth"
          :rules="rules"
          :type="type"
          :name="name"
          :errors="errors"
          :placeholder="placeholder"
          :note="note"
          :isOk="isOk"
          :errorMessage="errorMessage"
          :disabled="disabled"
          :value="value"
          :maxlength="maxlength"
          :stateData="stateData"
          :suffixText="suffixText"
          @onFocus="$_onFocus()"
          @onBlur="$_onBlur()"
          @input="$emit('input',$event)"
          @enter="$emit('submit',$event)"
        ></WsStateInput>
        <WsStateSearch
          v-if="type=='search'"
          ref="WsStateInput"
          @keyup="$_onKeyup"
          :autofocus="autofocus"
          :maxWidth="maxWidth"
          :rules="rules"
          :type="type"
          :name="name"
          :errors="errors"
          :placeholder="placeholder"
          :note="note"
          :isOk="isOk"
          :errorMessage="errorMessage"
          :disabled="disabled"
          :value="value"
          :maxlength="maxlength"
          :stateData="stateData"
          :suffixText="suffixText"
          :searchOnClick="searchOnClick"
          @input="$emit('input',$event)"
          @enter="$emit('submit',$event)"
          @search="$emit('search',$event)"
        ></WsStateSearch>
        <WsStateTextarea
          v-else-if="type=='textarea'"
          :value="value"
          :autoGrow="autoGrow"
          :placeholder="placeholder"
          @input="$emit('input',$event)"
          @keyup="$_onKeyup"
          @onFocus="$_onFocus()"
          @onBlur="$_onBlur()"
          :autofocus="autofocus"
        ></WsStateTextarea>
        <WsStateEditor
          v-else-if="type=='editor'"
          :value="value"
          :getUploadUrl="getUploadUrl"
          :uploadable="uploadable"
          :linkable="linkable"
          :pocketable="pocketable"
          @input="$emit('input',$event)"
        ></WsStateEditor>
        <WsStateModelSelect
          v-else-if="type=='belongsTo'"
          :modelName="modelName"
          :conditions="conditions"
          :disabled="disabled"
          :textKey="textKey"
          :getText="getText"
          :errors="errors"
          :value="value"
          :optionAll="optionAll"
          :note="note"
          :rules="rules"
          :fields="fields"
          :requiredField="requiredField"
          :extendParams="extendParams"
          :placeholder="placeholder"
          :showTags="showTags"
          @input="$emit('input',$event)"
        ></WsStateModelSelect>
        <WsStateTable
          v-else-if="type=='table'"
          :fields="fields"
          :value="value"
          @input="$emit('input',$event)"
        >
        </WsStateTable>
        <WsStateModelSelect
          v-else-if="type=='belongsToMany'"
          :modelName="modelName"
          :conditions="conditions"
          :disabled="disabled"
          :textKey="textKey"
          :getText="getText"
          :errors="errors"
          :value="value"
          :note="note"
          :rules="rules"
          :fields="fields"
          :requiredField="requiredField"
          :extendParams="extendParams"
          :requiredFieldState="requiredFieldState"
          :placeholder="placeholder"
          :showTags="showTags"
          @input="$emit('input',$event)"
          multiple
        ></WsStateModelSelect>
        <WsStateBelongsToManyMultiLayers
          v-else-if="type=='belongs-to-many-multi-layers'"
          :placeholder="placeholder"
          :value="value"
          @input="$emit('input',$event)"
          :layerFields="layerFields"
          :showTags="showTags"
        ></WsStateBelongsToManyMultiLayers>
        <WsStateHasMany
          v-else-if="type=='hasMany'"
          :modelName="modelName"
          :params="params"
          :showFields="_showFields"
          :fields="fields"
          :belongsToKey="belongsToKey"
          :belongsToValue="belongsToValue"
        />
        <WsStateSelectTwoLayer
          v-else-if="type === 'two-layer-select'"
          :value="value"
          outline
          :menu="items"
          :placeholder="placeholder"
          :all-select-text="selectAllText"
          @input="$emit('input',$event)"
        ></WsStateSelectTwoLayer>
        <!-- <WsStateRelationshipSelect
          ref="belongsToState"
          v-else-if="type=='belongsTo'"
          :placeholder="placeholder"
          :modelName="modelName"
          :parentState="parentState"
          :requiredField="requiredField"
          :requiredFieldState="requiredFieldState"
          :conditions="conditions"
          :parent="parent"
          :textKey="textKey"
          :getText="getText"
          :value="value"
          :errors="errors"
          :originValue="originValue"
          @input="$emit('input',$event)"
          :note="note"
          :rules="rules"
          :errorMessage="errorMessage"
        /> -->
        <!-- <WsStateRelationshipSelect
          v-else-if="type=='belongsToMany'"
          :placeholder="placeholder"
          :modelName="modelName"
          :conditions="conditions"
          :rules="rules"
          multiple
          :textKey="textKey"
          :getText="getText"
          :errors="errors"
          :parentState="parentState"
          :requiredField="requiredField"
          :requiredFieldState="requiredFieldState"
          :parent="parent"
          :value="value"
          :originValue="originValue"
          @input="$emit('input',$event)"
        /> -->
        <WsStateSwitch
          v-else-if="type=='switch'"
          :activeText="activeText"
          :inactiveText="inactiveText"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateSwitch>
        <WsStateVersion
          v-else-if="type=='last_version'"
          :fields="fields"
          :value="value"
          :originValue="originValue"
          @input="$emit('input',$event)"
        ></WsStateVersion>
        <WsStateDate
          v-else-if="type=='date'"
          :value="value"
          :startDate="startDate"
          :disabledDate="disabledDate"
          :modelData="stateData"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="$emit('input',$event)"
        ></WsStateDate>
        <WsStateDateRangeOrNot
          v-else-if="type=='date-range-or-not'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateDateRangeOrNot>
        <WsStateDateRange
          v-else-if="type=='date-range'"
          :value="value"
          :startDate="startDate"
          :disabledDate="disabledDate"
          :modelData="stateData"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="$emit('input',$event)"
        ></WsStateDateRange>
        <WsStateDatetime
          v-else-if="type=='datetime'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateDatetime>
        <WsStateTime
          v-else-if="type=='time'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateTime>
        <WsStateLocales
          v-else-if="type=='locales'"
          :fields="fields"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateLocales>
        <WsStatePocketFile
          v-else-if="type=='pocket_file'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :originValue="originValue"
          @input="$emit('input',$event)"
        ></WsStatePocketFile>
        <WsStateFile
          v-else-if="type=='file'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input',$event)"
        ></WsStateFile>
        <WsStateFiles
          v-else-if="type=='files'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input',$event)"
        ></WsStateFiles>
        <!-- <WsStateFile
          v-else-if="type=='file'"
          :value="value"
          :uploadUrl="uploadUrl"
          :parent="parent"
          :parentState="parentState"
          @input="$emit('input',$event)"
        ></WsStateFile>
        <WsStateFiles
          v-else-if="type=='files'"
          :value="value"
          :uploadUrl="uploadUrl"
          :parent="parent"
          :parentState="parentState"
          @input="$emit('input',$event)"
        ></WsStateFiles> -->
        <WsStateTags
          v-else-if="type=='tags'"
          :value="value"
          @input="$emit('input',$event)"
          :placeholder="placeholder"
        ></WsStateTags>
        <WsStateSelect
          v-else-if="type=='select'"
          :items="items"
          :value="value"
          :modelName="modelName"
          :textKey="textKey"
          :valueKey="valueKey"
          :placeholder="placeholder"
          :stateData="stateData"
          :customItems="customItems"
          @input="$emit('input',$event)"
        ></WsStateSelect>
        <WsStateSelect
          v-else-if="type=='multi-select'"
          :items="items"
          :value="value"
          :modelName="modelName"
          :textKey="textKey"
          :valueKey="valueKey"
          multiple
          :selectAll="selectAll"
          :placeholder="placeholder"
          @input="$emit('input',$event)"
        ></WsStateSelect>
        <WsStateSelectMultiLayers
          v-else-if="type=='select-multi-layers'"
          :items="items"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateSelectMultiLayers>
        <WsStateRadio
          v-else-if="type=='radio'"
          :items="items"
          :value="value"
          :nullable="nullable"
          @input="$emit('input',$event)"
        ></WsStateRadio>
        <WsStateRadioForm
          v-else-if="type=='radio-input'"
          :items="items"
          :value="value"
          :stateData="stateData"
          @input="$emit('input',$event)"
          @form-input="$emit('form-input',$event)"
        ></WsStateRadioForm>
        <WsStatePocketImage
          v-else-if="type=='pocket_image'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :originValue="originValue"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input',$event)"
        ></WsStatePocketImage>
        <WsStateImage
          v-else-if="type=='image'"
          :value="value"
          :signed="signed"
          :getUploadUrl="getUploadUrl"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input',$event)"
        ></WsStateImage>
        <WsStateImages
          v-else-if="type=='images'"
          :value="value"
          :signed="signed"
          :previewMaxWidth="previewMaxWidth"
          :previewMaxHeight="previewMaxHeight"
          :getUploadUrl="getUploadUrl"
          :pocketable="pocketable"
          :uploadable="uploadable"
          :linkable="linkable"
          @input="$emit('input',$event)"
        ></WsStateImages>
        <WsStateColor
          v-else-if="type=='color'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateColor>
        <WsStateList
          v-else-if="type=='list'"
          :value="value"
          @input="$emit('input',$event)"
          :fields="fields"
          :showFields="_showFields"
          :label="label"
          :inRowBtnRead="inRowBtnRead"
          :inRowBtnUpdate="inRowBtnUpdate"
          :inRowBtnDelete="inRowBtnDelete"
          :textKey="textKey"
        >
          <template v-slot:extendCols>
            <slot name="extendCols"></slot>
          </template>
        </WsStateList>
        <WsStateFields
          v-else-if="type=='fields'"
          :value="value"
          @input="$emit('input',$event)"
        >
        </WsStateFields>
        <WsStateSort
          v-else-if="type=='sort'"
          :value="value"
          :layerFields="layerFields"
          @input="$emit('input',$event)"
        >
          <template #slick-item="props">
            <slot
              name="slick-item"
              v-bind="props"
            ></slot>
          </template>
        </WsStateSort>
        <WsStateRules
          v-else-if="type=='rules'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateRules>
        <WsStateForm
          v-else-if="type=='form'"
          :fields="fields"
          :value="value"
          @input="$emit('input',$event)"
          :errorMessages="errorMessages"
          :defaultValueOnEmpty="defaultValueOnEmpty"
        ></WsStateForm>
        <WsStateCheckbox
          v-else-if="type=='checkbox'"
          :value="value"
          :text="text"
          @input="$emit('input',$event)"
        ></WsStateCheckbox>
        <WsStateCheckboxs
          v-else-if="type=='checkboxs'"
          :value="value"
          :items="items"
          @input="$emit('input',$event)"
        ></WsStateCheckboxs>
        <WsStateWasaIntro
          v-else-if="type=='wasaintro'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateWasaIntro>
        <WsStateBusinessHours
          v-else-if="type=='business_hours'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateBusinessHours>
        <WsStateAvailableTime
          v-else-if="type=='available_time'"
          :value="value"
          @input="$emit('input',$event)"
        ></WsStateAvailableTime>
        <WsStateListInfinite
          v-else-if="type=='list-infinite'"
          :createBtnText="createBtnText"
          :fields="fields"
          :value="value"
          :creatable="creatable"
          :initValueLength="initValueLength"
          :valueLengthLimit="valueLengthLimit"
          @input="$emit('input',$event)"
        ></WsStateListInfinite>
        <WsStateSpecSettings
          v-else-if="type=='spec_settings'"
          :createBtnText="createBtnText"
          :fields="fields"
          :value="value"
          :creatable="creatable"
          :initValueLength="initValueLength"
          :specSettingItemsModelName="specSettingItemsModelName"
          :valueLengthLimit="valueLengthLimit"
          @input="$emit('input',$event)"
        ></WsStateSpecSettings>
        <WsStateTableList
          v-else-if="type=='specs'"
          :fields="fields"
          :value="value"
          :stateData="stateData"
          :specSettings="specSettings"
          @input="$emit('input',$event)"
        ></WsStateTableList>
        <WsStateConfig
          v-else-if="type=='config'"
          :fields="fields"
          :value="value"
          :label="label"
          :checkable="checkable"
          :exportable="exportable"
          @input="$emit('input',$event)"
        ></WsStateConfig>
        <WsStateBoolean
          v-else-if="type=='boolean'"
          :value="value"
          :label="label"
          @input="$emit('input',$event)"
        ></WsStateBoolean>
        <WsStateYear
          v-else-if="type=='year'"
          :value="value"
          :range="range"
          @input="$emit('input',$event)"
        ></WsStateYear>
        <input
          type="hidden"
          :value="value"
        >
        <WsText
          class="mt-3"
          :size="12"
        >
          {{hint}}
        </WsText>
        <WsErrorMessage
          class="mt-8"
          v-if="errors&&errors.length"
        >{{errors[0]}}</WsErrorMessage>
        <div v-if="errorMessage&&errorMessage.length&&(typeof errorMessage == 'array'||typeof errorMessage == 'object')">
          <WsErrorMessage
            v-for="(errorMessageItem, errorMessageItemIndex) in errorMessage"
            :key="errorMessageItemIndex"
            class="mt-8"
          >{{$t(errorMessageItem)}}</WsErrorMessage>
        </div>
        <WsErrorMessage
          class="mt-8"
          v-else-if="errorMessage"
        >{{$t(errorMessage)}}</WsErrorMessage>
      </div>
    </div>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { extend } from "vee-validate";
import {
  required,
  email,
  min,
  max,
  min_value,
  max_value,
  alpha_num,
  integer,
  is_not,
} from "vee-validate/dist/rules";
import { message } from "@/__vue2stone_cms";
extend("max", {
  ...max,
  // message: "不得多於{length}位"
  message: message["validate-max"],
});
extend("min", {
  ...min,
  // message: "不得少於{length}位"
  message: message["validate-min"],
});
extend("min_value", {
  ...min_value,
  message: "不得少於{min}",
});
extend("max_value", {
  ...max_value,
  message: "不得大於{max}",
});
extend("required", {
  ...required,
  // message: "此項目為必填"
  message: message["validate-required"],
});
extend("email", {
  ...email,
  // message: "email格式不符"
  message: message["validate-email"],
});
extend("alpha_num", {
  ...alpha_num,
  message: "輸入文字必須為英文或數字",
});
extend("integer", {
  ...integer,
  message: "請輸入整數",
});
extend("is_not", {
  ...is_not,
  message: "不可為 {other}",
});
extend("int_or_de", {
  params: ["num"],
  validate: (value, { num = 0 }) => {
    const regex = new RegExp(`^(-)?\\d+(\\.\\d{1,${num}})?$`);
    return regex.test(value);
  },
  message: `請輸入正確的值，整數或小數，小數點後只能後{num}位`,
});

export default {
  name: "WsState",
  data: () => ({
    isFocus: false,
  }),
  components: {
    ValidationProvider,
  },
  computed: {
    _label() {
      if (this.displayLabel) {
        if (this.labelInLocale) {
          return this.$t(this.label);
        } else if (this.type == "config") {
          return null;
        } else if (this.type == "boolean") {
          return null;
        } else {
          return this.$t(this.label);
        }
      } else {
        return null;
      }
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else if (this.fields) {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      } else {
        return [];
      }
    },
  },
  methods: {
    $_hasRule(rule) {
      if (!this.rules) {
        return false;
      }
      const _rules = this.rules.split("|");
      const exist = _rules.find((e) => {
        return e == rule;
      });
      return exist;
    },
    $_onFocus() {
      this.isFocus = true;
    },
    $_onBlur() {
      this.isFocus = false;
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
    focus() {
      if (
        this.type == "text" ||
        this.type == "email" ||
        this.type == "search" ||
        this.type == "password"
      ) {
        if (this.$refs.WsStateInput) {
          this.$refs.WsStateInput.focus();
        }
      }
    },
  },
  mounted() {
    if (this.autofocus) {
      if (this.$refs.WsStateInput) {
        this.$refs.WsStateInput.$el.focus();
      }
    }
    if (this.value == undefined && this.defaultValue != undefined) {
      this.$emit("input", this.defaultValue);
    }
  },
  props: {
    optionAll: {
      type: Boolean,
    },
    vid: {
      type: String,
    },
    extendParams: {
      type: Object,
    },
    labelDes: {
      type: String,
    },
    startDate: {
      type: String,
    },
    belongsToKey: {
      type: String,
    },
    belongsToValue: {},
    params: {
      type: Object,
    },
    defaultValueOnEmpty: {},
    arrange: {
      type: String,
      default: "column",
    },
    range: {
      type: Array,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    showFields: {
      type: Array,
      default: null,
    },
    originValue: {},
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getText: {
      type: Function,
      default: null,
    },
    textKey: {
      type: String,
    },
    requiredFieldState: {},
    requiredField: {
      type: String,
      default: null,
    },
    autoGrow: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
      default: null,
    },
    uploadUrl: {
      type: String,
      default: null,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
    },
    maxHeight: {
      type: String,
    },
    previewMaxWidth: {
      type: String,
    },
    previewMaxHeight: {
      type: String,
    },
    fields: {
      type: [Object, Array],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: [String, Array],
      default: null,
    },
    errorMessages: {
      type: [Object, String],
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: String,
      default: null,
    },
    rules: {
      type: [String, Array],
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "default",
    },
    staticUrl: {
      type: Boolean,
      default: false,
    },
    postData: {
      type: Object,
      default: null,
    },
    requestMethod: {
      type: String,
      default: "put",
    },
    labelSize: {
      type: [String, Number],
      default: 14,
    },
    valueKey: {
      type: String,
    },
    text: {
      type: String,
    },
    validationMode: {
      type: String,
      default: "passive",
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: [Number, String],
    },
    stateData: {
      type: Object,
    },
    customItems: {
      type: Function,
    },
    allSelectText: {
      type: String,
    },
    selectAll: {
      type: Boolean,
    },
    hasCountryCode: {
      type: Boolean,
    },
    disabledDate: {
      type: Function,
    },
    createBtnText: {
      type: String,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    initValueLength: {
      type: Number,
      default: 1,
    },
    valueLengthLimit: {
      type: Number,
    },
    specSettings: {
      type: Array,
    },
    specSettingItemsModelName: {
      type: String,
    },
    hint: {
      type: String,
    },
    layerFields: {
      type: Array,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
    },
    defaultValue: {},
    suffixText: {
      type: String,
    },
    searchOnClick: {
      type: Boolean,
    },
    nullable: {
      type: Boolean,
    },
  },
};
</script>