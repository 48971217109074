import config from '@/__config'
export default {
  modelName: config.auth.admin_blur ? 'cmser_role' : "admin_role",
  label: "管理系統權限角色",
  fields: {
    id: {
      type: "text",
      title: 'ID',
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
    is_default: {
      type: 'switch',
      label: '預設角色',
      activeText: '是',
      inactiveText: '否',
      readonly: true
    },
    scopes: {
      type: 'multi-select',
      label: 'Scopes',
      items: config.auth.scope.list
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      textKey: "name",
      readonly: true,
    },
  },
}
