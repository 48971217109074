<template>
  <img
    class="ws-info-image"
    v-if="value"
    v-lazy="value"
    alt=""
    :style="`max-width:${previewMaxWidth};max-height:${previewMaxHeight}`"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
  },
};
</script>

<style>
</style>