<template>
  <div>
    <div v-if="value&&value.length">
      <div
        v-for="(valueItem, valueItemIndex) in value"
        :key="valueItemIndex"
        class="ws-state-image"
      >
        <img
          v-lazy="valueItem"
          alt=""
          class="mb-10"
          :style="`max-width:${previewMaxWidth};max-height:${previewMaxHeight}`"
        >
        <WsIconBtn
          @click="$_onClear(valueItemIndex)"
          class="remove-btn"
          backgroundColor="#000"
          name="icon-md-delete"
        />
      </div>
    </div>
    <WsBtn @click="$_onPickDialogOpen()">{{$t('pick_image')}}</WsBtn>
    <WsPocketImagePicker
      @submit="$_onPocketImageSubmit($event)"
      v-model="dialog"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      multiple
      :pocketable="pocketable"
      :uploadable="uploadable"
      :linkable="linkable"
    ></WsPocketImagePicker>
  </div>
</template>

<script>
import PocketImageService from "@/__vue2stone_cms/service/api/v1/pocket_image";
export default {
  data: () => ({
    dialog: false,
    fetchedItems: [],
  }),

  computed: {},

  methods: {
    $_onClear(valueItemIndex) {
      const _value = [...this.value];
      _value.splice(valueItemIndex, 1);
      this.$emit("input", _value);
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    $_onPocketImageSubmit($event) {
      const _value = this.value ? [...this.value] : [];
      $event.forEach((valueItem) => {
        _value.push(PocketImageService.getSrc(valueItem));
      });
      this.$emit("input", _value);
    },
  },

  props: {
    value: {
      type: Array,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
    },
    pocketable: {
      type: Boolean,
    },
    uploadable: {
      type: Boolean,
    },
    linkable: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>