export default {
  modelName: 'shop_return_record',
  label: '商品退貨紀錄',
  fields: {
    created_at: {
      type: 'datetime',
      label: '退貨日期',
    },
    updated_at: {
      type: 'datetime',
      label: '更新退貨日期',
    },
    count: {
      type: 'number',
      label: '退訂數量',
    },
    remark: {
      type: 'textarea',
      label: '退訂備註',
    },
    price: {
      type: 'number',
      label: '退訂價錢',
    },
    return_reason: {
      type: 'textarea',
      label: '退訂原因',
    },
    shop_order: {
      type: 'shop_order',
      label: '訂單',
      fields: {
        id: {
          label: 'id',
          type: 'number',
        },
      },
    },
    shop_order_shop_product: {
      type: 'shop_order_shop_product',
      fields: {
        no: {
          label: '商品編號',
          type: 'number',
        },
        name: {
          label: '商品名稱',
          type: 'text',
        },
        spec: {
          label: '規格',
          type: 'text',
        },
        weight_capacity: {
          label: '商品重量',
          type: 'number',
        },
        price: {
          label: '單價',
          type: 'number',
        },
        count: {
          type: 'number',
          label: '退訂數量',
        },
      },
    },
  },
  leftFields: ['return_reason', 'remark', 'count'],
};
