<template>
  <div
    class="ws-state-select"
    :class="[{active:menuActive},{'is-error':errors&&errors.length},{'is-disabled':_disabled},_type]"
    v-click-outside="$_onClickOutside"
  >
    <WsBtn
      class="w-full"
      maxWidth="unset"
      outlined
      @click="$_onOpenClick()"
    >
      <WsFlex
        class="w-full"
        justifyContent="space-between"
        alignItems="center"
      >
        <template v-if="isEmitItem">
          <WsText
            class="w-full"
            size="14"
          >{{placeholder}}</WsText>
        </template>
        <template v-else>
          <WsText
            v-if="_hasValue"
            class="w-full"
            size="14"
          >{{_valueText}}</WsText>
          <WsDes
            v-else
            size="14"
          >{{placeholder}}</WsDes>
        </template>
        <WsIcon name="icon-md-arrow-drop-down" />
      </WsFlex>
    </WsBtn>
    <div
      v-if="menuActive"
      class="ws-state-select__menu"
    >
      <div class="ws-state-select__options">
        <div
          v-if="selectAll&&this.items.length>0&&multiple"
          class="ws-state-select__options__all-btn"
        >
          <a
            @click.prevent="$_selectAll()"
            href="#"
          >
            <div class="checkbox">
              <WsIcon
                :name="_allBtnIcon"
                class="active"
              />
              <WsText size="14">{{$t(selectAllText)}}</WsText>
            </div>
          </a>
        </div>
        <a
          v-for="(item,itemIndex) in _items"
          :key="itemIndex"
          href="#"
          @click.prevent="$_onOptionClick(item)"
          :class="{active:$_activeCheck(item)}"
        >
          <div
            v-show="multiple"
            class="checkbox"
          >
            <WsIcon
              :class="{active:$_activeCheck(item)}"
              name="icon-md-check-box"
            />
            <WsIcon
              :class="{active:!$_activeCheck(item)}"
              name="icon-md-check-box-outline-blank"
            />
          </div>
          <WsText size="14">{{item[textKey]}}</WsText>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    menuActive: false,
    loading: false,
    modelDatas: [],
  }),
  methods: {
    $_onOpenClick() {
      this.menuActive = !this.menuActive;
    },
    $_activeCheck(item) {
      if (!this.value) {
        return;
      }
      if (this.multiple) {
        return this.value.includes(item.value);
      } else {
        return this.value == item.value;
      }
    },
    $_onOptionClick(item) {
      if (this.multiple) {
        const _value = Array.isArray(this.value)
          ? JSON.parse(JSON.stringify(this.value))
          : [];
        const itemIndex = _value.findIndex((e) => {
          return e == item.value;
        });
        if (itemIndex >= 0) {
          _value.splice(itemIndex, 1);
        } else {
          _value.push(item.value);
        }
        this.$emit("input", _value);
      } else {
        this.$emit("input", item.value);
        this.menuActive = false;
      }
    },
    $_onClickOutside() {
      this.menuActive = false;
    },
    async $_setItemsFromModel() {
      this.loading = true;
      let getUrl = `/${this.modelName}`;
      const params = {
        order_by: "updated_at",
        order_way: "desc",
      };
      try {
        const res = await this.$axios.get(getUrl, {
          params: params,
        });
        if (res.data.meta) {
          this.lastPage = res.data.meta.last_page;
        } else {
          this.fetchAll = true;
        }
        this.modelDatas.push(...res.data.data);
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    $_selectAll() {
      if (!this._items.length) return;
      if (
        !this.value ||
        !this.value.length ||
        this.value.length < this._items.length
      ) {
        this.$emit("input", this._allItemsValue);
      } else {
        this.$emit("input", []);
      }
    },
  },
  computed: {
    _items() {
      if (this.items) {
        if (this.customItems) {
          return this.customItems(this.stateData);
        } else {
          if (this.items.length) {
            if (typeof this.items[0] == "string") {
              const _items = [];
              this.items.forEach((item) => {
                _items.push({
                  value: item,
                  text: item,
                });
              });
              return _items;
            } else {
              return this.items;
            }
          } else {
            return [];
          }
        }
      } else {
        const _items = [];
        this.modelDatas.forEach((modelData) => {
          _items.push({
            text: modelData[this.textKey],
            value: modelData[this.valueKey],
          });
        });
        return _items;
      }
    },
    _type() {
      if (this.light) {
        return "light";
      } else {
        return "round";
      }
    },
    _disabled() {
      if (this.parent && !this.parentState) {
        return true;
      } else if (
        this.requiredField &&
        (!this.requiredFieldState || this.requiredFieldState.length == 0)
      ) {
        return true;
      } else {
        return false;
      }
    },
    _valueText() {
      if (this.value === undefined) {
        return null;
      } else {
        if (this.multiple) {
          let _valueText = "";
          if (!Array.isArray(this.value)) {
            return _valueText;
          }
          this.value.forEach((valueItem) => {
            const tarItem = this._items.find((e) => {
              return e[this.valueKey] == valueItem;
            });
            if (tarItem) {
              if (_valueText) {
                _valueText += `, ${tarItem[this.textKey]}`;
              } else {
                _valueText += tarItem[this.textKey];
              }
            }
          });
          return _valueText;
        } else {
          const tarItem = this._items.find((e) => {
            return e[this.valueKey] == this.value;
          });
          if (!tarItem) {
            return null;
          } else {
            return tarItem[this.textKey];
          }
        }
      }
    },
    _allItemsValue() {
      if (this._items.length) {
        return this._items.map((item) => {
          return item.value;
        });
      } else {
        return null;
      }
    },
    _hasValue() {
      if (this.multiple) {
        if (this.value && this.value.length) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.value || this.value == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    _allBtnIcon() {
      if (
        !this._items ||
        !this._items.length ||
        !this.value ||
        !this.value.length
      ) {
        return "icon-md-check-box-outline-blank";
      }
      if (this._items.length === this.value.length) {
        return "icon-md-check-box";
      } else if (
        this.value.length > 0 &&
        this._items.length > this.value.length
      ) {
        return "icon-ws-filled-indeterminate-check-box";
      } else {
        return "icon-md-check-box-outline-blank";
      }
    },
  },
  mounted() {
    if (!this.items && this.modelName) {
      this.$_setItemsFromModel();
    }
  },
  props: {
    menuBackgroundColor: {
      type: String,
    },
    light: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: true,
    },
    placeholder: {
      type: String,
      default: "select",
    },
    value: {
      type: [Number, String, Array, RegExp],
      default: null,
    },
    items: {
      type: Array,
    },
    requiredField: {
      default: null,
    },
    requiredFieldState: {
      default: null,
    },
    modelName: {
      type: String,
    },
    textKey: {
      type: String,
      default: "text",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    isEmitItem: {
      type: Boolean,
      default: false,
    },
    stateData: {
      type: Object,
    },
    customItems: {
      type: Function,
    },
    selectAllText: {
      type: String,
      default: "全部",
    },
    selectAll: {
      type: Boolean,
      default: false,
    },
  },
};
</script>