<template>
  <div class="ws-state-radio-form">
    <div
      v-for="(item,index) in items"
      :key="index"
    >
      <input
        type="radio"
        :id="item.value"
        :value="item.value"
        :checked="$_value(item.value)"
        @input="$_onInput"
      >
      <label :for="item.value">
        <span>{{item.text}}</span>
      </label>
      <WsStateForm
        v-if="item.fields"
        :fields="item.fields"
        :value="stateData"
        :isDisabled="!$_value(item.value)"
        @input="$_onStateFormInput($event)"
      ></WsStateForm>
    </div>

  </div>
</template>

<script>
export default {
  name: "WsStateRadioForm",
  props: {
    items: {
      type: Array,
    },
    value: {
      type: [Boolean, String, Number],
    },
    stateData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    $_value(item) {
      if (!this.value && this.value !== 0) return false;
      if (this.value === item) return true;
      return false;
    },
    $_onInput($event) {
      this.$emit("input", $event.target.value);
    },
    $_onStateFormInput($event) {
      this.$emit("form-input", $event);
    },
  },
};
</script>