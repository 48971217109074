import config from '@/__config';

const model = {
  modelName: 'service_store',
  label: '服務中心',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: '名稱',
      autofocus: true,
      rules: 'required',
      labelInLocale: true,
      maxlength: 50,
    },
    appointment_availables: {
      type: 'available_time',
      label: '可預約時段',
    },
    cover_image: {
      type: 'image',
      label: '圖片',
      labelDes: '(488*366)',
      labelInLocale: true,
      signed: false,
    },
    tel: {
      type: 'text',
      labelInLocale: true,
      label: '電話',
    },
    address: {
      type: 'text',
      labelInLocale: true,
      label: '地址',
    },
    des: {
      type: 'textarea',
      labelInLocale: true,
      label: '說明',
    },
    // parking_info: {
    //   type: 'textarea',
    //   labelInLocale: true,
    //   label: '停車資訊'
    // },
    // parking_link: {
    //   type: 'link',
    //   labelInLocale: true,
    //   label: '停車場連結(建議Google Map)'
    // },
    parking_infos: {
      type: 'list',
      label: '停車資訊 (多筆)',
      labelInLocale: true,
      fields: {
        info: {
          type: 'textarea',
          labelInLocale: true,
          label: '停車資訊',
        },
        link: {
          type: 'link',
          labelInLocale: true,
          label: '停車場連結(建議Google Map)',
        },
      },
    },
    parking_image: {
      type: 'image',
      label: '停車場示意圖',
      labelDes: '(488*366)',
      labelInLocale: true,
      signed: false,
    },
    transportation_info: {
      type: 'textarea',
      labelInLocale: true,
      label: '交通資訊',
    },
    business_hours: {
      type: 'business_hours',
      labelInLocale: true,
      label: '營業時間',
    },
    lat: {
      type: 'number',
      labelInLocale: true,
      label: '經度',
    },
    lng: {
      type: 'number',
      labelInLocale: true,
      label: '緯度',
    },
    is_active: {
      type: 'switch',
      label: 'is_active',
      activeText: 'Yes',
      inactiveText: 'No',
      defaultValue: true,
      labelInLocale: true,
    },
    work_on_holiday: {
      type: 'switch',
      label: '六日營業',
      defaultValue: 0,
      labelInLocale: true,
    },
    service_at_night: {
      type: 'switch',
      label: '夜間服務',
      defaultValue: 0,
      labelInLocale: true,
    },
    notify_emails: {
      type: 'list',
      label: '通知信箱 (多筆)',
      labelInLocale: true,
      fields: {
        email: {
          type: 'email',
          labelInLocale: true,
          label: 'Email',
        },
        remark: {
          type: 'textarea',
          labelInLocale: true,
          label: 'remark',
        },
      },
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    updated_admin: {
      type: 'belongsTo',
      label: '最後更新人',
      readonly: true,
      textKey: 'name',
      modeldataName: 'admin',
    },
  },
};

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'cmser_group',
      labelInLocale: true,
    };
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'admin_group',
      labelInLocale: true,
    };
  }
}

if (config.wsmodule.area) {
  model.fields.area = {
    type: 'belongsTo',
    label: '地區',
    modelName: 'area',
    labelInLocale: true,
  };
}

if (config.wsmodule.country_code) {
  model.fields.country_code = {
    type: 'select',
    label: '國家/地區代碼',
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  };
}

// if (config.wsmodule.service_store.appointment) {
//   if (config.wsmodule.service_store.appointment.notify) {
//     if (config.wsmodule.service_store.appointment.notify.today_appointments) {
//       model.fields.today_appointments_notify_time = {
//         type: 'time',
//         label: '今日預約通知時間',
//       };
//     }
//     if (config.wsmodule.service_store.appointment.notify.tomorrow_appointments) {
//       model.fields.tomorrow_appointments_notify_time = {
//         type: 'time',
//         label: '今日預約通知時間',
//       };
//     }
//   }
// }

export default model;
