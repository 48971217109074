export default {
  modelName: "user_service_plan_record",
  label: "會員方案項目使用紀錄",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    count: {
      type: 'number',
      label: '使用數量'
    },
    remark: {
      type: 'textarea',
      label: '備註'
    },
    user: {
      type: "belongsTo",
      label: "會員",
      labelInLocale: true,
      textKey: "name",
      relationPopup: true,
      readonly: true,
      modelName: "user"
    },
    service_plan: {
      type: "belongsTo",
      label: "服務方案",
      labelInLocale: true,
      textKey: "name",
      relationPopup: true,
      readonly: true,
      modelName: "service_plan"
    },
    service_plan_item: {
      type: "belongsTo",
      label: "服務方案項目",
      labelInLocale: true,
      textKey: "name",
      relationPopup: true,
      readonly: true,
      modelName: "service_plan_item"
    },
    user_service_plan: {
      type: "belongsTo",
      label: "會員綁定方案",
      labelInLocale: true,
      textKey: "name",
      relationPopup: true,
      readonly: true,
      modelName: "user_service_plan"
    },
    user_service_plan_item: {
      type: "belongsTo",
      label: "會員綁定方案項目",
      labelInLocale: true,
      textKey: "name",
      relationPopup: true,
      readonly: true,
      modelName: "user_service_plan"
    },
    admin: {
      type: "belongsTo",
      label: "後台編輯人員",
      labelInLocale: true,
      textKey: "name",
      readonly: true,
      modelName: "admin"
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
}
