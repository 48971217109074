import S_APP_Shop from "@/__vue2stone_cms/service/app/shop";

const model = {
  modelName: 'shop_free_shipping',
  label: '免運金額',
  fields: {
    name: {
      type: 'text',
      label: '免運名稱',
      placeholder: '輸入...',
    },
    price: {
      type: 'number',
      label: '免運金額',
      placeholder: '0000',
    },
    start_date: {
      type: 'date',
      label: '起始日期',
      placeholder: '年.月.日',
      rules: 'required',
      disabledDate: function (data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: '結束日期',
      placeholder: '年.月.日',
      rules: 'required',
      disabledDate: function (data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    order_type: {
      type: 'select',
      label: '訂單類型',
      rules: 'required',
      items: S_APP_Shop.getOrderTypeItems()
    },
    condition: {
      label: '指定對象',
      type: 'select',
      items: [
        {
          text: '首購',
          value: 'first-purchase',
        },
        {
          text: '不限',
          value: 'null',
        },
      ],
      placeholder: '請選擇指定對象',
      rules: 'required',
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['order_type', 'name', 'price', 'start_date', 'end_date'],
      infiniteScroll: false,
      inRowBtnRead: true,
      pageMode: true,
    },
    read: {
      leftFields: [
        'order_type',
        'name',
        'price',
        'is_no_limited',
        'start_date',
        'end_date',
        'condition',
      ],
      rightFields: [],
      titleKey: 'name',
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'order_type',
                'name',
                'price',
                'is_no_limited',
                'start_date',
                'end_date',
                'condition',
              ],
            },
          ],
        },
      ],
    },
    update: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'order_type',
                'name',
                'price',
                'is_no_limited',
                'start_date',
                'end_date',
                'condition',
              ],
            },
          ],
        },
      ],
    },
  },
};

export default model;
