<template>
  <div
    class="ws-btn"
    :class="[
      {'is-focus':isFocus},
      {outlined:outlined},
      {small:small},
      {alert:alert},
      {full:full},
      {disabled:disabled},
      {verybig:verybig}
    ]"
    :style="[
      {'min-width':`${minWidth}px`},
      {'max-width':`${_maxWidth}`},
      {'min-height':minHeight},
      {'background-color':_color},
      {'has-hover-effect':hasHoverEffect},
      {'border-width':_borderWidth},
      {'border-color':_borderColor}
    ]"
  >
    <WsLoading v-if="loading"></WsLoading>
    <component
      v-else
      :is="_component"
      :type="_type"
      @click="onClick()"
      class="ws-btn__button"
      @mouseover="onMouseover()"
      @focus="$_onFocus"
      @blur="$_onBlur"
      :class="[{small:small}]"
      :disabled="disabled"
      :to="to"
      :style="[{color:textColor}]"
    >
      <slot></slot>
    </component>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isFocus: false,
    };
  },
  methods: {
    $_onFocus() {
      this.isFocus = true;
    },
    $_onBlur() {
      this.isFocus = false;
    },
    onClick() {
      this.$emit("click");
    },
    onMouseover() {
      this.$emit("mouseover");
    },
  },

  computed: {
    _type() {
      if (this.type) {
        return this.type;
      }
      if (this._component == "button") {
        return "button";
      }
      return null;
    },
    _borderColor() {
      if (this.outlined) {
        return this.color;
      } else {
        return this.borderColor;
      }
    },
    _color() {
      if (this.outlined) {
        return null;
      } else {
        return this.color;
      }
    },
    _borderWidth() {
      if (this.outlined) {
        return "1px";
      } else {
        return null;
      }
    },
    _maxWidth() {
      if (this.full) {
        return "unset";
      } else {
        return `${this.maxWidth}px`;
      }
    },
    _component() {
      if (this.to) {
        return "RouterLink";
      } else {
        return "button";
      }
    },
  },

  props: {
    borderColor: {
      type: String,
    },
    minWidth: {
      type: [Number, String],
      default: 80,
    },
    maxWidth: {
      type: [Number, String],
      default: 200,
    },
    hasHoverEffect: {
      type: Boolean,
      default: true,
    },
    verybig: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    href: {
      type: String,
      default: null,
    },
    target: {
      type: String,
      default: null,
    },
    to: {
      type: [String, Object],
      default: null,
    },
    minHeight: {
      type: String,
      default: null,
    },
    color: {
      type: String,
    },
    textColor: {
      type: String,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    alert: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    block: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>