import axios from 'axios';
export default {
  async index(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('shop_order', {
          params: params
        })
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async index_print(params) {
    return new Promise((resolve, reject) => {
      axios
        .get('shop_order/print/pick', {
          params: params
        })
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async show(id) {
    return new Promise((resolve, reject) => {
      axios.get(`/shop_order/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async update(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/shop_order/${id}`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async cancel(id, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/shop_order/${id}/cancel`, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async return_cancel(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/shop_order/${id}/return/cancel`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  async export_excel({
    selected,
    params,
  }) {
    const url = `/shop_order/export/excel/signedurl`;
    let _params = {
      ...params
    };
    if (selected.length) {
      _params.ids = selected.map((item) => item.id).toString();
    } else {
      _params.get_all = 1;
    }
    try {
      const res = await axios.get(url, {
        params: _params
      });
      window.open(res.data, "_blank");
    } catch (err) {
      alert(err);
    }
  },
  async export_prd({
    selected
  }) {
    const url = `/shop_order/export/pdf/signedurl`;
    let params = {};
    if (selected.length) {
      params.shop_orders = selected.map((item) => item.id).toString();
    } else {
      params.get_all = 1;
    }
    try {
      const res = await axios.get(url, { params });
      window.open(res.data, "_blank");
    } catch (err) {
      alert(err);
    }
  },
  async batch_update(ids, data) {
    const url = `/shop_order/batch`;
    try {
      const res = await axios.patch(url, {
        ...data,
        ids: ids
      });
      return res.data.data
    } catch (error) {
      console.error(error);
    }
  },
  async re_create_invoice(id) {
    const url = `/shop_order/${id}/re_create_invice`;
    try {
      await axios.post(url);
    } catch (error) {
      console.error(error);
    }
  }
};
