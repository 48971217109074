<template>
  <span class="ws-state-sort">
    <WsBtn
      class="ml-10"
      @click="$_onSortOpen()"
    >{{$t('adjust_the_order')}}</WsBtn>
    <WsSortDialog
      ref="WsSortDialog"
      @input="$emit('input',$event)"
      :value="value"
      :textKey="textKey"
    ></WsSortDialog>
  </span>
</template>

<script>
export default {
  methods: {
    $_onSortOpen() {
      this.$refs.WsSortDialog.open();
    },
  },

  props: {
    value: {
      type: Array,
      default: null,
    },
    textKey: {
      type: String,
      default: "name",
    },
  },
};
</script>