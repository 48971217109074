// import config from '@/__config'
import i18n from '@/i18n';

const model = {
  modelName: "download_info",
  label: "下載資訊",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      labelInLocale: true,
    },
    url: {
      type: 'file',
      label: '檔案',
      // signed: false,
    },
    year: {
      type: 'year',
      label: '年份',
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      showFields: [
        'id',
        'name',
        'year',
        'url',
      ],
      pageMode: true
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('新增'),
              fields: [
                'name',
                'year',
                'url',
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('編輯'),
              fields: [
                'name',
                'year',
                'url',
              ],
            },
          ],
        },
      ],
    },
    read: {

    }
  }
}

export default model