import config from '@/__config'

const model = {
  modelName: "acumatica_app",
  label: "Acumatica App",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    client_id: {
      type: 'text',
      label: 'Client ID'
    },
    client_secret: {
      type: 'text',
      label: 'Client Secret'
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        "id",
        "client_id",
        "client_secret",
        "country_code",
      ],
      infiniteScroll: true,
      pageMode: true,
    },
  },
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
}

export default model