export default {
  modelName: "otp",
  label: "OTP",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    is_active: {
      type: 'switch',
      label: '啟用'
    },
    content: {
      type: 'text',
      label: '內容'
    },
    usage: {
      type: 'text',
      label: '用途'
    },
    expired_at: {
      type: 'datetime',
      label: '過期時間'
    },
    user: {
      type: "belongsTo",
      label: "使用者",
      textKey: "name",
      modelName: 'user',
      readonly: true,
      relationPopup: true

    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      pageMode: true,
      showFields: [
        'id',
        'is_active',
        'content',
        'usage',
        'expired_at',
        'user',
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      // creatableScopes: ['app_developer-read'],
      // updatableScopes: ['app_developer-read'],
      // deletableScopes: ['app_developer-read'],
    },
    read: {
      leftFields: [
        'content',
        'usage',
        'expired_at',
        'user',
      ],
      rightFields: [
        'id',
        'is_active',
      ],
      titleKey: 'content',
    },
  }
}
