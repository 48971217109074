import config from '@/__config'

const model = {
  modelName: "system_class",
  label: "領域",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序",
      maxlength: 10
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modeldataName: "admin"
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ["id", "name"],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true
    }
  }
}

if (config.wsmodule.area) {
  model.fields.area = {
    type: "belongsTo",
    label: "適用地區",
    textKey: "name",
    modelName: "area",
  }
  model.crudSetting.index.showFields.push('area')
}

export default model