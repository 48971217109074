<template>
  <div>
    <WsDialogCRUDUpdate
      ref="WsDialogCRUDUpdate"
      :title="$t('申請全部退訂')"
      :fields="_fields"
      modelName="shop_order"
      v-model="state"
      @modelDataInit="$_onModelDataInit($event)"
      @submit="$_onModelDataSubmit($event)"
      @close="$_onDialogClose()"
      emitInput
      emitSubmit
    >
      <template #extend-content>
        <WsCrud
          class="mt-40"
          v-if="modelData"
          :label="$t('商品資訊')"
          :fields="models.shop_order_shop_product.fields"
          :modelName="models.shop_order_shop_product.modelName"
          :showFields="models.shop_order_shop_product.showFields"
          :hasFilter="false"
          inRowBtnRead
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
          :creatable="false"
          :params="_shopOrderShopProductParams"
          :expandable="false"
          @input="$_onShopOrderShopProductInput($event)"
        >
        </WsCrud>
      </template>
    </WsDialogCRUDUpdate>

  </div>
</template>

<script>
import S_ShopOrder from "@/__vue2stone_cms/service/api/v1/shop_order";
export default {
  methods: {
    $_getReturnPriceFromShopOrderShopProducts(shopOrderShopProducts) {
      if (!shopOrderShopProducts) {
        return 0;
      }
      let returnPrice = 0;
      shopOrderShopProducts.forEach((shopOrderShopProducts) => {
        returnPrice +=
          shopOrderShopProducts.price * shopOrderShopProducts.count;
      });
      return returnPrice;
    },
    $_onShopOrderShopProductInput($event) {
      this.shopOrderShopProducts = $event;
    },
    $_clear() {
      this.state = {};
      this.modelData = null;
    },
    $_onDialogClose() {
      this.$_clear();
    },
    async $_onModelDataSubmit($event) {
      this.$refs.WsDialogCRUDUpdate.loadingSubmitStart();
      const res = await S_ShopOrder.cancel(this.modelData.id, $event);
      this.$refs.WsDialogCRUDUpdate.loadingSubmitStop();
      this.$refs.WsDialogCRUDUpdate.close();
      this.$emit("complete", res.data);
    },
    $_onModelDataInit(shopOrder) {
      this.modelData = shopOrder;
    },
    open(id) {
      this.$refs.WsDialogCRUDUpdate.open(id);
    },
  },
  computed: {
    _fields() {
      const showFields = [
        "products_price",
        "shop_product_campaign_deduct",
        "bonus_points_deduct",
        "campaign_deduct",
        "invite_no_deduct",
        "freight",
        "order_price",
        "pay_type",
        "pay_at",
        "bonus_points",
      ];
      const _fields = {
        ...this.fields,
      };
      showFields.forEach((showField) => {
        _fields[showField] = {
          ...this.$store.state.stone_model.shop_order.fields[showField],
          info: true,
        };
      });
      _fields.return_cost = {
        type: "custom",
        label: "退訂小計",
        info: true,
        getValue: () => {
          let _return_cost = 0;
          this.shopOrderShopProducts.forEach((shopOrderShopProduct) => {
            _return_cost +=
              shopOrderShopProduct.count * shopOrderShopProduct.price;
          });
          return _return_cost;
        },
      };
      return _fields;
    },
    _shopOrderShopProductParams() {
      return {
        shop_order: this.modelData.id,
      };
    },
  },
  watch: {
    shopOrderShopProducts: {
      handler() {
        if (!this.shopOrderShopProducts) {
          return;
        }
        setTimeout(() => {
          this.state.return_price = this.modelData.order_price;
          // this.$_getReturnPriceFromShopOrderShopProducts(
          //   this.shopOrderShopProducts
          // );
        }, 100);
      },
    },
  },
  data() {
    return {
      models: {
        shop_order_shop_product: {
          showFields: ["name", "shop_campaigns_name", "count", "price"],
          ...this.$store.state.stone_model.shop_order_shop_product,
        },
      },
      state: {},
      modelData: null,
      shopOrderShopProducts: [],
      fields: {
        return_reason: {
          type: "select",
          label: "退貨原因",
          placeholder: "選擇退貨原因",
          items: [
            {
              text: this.$t("欠品"),
              value: "欠品",
            },
            {
              text: this.$t("貨物狀況不佳"),
              value: "貨物狀況不佳",
            },
          ],
          rules: "required",
          col: 12,
        },
        return_remark: {
          type: "textarea",
          label: "退訂備註",
          placeholder: "輸入...",
          rules: "required",
          col: 12,
        },
        return_price: {
          type: "number",
          label: "退訂金額",
          placeholder: "輸入...",
          rules: "required",
          defaultValue: 0,
          col: 12,
        },
      },
    };
  },
};
</script>

<style>
</style>