import config from '@/__config';
import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_product',
  label: i18n.t('商品'),
  fields: {
    id: {
      type: 'text',
      label: i18n.t('商品ID'),
      readonly: true,
    },
    uuid: {
      type: 'text',
      label: i18n.t('商品UUID'),
      readonly: true,
    },
    no: {
      type: 'text',
      label: i18n.t('商品編號'),
      rules: 'required',
      width: '140px',
    },
    name: {
      type: 'text',
      label: i18n.t('商品名稱'),
      rules: 'required',
      autofocus: true,
      width: '250px',
    },
    subtitle: {
      type: 'text',
      label: i18n.t('商品副標'),
    },
    cost: {
      type: 'number',
      label: i18n.t('成本'),
      rules: 'required|min_value:0|int_or_de:1|is_not:0',
      updatable: {
        getAlertContent: function () {
          return `確認要變更成本嗎？`;
        },
      },
      col: 3,
    },
    price: {
      type: 'number',
      label: i18n.t('售價'),
      rules: 'required|min_value:1|integer',
      updatable: {
        getAlertContent: function () {
          return `確認要變更售價嗎？`;
        },
      },
      col: 3,
    },
    tax: {
      type: 'switch',
      label: i18n.t('應稅/免稅'),
      activeText: i18n.t('應稅'),
      inactiveText: i18n.t('免稅'),
      defaultValue: true,
      col: 6,
    },
    stock_count: {
      type: 'number',
      label: i18n.t('庫存數量'),
      rules: 'required|min_value:0|integer',
      updatable: {
        getAlertContent: function () {
          return `確認要變更庫存數量嗎？`;
        },
      },
      col: 3,
    },
    stock_alert_count: {
      type: 'number',
      label: i18n.t('庫存預警數量'),
      rules: 'required|min_value:0|integer',
      updatable: {
        getAlertContent: function () {
          return `確認要變更庫存預警數量嗎？`;
        },
      },
      col: 3,
    },
    max_buyable_count: {
      type: 'number',
      label: i18n.t('最大可購買數量'),
      rules: 'required|min_value:1|integer',
      col: 4,
    },
    storage_space: {
      type: 'text',
      label: i18n.t('商品儲位'),
      rules: 'required|alpha_num',
      col: 3,
    },
    store_temperature: {
      label: i18n.t('溫層'),
      type: 'select',
      placeholder: i18n.t('請選擇溫層'),
      items: [
        {
          text: '冷凍',
          value: 'freezing',
        },
        {
          text: '常溫',
          value: 'normal',
        },
        {
          text: '低溫',
          value: 'cold',
        },
      ],
      rules: 'required',
      col: 3,
    },
    purchaser: {
      label: i18n.t('採購姓名'),
      type: 'text',
      col: 3,
    },
    on_time: {
      type: 'datetime',
      label: i18n.t('上架時間'),
      rules: 'required',
      col: 3,
    },
    off_time: {
      type: 'datetime',
      label: i18n.t('下架時間'),
      col: 3,
    },
    is_active: {
      type: 'switch',
      label: i18n.t('商品狀態'),
      activeText: i18n.t('已上架'),
      inactiveText: i18n.t('下架'),
      updatable: {
        getAlertContent: function (data) {
          let _isActive = data.is_active ? i18n.t('下架') : i18n.t('上架');
          return `確認要將此商品“${data.name}”${_isActive}嗎？`;
        },
      },
      width: '140px',
      col: 6,
    },
    spec: {
      type: 'text',
      label: i18n.t('商品規格'),
      rules: 'required',
      col: 3,
    },
    weight_capacity: {
      type: 'number',
      label: `${i18n.t('重量')} / ${i18n.t('容量')}`,
      rules: 'required|int_or_de:3',
      col: 3,
    },
    weight_capacity_unit: {
      type: 'select',
      label: i18n.t('單位'),
      placeholder: i18n.t('選擇單位'),
      rules: 'required',
      items: [
        {
          text: 'kg',
          value: 'kg',
        },
        {
          text: 'ml',
          value: 'ml',
        },
      ],
      col: 3,
    },
    show_weight_capacity: {
      type: 'switch',
      label: i18n.t('重量') + '/' + i18n.t('容量') + i18n.t('前台是否顯示'),
      activeText: i18n.t('前台顯示'),
      inactiveText: i18n.t('前台不顯示'),
      defaultValue: true,
      col: 3,
    },
    cover_image: {
      type: 'image',
      label: i18n.t('商品封面圖'),
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    shop_product_cover_frame: {
      type: 'belongsTo',
      label: i18n.t('活動圖框'),
      relationPopup: true,
      textKey: 'name',
      modelName: 'shop_product_cover_frame',
      col: 3,
    },
    images: {
      type: 'images',
      label: i18n.t('商品圖'),
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    description: {
      type: 'editor',
      label: i18n.t('商品簡介'),
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    // source: {
    //   type: 'text',
    //   label: ''
    // },
    // store_temperature: {
    //   type: 'text',
    //   label: ''
    // },
    // ranking_score: {
    //   type: 'number',
    //   label: '熱門分數'
    // },
    shop_classes: {
      type: 'belongsToMany',
      label: i18n.t('分類'),
      modelName: 'shop_class',
      labelInLocale: true,
      rules: 'required',
      extendParams: {
        order_type: 'next-day',
      },
      layerFields: [
        {
          stateKey: '',
          modelKey: '',
        },
      ],
    },
    shop_subclasses: {
      type: 'belongsToMany',
      label: i18n.t('子分類'),
      modelName: 'shop_subclass',
      labelInLocale: true,
      conditions: [
        {
          type: 'array',
          value: '',
          key: 'shop_classes',
        },
      ],
      rules: 'required',
      getExtendParamsFromValue(value) {
        if (!value) {
          return {};
        } else if (value.shop_classes) {
          return {
            shop_class: value.shop_classes.map((item) => item.id).toString(),
          };
        } else {
          return {};
        }
      },
      requiredField: 'shop_classes',
    },
    shop_shop_classes: {
      type: 'belongs-to-many-multi-layers',
      label: i18n.t('商品分類/子分類'),
      rules: 'required',
      extendParams: {
        order_type: 'next-day',
      },
      layerFields: [
        {
          modelName: 'shop_class',
          stateKey: 'shop_classes',
          modelKey: 'shop_classes',
        },
        {
          modelName: 'shop_subclass',
          stateKey: 'shop_subclasses',
          modelKey: 'shop_subclasses',
        },
      ],
      col: 3,
    },
    stock_level: {
      type: 'icon',
      color: 'var(--danger)',
      getDisplayText(value) {
        if (value.stock_count < value.stock_alert_count) {
          //低於預警數量
          return `icon-ws-filled-alert`;
        } else {
          //庫存量充足
          return `-`;
        }
      },
      width: '30px',
      col: 3,
    },
    featured_classes: {
      type: 'belongsToMany',
      label: i18n.t('精選分類'),
      modelName: 'featured_class',
      labelInLocale: true,
      extendParams: {
        order_type: 'next-day',
      },
      col: 9,
    },
    // suggests: {
    //   type: 'belongsToMany',
    //   label: i18n.t('推薦商品'),
    //   modelName: 'shop_product',
    //   labelInLocale: true,
    //   relationPopup: true,
    // },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    order_type: {
      type: 'select',
      label: i18n.t('訂單類型'),
      items: [
        {
          text: i18n.t('一般訂單'),
          value: 'next-day',
        },
        {
          text: i18n.t('預購訂單'),
          value: 'pre-order',
        },
      ],
    },
    shop_product_cover_frame_duration: {
      label: i18n.t('現有活動圖框'),
      type: 'custom',
      getValue: function (data) {
        if (!data.shop_product_cover_frame) return '-';
        let _text = '-';
        _text = data.shop_product_cover_frame.name + '\n' || '';
        if (
          data.shop_product_cover_frame.start_date &&
          data.shop_product_cover_frame.end_date
        ) {
          _text += `${moment(data.shop_product_cover_frame.start_date).format(
            'YYYY-MM-DD',
          )} - ${moment(data.shop_product_cover_frame.end_date).format(
            'YYYY-MM-DD',
          )}`;
        } else {
          _text += i18n.t('無期限');
        }
        return _text;
      },
    },
    store_house_class: {
      label: i18n.t('內部主分類'),
      type: 'text',
      col: 3,
    },
    store_house_subclass: {
      label: i18n.t('內部子分類'),
      type: 'text',
      col: 9,
    },
    shop_campaigns_names: {
      label: '商品活動名稱',
      type: 'custom',
      getValue: function (data) {
        if (data.shop_campaigns && data.shop_campaigns.length) {
          let shopCampaignsArr = []
          data.shop_campaigns.forEach(shop_campaign => {
            shopCampaignsArr.push(shop_campaign.name)
          });
          return shopCampaignsArr.join(',')
        } else {
          return null
        }
      }
    }
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        'stock_level',
        'cover_image',
        'no',
        'name',
        'storage_space',
        'is_active',
        'spec',
        'weight_capacity',
        'cost',
        'price',
        'discount_price',
        'stock_count',
        'stock_alert_count',
        'tax',
      ],
      showFieldsUpdate: [
        'no',
        'name',
        'subtitle',
        "cost",
        "price",
        "tax",
        "stock_count",
        "stock_alert_count",
        "max_buyable_count",
        "storage_space",
        "store_temperature",
        "purchaser",
        "ranking_score",
        "store_house_class",
        "store_house_subclass",
        "spec",
        "weight_capacity",
        "weight_capacity_unit",
        "show_weight_capacity",
        "on_time",
        "off_time",
        "is_active",
        "shop_shop_classes",
        "featured_classes",
        "cover_image",
        "images",
        "description",
        "shop_product_cover_frame",
        "suggests",
      ],
      expandable: false,
      infiniteScroll: false,
      inRowBtnRead: true,
      pageMode: true,
      filterUniqueSection: true,
      hasSticky: true,
      dialogUpdate: true,
      dialogRead: true,
      itemsPerPage: 100,
      hasToggleFilter: true,
      titleBarCustomBtns: [
        {
          label: i18n.t('匯出所有商品庫存'),
          event: 'export-product',
        },
        {
          label: i18n.t('匯入商品庫存'),
          event: 'import-product',
        },
      ],
      plusOrderItems: [
        {
          value: 'first_storage_space',
          text: '依儲位排序',
          order_by: 'storage_space',
          order_way: 'asc',
        },
      ],
      filterSelects: {
        featured_classes: {
          type: 'belongsTo',
          label: i18n.t('篩選精選分類'),
          modelName: 'featured_class',
          optionAll: true,
          col: 2,
        },
        shop_classes: {
          type: 'belongsTo',
          label: '篩選商品分類',
          modelName: 'shop_class',
          optionAll: true,
          col: 2
        },
        shop_subclasses: {
          type: 'belongsTo',
          label: '篩選商品子分類',
          modelName: 'shop_subclass',
          optionAll: true,
          col: 2,
          getDisabledFromValue: (value) => {
            if (value.shop_classes) {
              return false
            } else {
              return true
            }
          },
          getExtendParamsFromValue: (value) => {
            if (!value.shop_classes) {
              return {}
            } else {
              return {
                shop_class: value.shop_classes.id
              }
            }
          }
        },
        is_active: {
          label: i18n.t('篩選商品狀態'),
          type: 'multi-select',
          placeholder: i18n.t('所有商品狀態'),
          selectAll: true,
          items: [
            {
              text: i18n.t('已上架'),
              value: 1,
            },
            {
              text: i18n.t('下架'),
              value: 0,
            },
          ],
          col: 2,
        },
        stock_level: {
          label: i18n.t('篩選商品庫存狀態'),
          type: 'select',
          placeholder: i18n.t('所有商品庫存狀態'),
          selectAll: true,
          items: [
            {
              text: i18n.t('所有商品庫存狀態'),
              value: '',
            },
            {
              text: i18n.t('商品庫存已達預警'),
              value: 2,
            },
            {
              text: i18n.t('商品庫存未達預警'),
              value: 1,
            },
          ],
          col: 2,
        },
      },
      params: {
        order_type: 'next-day',
        time_fields: 'created_at',
      },
      headerSticky: true,
      showFieldsRead: [
        "id",
        "name",
        "no",
        "subtitle",
        "order_type",
        "spec",
        "cost",
        "price",
        "discount_price",
        "weight_capacity",
        "weight_capacity_unit",
        "show_weight_capacity",
        "tax",
        "stock_count",
        "stock_alert_count",
        "max_buyable_count",
        "storage_space",
        "purchaser",
        "store_temperature",
        "cover_image",
        "shop_product_cover_frame",
        "images",
        "description",
        "ranking_score",
        "suggests",
        "store_house_class",
        "store_house_subclass",
        "is_active",
        "on_time",
        "off_time",
        "shop_classes",
        "shop_subclasses",
        "featured_classes",
        "areas",
        "area_sections",
      ]
    },
  },
};
if (config.wsmodule.area) {
  model.fields.areas = {
    type: 'belongsToMany',
    label: i18n.t('地區'),
    modelName: 'area',
    labelInLocale: true,
  };
  model.fields.area_sections = {
    type: 'belongsToMany',
    label: i18n.t('子地區'),
    modelName: 'area_section',
    labelInLocale: true,
    conditions: [
      {
        type: 'array',
        value: '',
        key: 'area',
      },
    ],
    getExtendParamsFromValue(value) {
      if (!value) {
        return {};
      } else if (value.areas) {
        return {
          area: value.areas.map((item) => item.id).toString(),
        };
      } else {
        return {};
      }
    },
    requiredField: 'areas',
  };
}

if (config.wsmodule.shop) {
  if (config.wsmodule.shop.discount_price) {
    model.fields.discount_price = {
      type: 'number',
      label: i18n.t('優惠價'),
      rules: 'required|min_value:1|integer',
    };
  }
  if (config.wsmodule.shop.ranking_score) {
    model.fields.ranking_score = {
      type: 'number',
      label: i18n.t('熱門分數'),
      rules: 'min_value:1|integer',
      col: 3,
    };
  }
  if (config.wsmodule.shop.suggests) {
    model.fields.suggests = {
      type: 'belongsToMany',
      label: i18n.t('推薦商品'),
      modelName: 'shop_product',
      labelInLocale: true,
      relationPopup: true,
      textKey: 'name',
      showTags: true,
      col: 3,
    };
  }
}

export default model;
