<template>
  <div class="ws-dialog-shop-order-read">
    <WsDialogCRUDRead
      ref="WsDialogCRUDRead"
      :fields="fields"
      :modelName="modelName"
      :showFields="showFields"
      @modelDataInit="$_onModelDataInit($event)"
      @close="$_onDialogClose()"
      :customBtns="$store.state.stone_model.shop_order.crudSetting.read.customBtns"
      :customDropdownMenu="$store.state.stone_model.shop_order.crudSetting.read.customDropdownMenu"
      @custom-btn-click="$_onCustomBtnClick($event)"
      @custom-dropdown-menu-click="$_onCustomDropdownMenuClick($event)"
      titleKey="no"
    >
      <template #extend-content>
        <WsCrud
          class="mt-40"
          v-if="modelData"
          :label="$t('商品資訊')"
          :fields="models.shop_order_shop_product.fields"
          :modelName="models.shop_order_shop_product.modelName"
          :showFields="models.shop_order_shop_product.showFields"
          :hasFilter="false"
          inRowBtnRead
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
          :creatable="false"
          :params="_shopOrderShopProductParams"
          :expandable="false"
        >
        </WsCrud>
        <template v-if="modelData&&modelData.shop_return_records&&modelData.shop_return_records.length">
          <WsReadSection
            title="退訂資訊"
            :modelName="models.shop_order.modelName"
            :id="$refs.WsDialogCRUDRead.id"
            :localeMode="true"
            :label="models.shop_order.label"
            :fields="models.shop_order.fields"
            :leftFields="models.shop_order.leftFields"
            :rightFields="models.shop_order.rightFields"
            :customBtns="titleBarCustomBtns.shop_order"
            :showBreadcrumbs="false"
            :deletable="false"
            :updatable="false"
            @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
          />
          <WsCrud
            v-if="modelData"
            :modelName="models.shop_return_record.modelName"
            :fields="models.shop_return_record.fields"
            :showFields="models.shop_return_record.showFields"
            :infiniteScroll="false"
            :creatable="false"
            inRowBtnRead
            :inRowBtnUpdate="false"
            :inRowBtnDelete="false"
            :showExpand="true"
            :expandable="true"
            :hasFilter="false"
            :params="_shopOrderReturnRecordParams"
          ></WsCrud>
        </template>
      </template>
    </WsDialogCRUDRead>
    <WsPopupAlert
      ref="WsPopupAlert"
      @submit="$_onPopupAlertSubmit($event)"
    >
    </WsPopupAlert>
    <WsDialogShopCustomerServiceRemark ref="WsDialogShopCustomerServiceRemark"></WsDialogShopCustomerServiceRemark>
    <WsPopup ref="WsPopupAlertMessage">
      <template #content>
        {{WsPopupAlertMessage}}
      </template>
      <template #rightActions>
        <WsBtn>
          <div
            class="ml-6"
            @click="onWsPopupAlertMessageClose()"
          >{{$t('了解')}}</div>
        </WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
import S_ShopOrder from "@/__vue2stone_cms/service/api/v1/shop_order";
export default {
  methods: {
    $_onCustomBtnClick($event) {
      if ($event == "export-excel") {
        S_ShopOrder.export_excel({
          selected: [this.modelData],
        });
      } else if ($event == "print") {
        const _data = {
          id: "print",
          value: "collected",
        };
        const title = this.$t("是否確定列印？");
        const description = this.$t(
          "列印撿貨單時，會自動將訂單出貨狀態由「待出貨」改為「準備出貨」，已為準備出貨的訂單則不受影響。"
        );
        this.$refs.WsPopupAlert.open(_data, title, description);
      } else if ($event == "edit-customer-service") {
        this.$refs.WsDialogShopCustomerServiceRemark.open(this.modelData.id);
        this.close();
      }
    },
    $_onCustomDropdownMenuClick($event) {
      let title;
      let description = this.$t("是否確定更改？");
      if ($event.id == "change_order_status") {
        if (
          !this.$_changeOrderStatusCheck(this.modelData.status, $event.value)
        ) {
          title = this.$t("無法變更訂單狀態");
          const currStatus = this.modelData.status
            ? this.$store.state.stone_model.shop_order.fields.status.items.find(
                (item) => item.value === this.modelData.status
              ).text
            : "";
          const targetStatus = $event.value
            ? this.$store.state.stone_model.shop_order.fields.status.items.find(
                (item) => item.value === $event.value
              ).text
            : "";
          description = this.$t(`無法從"${currStatus}"變為"${targetStatus}"`);
        } else {
          title = this.$t("確定要更改訂單狀態嗎？");
        }
      } else if ($event.id === "change_ship_status") {
        if (
          this.modelData.ship_status === "unfulfilled" &&
          $event.value === "shipped"
        ) {
          title = this.$t("無法變更出貨狀態");
          description = this.$t("僅有準備出貨訂單可以變更為已出貨");
        } else {
          title = this.$t("確定要更改出貨狀態嗎？");
        }
      } else if ($event.id === "need_handle") {
        title = this.$t("確定要更改問題處理狀態？");
      }
      this.$refs.WsPopupAlert.open($event, title, description);
    },
    $_changeOrderStatusCheck(currValue, targetValue) {
      // status
      if (currValue === targetValue) return false;
      if (currValue === "established") {
        if (
          targetValue === "return-part-apply" ||
          targetValue === "cancel" ||
          targetValue === "complete"
        ) {
          return true;
        }
      } else if (currValue === "not-established") {
        if (targetValue === "established") return true;
      } else if (currValue === "return-part-apply") {
        if (
          targetValue === "return-part-complete" ||
          targetValue === "established"
        )
          return true;
      } else if (currValue === "cancel") {
        if (targetValue === "cancel-complete" || targetValue === "established")
          return true;
      } else if (currValue === "return-part-complete") {
        if (targetValue === "return-part-apply") return true;
      } else if (currValue === "complete") {
        if (targetValue === "return-part-apply" || targetValue === "cancel")
          return true;
      }
      return false;
    },
    async $_onPopupAlertSubmit($event) {
      if ($event.id == "return-cancel") {
        this.$refs.WsPopupAlert.loadingStart();
        const res = await S_ShopOrder.return_cancel(this.modelData.id);
        this.$refs.WsPopupAlert.close();
        this.close();
        this.$emit("complete-return-cancel", res.data);
      } else {
        let key;
        if ($event.id == "change_order_status") {
          key = "status";
          if (
            !this.$_changeOrderStatusCheck(this.modelData[key], $event.value)
          ) {
            this.$refs.WsPopupAlert.close();
            this.close();
            return;
          }
          if ($event.value === "return-part-apply") {
            this.$emit("return-part", this.modelData.id);
            this.$refs.WsPopupAlert.close();
            this.close();
            return;
          } else if ($event.value === "cancel") {
            this.$emit("return-all", this.modelData.id);
            this.$refs.WsPopupAlert.close();
            this.close();
            return;
          }
        } else if ($event.id == "change_ship_status") {
          key = "ship_status";
          if (
            !this.$_changeShipStatusCheck(this.modelData[key], $event.value)
          ) {
            this.$refs.WsPopupAlert.close();
            this.close();
            return;
          }
        } else if ($event.id == "need_handle") {
          key = "need_handle";
        } else if ($event.id == "print") {
          S_ShopOrder.export_prd({
            selected: [this.modelData],
          });
          const _data = {
            ...$event,
            id: "change_ship_status",
          };
          this.$_onPopupAlertSubmit(_data);
          return;
        }
        this.$refs.WsPopupAlert.loadingStart();
        await S_ShopOrder.batch_update([this.modelData.id], {
          [key]: $event.value,
        });
        this.$emit("update", {
          ...this.modelData,
          [key]: $event.value,
        });
        this.$refs.WsPopupAlert.close();
        this.close();
      }
    },
    $_changeShipStatusCheck(currValue, targetValue) {
      // ship_status
      if (currValue === targetValue) return false;
      if (targetValue === "unfulfilled") {
        if (currValue === "shipped") return true;
      } else if (targetValue === "collected") {
        if (currValue === "unfulfilled" || currValue === "shipped") return true;
      } else if (targetValue === "shipped") {
        if (currValue === "collected") return true;
      }
      return false;
    },
    $_clear() {
      this.modelData = null;
    },
    close() {
      this.$refs.WsDialogCRUDRead.close();
    },
    $_onDialogClose() {
      this.$_clear();
    },
    $_titleBarCustomBtnClick($event) {
      if ($event == "return-cancel") {
        // this.close();
        if (
          this.modelData.status === "return-part-complete" ||
          this.modelData.status === "cancel-complete"
        ) {
          this.WsPopupAlertMessage = this.$t("已完成退訂的訂單無法取消退訂");
          this.onWsPopupAlertMessageOpen();
        } else {
          this.$refs.WsPopupAlert.open(
            {
              id: "return-cancel",
              value: this.modelData,
            },
            "",
            "是否取消退訂？"
          );
        }
      } else if ($event == "return-update") {
        if (this.modelData.status === "cancel-complete") {
          this.WsPopupAlertMessage = this.$t("全部退訂的訂單無法異動退訂資料");
          this.onWsPopupAlertMessageOpen();
        } else if (this.modelData.status === "cancel") {
          this.WsPopupAlertMessage = this.$t(
            "全部退訂的訂單無法異動退訂資料，若需調整退訂內容請取消退訂重新進行退訂"
          );
          this.onWsPopupAlertMessageOpen();
        } else {
          this.$emit("return-part", this.modelData.id);
          this.close();
        }
      }
    },
    $_onModelDataInit(shopOrder) {
      this.modelData = shopOrder;
    },
    open(id) {
      this.$refs.WsDialogCRUDRead.open(id);
    },
    onWsPopupAlertMessageOpen() {
      this.$refs.WsPopupAlertMessage.open();
    },
    onWsPopupAlertMessageClose() {
      this.$refs.WsPopupAlertMessage.close();
    },
  },
  computed: {
    _shopOrderReturnRecordParams() {
      return {
        shop_order: this.modelData.id,
      };
    },
    _shopOrderShopProductParams() {
      return {
        shop_order: this.modelData.id,
      };
    },
  },
  data() {
    return {
      modelData: null,
      WsPopupAlertMessage: "",
      models: {
        shop_order_shop_product: {
          showFields: [
            "name",
            "shop_campaigns_name",
            "price",
            "count",
            "current_num",
            "change_reason",
          ],
          ...this.$store.state.stone_model.shop_order_shop_product,
        },
        shop_return_record: {
          showFields: [
            "shop_order_shop_product__no",
            "shop_order_shop_product__name",
            "shop_order_shop_product__spec",
            "shop_order_shop_product__weight_capacity",
            "shop_order_shop_product__price",
            "count",
          ],
          ...this.$store.state.stone_model.shop_return_record,
        },
        shop_order: {
          leftFields: ["return_reason", "return_remark", "return_price"],
          rightFields: [],
          ...this.$store.state.stone_model.shop_order,
        },
      },
      ...this.$store.state.stone_model.shop_order,
      showFields: [
        "no",
        "orderer",
        "orderer_tel",
        "orderer_address",
        "type",
        "receiver",
        "receiver_tel",
        "receive_address",
        "created_at",
        "ship_time",
        "ship_status",
        "status",
        "pay_status",
        "ship_date",
        "ship_way",
        "invoice_number",
        "invoice_status",
        "invoice_type",
        "package_way",
        "invoice_title",
        "invoice_uniform_number",
        "invoice_carrier_type",
        "invoice_carrier_number",
        "receive_remark",
        "customer_service_remark",
        "receive_way",
        "products_price",
        "shop_product_campaign_deduct",
        "bonus_points_deduct",
        "campaign_deduct",
        "invite_no_deduct",
        "freight",
        "order_price",
        "pay_type",
        "pay_at",
        "bonus_points",
        "return_reason",
        "return_remark",
        "return_price",
        "orderer_email",
      ],
      titleBarCustomBtns: {
        shop_order: [
          {
            label: "取消退訂",
            event: "return-cancel",
          },
          {
            label: "異動退訂",
            event: "return-update",
          },
        ],
      },
    };
  },
};
</script>

<style lang="scss">
@import "@/__vue2stone_cms/scss/@components/ws-dialog-shop-order-read.scss";
</style>