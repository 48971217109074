import config from '@/__config'

const model = {
  modelName: "contact_request_notify_mail",
  label: "聯絡請求通知Email",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    is_active: {
      type: "switch",
      label: "is_active",
      defaultValue: true,
      labelInLocale: true,
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      labelInLocale: true,
    },
    mail: {
      type: "email",
      label: "E-mail",
    },
    remark: {
      type: 'textarea',
      label: '備註',
      labelInLocale: true,
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      labelInLocale: true,
      showFields: [
        'id',
        'is_active',
        'name',
        'mail',
        'created_at',
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true,
      expandable: false,
      countryCode: true
    },
  },
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
  model.crudSetting.index.showFields.push('country_code')
}

export default model