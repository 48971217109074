import config from '@/__config';
import user_device_token from './user_device_token';
import i18n from '@/i18n';
const model = {
  modelName: 'user',
  label: i18n.t('會員'),
  fields: {
    uuid: {
      type: 'text',
      label: i18n.t('會員') + 'UUID',
      readonly: true,
    },
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: i18n.t('名稱'),
      rules: 'required',
    },
    email: {
      type: 'email',
      label: 'Email',
      rules: 'required',
    },
    password: {
      type: 'password',
      label: i18n.t('密碼'),
      rules: 'required',
    },
    email_verified_at: {
      type: 'datetime',
      label: i18n.t('信箱認證時間'),
    },
    pocket_avatar: {
      type: 'pocket_image',
      label: i18n.t('頭像'),
      getUploadUrl: '/user/avatar/upload_url',
      signed: false,
    },
    description: {
      type: 'textarea',
      label: i18n.t('介紹'),
    },
    scopes: {
      type: 'multi-select',
      label: i18n.t('權限'),
      items: [
        {
          text: i18n.t('一般使用者'),
          value: 'user',
        },
        {
          text: i18n.t('企業用戶'),
          value: 'business',
        },
      ],
    },
    tel: {
      type: 'text',
      label: i18n.t('電話'),
    },
    gender: {
      type: 'select',
      label: i18n.t('性別'),
      items: [
        {
          value: 'female',
          text: i18n.t('女生'),
        },
        {
          value: 'male',
          text: i18n.t('男生'),
        },
      ],
    },
    birthday: {
      type: 'date',
      label: i18n.t('生日'),
    },
    // : {
    //   type: 'datetime',
    //   label: '註冊時間',
    // },
    // : {
    //   type: 'datetime',
    //   label: '最後一次登入時間',
    // },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
    },
    // addresses: {
    //   type: 'text',
    //   label: '地址',
    // },
    bonus_points: {
      type: 'number',
      label: i18n.t('紅利點數'),
    },
  },
};

if (config.wsmodule.user_device_token) {
  model.fields.user_device_tokens = {
    type: 'hasMany',
    readonly: true,
    label: 'Device Tokens',
    textKey: 'device_token',
    modelName: 'user_device_token',
    relationPopup: true,
    fields: user_device_token.fields,
    showFields: ['id', 'is_active', 'device_token', 'remark'],
  };
}

if (config.wsmodule.user) {
  if (config.wsmodule.user.is_bad) {
    model.fields.is_bad = {
      type: 'switch',
      label: i18n.t('黑名單'),
      activeText: i18n.t('是'),
      inactiveText: i18n.t('否'),
      defaultValue: false,
    };
  }

  if (config.wsmodule.user.bonus_points) {
    model.fields.bonus_points = {
      type: 'number',
      label: i18n.t('紅利目前點數'),
    };
  }

  if (config.wsmodule.user.customer_id) {
    model.fields.customer_id = {
      type: 'text',
      label: i18n.t('會員編號'),
    };
  }
  if (config.wsmodule.user.invite) {
    model.fields.invite_no = {
      type: 'text',
      label: i18n.t('邀請碼'),
    };
  }
}
if (config.wsmodule.acumatica) {
  model.fields.acumatica_id = {
    type: 'text',
    label: 'Acumatica ID',
  };
}

export default model;
