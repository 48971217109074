<template>
  <WsCard class="ws-state-tulpa-sections">
    <WsText
      size="20"
      class="mb-20"
      bold
    >{{$t('頁面內容')}}</WsText>
    <WsFlexWrap v-if="amountEditable">
      <WsBtn @click="$_onCreateOpen()">{{$t('new')}} {{$t('page_section')}}</WsBtn>
      <WsBtn
        class="ml-10"
        @click="$_onSortOpen()"
      >{{$t('adjust_the_order')}}</WsBtn>
      <WsSortDialog
        ref="WsSortDialog"
        @input="$_onSortInput($event)"
        :value="_sortValue"
        textKey="name"
      ></WsSortDialog>
      <!-- <WsSortDialog
        ref="WsSortDialog"
        @input="$emit('input',$event)"
        :value="value"
        textKey="name"
      ></WsSortDialog> -->
    </WsFlexWrap>
    <WsStateTulpaSectionContent
      v-for="(contentItem, contentItemIndex) in value"
      :key="contentItemIndex"
      class="mt-10"
      color="#333"
      :value="contentItem.content"
      :tulpaSection="contentItem"
      @input="$_onStateInput($event,contentItemIndex)"
      @remove="$_onRemove(contentItem,contentItemIndex)"
      :removable="amountEditable"
    >

    </WsStateTulpaSectionContent>
    <!-- <WsCard
      v-for="(contentItem, contentItemIndex) in value"
      :key="contentItemIndex"
      color="#333"
      class="mt-10"
    >
      <WsFlex justify-content="space-between">
        <WsText>{{contentItem.name}}</WsText>
        <WsIconBtn
          v-if="amountEditable"
          @click="$_onRemove(contentItem,contentItemIndex)"
          name="icon-md-delete"
        />
      </WsFlex>
      <WsRow class="mt-10">
        <WsCol class="col-7">
          <WsCard class="mt-10">
            <WsState
              type="form"
              :fields="contentItem.fields"
              :value="contentItem.content"
              @input="$_onStateInput($event,contentItemIndex)"
            ></WsState>
          </WsCard>
        </WsCol>
        <WsCol class="col-5">
          <WsText size="5">{{$t('未輸入之預設值')}}</WsText>
          <WsCard class="mt-10">
            <WsInfo
              type="form"
              :fields="contentItem.fields"
              :value="contentItem.defaultContent"
            ></WsInfo>
          </WsCard>
        </WsCol>
      </WsRow>
    </WsCard> -->
    <WsModelPicker
      ref="WsModelPicker"
      @submit="$_onModelPickerSubmit($event)"
      modelName="tulpa_section"
      :fields="model.tulpa_section.fields"
      :showFields="showFields.tulpa_section"
    ></WsModelPicker>
    <WsBtn
      class="mt-10"
      @click="$_onCreateOpen()"
      v-if="value&&Object.keys(value).length&&amountEditable"
    >{{$t('new')}} {{$t('page_section')}}</WsBtn>
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </WsCard>
</template>

<script>
import tulpa_section from "@/__vue2stone_cms/models/tulpa_section";
import S_Wasa from "@/__vue2stone_cms/service/wasa";
export default {
  data: () => ({
    model: {
      tulpa_section: tulpa_section,
    },
    showFields: {
      tulpa_section: ["id", "name", "tags", "remark"],
    },
  }),

  methods: {
    $_onSortInput($event) {
      const _value = {};
      $event.forEach((eventItem) => {
        const _itemValue = { ...eventItem };
        delete _itemValue._sortKey;
        _value[eventItem._sortKey] = _itemValue;
      });
      this.$emit("input", _value);
    },
    $_onStateInput($event, contentItemIndex) {
      const _value = { ...this.value };
      _value[contentItemIndex].content = {
        ..._value[contentItemIndex].content,
        ...$event,
      };
      this.$emit("input", _value);
    },
    $_onDeleteSubmit($event) {
      const _index = $event.itemIndex;
      const _value = { ...this.value };
      delete _value[_index];
      this.$emit("input", _value);
      this.$refs.deleteAlert.close();
    },
    $_onRemove(contentItem, contentItemIndex) {
      this.$refs.deleteAlert.open({
        item: contentItem,
        itemIndex: contentItemIndex,
      });
    },
    $_onSortOpen() {
      this.$refs.WsSortDialog.open();
    },
    $_onCreateOpen() {
      this.$refs.WsModelPicker.open();
    },
    $_onModelPickerSubmit($event) {
      const _value = { ...this.value };
      $event.forEach((eventItem) => {
        const uuid = S_Wasa.getUUID();
        _value[uuid] = {
          id: eventItem.id,
          content: {},
          name: eventItem.name,
          fields: eventItem.fields,
          defaultContent: eventItem.content,
        };
      });
      this.$emit("input", _value);
    },
  },

  computed: {
    _sortValue() {
      const _sortValue = [];
      for (const key in this.value) {
        _sortValue.push({
          _sortKey: key,
          ...this.value[key],
        });
      }
      return _sortValue;
    },
  },

  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
    amountEditable: {
      type: Boolean,
      default: true,
    },
  },
};
</script>