const model = {
  modelName: "socialite_facebook_account",
  label: "Facebook登入綁定",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    user: {
      type: 'belongsTo',
      label: '會員',
      textKey: "name",
      modelName: "user",
      relationPopup: true
    },
    provider: {
      type: 'text',
      label: 'Provider'
    },
    provider_user_id: {
      type: 'text',
      label: 'Provider User ID'
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['id', 'user', 'provider_user_id', 'created_at'],
      infiniteScroll: true
    }
  }
}

export default model