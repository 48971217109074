import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_campaign',
  urlModelName: 'bonus_point',
  label: i18n.t('紅利點數回饋'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    type: {
      type: 'radio-input',
      label: i18n.t('類型'),
      items: [
        {
          text: i18n.t('紅利點數回饋'),
          value: 'bonus_point_feedback',
        },
        {
          text: i18n.t('折扣碼'),
          value: 'discount_code',
        },
      ],
    },
    discount_way: {
      type: 'radio-input',
      label: i18n.t('折扣方式'),
      items: [
        {
          value: 'discount_percent',
          text: i18n.t('打折折數'),
          fields: {
            discount_percent: {
              type: 'text',
              label: '',
              placeholder: i18n.t('折'),
            },
          },
        },
        {
          value: 'discount_amount',
          text: i18n.t('折抵金額'),
          fields: {
            discount_amount: {
              type: 'text',
              label: '',
              placeholder: `NT$ ${i18n.t('輸入')}...`,
            },
          },
        },
      ],
    },
    name: {
      type: 'text',
      label: i18n.t('活動名稱'),
      rules: 'required',
    },
    start_date: {
      type: 'date',
      label: i18n.t('活動開始日'),
      placeholder: i18n.t('選擇起日'),
      rules: 'required',
      disabledDate: function(data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('活動結束日'),
      placeholder: i18n.t('選擇迄日'),
      rules: 'required',
      disabledDate: function(data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    discount_code: {
      type: 'text',
      label: i18n.t('折扣碼'),
    },
    status: {
      type: 'select',
      label: i18n.t('活動狀態'),
      labelInLocale: true,
      defaultValue: 'in-progress',
      items: [
        {
          value: 'in-progress',
          text: i18n.t('進行中'),
        },
        {
          value: 'not-started',
          text: i18n.t('未開始'),
        },
        {
          value: 'end',
          text: i18n.t('已結束'),
        },
      ],
    },
    is_active: {
      type: 'switch',
      label: i18n.t('活動狀態'),
      defaultValue: true,
      labelInLocale: true,
      activeText: i18n.t('啟用'),
      inactiveText: i18n.t('關閉'),
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    full_amount: {
      type: 'number',
      label: i18n.t('滿額限制'),
    },
    condition: {
      label: i18n.t('活動內容'),
      type: 'select',
      items: [
        {
          text: i18n.t('首購'),
          value: 'first-purchase',
        },
        {
          text: i18n.t('紅利點數回饋比例'),
          value: 'rate',
        },
        {
          text: i18n.t('無期限'),
          value: 'null',
        },
      ],
    },
    feedback_rate: {
      label: i18n.t('回饋比例') + '(%)',
      type: 'number',
      placeholder: '%',
      rules: 'required',
      suffixText: '%',
      col: 4,
    },
    feedback_rate_show: {
      label: i18n.t('回饋比例'),
      type: 'custom',
      getValue: function(data) {
        return data.feedback_rate || 0 + '%';
      },
    },
    duration: {
      type: 'custom',
      label: i18n.t('使用期間'),
      getValue: function(data) {
        let _duration = '-';
        if (data.start_date)
          _duration = `${moment(data.start_date).format('YYYY.MM.DD')} - `;
        if (data.end_date)
          _duration += moment(data.end_date).format('YYYY.MM.DD');
        return _duration;
      },
    },
    custom_discount_way: {
      type: 'custom',
      label: i18n.t('折扣方式'),
      getValue: function(data) {
        if (data.discount_way === 'discount_amount') {
          return `${i18n.t('折抵金額')} | NT$ ${data.discount_amount || 0}`;
        } else if (data.discount_way === 'discount_percent') {
          return `${i18n.t('打折折數')} | ${i18n.t('{num} 折', {
            num: data.discount_percent || 0,
          })}`;
        } else {
          return '-';
        }
      },
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: ['name', 'duration', 'feedback_rate_show'],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      inRowBtnUpdate: true,
      inRowBtnDelete: true,
      filterTabs: [
        {
          text: i18n.t('進行中'),
          value: 'in-progress',
          params: {
            status: 'in-progress',
          },
        },
        {
          text: i18n.t('未開始'),
          value: 'not-started',
          params: {
            status: 'not-started',
          },
        },
        {
          text: i18n.t('已結束'),
          value: 'end',
          params: {
            status: 'end',
          },
        },
      ],
      params: {
        type: 'bonus_point_feedback',
      },
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('新增紅利點數回饋'),
              fields: [
                'name',
                'is_active',
                'start_date',
                'end_date',
                'feedback_rate',
              ],
            },
          ],
        },
      ],
      extendPostData: {
        type: 'bonus_point_feedback',
      },
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: i18n.t('編輯紅利點數回饋'),
              fields: [
                'name',
                'is_active',
                'start_date',
                'end_date',
                'feedback_rate',
              ],
            },
          ],
        },
      ],
      extendPostData: {
        type: 'bonus_point_feedback',
      },
    },
    read: {
      leftFields: ['name', 'status', 'duration', 'feedback_rate_show'],
      rightFields: [],
      updatable: false,
      deletable: false,
      customBtns: [
        {
          label: i18n.t('編輯活動'),
          event: 'update-bonus',
          icon: {
            left: true,
            name: 'icon-md-edit',
          },
        },
        {
          label: i18n.t('刪除活動'),
          event: 'delete-bonus',
          icon: {
            left: true,
            name: 'icon-md-delete',
          },
        },
      ],
      titleKey: 'name',
    },
  },
};

export default model;
