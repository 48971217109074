<template>
  <div
    class="ws-crud"
    :class="{sticky:headerSticky}"
  >
    <WsText
      size="18"
      v-if="title"
    >{{title}}</WsText>
    <WsCrudTitleBar
      class="mb-20"
      :importable="importable"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :params="_params"
      :pageMode="pageMode"
      :dialogCreate="dialogCreate"
      :createUrl="createUrl"
      :orderable="_orderable"
      :creatable="creatable"
      :exportable="exportable"
      :labelInLocale="labelInLocale"
      :customBtns="titleBarCustomBtns"
      :customDropdownMenu="titleCustomDropdownMenu"
      @create="$_onCreate()"
      @customBtnClick="$emit('title-bar-custom-btn-click',$event)"
      @title-custom-dropdown-menu-click="$emit('title-custom-dropdown-menu-click',$event)"
    ></WsCrudTitleBar>
    <WsTabs
      v-if="filterTabs"
      :items="filterTabs"
      v-model="filterTabsData"
    >
    </WsTabs>
    <WsCard>
      <div class="ws-crud-filter-container">
        <WsCrudFilter
          v-if="hasFilter"
          ref="filter"
          :searching="searching"
          :orderBy="filter.orderBy"
          :orderWay="filter.orderWay"
          :order.sync="C_order"
          :orderItems="_orderItems"
          @search="$_onQueryUpdate($event,'searching')"
          @update:searching="$_onQueryUpdate($event,'searching')"
        >
        </WsCrudFilter>
        <WsStateForm
          ref="WsStateForm"
          class="mt-8"
          :fields="filterSelects"
          :value="filterSelectsData"
          @input="$_onFilterSelectFormInput($event)"
        ></WsStateForm>
      </div>
      <WsCrudTable
        v-if="_tableDisplay"
        ref="WsCrudTable"
        :loading="modeldataLoading"
        :inRowBtnComplete="inRowBtnComplete"
        :inRowBtnRead="inRowBtnRead"
        :inRowBtnUpdate="_inRowBtnUpdate"
        :inRowBtnDelete="_inRowBtnDelete"
        :dialogRead="dialogRead"
        :sortOption="sortOption"
        :dialogUpdate="dialogUpdate"
        :dialogDelete="dialogDelete"
        :items="modelDatas"
        :showFields="showFields"
        :itemsPerPage="itemsPerPage"
        :fields="fields"
        :displayFields="_displayFields"
        :headers="_tableHeaders"
        :pageMode="pageMode"
        :modelName="modelName"
        :urlModelName="urlModelName"
        :dataTotalCount="dataTotalCount"
        :currentPage="page"
        :lastPage="lastPage"
        :expandable="expandable"
        :orderBy.sync="filter.orderBy"
        :orderWay.sync="filter.orderWay"
        :orderByDefault="_orderByDefault"
        :orderWayDefault="_orderWayDefault"
        :scrollTop="scrollTop"
        :headerSticky="headerSticky"
        :showDataTotalCount="showDataTotalCount"
        @read="$_onRead($event)"
        @pageto="$_onPageto($event)"
        @update="$_onUpdate($event)"
        @delete="$_onDelete($event)"
        @complete="$_onComplete($event)"
        @sort-update="$_onSortUpdate($event)"
        @custom-table-action="$_onCustomTableAction($event)"
        @updateAlert="$_onUpdateModelDatas($event)"
        @update:order="$_onUpdateOrder($event)"
        :paginate="_paginate"
        :getUpdateUrl="getUpdateUrl"
        :getReadUrl="getReadUrl"
        :customTableActions="customTableActions"
        :selectable="selectable"
        :modelDataKey="modelDataKey"
        :hasMaxHeight="hasSticky"
        :value="selected"
        @input="$_onSelect($event)"
      ></WsCrudTable>
      <WsLoading v-if="modeldataLoading"></WsLoading>
      <!-- </div> -->
    </WsCard>
    <WsCreateDialog
      ref="createDialog"
      :label="label"
      :fields="_createFields"
      :modelName="modelName"
      :errorMessages="createErrorMessages"
      @submit="$_onCreateSubmit($event)"
    />
    <WsReadDialog
      ref="readDialog"
      :fields="_readFields"
      :titleKey="titleKey"
      @delete="$_onDelete($event)"
      @update="$_onUpdate($event)"
      @copy="$_onCopy($event)"
      :pageMode="pageMode"
      :dialogUpdate="dialogUpdate"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :copyable="copyable"
      :deletable="deletable"
      :updatable="updatable"
      :fetchOnOpen="fetchOnOpenRead"
      :getUpdateUrl="getUpdateUrl"
    >
      <template
        v-if="$scopedSlots.readDialogContent"
        v-slot:content="readContentSlotProps"
      >
        <slot
          name="readDialogContent"
          :item="readContentSlotProps.item"
        ></slot>
      </template>
      <template v-slot:leftActions="readLeftActionsSlotProps">
        <slot
          name="readDialogLeftActions"
          :item="readLeftActionsSlotProps.item"
        ></slot>
      </template>
      <template v-slot:rightActions="readRightActionsSlotProps">
        <slot
          name="readDialogRightActions"
          :item="readRightActionsSlotProps.item"
        ></slot>
      </template>
    </WsReadDialog>
    <WsUpdateDialog
      ref="updateDialog"
      :label="label"
      :fields="fields"
      :updateFields="_updateFields"
      :modelName="modelName"
      :fetchOnOpen="fetchOnOpenUpdate"
      :errorMessages="updateErrorMessages"
      @submit="$_onUpdateSubmit($event)"
    />
    <WsAlert
      ref="deleteAlert"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    ></WsAlert>
  </div>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  data: () => ({
    page: 1,
    filter: {
      orderBy: "created_at",
      orderWay: "desc",
    },
    filterTabsData: null,
    isMounted: false,
    modeldataLoading: false,
    modelDatas: [],
    lastPage: null,
    dataTotalCount: null,
    searching: "",
    filterSelectsData: {},
    C_order: null,
    createErrorMessages: null,
    updateErrorMessages: null,
    currentStickyOffset: 0,
    filterHide: true,
    scrollTop: 0,
    // >_< Axios
    // cancelTokenSource deprecate from 0.22
    cancelTokenSource: null,
    // focusReadIndex: null,
  }),
  methods: {
    $_updateModelData(modelData) {
      const _modelDatas = [...this.modelDatas];
      const _index = _modelDatas.findIndex((e) => {
        return e.id == modelData.id;
      });
      if (_index >= 0) {
        _modelDatas.splice(_index, 1);
        _modelDatas.splice(_index, 0, modelData);
      }
      this.modelDatas = _modelDatas;
    },
    async $_onUpdateOrder($event) {
      this.modelDatas = $event;
      setTimeout(() => {
        const _order = [];
        this.modelDatas.forEach((modelData, modelDataIndex) => {
          _order.push({
            id: modelData.id,
            sq: String(modelDataIndex).padStart(this.sequenceLength, "0"),
          });
        });
        if (this.sortOption && this.sortOption.patchUrl) {
          this.$axios
            .patch(this.sortOption.patchUrl, {
              order: _order,
            })
            .then(() => {
              this.$store.dispatch("app/addSnack", this.$t("排序更新"));
            });
        }
      }, 0);
    },
    $_getFilterTabsParams() {
      if (!this.filterTabsData) {
        return {};
      }
      const _filterTab = this.filterTabs.find((e) => {
        return e.value == this.filterTabsData;
      });
      return _filterTab.params;
    },
    $_onSelect($event) {
      this.$emit("update:selected", $event);
    },
    $_onCustomTableAction($event) {
      this.$emit($event.emit, $event.data);
      this.$emit("custom-table-action", $event);
    },
    reset() {
      this.page = 1;
      this.modelDatas = [];
    },
    $_handleScroll() {
      if (!document) {
        return;
      }
      if (this.headerSticky) {
        const extendTop = 168;
        this.scrollTop = window.scrollY - extendTop;
      }
      if (!this.infiniteScroll) {
        return;
      }
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - this.startLoadingDis
      ) {
        if (this.modeldataLoading) {
          return;
        } else {
          if (this.lastPage > this.page) {
            this.$_onPageto(this.page + 1);
          }
        }
      }
    },
    $_defaultSet() {
      if (this.orderBySq) {
        this.C_order = "sq";
        const orderQuery = this.$_getOrderUrl("sq");
        this.filter = {
          orderBy: orderQuery.order_by,
          orderWay: orderQuery.order_way,
        };
      } else if (this.order) {
        this.C_order = this.order;
        const orderQuery = this.$_getOrderUrl(this.order);
        this.filter = {
          orderBy: orderQuery.order_by,
          orderWay: orderQuery.order_way,
        };
      }
      if (this.filterTabs && this.filterTabs.length) {
        this.filterTabsData = this.filterTabs[0].value;
      }
      setTimeout(() => {
        this.isMounted = true;
      }, 0);
    },
    $_formatErrorMessages(errorMessages) {
      const formeted_error_messages = {};
      for (let key in errorMessages) {
        const errorMessage = errorMessages[key][0];
        if (this.$locale[errorMessage]) {
          formeted_error_messages[key] = this.$locale[errorMessage];
        } else {
          formeted_error_messages[key] = errorMessage;
        }
      }
      return formeted_error_messages;
    },
    $_getOrderUrl(order) {
      const orderItem = this._orderItems.find((e) => {
        return e.value == order;
      });
      return orderItem;
    },
    $_onOrderChange(order) {
      const orderQuery = this.$_getOrderUrl(order);
      this.filter = {
        orderBy: orderQuery.order_by,
        orderWay: orderQuery.order_way,
      };
    },
    $_onPageto($event) {
      this.page = $event;
      this.fetchData();
    },
    async fetchData() {
      if (this.$refs.WsCrudTable) {
        this.$refs.WsCrudTable.expandReset();
      }
      this.modeldataLoading = true;
      try {
        let getUrl;
        if (this.getUrl) {
          getUrl = this.getUrl;
        } else if (this.parentModelName) {
          getUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          getUrl = `/${this.modelName}`;
        }
        this.$emit("params", this._params);
        if (this.cancelTokenSource) {
          this.cancelTokenSource.cancel();
        }
        this.cancelTokenSource = this.$axios.CancelToken.source();
        const res = await this.$axios.get(getUrl, {
          params: this._params,
          cancelToken: this.cancelTokenSource.token,
        });
        if (res.data.meta && res.data.meta.last_page) {
          this.lastPage = res.data.meta.last_page;
        }
        if (res.data.meta && res.data.meta.total) {
          this.dataTotalCount = res.data.meta.total;
        }
        if (this.infiniteScroll) {
          this.modelDatas = [...this.modelDatas, ...res.data.data];
        } else {
          this.modelDatas = res.data.data;
        }
        this.$emit("input", this.modelDatas);
        this.modeldataLoading = false;
      } catch (error) {
        if (!this.$axios.isCancel(error)) {
          alert("存取資料錯誤，請重新嘗試");
        }
      }
    },
    $_onQueryUpdate($event, type) {
      this[type] = $event;
    },
    $_onCreate() {
      this.createErrorMessages = null;
      this.$refs.createDialog.open();
    },
    $_onRead($event) {
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.open(_item, $event.itemIndex);
    },
    $_onUpdate($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.updateDialog.open(_item);
    },
    $_onDelete($event) {
      this.$refs.deleteAlert.open({
        item: $event.item,
        itemIndex: $event.itemIndex,
      });
    },
    $_onCopy($event) {
      this.updateErrorMessages = null;
      const _item = this.modelDatas[$event.itemIndex];
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    $_onComplete($event) {
      if (!this.completeAction) {
        return;
      }
      this.completeAction($event);
    },
    copyOpen(id) {
      const _item = this.modelDatas.find((e) => {
        return e.id == id;
      });
      this.$refs.readDialog.close();
      this.$refs.createDialog.open(_item);
    },
    async $_onCreateSubmit($event) {
      this.$refs.createDialog.startLoading();
      try {
        let postData = {
          ...$event,
          ...this.createData,
        };
        let postUrl;
        if (this.parentModelName) {
          postUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          postUrl = `/${this.modelName}`;
        }
        if (this.createDefaultValues) {
          postData = { ...postData, ...this.createDefaultValues };
        }
        await this.$axios.post(postUrl, postData);
        // this.reset();
        // this.fetchData();
        this.$_reFetchData();
        this.$refs.createDialog.close();
      } catch (error) {
        if (error && error.response && error.response.data.message) {
          if (typeof error.response.data.message == "object") {
            this.createErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          } else {
            alert(`${this.$t(error.response.data.message)}`);
          }
        } else {
          alert(`${this.$t("new")}${this.$t("fail")}`);
        }
      } finally {
        this.$refs.createDialog.stopLoading();
      }
    },
    async $_onUpdateSubmit($event) {
      this.$refs.updateDialog.startLoading();
      try {
        let postData = {
          ...$event,
          ...this.updateData,
        };
        const res = await this.$axios.patch(
          `/${this.modelName}/${$event.id}`,
          postData
        );
        this.$_updateModelData(res.data.data);
        setTimeout(() => {
          this.$refs.updateDialog.close();
        }, 0);
        // this.reset();
        // this.fetchData();
        // this.$_reFetchData();
      } catch (error) {
        console.error(error);
        if (error && error.response && error.response.data.message) {
          if (typeof error.response.data.message == "object") {
            this.updateErrorMessages = this.$_formatErrorMessages(
              error.response.data.message
            );
          } else {
            alert(`${this.$t(error.response.data.message)}`);
          }
        } else {
          alert(`${this.$t("data update fail.")}`);
        }
      } finally {
        this.$refs.updateDialog.stopLoading();
      }
    },
    async $_onDeleteSubmit($event) {
      this.$refs.deleteAlert.startLoading();
      try {
        await this.$axios.delete(`/${this.modelName}/${$event.item.id}`);
        // this.reset();
        // this.fetchData();
        this.$_reFetchData();
      } catch (error) {
        alert("刪除發生錯誤");
      } finally {
        this.$refs.deleteAlert.stopLoading();
        this.$refs.deleteAlert.close();
        this.$refs.readDialog.close();
      }
    },
    $_onFilterSelectFormInput($event) {
      this.filterSelectsData = { ...this.filterSelectsData, ...$event };
      this.$emit("update:filter-select-form", this.filterSelectsData);
    },
    $_onUpdateModelDatas($event) {
      let _modelDatas = [...this.modelDatas];
      for (let i = 0; i < _modelDatas.length; i++) {
        _modelDatas[i] = { ..._modelDatas[i], ...$event[i] };
      }
      this.modelDatas = _modelDatas;
    },
    $_filterHideToggle() {
      this.filterHide = !this.filterHide;
    },
    $_reFetchData() {
      if (!this.isMounted) {
        return;
      }
      this.reset();
      this.fetchData();
    },
  },
  mounted() {
    this.$_defaultSet();
    setTimeout(() => {
      this.reset();
      this.fetchData();
    }, 0);
  },

  watch: {
    _params: {
      handler() {
        this.$emit("update:params", this._params);
      },
    },
    value: {
      handler() {
        if (this.value) {
          this.modelDatas = this.value;
        }
      },
      deep: true,
    },
    filterTabsData: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    filterSelectsData: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    filter: {
      handler() {
        this.$_reFetchData();
      },
      deep: true,
    },
    C_order: {
      handler() {
        if (!this.isMounted) {
          return;
        }
        this.$_onOrderChange(this.C_order);
      },
    },
    searching: {
      handler() {
        this.$_reFetchData();
        // this.reset();
        // this.fetchData();
      },
    },
  },

  computed: {
    _tableDisplay() {
      if (this.infiniteScroll) {
        return true;
      } else {
        return !this.modeldataLoading;
      }
    },
    _items() {
      if (this.value) {
        return this.value;
      } else {
        return this.modelDatas;
      }
    },
    _params() {
      let _params = {
        ...this.params,
        page: this.page,
        search: this.searching,
        order_way: this.filter.orderWay,
        order_by: this.filter.orderBy,
      };
      if (this.$config.locale.api && this.$config.locale.lang) {
        _params.lang = this.$store.state.locale.selectingLocale;
      }
      if (this.fetchQuery) {
        _params = { ..._params, ...this.fetchQuery };
      }

      // filterSelectsData
      if (JSON.stringify(this.filterSelectsData) !== "{}") {
        let filterParams = null;
        filterParams = S_App_State.getFormatedStates(
          // this.fields,
          this.filterSelects,
          this.filterSelectsData
        );
        // for (let key in filterParams) {
        //   let keepParam = false;
        //   for (let selectKey in this.filterSelects) {
        //     if (selectKey == key) {
        //       keepParam = true;
        //     }
        //     if (
        //       this.filterSelects[selectKey] &&
        //       this.filterSelects[selectKey].type === "date-range"
        //     ) {
        //       if (
        //         this.filterSelects[selectKey].startKey === key ||
        //         this.filterSelects[selectKey].endKey === key
        //       ) {
        //         keepParam = true;
        //       }
        //     }
        //   }
        //   if (!keepParam) {
        //     delete filterParams[key];
        //   }
        // }
        for (let key in filterParams) {
          const filterParamsItem = filterParams[key];
          if (filterParamsItem == null || filterParamsItem == undefined) {
            continue;
          } else {
            if (Array.isArray(filterParamsItem)) {
              filterParams[key] = filterParamsItem.join(",");
            } else {
              filterParams[key] = filterParamsItem;
            }
            _params = { ..._params, ...filterParams };
          }
        }
      }

      _params = {
        ..._params,
        ...this.$_getFilterTabsParams(),
      };

      return _params;
    },
    _inRowBtnUpdate() {
      if (!this.updatable) {
        return false;
      } else {
        return this.inRowBtnUpdate;
      }
    },
    _inRowBtnDelete() {
      if (!this.deletable) {
        return false;
      } else {
        return this.inRowBtnDelete;
      }
    },
    _orderByDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_by;
    },
    _orderWayDefault() {
      const orderQuery = this.$_getOrderUrl(this.order);
      return orderQuery.order_way;
    },
    _paginate() {
      if (this.infiniteScroll) {
        return false;
      } else if (this.dataTotalCount != null) {
        return true;
      } else {
        return false;
      }
    },
    _orderItems() {
      // orderBySq
      let _orderItems = [];
      if (this.orderBySq) {
        _orderItems = [
          ..._orderItems,
          {
            value: "sq",
            text: this.$t("排序設定"),
            order_by: "sq",
            order_way: "asc",
          },
        ];
      }
      _orderItems = [..._orderItems, ...this._baseOrderItems];
      if (this.plusOrderItems) {
        _orderItems = [..._orderItems, ...this.plusOrderItems];
      }
      return _orderItems;
    },
    _baseOrderItems() {
      return [
        {
          value: "last_update",
          text: this.$t("更新時間近至遠"),
          order_by: "updated_at",
          order_way: "desc",
        },
        {
          value: "last_created",
          text: this.$t("建立時間近至遠"),
          order_by: "created_at",
          order_way: "desc",
        },
      ];
    },
    _createFields() {
      return S_APP_General.geCreateFields(this.fields, this.createHideFields);
    },
    _updateFields() {
      return S_APP_General.geUpdateFields(
        this.fields,
        this.updateHideFields,
        this.showFieldsUpdate
      );
    },
    _displayFields() {
      return S_APP_General.getDisplayFields(this.fields);
    },
    _readFields() {
      return S_APP_General.getDisplayFields(this.fields, this.showFieldsRead);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this.showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          const _label = field.labelInLocale
            ? this.$t(field.label)
            : field.label;
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }
          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: _label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: _label,
            width: field.width,
            sortable: field.sortable,
          });
        }
      });
      return _tableHeaders;
    },
    _orderable() {
      return this.orderLayerFields ? true : false;
    },
  },

  props: {
    fetchOnOpenUpdate: {
      type: Boolean,
      default: true,
    },
    fetchOnOpenRead: {
      type: Boolean,
      default: true,
    },
    sequenceLength: {
      type: Number,
      default: 3,
    },
    sortOption: {
      type: Object,
    },
    title: {
      type: String,
    },
    selected: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    titleBarCustomBtns: {
      type: Array,
    },
    titleCustomDropdownMenu: {
      type: Array,
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    customTableActions: {
      type: Array,
      default: null,
    },
    completeAction: {
      type: Function,
      default: null,
    },
    startLoadingDis: {
      type: Number,
      default: 300,
    },
    infiniteScroll: {
      type: Boolean,
      default: false,
    },
    dialogCreate: {
      type: Boolean,
      default: false,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    createDefaultValues: {
      type: Object,
      default: null,
    },
    fetchQuery: {
      type: Object,
      default: null,
    },
    creatable: {
      type: Boolean,
      default: true,
    },
    updatable: {
      type: Boolean,
      default: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
    copyable: {
      type: Boolean,
      default: false,
    },
    order: {
      type: String,
      default: "last_created",
    },
    plusOrderItems: {
      type: Array,
      default: null,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    createUrl: {
      type: String,
      default: null,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    createHideFields: {
      type: Array,
      default: null,
    },
    updateHideFields: {
      type: Array,
      default: null,
    },
    rowClickRead: {
      type: Boolean,
      default: false,
    },
    filterSelects: {
      type: Object,
      default: null,
    },
    itemsPerPage: {
      type: Number,
      default: 15,
    },
    expandable: {
      type: Boolean,
      // default: true,
    },
    importRoute: {
      type: String,
      default: null,
    },
    pageMode: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    showFieldsRead: {
      type: Array,
    },
    showFieldsUpdate: {
      type: Array,
    },
    showFields: {
      type: Array,
      required: true,
    },
    inRowBtnRead: {
      type: Boolean,
      default: false,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    liveSearching: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      required: true,
    },
    modelName: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    importable: {
      type: Boolean,
      default: false,
    },
    parentModelName: {
      type: String,
    },
    parentId: {
      type: [String, Number],
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    createData: {
      type: Object,
    },
    updateData: {
      type: Object,
    },
    modelDataKey: {
      type: String,
      default: "id",
    },
    filterUniqueSection: {
      type: Boolean,
      default: false,
    },
    hasFilter: {
      type: Boolean,
      default: true,
    },
    urlModelName: {
      type: String,
    },
    orderLayerFields: {
      type: Array,
    },
    orderBySq: {
      type: Boolean,
    },
    hasSticky: {
      type: Boolean,
      default: false,
    },
    hasToggleFilter: {
      type: Boolean,
      default: false,
    },
    swiperNoSwiping: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
    },
    filterTabs: {
      type: Array,
    },
    headerSticky: {
      type: Boolean,
    },
    getUrl: {
      type: String,
    },
    showDataTotalCount: {
      type: Boolean,
    },
  },

  created() {
    window.addEventListener("scroll", this.$_handleScroll);
  },

  destroyed() {
    window.removeEventListener("scroll", this.$_handleScroll);
  },
};
</script>