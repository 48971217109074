import auth from './auth'
import locale from './locale'
import menu from './menu'
import message from './message'
import scopes from './scopes'
import theme from './theme'
import storage from './storage'
import wsmodule from './wsmodule'
import general from './general'
export default {
  auth,
  locale,
  menu,
  message,
  scopes,
  theme,
  storage,
  wsmodule,
  general,
}