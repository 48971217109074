export default {
  modelName: "xc_work_type",
  label: "作業類型",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      labelInLocale: true,
      autofocus: true,
      rules: 'required'
    },
    sq: {
      type: 'text',
      label: '順序設定',
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['id', 'name', 'updated_at', 'created_at'],
      infiniteScroll: true,
      creatableScopes: ['xc_work_type-edit'],
      updatableScopes: ['xc_work_type-edit'],
      deletableScopes: ['xc_work_type-edit'],
    }
  }
}