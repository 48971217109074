<template>
  <div class="ws-state-spec-settings">
    <WsCard
      class="ws-state-spec-settings__spec-setting mb-20"
      :color="$color.black4l"
      v-for="(valueItem,valueIndex) in value"
      :key="valueIndex"
    >
      <WsFlex>
        <WsText class="w-full">{{$t('規格名稱')}}</WsText>
        <WsIconBtn
          @click="$_onSpecSettingRemoveClick(valueIndex)"
          class="ws-state-spec-settings__spec-setting__remove-btn ml-8"
          name="icon-ws-outline-delete"
        ></WsIconBtn>
      </WsFlex>
      <WsState
        class="mt-12"
        :value="valueItem.name"
        :placeholder="$t('請輸入規格名稱')"
        rules="required"
        @input="$_onSpecSettingInput($event,valueIndex,'name')"
      ></WsState>
      <WsText class="mt-12">{{$t('規格選項')}}</WsText>
      <WsStateSort
        :value="valueItem[specSettingItemsModelName]"
        @input="$_onSpecSettingInput($event,valueIndex,specSettingItemsModelName)"
        :useDragHandle="true"
      >
        <template #slick-item="{item,itemIndex}">
          <div class="pl-20">
            <WsCard>
              <WsText>{{$t('規格選項名稱')}}</WsText>
              <WsFlex class="mt-12">
                <WsState
                  class="w-full"
                  :value="item.name"
                  @input="$_onSpecSettingItemInput($event,valueIndex,itemIndex,'name')"
                >
                </WsState>
                <WsIconBtn
                  v-handle
                  class="ml-12"
                  name="icon-ws-outline-drag-horizontal"
                ></WsIconBtn>
                <WsIconBtn
                  @click="$_onSpecSettingItemRemoveClick(valueIndex,itemIndex)"
                  class="ml-12"
                  name="icon-ws-outline-delete"
                ></WsIconBtn>
              </WsFlex>
            </WsCard>
          </div>
        </template>
      </WsStateSort>
      <div class="mt-12 pl-20">
        <WsBtn @click="$_addSpecSettingItem(valueIndex)">{{$t('新增規格選項')}}</WsBtn>
      </div>
    </WsCard>
    <WsBtn
      class="mt-12"
      v-if="_specSettingCreatable"
      @click="$_addSpecSetting()"
    >{{createBtnText}}{{$t('規格')}}</WsBtn>
  </div>
</template>
<script>
export default {
  name: "WsStateSpecSettings",
  data() {
    return {
      fields: {
        specSetting: {
          name: {
            type: "text",
            label: this.$t("規格名稱"),
            placeholder: this.$t("請輸入規格名稱"),
          },
        },
        specSettingItem: {
          name: {
            type: "text",
            label: this.$t("規格選項"),
            placeholder: this.$t("請輸入規格選項"),
          },
        },
      },
    };
  },
  mounted() {
    this.$_initState();
  },
  methods: {
    $_onSpecSettingRemoveClick(index) {
      const _value = this.value ? [...this.value] : [];
      _value.splice(index, 1);
      this.$emit("input", _value);
    },
    $_onSpecSettingItemRemoveClick(specSettingIndex, specSettingItemIndex) {
      const _value = this.value ? [...this.value] : [];
      _value[specSettingIndex][this.specSettingItemsModelName].splice(
        specSettingItemIndex,
        1
      );
      this.$emit("input", _value);
    },
    $_onSpecSettingItemInput(
      $event,
      specSettingIndex,
      specSettingItemIndex,
      target
    ) {
      const _value = this.value ? [...this.value] : [];
      _value[specSettingIndex][this.specSettingItemsModelName][
        specSettingItemIndex
      ][target] = $event;
      this.$emit("input", _value);
    },
    $_onSpecSettingInput($event, index, target) {
      const _value = this.value ? [...this.value] : [];
      _value[index][target] = $event;
      this.$emit("input", _value);
    },
    $_addSpecSettingItem(valueIndex) {
      const _value = this.value ? [...this.value] : [];
      if (!_value[valueIndex][this.specSettingItemsModelName]) {
        _value[valueIndex][this.specSettingItemsModelName] = [];
      }
      _value[valueIndex][this.specSettingItemsModelName].push({
        name: "",
      });
      this.$emit("input", _value);
    },
    $_onInput($event, index) {
      let _value = [...this.value];
      _value[index] = { ..._value[index], ...$event };
      this.$emit("input", _value);
    },
    $_addSpecSetting() {
      const _value = this.value ? [...this.value] : [];
      _value.push({
        name: "",
        [this.specSettingItemsModelName]: [
          {
            name: "",
          },
        ],
      });
      this.$emit("input", _value);
    },
    $_addState() {
      let _value = [];
      let _state = {};
      if (this.value && this.value.length) {
        _value = [...this.value];
      }
      for (let itemKey in this.fields) {
        _state[itemKey] = null;
      }
      _state[this.specSettingItemsModelName] = [_state];
      _value.push(_state);
      this.$emit("input", _value);
    },
    $_addInnerState(index) {
      let _value = [];
      let _state = {};
      if (this.value && this.value.length) {
        _value = [...this.value];
      }
      for (let itemKey in this.fields) {
        _state[itemKey] = null;
      }
      _state._key = (Math.random() + 1).toString(36).substring(6);
      if (
        this.value[index][this.specSettingItemsModelName] &&
        this.value[index][this.specSettingItemsModelName].length
      ) {
        _value[index][this.specSettingItemsModelName].push(_state);
      } else {
        _value[index][this.specSettingItemsModelName] = [_state];
      }
      this.$emit("input", _value);
    },
    $_initState() {
      let _value = [];
      if (this.value && this.value.length) {
        _value = [...this.value];
        let i = 0;
        for (i = 0; i < this.value.length; i++) {
          for (let itemKey in this.fields) {
            if (_value[i][itemKey] !== 0 && !_value[i][itemKey]) {
              _value[i][itemKey] = null;
            }
          }
          _value[i]._key = (Math.random() + 1).toString(36).substring(6);
          if (_value[i] && _value[i][this.specSettingItemsModelName].length) {
            for (
              let j = 0;
              j < _value[i][this.specSettingItemsModelName].length;
              j++
            ) {
              _value[i][this.specSettingItemsModelName][j]._key = (
                Math.random() + 1
              )
                .toString(36)
                .substring(8);
            }
          }
        }
      }
      const valueLength =
        this.value && this.value.length ? this.value.length : 0;
      let i = 0;
      if (this.initValueLength - valueLength > 0) {
        for (i = 0; i < this.initValueLength - valueLength; i++) {
          let _state = {};
          for (let itemKey in this.fields) {
            _state[itemKey] = null;
          }
          _state._key = (Math.random() + 1).toString(36).substring(6);
          _value.push(_state);
        }
      }
      this.$emit("input", _value);
    },
    $_deleteState(index) {
      let _value = [...this.value];
      _value.splice(index, 1);
      this.$emit("input", _value);
    },
    $_deleteInnerState(index, innerIndex) {
      let _value = [...this.value];
      _value[index][this.specSettingItemsModelName].splice(innerIndex, 1);
      this.$emit("input", _value);
    },
    $_innerOnInput($event, index, innerIndex) {
      let _value = [...this.value];
      _value[index][this.specSettingItemsModelName][innerIndex] = {
        ..._value[index][innerIndex],
        ...$event,
      };
      this.$emit("input", _value);
    },
  },
  computed: {
    _specSettingCreatable() {
      if (!this.creatable) {
        return false;
      }
      if (!this.value) {
        return true;
      }
      if (this.valueLengthLimit > this.value.length) {
        return true;
      } else {
        return false;
      }
    },
    _showAddBtn() {
      if (!this.creatable) {
        if (this.value && this.initValueLength > this.value.length) {
          return true;
        } else {
          return false;
        }
      }
      if (this.valueLengthLimit !== 0 && !this.valueLengthLimit) return true;
      if (
        this.value &&
        this.value.length &&
        this.valueLengthLimit > this.value.length
      ) {
        return true;
      } else {
        return false;
      }
    },
    _innerFields() {
      if (!this.fields || !this.fields.length) return [];
      let _innerFields = [...this.fields];
      _innerFields.splice(0, 1);
      return _innerFields;
    },
  },
  props: {
    value: {
      type: Array,
    },
    createBtnText: {
      type: String,
      default() {
        return this.$t("新增");
      },
    },
    // fields: {
    //   type: Object,
    // },
    creatable: {
      type: Boolean,
      default: true,
    },
    initValueLength: {
      type: Number,
      default: 1,
    },
    valueLengthLimit: {
      type: Number,
    },
    specSettingItemsModelName: {
      type: String,
    },
  },
};
</script>