export default {
  modelName: 'user_device_token',
  label: 'User Device Token',
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    device_token: {
      type: 'text',
      label: 'Device Token'
    },
    is_active: {
      type: 'switch',
      label: '啟用狀態',
      activeText: 'On',
      inactiveText: 'Off',
      defaultValue: true
    },
    remark: {
      type: 'text',
      label: '備註',
    },
    user: {
      type: "belongsTo",
      label: "使用者",
      rules: 'required',
      textKey: "name",
      modelName: "user",
    }
  }
}