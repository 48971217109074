<template>
  <div
    v-if="editor"
    class="ws-state-editor"
  >
    <div class="menubar">
      <div class="toolbar">
        <WsIconBtn
          @click="editor.chain().focus().undo().run()"
          :disabled="!editor.can().undo()"
          name="icon-md-undo"
        />
        <WsIconBtn
          @click="editor.chain().focus().redo().run()"
          :disabled="!editor.can().redo()"
          name="icon-md-redo"
        />
        <WsBtnColor
          @input="editor.chain().focus().setColor($event.target.value).run()"
          :value="editor.getAttributes('textStyle').color"
        ></WsBtnColor>
        <WsIconBtn
          @click="editor.chain().focus().setTextAlign('left').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }"
          name="icon-md-format-align-left"
        />
        <WsIconBtn
          @click="editor.chain().focus().setTextAlign('center').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }"
          name="icon-md-format-align-center"
        />
        <WsIconBtn
          @click="editor.chain().focus().setTextAlign('right').run()"
          :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }"
          name="icon-md-format-align-right"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
          name="icon-md-format-bold"
        />

        <WsIconBtn
          @click="editor.chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
          name="icon-md-format-italic"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
          name="icon-md-strikethrough-s"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
          name="icon-md-format-underlined"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleCode().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
          name="icon-md-code"
        />
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        >H1</WsIconBtn>
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        >H2</WsIconBtn>
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        >H3</WsIconBtn>
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
        >H4</WsIconBtn>
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
        >H5</WsIconBtn>
        <WsIconBtn
          text
          @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
          :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
        >H6</WsIconBtn>
        <WsIconBtn
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
          name="icon-md-format-quote"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
          name="icon-md-format-list-bulleted"
        />
        <WsIconBtn
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
          name="icon-md-format-list-numbered"
        />
        <WsIconBtn
          @click="$_linkSetOpen()"
          :class="{ 'is-active': editor.isActive('link') }"
          name="icon-md-link"
        />
        <WsIconBtn
          @click="$_imageSetOpen()"
          name="icon-md-image"
        />
      </div>
      <div class="toolbar">
        <WsBtn
          outlined
          @click="editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()"
        >
          {{$t('加入表格')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addColumnBefore().run()"
          :disabled="!editor.can().addColumnBefore()"
        >
          {{$t('向左加入一列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addColumnAfter().run()"
          :disabled="!editor.can().addColumnAfter()"
        >
          {{$t('向右加入一列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteColumn().run()"
          :disabled="!editor.can().deleteColumn()"
        >
          {{$t('刪除列')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addRowBefore().run()"
          :disabled="!editor.can().addRowBefore()"
        >
          {{$t('向上加入一行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().addRowAfter().run()"
          :disabled="!editor.can().addRowAfter()"
        >
          {{$t('向下加入一行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteRow().run()"
          :disabled="!editor.can().deleteRow()"
        >
          {{$t('刪除行')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().deleteTable().run()"
          :disabled="!editor.can().deleteTable()"
        >
          {{$t('刪除表格')}}
        </WsBtn>
        <!-- <WsBtn
        outlined
          @click="editor.chain().focus().mergeCells().run()"
          :disabled="!editor.can().mergeCells()"
        >
          mergeCells
        </WsBtn>
        <WsBtn
        outlined
          @click="editor.chain().focus().splitCell().run()"
          :disabled="!editor.can().splitCell()"
        >
          splitCell
        </WsBtn> -->
        <!-- <WsBtn
        outlined
          @click="editor.chain().focus().toggleHeaderColumn().run()"
          :disabled="!editor.can().toggleHeaderColumn()"
        >
          toggleHeaderColumn
        </WsBtn>
        <WsBtn
        outlined
          @click="editor.chain().focus().toggleHeaderRow().run()"
          :disabled="!editor.can().toggleHeaderRow()"
        >
          toggleHeaderRow
        </WsBtn> -->
        <WsBtn
          outlined
          @click="editor.chain().focus().toggleHeaderCell().run()"
          :disabled="!editor.can().toggleHeaderCell()"
        >
          {{$t('設為表頭')}}
        </WsBtn>
        <WsBtn
          outlined
          @click="editor.chain().focus().mergeOrSplit().run()"
          :disabled="!editor.can().mergeOrSplit()"
        >
          {{$t('合併儲存格')}}
        </WsBtn>
        <!-- <WsBtn
        outlined
          @click="editor.chain().focus().setCellAttribute('backgroundColor', '#FAF594').run()"
          :disabled="!editor.can().setCellAttribute('backgroundColor', '#FAF594')"
        >
          setCellAttribute
        </WsBtn>
        <WsBtn
        outlined
          @click="editor.chain().focus().fixTables().run()"
          :disabled="!editor.can().fixTables()"
        >
          fixTables
        </WsBtn>
        <WsBtn
        outlined
          @click="editor.chain().focus().goToNextCell().run()"
          :disabled="!editor.can().goToNextCell()"
        >
          goToNextCell
        </WsBtn>
        <WsBtn
        outlined
          @click="editor.chain().focus().goToPreviousCell().run()"
          :disabled="!editor.can().goToPreviousCell()"
        >
          goToPreviousCell
        </WsBtn> -->
      </div>
    </div>
    <!-- </editor-menu-bar> -->
    <WsMain
      @click.native="$_onContentClick()"
      class="ws-state-editor__content"
    >
      <editor-content :editor="editor" />
    </WsMain>
    <WsPocketImagePicker
      v-model="active.pocketImagePicker"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :uploadable="uploadable"
      :linkable="linkable"
      :pocketable="pocketable"
      @submit="$_onPocketImageSubmit($event)"
    />
    <WsPopup
      title="設定連結"
      ref="linkPopup"
    >
      <template v-slot:content>
        <WsStateInput
          v-model="state.link"
          autofocus
          full
        ></WsStateInput>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onLinkClear()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('clear')}}</WsBtn>
        <WsBtn
          @click="$_onLinkCancel()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onLinkSubmit()"
          :minWidth="100"
          color="primary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
import S_App_Image from "@/__vue2stone_cms/service/app/image";
import { Editor, EditorContent } from "@tiptap/vue-2";
import { Color } from "@tiptap/extension-color";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Blockquote from "@tiptap/extension-blockquote";
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import CodeBlock from "@tiptap/extension-code-block";
import Heading from "@tiptap/extension-heading";
import Image from "@tiptap/extension-image";
import Dropcursor from "@tiptap/extension-dropcursor";
import TextAlign from "@tiptap/extension-text-align";
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TextStyle from "@tiptap/extension-text-style";

export default {
  components: {
    EditorContent,
  },

  props: {
    value: {
      type: String,
      default: "",
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
    },
    valueType: {
      type: String,
      default: "html",
    },
    uploadable: {
      type: Boolean,
      default: true,
    },
    linkable: {
      type: Boolean,
      default: true,
    },
    pocketable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      editor: null,
      state: {
        link: "",
      },
      active: {
        linkPopup: false,
        pocketImagePicker: false,
      },
      test: null,
    };
  },
  methods: {
    $_onContentClick() {
      this.editor.view.dom.focus();
    },
    $_onLinkCancel() {
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_onLinkClear() {
      this.editor.chain().focus().unsetLink().run();
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_onLinkSubmit() {
      this.editor
        .chain()
        .focus()
        .extendMarkRange("link")
        .setLink({ href: this.state.link })
        .run();
      this.state.link = "";
      this.$refs.linkPopup.close();
    },
    $_linkSetOpen() {
      this.$refs.linkPopup.open();
    },
    async $_onPocketImageSubmit($event) {
      let src;
      if ($event.signed) {
        src = await S_App_Image.getBase64FromUrl($event.signed_url);
      } else {
        src = $event.url;
      }

      if (src) {
        this.editor.chain().focus().setImage({ src: src }).run();
      }
    },
    $_imageSetOpen() {
      this.active.pocketImagePicker = true;
    },
  },

  watch: {
    value(value) {
      let isSame;

      if (this.valueType == "json") {
        isSame = this.editor.getJSON().toString() === value.toString();
      } else {
        isSame = this.editor.getHTML() === value;
      }
      if (isSame) {
        return;
      }
      this.editor.commands.setContent(this.value, false);
    },
  },

  mounted() {
    this.editor = new Editor({
      extensions: [
        StarterKit,
        Table.configure({
          resizable: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Document,
        Paragraph,
        Text,
        Color,
        TextStyle,
        Blockquote,
        BulletList,
        ListItem,
        CodeBlock,
        Heading,
        Image,
        Dropcursor,
        OrderedList,
        TextAlign.configure({
          types: ["heading", "paragraph"],
        }),
        Bold,
        Italic,
        Strike,
        Underline,
        Link,
      ],
      content: this.value,
      onUpdate: () => {
        if (this.valueType == "json") {
          this.$emit("input", this.editor.getJSON());
          this.test = this.editor.getJSON();
        } else {
          this.$emit("input", this.editor.getHTML());
        }
      },
    });
  },

  beforeUnmount() {
    this.editor.destroy();
  },
};
</script>