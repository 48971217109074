import i18n from '@/i18n';
const model = {
  modelName: 'shop_class',
  label: i18n.t('商品分類'),
  orderLayerFields: [
    {
      key: 'shop_subclasses',
    },
  ],
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    uuid: {
      type: 'text',
      label: 'UUID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: i18n.t('分類名稱'),
      autofocus: true,
    },
    icon: {
      type: 'image',
      label: i18n.t('分類圖片'),
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    sq: {
      type: 'text',
      label: i18n.t('順序設定'),
    },
    type: {
      type: 'select',
      label: i18n.t('分類類型'),
      defaultValue: 'current',
      items: [
        {
          text: i18n.t('現貨商品'),
          value: 'current',
        },
        {
          text: i18n.t('預售商品'),
          value: 'pre_order',
        },
      ],
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    order_type: {
      type: 'select',
      label: i18n.t('訂單類別'),
      placeholder: i18n.t('請選擇訂單類別'),
      items: [
        {
          value: 'next-day',
          text: i18n.t('雙北'),
        },
        {
          value: 'pre-order',
          text: i18n.t('預購'),
        },
      ],
    },
    is_active: {
      type: 'switch',
      label: i18n.t('是否顯示於前台'),
      activeText: i18n.t('顯示'),
      inactiveText: i18n.t('隱藏'),
      updatable: {
        getAlertContent: function (data) {
          let _isOutstanding = data.is_outstanding
            ? i18n.t('顯示')
            : i18n.t('隱藏');
          return `確認要將此商品“${data.name}”${_isOutstanding}嗎？`;
        },
      },
      width: '140px',
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['id', 'name', 'is_active'],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true,
      orderBySq: true,
      filterTabs: [
        {
          text: i18n.t('雙北'),
          value: 'next-day',
          params: {
            order_type: 'next-day',
          },
        },
        {
          text: i18n.t('預購'),
          value: 'pre-order',
          params: {
            order_type: 'pre-order',
          },
        },
      ],
      customTableActions: [
        {
          icon: "icon-md-view-list",
          emit: "shop_product",
          tooltip: i18n.t("管理商品"),
        },
      ],
    },
  },
};

export default model;
