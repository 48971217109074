import i18n from '@/i18n';
import config from '@/__config';
import S_ShopOrderShopProduct from '@/__vue2stone_cms/service/api/v1/shop_order_shop_product'

const model = {
  modelName: 'shop_order_shop_product',
  fields: {
    name: {
      label: i18n.t('商品名稱'),
      type: 'text',
    },
    count: {
      label: i18n.t('購買數量'),
      // type: 'number',
      type: 'custom',
      getValue: (item) => {
        let count = item.shop_cart_product.count
        if (item.shop_cart_product.count != item.count) {
          count += `(${item.count})`
        }
        return count
      }
    },
    price: {
      label: i18n.t('單價'),
      type: 'custom',
      getValue(item) {
        if (item.price_campaigned) {
          return `<del>${item.price}</del> ${item.price_campaigned}`
        } else {
          return item.price
        }
      },
    },
    discount_price: {
      type: 'number',
      label: i18n.t('優惠價'),
    },
    spec: {
      label: i18n.t('規格'),
      type: 'text',
    },
    weight_capacity: {
      label: i18n.t('重量/容量'),
      type: 'number',
    },
    change_reason: {
      type: 'custom',
      label: '變動原因',
      getValue: (item) => {
        return S_ShopOrderShopProduct.getChangeReason(item)
      }
    },
    shop_cart_product: {
      type: 'belongsTo',
      label: '購物車商品資訊',
      modelName: "shop_cart_product",
      relationPopup: true
    },
    current_num: {
      label: i18n.t('實際出貨數量'),
      type: 'custom',
      getValue(data) {
        let _returnCount = 0;
        data.shop_return_records.forEach((item) => {
          _returnCount += item.count;
        });
        return Number(data.count) - _returnCount;
      },
    },
    shop_campaigns_name: {
      type: 'custom',
      label: '商品促銷活動',
      color: 'red',
      getValue(item) {
        const _arr = []
        item.shop_campaigns.forEach(shop_campaign => {
          _arr.push(shop_campaign.name)
        });
        return _arr.join(',')
      },
    }
  },
};

if (config.wsmodule.shop) {
  if (config.wsmodule.shop.discount_price) {
    model.fields.discount_price = {
      type: 'number',
      label: i18n.t('優惠價'),
      rules: 'required|min_value:1|integer',
    };
  }
}

export default model;
