<template>
  <WsAnalyticsCard :title="label">
    <template v-slot:content>
      <WsText
        size="48"
        class="py-66"
        bold
        align="center"
      >{{value}}</WsText>
    </template>
  </WsAnalyticsCard>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>