// import config from '@/__config'

export default {
  getModuleModels() {
    let modelesJson = {}
    const moduleFiles = this.getModulesJson()
    if (!moduleFiles) {
      return
    }
    for (const moduleKey in moduleFiles) {
      if (!moduleFiles[moduleKey].models) {
        continue
      }
      modelesJson = {
        ...modelesJson,
        ...moduleFiles[moduleKey].models
      }
    }
    return modelesJson
  },
  getModulesContext() {
    try {
      return require.context('@' + '/__modules/', true, /\.js/i);
    } catch (error) {
      return
    }
  },
  getModulesJson() {
    const modules = this.getModulesContext()
    if (!modules) {
      return
    }
    const moduleFiles = {}
    modules.keys().forEach(modulePath => {
      const keyArr = modulePath.split('/')
      const key = keyArr[1]
      if (!moduleFiles[key]) {
        moduleFiles[key] = {}
      }
      if (!moduleFiles[key][keyArr[2]]) {
        moduleFiles[key][keyArr[2]] = {}
      }
      if (keyArr[2] == 'models') {
        const modelKey = keyArr[3].split('.')[0]
        moduleFiles[key][keyArr[2]][modelKey] = modules(modulePath).default
      }
    });
    return moduleFiles
  }
}