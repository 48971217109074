export default {
  modelName: "user_service_plan_item",
  label: "會員綁定方案項目",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    service_plan: {
      type: "belongsTo",
      label: "服務方案",
      labelInLocale: true,
      textKey: "name",
      modelName: "service_plan"
    },
    service_plan_item: {
      type: "belongsTo",
      label: "服務方案項目",
      labelInLocale: true,
      textKey: "name",
      modelName: "service_plan_item"
    },
    user_service_plan: {
      type: "belongsTo",
      label: "會員綁定方案",
      labelInLocale: true,
      textKey: "name",
      modelName: "user_service_plan"
    },
    user: {
      type: "belongsTo",
      label: "會員",
      labelInLocale: true,
      textKey: "name",
      modelName: "user"
    },
    content: {
      type: 'text',
      label: '項目內容',
      labelInLocale: true,
    },
    total_count: {
      type: "number",
      label: "總次數",
      labelInLocale: true,
    },
    remain_count: {
      type: "number",
      label: "剩餘次數",
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
  },
}
