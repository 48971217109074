export default {
  modelName: "system_subclass",
  label: "子領域",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序",
      maxlength: 10
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: 'required',
      maxlength: 30
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      textKey: "name",
      readonly: true,
    },
  },
}
