export default {
  getValueFromFieldAndFormValue(field, formValue, key) {
    if (field.type == 'date-range-or-not') {
      if (formValue.start_date && formValue.end_date) {
        return {
          startDate: formValue.start_date,
          endDate: formValue.end_date,
        }
      } else {
        return {}
      }
    } else {
      return formValue[key]
    }
  }
}