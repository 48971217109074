<template>
  <div class="ws-table">
    <WsNoDataMessage v-if="(!items||!items.length)&&!loading">{{$t('no_data')}} :(</WsNoDataMessage>
    <div
      v-else
      class="ws-table__table-container"
    >
      <table
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
          >
            <span>{{headersItem.text}}</span>
          </th>
          <th v-if="actions">
          </th>
        </tr>
        <tr
          :key="itemIndex"
          v-for="(item,itemIndex) in items"
        >
          <td
            v-for="(headersItem,headersIndex) in headers"
            :key="headersIndex"
          >{{$_getItemValue(item,headersItem)}}</td>
          <td v-if="actions">
            <WsFlex>
              <div
                v-for="(actionItem, actionIndex) in actions"
                :key="actionIndex"
              >
                <WsIconBtn
                  class="ma-4"
                  v-if="$_actionDisplayCheck(actionItem,item)"
                  :name="actionItem.icon"
                  :to="$_getTo(actionItem,item)"
                  @click="$emit(actionItem.emit,{
                    item:item,
                    itemIndex:itemIndex
                  })"
                ></WsIconBtn>
              </div>
            </WsFlex>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    $_getTo(actionItem, item) {
      if (actionItem.getTo) {
        return actionItem.getTo(item);
      } else if (actionItem.to) {
        return actionItem.to;
      } else {
        return undefined;
      }
    },
    $_getItemValue(item, headersItem) {
      if (item[headersItem.value] !== null) {
        return item[headersItem.value];
      } else {
        return "-";
      }
    },
    $_actionDisplayCheck(actionItem, item) {
      if (!actionItem.displayCheck) {
        return true;
      } else {
        return actionItem.displayCheck(item);
      }
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array,
    },
    headers: {
      type: Array,
    },
    actions: {
      type: Array,
    },
  },
};
</script>

<style>
</style>