import i18n from '@/i18n';

export default {
  modelName: "page_setting",
  label: i18n.t('頁面設定'),
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    route: {
      type: "text",
      label: i18n.t('網站路徑'),
      rules: 'required',
    },
    name: {
      type: 'text',
      label: i18n.t('名稱'),
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ["id", "route", "name"],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true
    },
    read: {
      leftFields: [
        'route',
      ],
      titleKey: 'name',
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: `${i18n.t('新增')}${i18n.t('頁面設定')}`,
              fields: [
                'name',
                'route',
              ],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: `${i18n.t('編輯')}${i18n.t('頁面設定')}`,
              fields: [
                'name',
                'route',
              ],
            },
          ],
        },
      ],
    },
  }
}
