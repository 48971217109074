import config from '@/__config';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_product',
  urlModelName: 'shop_product_pre_order',
  label: i18n.t('預購商品'),
  fields: {
    id: {
      type: 'text',
      label: i18n.t('商品') + 'ID',
      readonly: true,
    },
    uuid: {
      type: 'text',
      label: i18n.t('商品') + 'UUID',
      readonly: true,
    },
    no: {
      type: 'text',
      label: i18n.t('商品編號'),
    },
    name: {
      type: 'text',
      label: i18n.t('商品名稱'),
      rules: 'required',
      autofocus: true,
    },
    subtitle: {
      type: 'text',
      label: i18n.t('商品副標'),
    },
    // status: {
    //   type: 'text',
    //   label: '狀態'
    // },
    on_time: {
      type: 'datetime',
      label: i18n.t('上架時間'),
    },
    off_time: {
      type: 'datetime',
      label: i18n.t('下架時間'),
    },
    is_active: {
      type: 'switch',
      label: i18n.t('商品狀態'),
      activeText: i18n.t('已上架'),
      inactiveText: i18n.t('下架'),
      updatable: {
        getAlertContent: function(data) {
          let _isActive = data.is_active ? i18n.t('上架') : i18n.t('下架');
          return `確認要將此商品“${data.name}”${_isActive}嗎？`;
        },
      },
    },
    spec: {
      type: 'text',
      label: i18n.t('商品規格'),
    },
    cost: {
      type: 'number',
      label: i18n.t('成本'),
    },
    price: {
      type: 'number',
      label: i18n.t('售價'),
    },
    weight_capacity: {
      type: 'text',
      label: `${i18n.t('重量')}/${i18n.t('容量')}`,
    },
    weight_capacity_unit: {
      type: 'select',
      label: i18n.t('單位'),
      placeholder: i18n.t('選擇單位'),
      items: [
        {
          text: 'kg',
          value: 'kg',
        },
        {
          text: 'ml',
          value: 'ml',
        },
      ],
    },
    tax: {
      type: 'switch',
      label: `${i18n.t('應稅')}/${i18n.t('免稅')}`,
      activeText: i18n.t('應稅'),
      inactiveText: i18n.t('免稅'),
      defaultValue: true,
    },
    stock_count: {
      type: 'number',
      label: i18n.t('庫存數量'),
    },
    stock_alert_count: {
      type: 'number',
      label: i18n.t('庫存預警數量'),
    },
    max_buyable_count: {
      type: 'number',
      label: i18n.t('最大可購買數量'),
    },
    storage_space: {
      type: 'text',
      label: i18n.t('商品儲位'),
    },
    storage_temperture: {
      type: 'text',
      label: i18n.t('溫層'),
    },
    freight: {
      type: 'text',
      label: i18n.t('運費'),
    },
    cover_image: {
      type: 'image',
      label: i18n.t('商品封面圖'),
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    shop_product_cover_frame: {
      type: 'belongsTo',
      label: i18n.t('活動圖框'),
      relationPopup: true,
      textKey: 'name',
      modelName: 'shop_product_cover_frame',
    },
    images: {
      type: 'images',
      label: i18n.t('商品圖'),
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    description: {
      type: 'editor',
      label: i18n.t('商品簡介'),
    },
    ranking_score: {
      type: 'number',
      label: i18n.t('熱門分數'),
    },
    sq: {
      type: 'number',
      label: i18n.t('順序'),
      placeholder: i18n.t('請輸入順序'),
    },
    shop_product_spec_settings: {
      type: 'spec_settings',
      label: i18n.t('規格'),
      initValueLength: 1,
      valueLengthLimit: 2,
      specSettingItemsModelName: 'shop_product_spec_setting_items',
      // fields: {
      //   name: {
      //     type: 'text',
      //     label: i18n.t('名稱'),
      //     placeholder: i18n.t('請輸入規格主題'),
      //   },
      //   // sq: {
      //   //   type: 'number',
      //   //   label: i18n.t('順序'),
      //   //   placeholder: i18n.t('請輸入順序'),
      //   // },
      // },
    },
    shop_product_specs: {
      type: 'specs',
      label: '',
      settingHeaders: function(data) {
        let _arr = [];
        if (!data || !data.shop_product_spec_settings) return [];
        _arr = data.shop_product_spec_settings.map((item) => {
          return item.name || '-';
        });
        return _arr;
      },
      settingValue: function(data) {
        let arr = [];
        if (
          !data ||
          !data.shop_product_spec_settings ||
          !data.shop_product_spec_settings.length
        )
          return [];
        arr = data.shop_product_spec_settings.map((item) => {
          return item.shop_product_spec_setting_items;
        });
        return arr;
      },
      fields: {
        no: {
          type: 'text',
          label: i18n.t('產品編號'),
        },
        cost: {
          type: 'number',
          label: i18n.t('成本'),
        },
        price: {
          type: 'number',
          label: i18n.t('售價'),
        },
        start_at: {
          type: 'date',
          label: i18n.t('開始日期'),
        },
        end_at: {
          type: 'date',
          label: i18n.t('結束日期'),
        },
        freight: {
          type: 'number',
          label: i18n.t('運費'),
        },
        max_buyable_count: {
          type: 'number',
          label: i18n.t('最大可購買數量'),
        },
        storage_space: {
          type: 'number',
          label: i18n.t('儲位'),
        },
        stock_count: {
          type: 'number',
          label: i18n.t('庫存'),
        },
        stock_alert_count: {
          type: 'number',
          label: i18n.t('庫存預警'),
        },
      },
    },
    shop_classes: {
      type: 'belongsToMany',
      label: i18n.t('商品分類'),
      modelName: 'shop_class',
      extendParams: {
        order_type: 'pre-order',
      },
      labelInLocale: true,
    },
    shop_subclasses: {
      type: 'belongsToMany',
      label: i18n.t('商品子分類'),
      modelName: 'shop_subclass',
      labelInLocale: true,
      conditions: [
        {
          type: 'array',
          value: '',
          key: 'shop_classes',
        },
      ],
      getExtendParamsFromValue(value) {
        if (!value) {
          return {};
        } else if (value.shop_classes) {
          return {
            shop_class: value.shop_classes.map((item) => item.id).toString(),
          };
        } else {
          return {};
        }
      },
      requiredField: 'shop_classes',
    },
    stock_level: {
      label: i18n.t('商品庫存狀態'),
      type: 'icon',
      getDisplayText(value) {
        if (value.stock_count < value.stock_alert_count) {
          //低於預警數量
          return `icon-ws-filled-alert`;
        } else {
          //庫存量充足
          return `-`;
        }
      },
      col: 3,
    },
    duration_switch: {
      type: 'radio-input',
      label: i18n.t('上下架時間'),
      items: [
        {
          text: i18n.t('時間區間'),
          value: 'interval',
          fields: {
            start_date: {
              type: 'date',
              label: i18n.t('開始時間'),
              placeholder: i18n.t('選擇起日'),
              readonly: true,
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.end_date) {
                  if (this.$moment(date).isAfter(data.end_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
            end_date: {
              type: 'date',
              label: i18n.t('結束時間'),
              placeholder: i18n.t('選擇迄日'),
              readonly: true,
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.start_date) {
                  if (this.$moment(date).isBefore(data.start_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
          },
        },
        {
          text: i18n.t('永不下架'),
          value: 'infinite',
        },
      ],
    },
    featured_classes: {
      type: 'belongsToMany',
      label: i18n.t('精選分類'),
      modelName: 'featured_class',
      extendParams: {
        order_type: 'pre-order',
      },
      labelInLocale: true,
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: [
        'stock_level',
        'no',
        'name',
        'spec',
        'is_active',
        'cost',
        'price',
        'discount_price',
        'tax',
        'stock_count',
        'stock_alert_count',
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      pageMode: true,
      filterUniqueSection: true,
      params: {
        order_type: 'pre-order',
      },
      titleBarCustomBtns: [
        {
          label: i18n.t('匯出所有商品庫存'),
          event: 'export-product',
        },
        {
          label: i18n.t('匯入商品庫存'),
          event: 'import-product',
        },
      ],
      filterSelects: {
        shop_classes: {
          label: i18n.t('篩選商品主分類'),
          type: 'belongsToMany',
          placeholder: i18n.t('選擇商品主分類'),
          relationPopup: true,
          modelName: 'shop_class',
          textKey: 'name',
          col: 3,
        },
        shop_subclasses: {
          label: i18n.t('篩選商品次分類'),
          type: 'belongsToMany',
          placeholder: i18n.t('選擇商品次分類'),
          relationPopup: true,
          modelName: 'shop_subclass',
          getExtendParamsFromValue(value) {
            if (!value) {
              return {};
            } else if (value.shop_classes) {
              const _shop_class = value.shop_classes
                .map((item) => item.id)
                .toString();
              return {
                shop_class: _shop_class,
              };
            } else {
              return {};
            }
          },
          conditions: [
            {
              type: 'array',
              value: '',
              key: 'shop_class',
            },
          ],
          requiredField: 'shop_classes',
          col: 3,
        },
        is_active: {
          label: i18n.t('篩選商品狀態'),
          type: 'multi-select',
          placeholder: i18n.t('所有商品狀態'),
          selectAll: true,
          items: [
            {
              text: i18n.t('已上架'),
              value: 1,
            },
            {
              text: i18n.t('下架'),
              value: 0,
            },
          ],
          col: 3,
        },
        stock_level: {
          label: i18n.t('篩選商品庫存狀態'),
          type: 'select',
          placeholder: i18n.t('所有商品庫存狀態'),
          selectAll: true,
          items: [
            {
              text: i18n.t('所有商品庫存狀態'),
              value: '',
            },
            {
              text: i18n.t('商品庫存已達預警'),
              value: 2,
            },
            {
              text: i18n.t('商品庫存未達預警'),
              value: 1,
            },
          ],
          col: 3,
        },
      },
    },
  },
};

if (config.wsmodule.shop) {
  if (config.wsmodule.shop.discount_price) {
    model.fields.discount_price = {
      type: 'number',
      label: i18n.t('優惠價'),
    };
    model.fields.shop_product_specs.fields.discount_price = {
      type: 'number',
      label: i18n.t('優惠價'),
    };
  }
}

if (config.wsmodule.area) {
  model.fields.areas = {
    type: 'belongsToMany',
    label: i18n.t('地區'),
    modelName: 'area',
    labelInLocale: true,
  };
  model.fields.area_sections = {
    type: 'belongsToMany',
    label: i18n.t('子地區'),
    modelName: 'area_section',
    labelInLocale: true,
  };
}

export default model;
