import penguin from './penguin'
export default {
  modelName: "ice",
  label: "冰塊",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: "text",
      label: "名稱",
      rules: 'required',
      autofocus: true
    },
    penguins: {
      type: 'hasMany',
      label: '企鵝',
      textKey: "name",
      modelName: "penguin",
      relationPopup: true,
      fields: penguin.fields,
      showFields: [
        'name',
      ]
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      readonly: true,
      textKey: "name",
      modelName: "wasa"
    },
  },
}