<template>
  <WsCard>
    <WsFlex>
      <WsText
        class="flex-0"
        size="5"
      >{{title}}</WsText>
      <WsSpacer></WsSpacer>
      <slot name="titleright"></slot>
    </WsFlex>
    <WsMain>
      <slot name="content"></slot>
    </WsMain>
  </WsCard>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>