<template>
  <WsCard
    class="ws-state-tulpa-section-content"
    :color="color"
  >
    <WsFlex justify-content="space-between">
      <WsText>{{tulpaSection.name}}</WsText>
      <WsIconBtn
        v-if="removable"
        @click="$emit('remove')"
        name="icon-md-delete"
      />
    </WsFlex>
    <WsRow class="mt-10">
      <WsCol :class="$config.wsmodule.tulpa.defaultContent?'col-7':'col-12'">
        <WsCard class="mt-10">
          <WsState
            type="form"
            :fields="tulpaSection.fields"
            :value="value"
            @input="$emit('input',$event)"
          ></WsState>
        </WsCard>
      </WsCol>
      <WsCol
        v-if="$config.wsmodule.tulpa.defaultContent"
        class="col-5"
      >
        <WsText size="5">{{$t('未輸入之預設值')}}</WsText>
        <WsCard class="mt-10">
          <WsInfo
            type="form"
            :fields="tulpaSection.fields"
            :value="tulpaSection[defaultContentKey]"
          ></WsInfo>
        </WsCard>
      </WsCol>
    </WsRow>
  </WsCard>
</template>

<script>
export default {
  props: {
    value: {
      type: [Object, Array],
    },
    tulpaSection: {
      type: Object,
    },
    color: {
      type: String,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    defaultContentKey: {
      type: String,
      default: "defaultContent",
    },
  },
};
</script>