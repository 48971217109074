import config from '@/__config'
export default {
  modelName: config.auth.admin_blur ? 'cmser_group' : "admin_group",
  label: "管理員分群",
  fields: {
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
  },
}
