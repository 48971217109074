<template>
  <RouterLink
    class="ws-menu-item"
    :to="link"
    v-ripple.300
  >
    <WsIcon
      v-if="icon"
      :name="icon"
    />
    <WsText
      v-if="titleInLocale"
      size="14"
    >{{$t(title)}}</WsText>
    <WsText
      v-else
      size="14"
    >{{title}}</WsText>
  </RouterLink>
</template>

<script>
export default {
  props: {
    link: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    titleInLocale: {
      type: Boolean,
      default: false,
    },
  },
};
</script>