<template>
  <div class="ws-analytics">
    <WsAnalyticsText
      v-if="type=='text'"
      :label="label"
      :value="value"
    ></WsAnalyticsText>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: null,
    },
  },
};
</script>

<style>
</style>