<template>
  <div class="ws-info">
    <WsText
      v-if="_label"
      size="12"
      class="ws-info__label"
    >{{_label}}</WsText>
    <div class="ws-info__content">
      <WsText v-if="(value==null||value.length==0||(typeof(value)=='object'&&Object.keys(value).length===0))&&type!='custom'&&type!='icon'&&type!='date-range-or-not'"> - </WsText>
      <WsInfoText
        v-else-if="type=='text'||type=='password'||type=='email'||type=='textarea'||type=='number'||type=='checkbox'||type=='rules'||type=='year'"
        :getDisplayText="getDisplayText"
        :value="value"
      ></WsInfoText>
      <WsInfoCurrency
        v-else-if="type=='currency'"
        :value="value"
      ></WsInfoCurrency>
      <WsInfoLink
        v-else-if="type=='link'"
        :value="value"
        :displayInText="displayInText"
      ></WsInfoLink>
      <WsInfoLocales
        v-else-if="type=='locales'"
        :value="value"
        :fields="fields"
        :locale="locale"
        :hideOnNull="hideOnNull"
        :showLocalePicker="showLocalePicker"
      ></WsInfoLocales>
      <WsInfoTime
        v-else-if="type=='time'"
        :value="value"
      ></WsInfoTime>
      <WsInfoDatetime
        v-else-if="type=='datetime'"
        :value="value"
      ></WsInfoDatetime>
      <WsInfoDate
        v-else-if="type=='date'||type=='dateOfBirth'"
        :value="value"
      ></WsInfoDate>
      <WsInfoDateRangeOrNot
        v-else-if="type=='date-range-or-not'"
        :value="value"
      ></WsInfoDateRangeOrNot>
      <WsInfoFile
        v-else-if="type=='file'"
        :value="value"
      ></WsInfoFile>
      <WsInfoFiles
        v-else-if="type=='files'"
        :value="value"
      ></WsInfoFiles>
      <WsInfoBelongsTo
        v-else-if="type=='belongsTo'"
        :value="value"
        :textKey="textKey"
        :fields="fields"
        :modelName="modelName"
        :getText="getText"
        :relationPopup="relationPopup"
      ></WsInfoBelongsTo>
      <WsInfoHasMany
        v-else-if="type=='hasMany'"
        :value="value"
        :textKey="textKey"
        :fields="fields"
        :modelName="modelName"
        :getText="getText"
        :relationPopup="relationPopup"
      ></WsInfoHasMany>
      <WsInfoBelongsToMany
        v-else-if="type=='belongsToMany'"
        :value="value"
        :textKey="textKey"
        :fields="fields"
        :modelName="modelName"
        :getText="getText"
        :relationPopup="relationPopup"
      ></WsInfoBelongsToMany>
      <WsInfoHtml
        v-else-if="type=='editor'"
        :value="value"
      ></WsInfoHtml>
      <WsInfoSwitch
        v-else-if="type=='switch'"
        :activeText="activeText"
        :inactiveText="inactiveText"
        :value="value"
      ></WsInfoSwitch>
      <WsInfoSelect
        v-else-if="type=='select'"
        :value="value"
        :items="items"
      ></WsInfoSelect>
      <WsInfoSelect
        v-else-if="type=='multi-select'"
        :value="value"
        multiple
        :items="items"
      ></WsInfoSelect>
      <WsInfoRadio
        v-else-if="type=='radio'"
        :value="value"
        :items="items"
      ></WsInfoRadio>
      <WsInfoList
        v-else-if="type=='list'"
        :value="value"
        :fields="fields"
        :showFields="_showFields"
      ></WsInfoList>
      <WsInfoList
        v-else-if="type=='hasManyList'"
        :value="value"
        :fields="fields"
        :showFields="_showFields"
      ></WsInfoList>
      <WsInfoPocketImage
        v-else-if="type=='pocket_image'"
        :previewMaxWidth="previewMaxWidth"
        :previewMaxHeight="previewMaxHeight"
        :value="value"
      />
      <WsInfoImage
        v-else-if="type=='image'"
        :previewMaxWidth="previewMaxWidth"
        :previewMaxHeight="previewMaxHeight"
        :value="value"
      />
      <WsInfoImages
        v-else-if="type=='images'"
        :previewMaxWidth="previewMaxWidth"
        :previewMaxHeight="previewMaxHeight"
        :value="value"
      />
      <WsInfoForm
        v-else-if="type=='form'"
        :value="value"
        :fields="fields"
      ></WsInfoForm>
      <WsInfoPocketFile
        v-else-if="type=='pocket_file'"
        :value="value"
      />
      <WsInfoColor
        v-else-if="type=='color'"
        :value="value"
      ></WsInfoColor>
      <WsInfoStringCompare
        v-else-if="type=='stringCompare'"
        :value="value"
      ></WsInfoStringCompare>
      <WsInfoTags
        v-else-if="type=='tags'"
        :value="value"
      ></WsInfoTags>
      <WsInfoSelect
        v-else-if="type=='checkboxs'"
        :value="value"
        :items="items"
        :multiple="true"
      ></WsInfoSelect>
      <WsInfoFields
        v-else-if="type=='fields'"
        :value="value"
      >
      </WsInfoFields>
      <WsInfoForm
        v-else-if="type=='payload'"
        :value="value"
        :fields="fields"
      >
      </WsInfoForm>
      <WsInfoBusinessHours
        v-else-if="type=='business_hours'"
        :value="value"
      ></WsInfoBusinessHours>
      <WsInfoAvailableTime
        v-else-if="type=='available_time'"
        :value="value"
      ></WsInfoAvailableTime>
      <WsInfoWasaIntro
        v-else-if="type=='wasaintro'"
        :value="value"
      ></WsInfoWasaIntro>
      <WsInfoIcon
        v-else-if="type=='icon'"
        :getDisplayText="getDisplayText"
        :modelData="modelData"
        :color="color"
      ></WsInfoIcon>
      <WsInfoSpecs
        v-else-if="type==='specs'"
        :value="value"
        :fields="fields"
        :stateData="modelData"
        :specSettings="specSettings"
      ></WsInfoSpecs>
      <WsInfoSpecSettings
        v-else-if="type==='spec_settings'"
        :value="value"
        :fields="fields"
        :specSettingItemsModelName="specSettingItemsModelName"
      >
      </WsInfoSpecSettings>
      <div
        v-else-if="type=='custom'"
        v-html="getValue(modelData,value)"
        :style="[
          {'color':color},
        ]"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    _label() {
      if (this.displayLabel) {
        if (this.labelInLocale) {
          return this.$t(this.label);
        } else {
          return this.$t(this.label);
        }
      } else {
        return null;
      }
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else if (this.fields) {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      } else {
        return [];
      }
    },
  },
  props: {
    modelData: {
      type: [Object, Array],
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    hideOnNull: {
      type: Boolean,
      default: true,
    },
    displayLabel: {
      type: Boolean,
      default: true,
    },
    getValue: {
      type: Function,
      default: null,
    },
    getText: {
      type: Function,
      default: null,
    },
    relationPopup: {
      type: Boolean,
      default: false,
    },
    showLocalePicker: {
      type: Boolean,
      default: false,
    },
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    textKey: {
      type: String,
      default: "name",
    },
    locale: {
      type: String,
      default: null,
    },
    active: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    conditions: {
      type: Array,
      default: null,
    },
    maxWidth: {
      type: String,
      default: null,
    },
    previewMaxWidth: {
      type: String,
    },
    previewMaxHeight: {
      type: String,
    },
    fields: {
      type: [Object, Array],
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    errorMessage: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {},
    name: {
      type: [String, Array],
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    parentState: {},
    parent: {
      type: String,
      default: null,
    },
    labelInLocale: {
      type: Boolean,
      default: false,
    },
    getDisplayText: {
      type: Function,
    },
    displayInText: {
      type: Boolean,
    },
    specSettingItemsModelName: {
      type: String,
    },
    specSettings: {
      type: Array,
    },
    color: {
      type: String,
    },
  },
};
</script>