import Vue from 'vue'
import VueI18n from 'vue-i18n'
import config from '@/__config'

Vue.use(VueI18n)

function loadLocaleMessages() {
  const stoneLocales = require.context('@/__vue2stone_cms/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  let locales = {}
  try {
    locales = require.context('@/locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  } catch (e) {
    locales = {}
  }
  const messages = {}
  stoneLocales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      let localeResource = {
        ...stoneLocales(key)
      }
      try {
        locales(key)
        localeResource = {
          ...localeResource,
          ...locales(key)
        }
      } catch (e) {
        localeResource = {
          ...localeResource
        }
      }
      messages[locale] = localeResource
    }
  })
  return messages
}

export default new VueI18n({
  locale: config.locale.lang || 'zh_tw',
  fallbackLocale: config.locale.lang || 'zh_tw',
  // locale: process.env.VUE_APP_I18N_LOCALE || config.locale,
  // fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || config.locale,
  messages: loadLocaleMessages()
})
