import Router from './router'
import Message from './message'
import config from '@/__config'
export const router = Router
export const message = Message[config.locale.lang]
import {
  initColor,
  initComponent,
  initPlugin,
  initProperty,
} from '@/__vue2stone_cms/init'
export default {
  install: (app) => {
    initColor(app)
    initComponent(app)
    initPlugin(app)
    initProperty(app)
  },
}