import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_campaign',
  label: i18n.t('折扣碼列表'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    is_active: {
      type: 'switch',
      label: 'is_active',
      defaultValue: true,
      labelInLocale: true,
    },
    name: {
      type: 'text',
      label: i18n.t('折扣碼名稱'),
      placeholder: i18n.t('輸入') + '...',
      rules: 'required',
    },
    type: {
      type: 'radio-input',
      label: i18n.t('類型'),
      items: [
        {
          text: i18n.t('紅利點數回饋'),
          value: 'bonus_point_feedback',
        },
        {
          text: i18n.t('折扣碼'),
          value: 'discount_code',
        },
      ],
    },
    discount_percent: {
      type: 'number',
      label: '打折折數',
      placeholder: i18n.t('折'),
      rules: 'required|max_value:10|min_value:0|int_or_de:1',
      hint: '最多至小數點後一位，範圍：0 < 折數 < 10',
      col: 6,
    },
    discount_amount: {
      type: 'number',
    },
    discount_way: {
      type: 'radio-input',
      label: i18n.t('折扣方式'),
      items: [
        {
          value: 'discount_percent',
          text: i18n.t('打折折數'),
          fields: {
            discount_percent: {
              type: 'number',
              label: '',
              placeholder: i18n.t('折'),
              rules: 'required|max_value:10|min_value:0|int_or_de:1',
              hint: '最多至小數點後一位，範圍：0 < 折數 < 10',
              col: 6,
            },
          },
        },
        {
          value: 'discount_amount',
          text: i18n.t('折抵金額'),
          fields: {
            discount_amount: {
              type: 'number',
              label: '',
              rules: 'required|min_value:0',
              placeholder: `NT$ ${i18n.t('輸入')}...`,
              col: 6,
            },
          },
        },
      ],
    },
    start_date: {
      type: 'date',
      label: i18n.t('開始時間'),
      placeholder: i18n.t('選擇起日'),
      disabledDate: function(data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('結束時間'),
      placeholder: i18n.t('選擇迄日'),
      disabledDate: function(data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    discount_code: {
      type: 'text',
      label: i18n.t('折扣碼'),
      rules: 'required',
    },
    status: {
      type: 'select',
      label: i18n.t('狀態'),
      labelInLocale: true,
      defaultValue: 'in-progress',
      items: [
        {
          value: 'in-progress',
          text: i18n.t('進行中'),
        },
        {
          value: 'not-started',
          text: i18n.t('未開始'),
        },
        {
          value: 'end',
          text: i18n.t('已結束'),
        },
      ],
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    full_amount: {
      type: 'number',
      label: i18n.t('滿額限制'),
      placeholder: 'NT$ ' + i18n.t('輸入') + '...',
      rules: 'required',
    },
    condition: {
      label: i18n.t('指定對象'),
      type: 'select',
      items: [
        {
          text: i18n.t('首購'),
          value: 'first-purchase',
        },
        {
          text: i18n.t('不限'),
          value: 'null',
        },
      ],
      placeholder: i18n.t('請選擇指定對象'),
      rules: 'required',
    },
    duration: {
      type: 'custom',
      label: i18n.t('使用期間'),
      getValue: function(data) {
        if (data.start_date && data.end_date) {
          return `${moment(data.start_date).format('YYYY.MM.DD')} - ${moment(
            data.end_date,
          ).format('YYYY.MM.DD')}`;
        } else {
          return '無期限';
        }
      },
      items: [
        {
          text: i18n.t('日期區間'),
          value: 'duration',
          fields: {
            start_date: {
              type: 'date',
              label: i18n.t('開始日期'),
              placeholder: i18n.t('選擇起日'),
              col: 4,
            },
            end_date: {
              type: 'date',
              label: i18n.t('結束日期'),
              placeholder: i18n.t('選擇迄日'),
              col: 4,
            },
          },
        },
        {
          text: i18n.t('無期限'),
          value: 'infinite',
        },
      ],
    },
    custom_discount_way: {
      type: 'custom',
      label: i18n.t('折扣方式'),
      getValue: function(data) {
        if (data.discount_way === 'discount_amount') {
          return `${i18n.t('折抵金額')} | NT$ ${data.discount_amount || 0}`;
        } else if (data.discount_way === 'discount_percent') {
          return `${i18n.t('打折折數')} | ${i18n.t('{num} 折', {
            num: data.discount_percent || 0,
          })}`;
        } else {
          return '-';
        }
      },
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: ['name', 'discount_code', 'duration', 'custom_discount_way'],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      params: {
        type: 'discount_code',
      },
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: ['name', 'discount_code', 'full_amount', 'type'],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      submitToIndex: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: ['name', 'discount_code', 'full_amount', 'type'],
            },
          ],
        },
      ],
    },
  },
};

export default model;
