export default [
  {
    text: '管理大大',
    value: 'boss'
  },
  {
    text: '企鵝王',
    value: 'penguin-boss'
  },
  {
    text: '菜逼八',
    value: 'admin'
  },
]