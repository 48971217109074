import store from '@/store';
import serviceAuth from './service/api/v1/auth';
import serviceLocale from './service/api/v1/locale';
import config from '@/__config';
import auth from './service/api/v1/auth';
import * as changeCase from 'change-case';

export default {
  async middlewareCheck(to, from, next) {
    // CurrentUser Set
    const accessToken = auth.getToken();
    let currentUser;
    if (accessToken && !store.state.auth.currentUser) {
      try {
        currentUser = await serviceAuth.getUser();
        auth.setToken(accessToken);
      } catch (error) {
        currentUser = null;
      }
    } else {
      currentUser = store.state.auth.currentUser;
    }

    // Locale Set
    if (config.locale.indexFromApi) {
      serviceLocale.setLocalesFromAPI();
    }

    // Auth Check
    const requiresAuth = to.matched.some(
      (record) => record.meta.middleware && record.meta.middleware == 'auth',
    );

    if (requiresAuth && !currentUser) {
      const query = to.path && to.path != '/' ? { redirect: to.path } : {};
      next({ path: '/login', query: query });
      return;
    }

    // Login Check
    if (to.name == 'login') {
      if (!currentUser) {
        next();
        return;
      } else {
        next('/');
        return;
      }
    }

    // Logout Check
    if (to.name == 'logout') {
      if (!currentUser) {
        next('/login');
        return;
      } else {
        next();
        return;
      }
    }

    // Scope Check
    const requiredScopes =
      to.meta && to.meta.requiredScopes ? to.meta.requiredScopes : null;
    if (requiredScopes) {
      const scopes = store.state.auth.scopes;
      if (!scopes) {
        next('404');
        return;
      }
      const hasRequiredScope = requiredScopes.some(
        (scope) => scopes.indexOf(scope) >= 0,
      );
      if (!hasRequiredScope) {
        next('404');
        return;
      } else {
        next();
        return;
      }
    }

    next();
    return;
  },

  getCmsRoutes(routes) {
    const _cmsRoutes = [
      ...routes,
      {
        path: '/login',
        name: 'login',
        component: () => import('./views/Login.vue'),
        meta: {
          template: 'auth',
        },
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('./views/Logout.vue'),
        meta: {
          template: 'auth',
        },
      },
      {
        path: '/me',
        name: 'me',
        component: () => import('./views/Me.vue'),
        meta: {
          middleware: 'auth',
          template: 'app',
        },
      },
    ];
    _cmsRoutes.push(
      ...[
        ...this.getResourceRoutes({
          routes: ['index'],
          modelName: 'admin',
          pathName: config.auth.admin_blur ? 'cmser' : 'admin',
          viewsPath: '__vue2stone_cms/views',
          meta: {
            middleware: 'auth',
            requiredScopes: ['boss'],
          },
        }),
      ],
    );
    if (config.auth.admin_group) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'admin_group',
            pathName: config.auth.admin_blur ? 'cmser_group' : 'admin_group',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['boss'],
            },
          }),
        ],
      );
    }
    if (config.auth.admin_role) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'admin_role',
            pathName: config.auth.admin_blur ? 'cmser_role' : 'admin_role',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['boss'],
            },
          }),
        ],
      );
    }
    if (config.auth.log) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'cms_log',
            pathName: 'cms_log',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['boss'],
            },
          }),
        ],
      );
    }
    if (config.wsmodule.locale) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'locale',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
            },
          }),
        ],
      );
    }
    if (config.wsmodule.user) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'read'],
            modelName: 'user',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
        ],
      );
      if (config.wsmodule.user.log) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index'],
              modelName: 'web_log',
              pathName: 'user_log',
              viewsPath: '__vue2stone_cms/views',
              meta: {
                middleware: 'auth',
                requiredScopes: ['admin'],
              },
            }),
          ],
        );
      }
      if (config.wsmodule.user.device) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index', 'read', 'create', 'update'],
              modelName: 'user_device',
              viewsPath: '__vue2stone_cms/views',
              meta: {
                middleware: 'auth',
                requiredScopes: ['admin'],
              },
            }),
          ],
        );
      }
      if (config.wsmodule.user.invite) {
        if (config.wsmodule.user.invite.general) {
          _cmsRoutes.push(
            ...[
              {
                path: '/general_user_invite',
                name: 'general_user_invite',
                component: () =>
                  import('./views/Setting/GeneralUserInvite.vue'),
                meta: {
                  middleware: 'auth',
                },
              },
            ],
          );
        }
      }
    }
    if (config.wsmodule.socialite) {
      if (config.wsmodule.socialite.google) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index'],
              modelName: 'socialite_google_account',
              viewsPath: '__vue2stone_cms/views',
              meta: {
                middleware: 'auth',
                requiredScopes: ['admin'],
              },
            }),
          ],
        );
      }
      if (config.wsmodule.socialite.facebook) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index'],
              modelName: 'socialite_facebook_account',
              viewsPath: '__vue2stone_cms/views',
              meta: {
                middleware: 'auth',
                requiredScopes: ['admin'],
              },
            }),
          ],
        );
      }
      if (config.wsmodule.socialite.line) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index'],
              modelName: 'socialite_line_account',
              viewsPath: '__vue2stone_cms/views',
              meta: {
                middleware: 'auth',
                requiredScopes: ['admin'],
              },
            }),
          ],
        );
      }
    }
    if (config.wsmodule.contact_request) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
            ],
            modelName: 'contact_request',
            middleware: 'auth',
            meta: {
            }
          })
        ],
      );
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'contact_request_notify_mail',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
        ],
      );
      if (config.wsmodule.contact_request.auto_reply) {
        _cmsRoutes.push({
          path: '/contact_request_auto_reply',
          name: 'contact_request_auto_reply',
          component: () => import('./views/ContactRequest/AutoReply.vue'),
          meta: {
            middleware: 'auth',
          },
        });
      }
    }
    if (config.wsmodule.ws_blog) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'ws_blog',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'ws_blog_class',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
        ],
      );
    }
    if (config.wsmodule.tag) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'tag',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
        ],
      );
    }
    if (config.wsmodule.area) {
      const areaRoute = {
        routes: ['index', 'create', 'update', 'read'],
        modelName: 'area',
        viewsPath: '__vue2stone_cms/views',
        meta: {
          middleware: 'auth',
          requiredScopes: ['admin'],
        },
      };
      if (config.wsmodule.area.urlModelName) {
        areaRoute.pathName = config.wsmodule.area.urlModelName;
      }
      _cmsRoutes.push(...[...this.getResourceRoutes(areaRoute)]);
    }
    if (config.wsmodule.system_class) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'system_class',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
        ],
      );
    }
    if (config.wsmodule.tulpa) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['create', 'update'],
            modelName: 'tulpa_page_template',
            filePrefix: '/Tulpa',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['boss'],
            },
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'read'],
            modelName: 'tulpa_page_template',
            filePrefix: '/Tulpa',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['admin'],
            },
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'tulpa_section',
            filePrefix: '/Tulpa',
            viewsPath: '__vue2stone_cms/views',
            meta: {
              middleware: 'auth',
              requiredScopes: ['boss'],
            },
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'tulpa_page',
            filePrefix: '/Tulpa',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: `/tulpa/:id/update/setting`,
            name: `tulpa_page_update_setting`,
            component: () =>
              import(
                `@/__vue2stone_cms/views/Tulpa/TulpaPage/UpdateSetting.vue`
              ),
            meta: {
              middleware: 'auth',
            },
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'tulpa_cross_item',
            filePrefix: '/Tulpa',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.user_device_token) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'user_device_token',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.notification) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'notification',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.service_store) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'service_store',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'service_store_close',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'service_store_noti',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.pin_card) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'pin_card',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/pin_card/generate',
            name: 'PinCardGenerate',
            component: () =>
              import('@/__vue2stone_cms/views/PinCard/Generate.vue'),
            meta: {
              middleware: 'auth',
            },
          },
        ],
      );
    }
    if (config.wsmodule.service_plan) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'service_plan',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'read'],
            modelName: 'user_service_plan',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['read'],
            modelName: 'user_service_plan_item',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'service_plan_item',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.appointment) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'read', 'update'],
            modelName: 'appointment',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.shop_order) {
      _cmsRoutes.push(
        {
          path: '/shop_order',
          name: 'shop_order',
          component: () =>
            import('@/__vue2stone_cms/views/ShopOrder/Index.vue'),
        },
        {
          path: '/shop_order/:id',
          name: 'shop_order_show',
          component: () => import('@/__vue2stone_cms/views/ShopOrder/Read.vue'),
        },
        ...this.getResourceRoutes({
          routes: ['index', 'create', 'update', 'read'],
          modelName: 'shop_order',
          viewsPath: '__vue2stone_cms/views',
        }),
        {
          path: '/part_return/:id',
          name: 'part_return',
          component: () =>
            import('@/__vue2stone_cms/views/ShopOrder/PartReturn.vue'),
        },
        {
          path: '/part_return/:id/update',
          name: 'part_return_update',
          component: () =>
            import('@/__vue2stone_cms/views/ShopOrder/PartReturnUpdate.vue'),
        },
        {
          path: '/all_return',
          name: 'all_return',
          component: () =>
            import('@/__vue2stone_cms/views/ShopOrder/AllReturn.vue'),
        },
      );
    }
    if (config.wsmodule.shop) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_product',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_product_pre_order',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/shop_class/order',
            name: 'shop_class_order',
            component: () => import('./views/ShopClass/Order.vue'),
            meta: {},
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_class',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_subclass',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/shop_subclass/:id/shop_product/order',
            name: 'shop_subclass_shop_product_order',
            component: () =>
              import('./views/ShopSubclass/ShopProductOrder.vue'),
            meta: {},
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_ship_area_setting',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_ship_time_setting',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_free_shipping',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/featured_class/order',
            name: 'featured_class_order',
            component: () => import('./views/FeaturedClass/Order.vue'),
            meta: {},
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'featured_class',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/featured_class/:id/shop_product/order',
            name: 'featured_class_shop_product_order',
            component: () =>
              import('./views/FeaturedClass/ShopProductOrder.vue'),
            meta: {},
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_notice',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_notice_class',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_campaign',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_product_campaign',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'bonus_point',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'page_cover',
            viewsPath: '__vue2stone_cms/views',
          }),
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'shop_product_cover_frame',
            viewsPath: '__vue2stone_cms/views',
          }),
          {
            path: '/print/shop_order_pick',
            name: 'print_shop_order_pick',
            component: () =>
              import('@/__vue2stone_cms/views/Print/ShopOrderPick.vue'),
            meta: {
              template: 'print'
            },
          },
        ],
      );
    }
    if (config.wsmodule.news) {
      // _cmsRoutes.push(
      //   ...[
      //     ...this.getResourceRoutes({
      //       routes: ['index', 'create', 'update', 'read'],
      //       modelName: 'news',
      //       viewsPath: '__vue2stone_cms/views',
      //     }),
      //   ],
      // );
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
              'create',
              'update',
            ],
            modelName: 'news',
            middleware: 'auth',
            meta: {
            }
          })
        ],
      );
    }
    if (config.wsmodule.setting) {
      if (config.wsmodule.setting.privacy_terms) {
        _cmsRoutes.push(
          ...[
            {
              path: '/privacy',
              name: 'privacy',
              component: () => import('./views/Setting/Privacy.vue'),
              meta: {
                middleware: 'auth',
              },
            },
            {
              path: '/terms',
              name: 'terms',
              component: () => import('./views/Setting/Terms.vue'),
              meta: {
                middleware: 'auth',
              },
            },
          ],
        );
      }
      if (config.wsmodule.setting.ez_about_us) {
        _cmsRoutes.push({
          path: '/about_us',
          name: 'ez_about_us',
          component: () => import('./views/Setting/EzAboutUs.vue'),
          meta: {
            middleware: 'auth',
          },
        });
      }
    }
    if (config.wsmodule.news_banner) {
      _cmsRoutes.push(
        ...[
          {
            path: '/news_banner/order',
            name: 'news_banner_order',
            component: () => import('./views/NewsBanner/Order.vue'),
            meta: {},
          },
          ...this.getResourceRoutes({
            routes: ['index', 'create', 'update', 'read'],
            modelName: 'news_banner',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.acumatica) {
      if (config.wsmodule.acumatica.acumatica_app) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index', 'create', 'update', 'read'],
              modelName: 'acumatica_app',
              viewsPath: '__vue2stone_cms/views',
            }),
          ],
        );
      }
    }
    if (config.wsmodule.dashboard) {
      _cmsRoutes.push({
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('@/__vue2stone_cms/views/Dashboard/Index.vue'),
      });
    }
    if (config.wsmodule.website) {
      if (config.wsmodule.website.page_setting) {
        _cmsRoutes.push(
          ...[
            ...this.getResourceRoutes({
              routes: ['index', 'create', 'update', 'read'],
              modelName: 'page_setting',
              viewsPath: '__vue2stone_cms/views',
            }),
          ],
        );
      }
    }
    if (config.wsmodule.xc_work_type) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'xc_work_type',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.app_developer) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
              'create',
              'update',
            ],
            modelName: 'app_developer',
            middleware: 'auth',
            meta: {
            }
          })
        ],
      );
    }
    if (config.wsmodule.otp) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
            ],
            modelName: 'otp',
            middleware: 'auth',
            meta: {
            }
          })
        ],
      );
    }
    if (config.wsmodule.download_info) {
      if (config.wsmodule.download_info.clone_types) {
        for (const key in config.wsmodule.download_info.clone_types) {
          const cloneType = config.wsmodule.download_info.clone_types[key]
          _cmsRoutes.push(
            ...[
              ...this.getCrudTemplateRoutes({
                routes: [
                  'index',
                  'read',
                  'create',
                  'update',
                ],
                urlModelName: key,
                modelName: 'download_info',
                middleware: 'auth',
                meta: {
                  params: {
                    clone_type: key,
                  },
                  label: cloneType.title
                }
              })
            ],
          );
        }
      }
    }
    if (config.wsmodule.xc_project) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
              'create',
              'update',
            ],
            modelName: 'xc_project',
            middleware: 'auth',
            meta: {
              // label: cloneType.title
            }
          })
        ],
      );
    }
    if (config.wsmodule.xc_task) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
              'create',
              'update',
            ],
            modelName: 'xc_task',
            middleware: 'auth',
            meta: {
              // label: cloneType.title
            }
          })
        ],
      );
    }
    if (config.wsmodule.xc_billing_statement) {
      _cmsRoutes.push(
        ...[
          ...this.getCrudTemplateRoutes({
            routes: [
              'index',
              'read',
              'create',
              'update',
              'index_my',
              'create_my',
            ],
            modelName: 'xc_billing_statement',
            middleware: 'auth',
            meta: {
              // label: cloneType.title
            }
          })
        ],
      );
    }
    if (config.wsmodule.invoice) {
      _cmsRoutes.push(
        ...[
          ...this.getResourceRoutes({
            routes: ['index'],
            modelName: 'invoice_job',
            viewsPath: '__vue2stone_cms/views',
          }),
        ],
      );
    }
    if (config.wsmodule.showcase) {
      _cmsRoutes.push(...[
        // {
        //   path: '/showcase/order',
        //   name: 'showcase_order',
        //   component: () => import('./views/Showcase/Order.vue'),
        //   meta: {},
        // },
        ...this.getCrudTemplateRoutes({
          routes: [
            'index',
            'read',
            'create',
            'update',
            'order',
          ],
          modelName: 'showcase',
          middleware: 'auth',
          meta: {
            params: {
            },
          }
        })
      ]
      )
    }
    if (config.wsmodule.user_location) {
      _cmsRoutes.push(...[
        ...this.getCrudTemplateRoutes({
          routes: [
            'index',
          ],
          modelName: 'user_location',
          middleware: 'auth',
          meta: {
            params: {
            },
          }
        })
      ]
      )
    }
    if (config.wsmodule.user_like) {
      _cmsRoutes.push(...[
        ...this.getCrudTemplateRoutes({
          routes: [
            'index',
          ],
          modelName: 'user_like',
          middleware: 'auth',
          meta: {
            params: {
            },
          }
        })
      ]
      )
    }
    if (config.wsmodule.user_friend) {
      _cmsRoutes.push(...[
        ...this.getCrudTemplateRoutes({
          routes: [
            'index',
          ],
          modelName: 'user_friend',
          middleware: 'auth',
          meta: {
            params: {
            },
          }
        })
      ]
      )
    }
    if (config.wsmodule.system) {
      _cmsRoutes.push(...[
        {
          path: '/system_info',
          name: 'system_info',
          component: () => import('@/__modules/system/views/SystemInfo/Read.vue'),
        }, {
          path: '/system_info/update',
          name: 'system_info/update',
          component: () => import('@/__modules/system/views/SystemInfo/Update.vue'),
        },
      ]
      )
    }
    _cmsRoutes.push(
      ...[
        {
          path: '/*',
          name: 'notfound',
          component: () => import('./views/Notfound.vue'),
        },
      ],
    );
    return _cmsRoutes;
  },

  getResourceRoutes({
    routes = ['index'],
    modelName,
    pathName,
    filePrefix = '',
    pathPrefix = '',
    viewsPath = 'views',
    meta = {
      middleware: 'auth',
    },
    // generalTemplate = false
  }) {
    const _pathName = pathName ? pathName : modelName;
    const _routes = [];
    const _pascalCase = changeCase.pascalCase(modelName);
    if (routes.includes('index')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}`,
        name: `${modelName}_index`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Index.vue`),
        meta: meta,
      });
    }
    if (routes.includes('create')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/create`,
        name: `${modelName}_create`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Create.vue`),
        meta: meta,
      });
    }
    if (routes.includes('update')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/:id/update`,
        name: `${modelName}_update`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Update.vue`),
        meta: meta,
      });
    }
    if (routes.includes('read')) {
      _routes.push({
        path: `${pathPrefix}/${_pathName}/:id`,
        name: `${modelName}_read`,
        component: () =>
          import(`@/${viewsPath}${filePrefix}/${_pascalCase}/Read.vue`),
        meta: meta,
      });
    }
    return _routes;
  },

  getCrudTemplateRoutes({
    routes = ['index'],
    urlModelName,
    modelName,
    pathPrefix = '',
    middleware,
    meta = {}
  }) {
    const _urlModelName = urlModelName ? urlModelName : modelName;
    const _meta = {
      middleware: middleware,
      modelName: modelName,
      urlModelName: _urlModelName,
      ...meta
    }
    const _routes = [];
    if (routes.includes('order')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/order`,
        name: `${_urlModelName}_order`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateCrudOrder.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('index')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}`,
        name: `${_urlModelName}_index`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateCrudIndex.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('create')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/create`,
        name: `${_urlModelName}_create`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateCrudCreate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('update')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/:id/update`,
        name: `${_urlModelName}_update`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateCrudUpdate.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('read')) {
      _routes.push({
        path: `${pathPrefix}/${_urlModelName}/:id`,
        name: `${_urlModelName}_read`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateCrudRead.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('index_my')) {
      _routes.push({
        path: `${pathPrefix}/my/${_urlModelName}`,
        name: `${_urlModelName}_index_my`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateMyCrudIndex.vue`),
        meta: _meta,
      });
    }
    if (routes.includes('create_my')) {
      _routes.push({
        path: `${pathPrefix}/my/${_urlModelName}/create`,
        name: `${_urlModelName}_create_my`,
        component: () =>
          import(`@/__vue2stone_cms/templates/TemplateMyCrudCreate.vue`),
        meta: _meta,
      });
    }
    return _routes
  },
};
