import Vue from 'vue'
import VueRouter from 'vue-router'
import { router as cmsRouter } from '@/__vue2stone_cms'

Vue.use(VueRouter)

let routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    meta: {
      middleware: 'auth'
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: cmsRouter.getCmsRoutes(routes)
})

router.beforeEach(cmsRouter.middlewareCheck)

export default router
