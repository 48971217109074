<template>
  <div>
    <WsDialog
      class="ws-model-picker"
      ref="WsDialog"
      :bottomEmitDis="50"
      :contentHasPadding="false"
      @reach-bottom="$_onReachBottom()"
      @close="$_onDialogClose()"
    >
      <template v-slot:label>
        <WsText class="pa-12">{{label}}</WsText>
      </template>
      <template v-slot:title>
        <div class="ws-model-picker__search-container">
          <WsState
            type="search"
            :placeholder="`${$t('search')}...`"
            v-model="seaching"
            autofocus
          ></WsState>
          <WsStateForm
            class="mt-8"
            :fields="filterSelects"
            :value="filterSelectsData"
            @input="$_onFilterSelectFormInput($event)"
          ></WsStateForm>
        </div>
      </template>
      <template v-slot:content>
        <WsLoading v-if="searchingLoading"></WsLoading>
        <div
          v-else
          class="ws-model-picker__models"
        >
          <div class="ws-model-picker__model">
            <table
              cellspacing="0"
              cellpadding="0"
            >
              <tr>
                <th>
                  <WsState
                    type="checkbox"
                    :value="_tableCheckValue"
                    @input="$_onTableCheckInput($event)"
                  ></WsState>
                </th>
                <th
                  v-for="(headersItem,headersKey) in _tableHeaders"
                  :key="headersKey"
                >{{headersItem.text}}</th>
              </tr>
              <tr
                v-for="(item,itemIndex) in _items"
                :key="itemIndex"
                @click="$_onRowClick(item,itemIndex)"
                :class="[{'picked':$_pickedCheck(item)}]"
              >
                <td>
                  <WsIcon
                    class="pick-icon"
                    :class="{active:$_pickedCheck(item)}"
                    name="icon-md-check-box"
                  />
                  <WsIcon
                    class="pick-icon"
                    :class="{active:!$_pickedCheck(item)}"
                    name="icon-md-check-box-outline-blank"
                  />
                </td>
                <td
                  v-for="(headersItem,headersKey) in _tableHeaders"
                  :key="headersKey"
                >
                  <WsInfo
                    :value="$_getValue(item,headersItem.value)"
                    v-bind="fields[headersItem.value]"
                    :label="undefined"
                    :modelData="item"
                  ></WsInfo>
                </td>
              </tr>
            </table>
            <WsLoading v-if="_loading"></WsLoading>
          </div>
        </div>
      </template>
      <template v-slot:rightActions>
        <WsBtn
          @click="$_onClose()"
          :minWidth="100"
          color="textSecondary"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          :minWidth="100"
          color="textSecondary"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsDialog>
    <WsAlert
      ref="deleteAlert"
      v-bind="alert"
      :description="$_getAlertDescription()"
      :closeOnSubmit="true"
    ></WsAlert>
  </div>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
import S_APP_State from "@/__vue2stone_cms/service/app/state";
export default {
  data: () => ({
    C_value: [],
    seaching: null,
    modelDatas: [],
    searchingLoading: false,
    searchingTimeout: null,
    C_loading: {
      fetch: false,
    },
    submitLoading: false,
    currentPage: null,
    dataTotalCount: 0,
    lastPage: 1,
    filterSelectsData: {},
  }),
  methods: {
    $_getAlertDescription() {
      if (this.alert && this.alert.getDescription(this.C_value)) {
        return this.alert.getDescription(this.C_value);
      } else {
        return;
      }
    },
    $_onTableCheckInput($event) {
      if ($event) {
        const _value = [];
        this.modelDatas.forEach((modelData) => {
          const exist = this.C_value.find((e) => {
            return e.id == modelData.id;
          });
          if (!exist) {
            _value.push(modelData);
          }
        });
        this.C_value = [...this.C_value, ..._value];
      } else {
        const _value = [];
        this.C_value.forEach((C_valueItem) => {
          const exist = this.modelDatas.find((e) => {
            return e.id == C_valueItem.id;
          });
          if (!exist) {
            _value.push(C_valueItem);
          }
        });
        this.C_value = _value;
      }
      if (this.alert && this.alert.checkAlert(this.C_value)) {
        this.$refs.deleteAlert.open();
      }
      this.$emit("input", this.C_value);
    },
    $_onDialogClose() {
      this.clear();
    },
    $_onSubmit() {
      this.$emit("submit", this.C_value);
      this.close();
    },
    $_pickedCheck(item) {
      if (!this.C_value) {
        return false;
      } else {
        if (this._valueIds.includes(item.id)) {
          return true;
        } else {
          return false;
        }
      }
    },
    async open() {
      this.$_valueSet();
      await this.$_fetchData();
      this.$refs.WsDialog.open();
    },
    close() {
      this.$refs.WsDialog.close();
      this.clear();
    },
    clear() {
      this.modelDatas = [];
      this.C_value = [];
      this.currentPage = 0;
    },
    $_onRowClick(item, itemIndex) {
      if (this._valueIds.includes(item.id)) {
        const tarIndex = this.C_value.findIndex((e) => {
          return e.id == item.id;
        });
        this.C_value.splice(tarIndex, 1);
      } else {
        this.C_value.push(this.modelDatas[itemIndex]);
      }
      if (this.alert && this.alert.checkAlert(this.C_value)) {
        this.$refs.deleteAlert.open();
      }
      if (this.valueSync) {
        this.$emit("input", this.C_value);
      }
    },
    $_onReachBottom() {
      if (this.C_loading.fetch) {
        return;
      } else {
        this.$_fetchData();
      }
    },
    $_valueSet() {
      if (this.value) {
        this.C_value = [...this.value];
      }
    },
    $_onClose() {
      this.close();
    },
    async $_fetchData() {
      if (!this.needFetch) {
        return;
      }
      if (this.currentPage == this.lastPage) {
        return;
      }
      this.C_loading.fetch = true;
      if (!this.currentPage) {
        this.currentPage = 1;
      } else {
        this.currentPage++;
      }
      try {
        const getUrl = `/${this.modelName}`;
        const res = await this.$axios.get(getUrl, {
          params: {
            ...this._params,
          },
        });
        this.modelDatas = [...this.modelDatas, ...res.data.data];
        if (res.data.meta) {
          this.lastPage = res.data.meta.last_page;
          this.dataTotalCount = res.data.meta.total;
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.C_loading.fetch = false;
        this.searchingLoading = false;
      }
    },
    $_formatItem(item, itemPre, field, fieldKey, keyPre = "") {
      this.$_formatItemOther(item, itemPre, fieldKey, keyPre);
    },
    $_formatItemOther(item, itemPre, fieldKey, keyPre) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre ? itemPre[fieldKey] : null;
    },
    $_getValue(item, key) {
      return S_APP_State.getValueByFieldKey(key, item);
    },
    $_onFilterSelectFormInput($event) {
      this.filterSelectsData = { ...this.filterSelectsData, ...$event };
    },
    reset() {
      this.currentPage = 0;
      this.modelDatas = [];
    },
  },
  computed: {
    _loading() {
      if (this.loading) {
        return this.loading;
      } else {
        return this.C_loading.fetch;
      }
    },
    _tableCheckValue() {
      if (this.C_value && this.C_value.length) {
        let check = true;
        this.modelDatas.every((modelData) => {
          const exist = this.C_value.find((e) => {
            return e.id == modelData.id;
          });
          if (!exist) {
            check = false;
            return false;
          } else {
            return true;
          }
        });
        return check;
      } else {
        return false;
      }
    },
    _valueIds() {
      if (!this.C_value) {
        return [];
      } else {
        const _valueIds = [];
        this.C_value.forEach((valueItem) => {
          _valueIds.push(valueItem.id);
        });
        return _valueIds;
      }
    },
    _items() {
      if (!this.modelDatas) {
        return [];
      }
      const _items = this.modelDatas;
      _items.forEach((item) => {
        for (let fieldKey in this.fields) {
          // 判斷顯示/隱藏
          const field = this.fields[fieldKey];
          this.$_formatItem(item, item, field, fieldKey);
        }
      });
      const field = this.fields[this.C_orderBy];
      if (!this.C_orderBy || !field || !this.modelDatas) {
        return _items;
      }
      const type = field.type;
      _items.sort((a, b) => {
        let x;
        let y;
        if (this.C_orderWay == "asc") {
          x = a;
          y = b;
        } else {
          x = b;
          y = a;
        }
        if (type == "datetime") {
          return this.$moment(x[this.C_orderBy]).diff(y[this.C_orderBy]);
        } else {
          let textX;
          let textY;
          if (type == "belongsTo") {
            textX = x[this.C_orderBy][field.textKey].toUpperCase();
            textY = y[this.C_orderBy][field.textKey].toUpperCase();
          } else {
            textX = x[this.C_orderBy];
            textY = y[this.C_orderBy];
          }
          if (textX < textY) {
            return -1;
          }
          if (textX > textY) {
            return 1;
          }
          return 0;
        }
      });
      return _items;
    },
    _displayFields() {
      return S_APP_General.getDisplayFields(this.fields);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this._showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }

          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: field.label,
            width: field.width,
          });
        }
      });
      return _tableHeaders;
    },
    _showFields() {
      if (this.showFields) {
        return this.showFields;
      } else {
        const _showFields = [];
        for (let fieldKey in this.fields) {
          _showFields.push(fieldKey);
        }
        return _showFields;
      }
    },
    _params() {
      let _params = {
        search: this.seaching,
        page: this.currentPage,
        order_by: this.orderBy,
        order_way: this.orderWay,
        ...this.params,
      };
      // filterSelectsData
      if (JSON.stringify(this.filterSelectsData) !== "{}") {
        let filterParams = null;
        filterParams = S_APP_State.getFormatedStates(
          this.filterSelects,
          this.filterSelectsData
        );
        for (let key in filterParams) {
          const filterParamsItem = filterParams[key];
          if (filterParamsItem == null || filterParamsItem == undefined) {
            continue;
          } else {
            if (Array.isArray(filterParamsItem)) {
              filterParams[key] = filterParamsItem.join(",");
            } else {
              filterParams[key] = filterParamsItem;
            }
            _params = { ..._params, ...filterParams };
          }
        }
      }

      return _params;
    },
  },
  mounted() {},
  watch: {
    value: {
      handler() {
        this.$_valueSet();
      },
    },
    seaching: {
      handler() {
        this.searchingLoading = true;
        clearTimeout(this.searchingTimeout);
        this.searchingTimeout = setTimeout(() => {
          this.modelDatas = [];
          this.currentPage = null;
          this.dataTotalCount = 0;
          this.lastPage = 1;

          this.$_fetchData();
        }, 500);
      },
    },
    filterSelectsData: {
      handler() {
        this.searchingLoading = true;
        this.reset();
        this.$_fetchData();
      },
      deep: true,
    },
  },
  props: {
    orderBy: {
      type: String,
      default: "created_at",
    },
    orderWay: {
      type: String,
      default: "desc",
    },
    value: {
      type: Array,
      default: null,
    },
    modelName: {
      type: String,
      required: true,
    },
    showFields: {
      type: Array,
      default: null,
    },
    fields: {
      type: Object,
      required: true,
    },
    valueSync: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default() {
        return {};
      },
    },
    filterSelects: {
      type: Object,
      default: null,
    },
    loading: Boolean,
    label: String,
    alert: Object,
    needFetch: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style>
</style>