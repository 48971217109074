// import config from '@/__config';
import moment from 'moment';
import i18n from '@/i18n.js';
import H_Shop from '@/__vue2stone_cms/helpers/shop.js';
const model = {
  modelName: 'shop_order',
  label: i18n.t('訂單列表'),
  fields: {
    no: {
      type: 'text',
      label: i18n.t('訂單編號'),
      col: 4,
    },
    order_type: {
      type: 'select',
      label: i18n.t('訂單類型'),
      col: 4,
      items: [
        {
          text: i18n.t('超市'),
          value: 'next-day',
        },
        {
          text: i18n.t('預購'),
          value: 'pre-order',
        },
      ],
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('訂購日期'),
      col: 12,
    },
    orderer: {
      type: 'text',
      label: i18n.t('訂購者'),
      col: 2,
    },
    orderer_tel: {
      type: 'text',
      label: i18n.t('訂購者電話'),
      col: 2,
    },
    orderer_address: {
      type: 'text',
      label: i18n.t('通訊地址'),
      col: 4,
    },
    receiver: {
      type: 'text',
      label: i18n.t('收件者'),
      col: 2,
    },
    receiver_tel: {
      type: 'text',
      label: i18n.t('收件者電話'),
      col: 2,
    },
    receive_address: {
      type: 'text',
      label: i18n.t('收件者地址'),
      col: 4,
    },
    receive_way: {
      type: 'select',
      label: i18n.t('收貨方式'),
      items: [
        {
          text: i18n.t('電話聯絡收件人'),
          value: 'phone-contact',
        },
        {
          text: i18n.t('電聯收件人後，交由管理室代收'),
          value: 'phone-contact-building-manager',
        },
        {
          text: i18n.t('不需電聯，直接交由管理室代收'),
          value: 'building-manager',
        },
      ],
      col: 12,
    },
    receive_remark: {
      type: 'textarea',
      label: i18n.t('收件備註'),
      editable: true,
      editPopupTitle: i18n.t('編輯{string}', { string: '收件備註' }),
      placeholder: i18n.t('輸入') + '...',
      col: 12,
    },
    package_way: {
      type: 'select',
      label: i18n.t('包裝方式'),
      items: [
        {
          text: i18n.t('減塑包裝'),
          value: 'plastic-package',
        },
        {
          text: i18n.t('一般包裝'),
          value: 'normal',
        },
      ],
      col: 4,
    },
    status: {
      type: 'select',
      label: i18n.t('訂單狀態'),
      width: '140px',
      col: 2,
      items: [
        {
          text: i18n.t('成立'),
          value: 'established',
        },
        {
          text: i18n.t('未成立'),
          value: 'not-established',
        },
        {
          text: i18n.t('申請部分退訂'),
          value: 'return-part-apply',
        },
        {
          text: i18n.t('申請全部退訂'),
          value: 'cancel',
        },
        {
          text: i18n.t('部分退訂完成'),
          value: 'return-part-complete',
        },
        {
          text: i18n.t('全部退訂完成'),
          value: 'cancel-complete',
        },
        {
          text: i18n.t('訂單完成'),
          value: 'complete',
        },
      ],
      placeholder: i18n.t('更改訂單狀態'),
    },
    shop_ship_time_setting: {
      type: 'belongsToMany',
      placeholder: i18n.t('所有配送時段'),
      label: i18n.t('篩選配送時段'),
      col: 3,
      textKey: 'start_time',
      getText: function (value) {
        return `${value.start_time || ''}-${value.end_time || ''}`;
      },
      modelName: 'shop_ship_time_setting',
    },
    ship_status: {
      type: 'select',
      label: i18n.t('出貨狀態'),
      col: 2,
      items: [
        {
          text: i18n.t('待出貨'),
          value: 'unfulfilled',
        },
        {
          text: i18n.t('準備出貨'),
          value: 'collected',
        },
        {
          text: i18n.t('已出貨'),
          value: 'shipped',
        },
      ],
      placeholder: i18n.t('更改出貨狀態'),
    },
    ship_date: {
      type: 'date',
      label: i18n.t('出貨日期'),
      col: 2,
      editable: true,
      getEditPopupTitle: function (modelData) {
        return `${i18n.t('編輯訂單')}"${modelData.no || ''}"${i18n.t(
          '出貨日期',
        )}`;
      },
    },
    ship_time: {
      type: 'custom',
      label: i18n.t('配送時段'),
      col: 4,
      getValue: function (value) {
        return `${moment(value.ship_start_time).format('HH:mm') || ''}~${moment(
          value.ship_end_time,
        ).format('HH:mm') || ''}`;
      },
    },
    shop_date_time: {
      type: 'custom',
      label: `${i18n.t('出貨日期')}\n${i18n.t('配送時段')}`,
      getValue: function (value) {
        return `${value.ship_date ? moment(value.ship_date).format('YYYY-MM-DD') : '-'
          }\n${value.ship_start_time
            ? moment(value.ship_start_time).format('HH:mm')
            : ''
          }~${value.ship_end_time ? moment(value.ship_end_time).format('HH:mm') : ''
          }`;
      },
      width: '150px',
    },
    order_price: {
      type: 'number',
      label: i18n.t('訂單金額'),
      col: 2,
      getDisplayText: function (value) {
        if (value) {
          return 'NT$ ' + value;
        } else {
          return 'NT$ 0';
        }
      },
    },
    invoice_status: {
      type: 'select',
      label: i18n.t('發票狀態'),
      items: [
        {
          text: i18n.t('未開立'),
          value: null,
        },
        {
          text: i18n.t('待開立'),
          value: 'waiting',
        },
        {
          text: i18n.t('開立成功'),
          value: 'done',
        },
        {
          text: i18n.t('開立失敗'),
          value: 'fail',
        },
        {
          text: i18n.t("手動開立成功"),
          value: "done-hand",
        },
      ],
      col: 2,
    },
    ship_way: {
      type: 'text',
      label: i18n.t('物流方式'),
      col: 2,
    },
    ship_remark: {
      type: 'textarea',
      label: i18n.t('配送備註'),
      editable: true,
      editPopupTitle: i18n.t('編輯{string}', { string: '配送備註' }),
      placeholder: '輸入...',
      col: 2,
    },
    customer_service_remark: {
      type: 'textarea',
      label: i18n.t('客服備註'),
      editable: true,
      editPopupTitle: i18n.t('編輯客服備註'),
      placeholder: i18n.t('輸入') + '...',
      col: 12,
    },
    pay_status: {
      type: 'select',
      placeholder: i18n.t('所有付款狀態'),
      label: i18n.t('付款狀態'),
      col: 4,
      items: [
        {
          text: i18n.t('未付款') + '-' + i18n.t('已取消'),
          value: 'not-paid',
        },
        {
          text: i18n.t('未付款') + '-' + i18n.t('待付款'),
          value: 'waiting',
        },
        {
          text: i18n.t('已付款'),
          value: 'paid',
        },
      ],
    },
    pay_type: {
      type: 'text',
      label: i18n.t('付款方式'),
      items: [
        {
          text: i18n.t('信用卡'),
          value: 'Credit',
        },
        {
          text: 'CVS',
          value: 'CVS',
        },
        {
          text: 'ATM',
          value: 'ATM',
        },
        {
          text: i18n.t('超商條碼'),
          value: 'BARCODE',
        },
        {
          text: 'LINE Pay',
          value: 'line_pay',
        },
      ],
      col: 2,
    },
    pay_at: {
      type: 'datetime',
      label: i18n.t('付款日期'),
      col: 2,
    },
    bonus_points: {
      type: 'number',
      label: i18n.t('獲得紅利點數'),
      col: 2,
    },
    invoice_address: {
      type: 'text',
      label: i18n.t('發票地址'),
    },
    invoice_uniform_number: {
      type: 'text',
      label: i18n.t('發票統一編號'),
      col: 2,
    },
    invoice_company_name: {
      type: 'text',
      label: i18n.t('發票公司名稱'),
      col: 2,
    },
    invoice_title: {
      type: 'text',
      label: i18n.t('發票抬頭'),
      col: 2,
    },
    invoice_type: {
      type: 'select',
      label: i18n.t('發票類型'),
      col: 2,
      items: [
        {
          text: i18n.t('電子發票'),
          value: 'personal',
        },
        {
          text: i18n.t('電子三聯式發票'),
          value: 'triple',
        },
      ],
    },
    invoice_carrier_type: {
      type: 'select',
      label: i18n.t('發票載具類型'),
      col: 2,
      items: [
        {
          text: i18n.t('手機'),
          value: 'phone',
        },
        {
          text: i18n.t('信箱'),
          value: 'email',
        },
        {
          text: i18n.t('證書'),
          value: 'certificate',
        },
      ],
    },
    invoice_carrier_number: {
      type: 'text',
      label: i18n.t('發票載具編號'),
      col: 2,
    },
    invoice_number: {
      type: 'text',
      label: i18n.t('發票號碼'),
      col: 2,
    },
    freight: {
      type: 'text',
      label: i18n.t('運費'),
      col: 2,
      getDisplayText: function (value) {
        if (value) {
          return 'NT$ ' + value;
        } else {
          return 'NT$ 0';
        }
      },
    },
    products_price: {
      type: 'text',
      label: i18n.t('商品總金額'),
      col: 2,
      getDisplayText: function (value) {
        if (value) {
          return 'NT$ ' + value;
        } else {
          return 'NT$ 0';
        }
      },
    },
    orderer_email: {
      type: 'text',
      label: i18n.t('訂購者Email'),
    },
    area: {
      type: 'belongsTo',
      label: i18n.t('地區'),
      relationPopup: true,
      textKey: 'name',
      modelName: 'area',
      placeholder: i18n.t('請選擇縣市'),
      getDisabledFromValue(value) {
        if (!value) {
          return true;
        } else {
          return false;
        }
      },
      col: 3,
    },
    area_sections: {
      type: 'belongsToMany',
      label: i18n.t('子地區'),
      modelName: 'area_section',
      relationPopup: true,
      placeholder: i18n.t('請選擇區域'),
      getExtendParamsFromValue(value) {
        if (!value) {
          return {};
        } else if (value.area) {
          return {
            area: value.area.id,
          };
        } else {
          return {};
        }
      },
      conditions: [
        {
          type: 'array',
          value: '',
          key: 'area',
        },
      ],
      requiredField: 'area',
      col: 3,
    },
    time_fields: {
      label: '',
      type: 'select',
      items: [
        {
          text: i18n.t('訂購日期'),
          value: 'created_at',
        },
        {
          text: i18n.t('出貨日期'),
          value: 'ship_date',
        },
      ],
    },
    // filterDuration: {
    //   placeholder: '篩選起訖時段',
    //   type: 'date-range',
    //   startKey: 'start_time',
    //   endKey: 'end_time',
    // },
    start_time: {
      label: i18n.t('起始時間'),
      type: 'date',
    },
    end_time: {
      label: i18n.t('結束時間'),
      type: 'date',
    },
    order_info: {
      label: `${i18n.t('訂單編號')}\n${i18n.t('訂單類型')}\n${i18n.t(
        '物流方式',
      )}\n${i18n.t('物流單號')}`,
      type: 'custom',
      width: '200px',
      getValue: function (modelData) {
        let _content = '';
        _content += `${modelData.no || '-'}\n`;
        if (modelData.order_type) {
          if (modelData.order_type === 'next-day') {
            _content += i18n.t('超市');
          } else if (modelData.order_type === 'pre-order') {
            _content += i18n.t('預購');
          } else {
            _content += '-';
          }
          _content += '\n';
        } else {
          _content += '-\n';
        }
        _content += `${modelData.ship_way || '-'}\n`;
        _content += `${modelData.ship_no || '-'}`;

        return _content;
      },
    },
    orderer_info: {
      label: `${i18n.t('訂購者姓名')}\nID\n${i18n.t('電話')}`,
      type: 'custom',
      width: '100px',
      getValue: function (modelData) {
        const user_id = modelData.user ? modelData.user.id : '-'
        return `${modelData.orderer || '-'}\n${user_id ||
          '-'}\n${modelData.orderer_tel || '-'}`;
      },
    },
    receiver_info: {
      label: `${i18n.t('收件者')}\n${i18n.t('訂購日期')}(${i18n.t(
        '日期',
      )}+${i18n.t('時間')})\n${i18n.t('電話')}\n${i18n.t('地址')}`,
      type: 'custom',
      width: '240px',
      getValue: function (modelData) {
        const _created_at = moment(modelData.created_at).format(
          'YYYY-MM-DD HH:mm',
        );
        return `${modelData.receiver || '-'}\n${_created_at ||
          '-'}\n${modelData.receiver_tel || '-'}\n${modelData.receive_address ||
          '-'}`;
      },
    },
    order_price_order_way: {
      label: `${i18n.t('訂單金額')}\n${i18n.t('訂單來源')}\n${i18n.t(
        '付款方式',
      )}`,
      type: 'custom',
      getValue: function (modelData) {
        let str = `${modelData.order_price || '-'}\n`;
        str += H_Shop.getShopOrderSourceLabel(modelData.source)
        str += '\n';
        str += H_Shop.getShopOrderPayTypeLabel(modelData.pay_type)
        return str;
      },
    },
    receive_way_receive_remark: {
      label: `${i18n.t('收貨方式')}\n${i18n.t('收件備註')}`,
      type: 'custom',
      width: '230px',
      getValue: function (modelData) {
        let _receiveWay = '-';
        if (modelData.receive_way === 'phone-contact') {
          _receiveWay = i18n.t('電話聯絡收件人');
        } else if (modelData.receive_way === 'phone-contact-building-manager') {
          _receiveWay = i18n.t('電聯收件人後，交由管理室代收');
        } else if (modelData.receive_way === 'building-manager') {
          _receiveWay = i18n.t('不需電聯，直接交由管理室代收');
        }
        return `${_receiveWay}\n${modelData.receive_remark || '-'}`;
      },
    },
    return_price: {
      label: i18n.t('退訂金額'),
      type: 'number',
      col: 2,
    },
    bonus_points_deduct: {
      type: 'number',
      label: i18n.t('紅利點數折抵'),
      col: 2,
    },
    shop_product_campaign_deduct: {
      type: 'number',
      label: '商品促銷折抵',
      col: 2,
    },
    campaign_deduct: {
      type: 'number',
      label: i18n.t('折扣碼折抵'),
      col: 2,
    },
    invite_no_deduct: {
      type: 'number',
      label: i18n.t('邀請碼折抵'),
      col: 2,
    },
    source: {
      type: 'select',
      items: [
        {
          text: 'PC',
          value: 'web-pc',
        },
        {
          text: 'APP',
          value: 'app',
        },
        {
          text: 'WEB',
          value: 'web-mobile',
        },
      ],
    },
    return_reason: {
      type: 'textarea',
      label: '退訂原因',
    },
    return_remark: {
      type: 'textarea',
      label: '退訂備註',
    },
    need_handle_alert: {
      type: 'icon',
      color: 'var(--danger)',
      getDisplayText(value) {
        if (value.need_handle) {
          return `icon-ws-filled-alert`;
        } else {
          return `-`;
        }
      },
      width: '30px',
      col: 3,
    },
  },
  crudSetting: {
    index: {},
    read: {
      customDropdownMenu: [
        {
          id: 'change_order_status',
          items: [
            {
              text: i18n.t('成立'),
              value: 'established',
            },
            {
              text: i18n.t('未成立'),
              value: 'not-established',
            },
            {
              text: i18n.t('申請部分退訂'),
              value: 'return-part-apply',
            },
            {
              text: i18n.t('申請全部退訂'),
              value: 'cancel',
            },
            {
              text: i18n.t('部分退訂完成'),
              value: 'return-part-complete',
            },
            {
              text: i18n.t('全部退訂完成'),
              value: 'cancel-complete',
            },
            {
              text: i18n.t('訂單完成'),
              value: 'complete',
            },
          ],
          placeholder: i18n.t('更改訂單狀態'),
        },
        {
          id: 'change_ship_status',
          items: [
            {
              text: i18n.t('待出貨'),
              value: 'unfulfilled',
            },
            {
              text: i18n.t('準備出貨'),
              value: 'collected',
            },
            {
              text: i18n.t('已出貨'),
              value: 'shipped',
            },
          ],
          placeholder: i18n.t('更改出貨狀態'),
        },
        {
          id: 'need_handle',
          items: [
            {
              text: i18n.t('無'),
              value: false,
            },
            {
              text: i18n.t('問題待處理'),
              value: true,
            },
          ],
          placeholder: i18n.t('問題處理狀態'),
        },
      ],
      customBtns: [
        {
          label: i18n.t('匯出訂單'),
          event: 'export-excel',
          icon: {
            left: true,
            name: 'icon-ws-outline-cloud-download',
          },
        },
        {
          label: i18n.t('列印揀貨單'),
          event: 'print',
          icon: {
            left: true,
            name: 'icon-md-print',
          },
        },
        {
          label: i18n.t('編輯客服備註'),
          event: 'edit-customer-service',
        },
      ],
      updatable: false,
      deletable: false,
      titleKey: 'no',
      showFields: [
        'no',
        'orderer',
        'orderer_tel',
        'orderer_address',
        'type',
        'receiver',
        'receiver_tel',
        'receive_address',
        'created_at',
        'ship_time',
        'ship_status',
        'status',
        'pay_status',
        'ship_date',
        'ship_way',
        'invoice_number',
        'invoice_status',
        'invoice_type',
        'package_way',
        'invoice_title',
        'invoice_uniform_number',
        'invoice_carrier_type',
        'invoice_carrier_number',
        'receive_remark',
        'customer_service_remark',
        'receive_way',
        'products_price',
        'freight',
        'invite_no_deduct',
        'bonus_points',
        'bonus_points_deduct',
        'campaign_deduct',
        'order_price',
        'pay_type',
        'pay_at',
        'return_price',
        'orderer_email',
      ],
      showBreadcrumbs: false,
    },
    update: {},
    create: {},
  },
};

// if (config.wsmodule.user) {
//   if (config.wsmodule.user.bonus_points) {
//     model.fields.bonus_points = {
//       // 訂單想使用(扣除)的紅利點數
//       type: 'number',
//       label: i18n.t('優惠券折抵'),
//       col: 2,
//     };
//   }
// }

export default model;
