<template>
  <div class="ws-info-spec-settings">
    <table
      cellspacing="0"
      cellpadding="0"
    >
      <tr>
        <th
          v-for="(valueItem,valueIndex) in value"
          :key="valueIndex"
        >
          <WsText>
            {{valueItem.name}}
          </WsText>
        </th>
      </tr>
      <tr
        v-for="(rowItem,rowIndex) in _rowData"
        :key="rowIndex"
      >
        <td
          v-for="(specSettingItem,specSettingIndex) in rowItem"
          :key="specSettingIndex"
        >
          <WsText>
            {{specSettingItem}}
          </WsText>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "WsInfoSpecSettings",
  mounted() {
    this.$_initState();
  },
  methods: {
    $_initState() {
      let _value = [];
      if (this.value && this.value.length) {
        _value = [...this.value];
        let i = 0;
        for (i = 0; i < this.value.length; i++) {
          for (let itemKey in this.fields) {
            if (_value[i][itemKey] !== 0 && !_value[i][itemKey]) {
              _value[i][itemKey] = null;
            }
          }
          _value[i].fakeKey = (Math.random() + 1).toString(36).substring(6);
          if (_value[i] && _value[i][this.specSettingItemsModelName].length) {
            for (
              let j = 0;
              j < _value[i][this.specSettingItemsModelName].length;
              j++
            ) {
              _value[i][this.specSettingItemsModelName][j].fakeKey = (
                Math.random() + 1
              )
                .toString(36)
                .substring(8);
            }
          }
        }
      }
      this.$emit("input", _value);
    },
    $_getShowCount(datas, index) {
      // computed datas[index][i] will show how many times in array
      if (datas[index + 1]) {
        return datas[index].length * this.$_getShowCount(datas, index + 1);
      } else {
        return datas[index].length;
      }
    },
    $_sortSettingValueIndex(datas, index, arr, startIndex) {
      // index : current datas's index;
      // arr : be transform array;
      // startIndex : this datas[index] of start;
      if (!datas.length || !datas[index] || !datas[index].length) return [];
      let _newArr = [...arr];
      let i = 0;
      for (i = 0; i < datas[index].length; i++) {
        if (index === 0) {
          // start item of datas
          let _showCount = 0;
          if (datas[index + 1]) {
            _showCount = this.$_getShowCount(datas, index + 1);
          } else {
            _showCount = 1;
          }
          for (let j = 0; j < _showCount; j++) {
            _newArr.push([i]);
          }
          if (datas[index + 1]) {
            _newArr = this.$_sortSettingValueIndex(
              datas,
              index + 1,
              _newArr,
              (i + 1) * _showCount - _showCount
            );
          }
        } else if (!datas[index + 1]) {
          // last item of datas
          const _showCount = 1;
          let _startIndex = (i + 1) * _showCount - _showCount + startIndex;
          _newArr[_startIndex] = [..._newArr[_startIndex], i];
        } else {
          // middle item of datas
          const _showCount = this.$_getShowCount(datas, index + 1);
          let _startIndex = (i + 1) * _showCount - _showCount + startIndex;
          for (let j = _startIndex; j < _startIndex + _showCount; j++) {
            _newArr[j] = [..._newArr[j], i];
          }
          _newArr = this.$_sortSettingValueIndex(
            datas,
            index + 1,
            _newArr,
            startIndex + i * _showCount
          );
        }
      }
      return _newArr;
    },
  },
  computed: {
    _innerFields() {
      if (!this.fields || !this.fields.length) return [];
      let _innerFields = [...this.fields];
      _innerFields.splice(0, 1);
      return _innerFields;
    },
    _rowData() {
      if (this._settingValue && this._settingValue.length) {
        const _sortSettingValueIndex = this.$_sortSettingValueIndex(
          this._settingValue,
          0,
          [],
          0
        );
        return _sortSettingValueIndex.map((sortItem) => {
          if (!sortItem.length) return sortItem;
          let arr = [];
          sortItem.forEach((settingItem, settingIndex) => {
            const _text =
              this._settingValue[settingIndex][settingItem].name || "-";
            arr = [...arr, _text];
          });
          return arr;
        });
      }
      return [];
    },
    _settingValue() {
      let arr = [];
      if (!this.value || !this.value.length) return [];
      arr = this.value.map((item) => {
        return item.shop_product_spec_setting_items;
      });
      return arr;
    },
  },
  props: {
    value: {
      type: Array,
    },
    fields: {
      type: Object,
    },
    specSettingItemsModelName: {
      type: String,
    },
  },
};
</script>