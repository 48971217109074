
<template>
  <WsMain>
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <slot name="beforeContent"></slot>
    <div class="ws-content-section pb-80">
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="createForm"
          @submit.prevent
        >
          <WsSectionPrimarySecondary>
            <template v-slot:primary>
              <WsStateContentBlock
                v-for="(item,itemKey) in primary"
                :key="itemKey"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :errorMessages="errorMessages"
                :value="_value"
                @input="$_onStateContentBlockInput($event)"
                :parentModelName="$_getParentModelName(item)"
                @error-popup-active="$_onErrorPopupActive($event)"
              ></WsStateContentBlock>
            </template>
            <template v-slot:secondary>
              <WsStateContentBlock
                v-for="(item,itemKey) in secondary"
                :key="itemKey"
                :type="item.type"
                :label="item.label"
                :title="item.title"
                :remark="item.remark"
                :floors="item.floors"
                :modedFields="_updateFields"
                :fields="item.fields"
                :field="item.field"
                :modelName="item.modelName"
                :modeVersions="item.modeVersions"
                :modeParent="item.modeParent"
                :customButtons="item.customButtons"
                :fieldsRead="item.fieldsRead"
                :errorMessages="errorMessages"
                :value="_value"
                @input="$_onStateContentBlockInput($event)"
                :parentModelName="$_getParentModelName(item)"
                @error-popup-active="$_onErrorPopupActive($event)"
              ></WsStateContentBlock>
            </template>
          </WsSectionPrimarySecondary>
        </form>
      </ValidationObserver>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  data: () => ({
    isMounted: false,
    parentModelData: null,
    state: {},
    loading: {
      submit: false,
    },
    errorMessages: {},
  }),
  methods: {
    $_onStateContentBlockInput($event) {
      this.$store.dispatch("app/startNeedSave");
      if (this.emitInput) {
        const _value = {
          ...this.value,
          ...$event,
        };
        this.$emit("input", _value);
      } else {
        const _value = {
          ...this.state,
          ...$event,
        };
        this.state = _value;
      }
    },
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        if (this.emitInput) {
          this.$emit("submit", this.value);
        } else {
          this.$emit("submit", this.state);
        }
        return;
      }
      this.loadingStart();
      try {
        let _value;
        if (this.emitInput) {
          _value = this.value;
        } else {
          _value = this.state;
        }
        let postUrl;
        if (this.postUrl) {
          postUrl = this.postUrl;
        } else if (this.parentModelName && this.parentId) {
          postUrl = `/${this.parentModelName}/${this.parentId}/${this.modelName}`;
        } else {
          postUrl = `/${this.modelName}`;
        }
        let postData = S_App_State.getFormatedStates(
          this.fields,
          _value,
          this.postEncode
        );
        if (this.extendPostData) {
          postData = {
            ...postData,
            ...this.extendPostData,
          };
        }
        const res = await this.$axios.post(postUrl, postData);
        if (this.afterSubmitAction) {
          this.afterSubmitAction(res.data.data);
        }
        if (this._updateFields.last_version) {
          try {
            await this.$axios.post(
              `/${this.modelName}/${res.data.data.id}/${this._versionModelName}`,
              this.updateData.last_version
            );
            this.loading.submit = false;
            this.$store.dispatch("app/stopNeedSave");
            if (this.$route.query && this.$route.query.redirect) {
              this.$router.push(this.$route.query.redirect);
            } else {
              this.$router.push(`/${this._urlModelName}`);
            }
          } catch (error) {
            alert("版本儲存發生錯誤");
            this.loadingStop();
          }
        } else {
          this.$store.dispatch("app/stopNeedSave");
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (this.returnUrl) {
            this.$router.push(this.returnUrl);
          } else {
            this.$router.push(`/${this._urlModelName}`);
          }
        }
      } catch (error) {
        // alert("儲存錯誤");
        this.$emit("error", error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.errorMessages = this.$t(error.response.data.message);
        }
        this.loadingStop();
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_parentFetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.parentModelName}/${this.parentId}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.parentModelData = res.data.data;
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onErrorPopupActive($event) {
      if ($event === false) {
        this.errorMessages = "";
      }
    },
  },
  computed: {
    _value() {
      if (this.emitInput) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _parentTitle() {
      if (!this.parentModelName) {
        return null;
      } else if (!this.parentModelData) {
        return this.parentLabel;
      } else {
        return S_APP_General.getValueByFieldKey(
          this.parentTitleKey,
          this.parentModelData
        );
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this._urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: `${this.$t("new")} ${this.$t(this.label)}`,
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
    _updateFields() {
      return S_APP_General.geUpdateFields(this.fields);
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  props: {
    postUrl: {
      type: String,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    emitInput: {
      type: Boolean,
      default: false,
    },
    afterSubmitAction: {
      type: Function,
      default: null,
    },
    value: {
      type: Object,
      default: null,
    },
    parentTitleKey: {
      type: String,
      default: null,
    },
    parentLabel: {
      type: String,
      default: null,
    },
    parentId: {
      type: [String, Number],
      default: null,
    },
    parentModelName: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
    },
    secondary: {
      type: Array,
    },
    modelName: String,
    label: String,
    fields: Object,
    extendPostData: {
      type: Object,
    },
    returnUrl: {
      type: String,
      default: "",
    },
    urlModelName: String,
  },
  mounted() {
    if (this.parentModelName && this.parentId) {
      this.$_parentFetchData();
    }
  },
};
</script>