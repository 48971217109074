<template>
  <div>
    <WsDialog
      ref="WsDialog"
      :title="title"
      @submit="$_onSubmit()"
      @close="$_onDialogClose()"
      :loadingSubmit="loading.submit"
    >
      <template #content>
        <WsLoading v-if="loading.fetch"></WsLoading>
        <ValidationObserver
          v-else
          ref="form"
        >
          <form @submit.prevent>
            <WsStateForm
              :fields="fields"
              :value="_value"
              @input="$_onInput($event)"
              :errorMessages="errorMessages"
            ></WsStateForm>
          </form>
        </ValidationObserver>
        <slot name="extend-content"></slot>
      </template>
      <template #rightActions>
        <WsBtn
          outlined
          @click="close()"
        >{{$t('取消')}}</WsBtn>
        <WsBtn
          @click="$_onSubmit()"
          :loading="loading.submit"
        >{{$t('送出')}}</WsBtn>
      </template>
    </WsDialog>
  </div>
</template>

<script>
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  data() {
    return {
      id: null,
      state: {},
      errorMessages: {},
      loading: {
        fetch: true,
        submit: false,
      },
    };
  },
  methods: {
    $_clear() {
      this.id = null;
      this.state = {};
      this.errorMessages = {};
    },
    $_onDialogClose() {
      this.$_clear();
      this.$emit("close");
    },
    $_onInput($event) {
      const _value = {
        ...this.value,
        ...$event,
      };
      if (this.emitInput) {
        this.$emit("input", _value);
      } else {
        this.state = _value;
      }
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        if (this.emitInput) {
          this.$emit("submit", this.value);
        } else {
          this.$emit("submit", this.state);
        }
        return;
      }
      this.loadingSubmitStart();
      const _value = this.emitInput ? { ...this.value } : { ...this.state };
      let postData = S_App_State.getFormatedStates(
        this.fields,
        _value,
        this.postEncode
      );
      if (this.extendPostData) {
        postData = {
          ...postData,
          ...this.extendPostData,
        };
      }
      if (this.formatSubmitPostData) {
        postData = this.formatSubmitPostData(postData);
      }
      try {
        const res = await this.$axios.patch(
          `/${this.modelName}/${this.id}`,
          postData
        );
        this.$emit("complete", res.data.data);
        this.close();
      } catch (error) {
        console.error(error);
        console.error(error.response);
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$emit("error", error.response.data);
          this.errorMessages = this.$t(error.response.data.message);
        } else {
          this.$emit("error");
          this.errorMessages = "送出錯誤";
        }
      } finally {
        this.loadingSubmitStop();
      }
    },
    async $_fetchData(id) {
      this.id = id;
      this.loading.fetch = true;
      try {
        const res = await this.$axios.get(`/${this.modelName}/${this.id}`);
        if (res.data.data) {
          if (this.emitInput) {
            const _value = {
              ...S_App_State.getValueFromFields(this.fields, res.data.data),
            };
            this.$emit("input", _value);
          } else {
            this.state = S_App_State.getValueFromFields(
              this.fields,
              res.data.data
            );
          }
          this.$emit("modelDataInit", res.data.data);
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.loading.fetch = false;
      }
    },
    loadingSubmitStart() {
      this.loading.submit = true;
    },
    loadingSubmitStop() {
      this.loading.submit = false;
    },
    close() {
      this.$refs.WsDialog.close();
    },
    open(id) {
      this.$_fetchData(id);
      this.$refs.WsDialog.open();
    },
  },
  props: {
    title: {
      type: String,
    },
    fields: {
      type: Object,
    },
    modelName: {
      type: String,
      required: true,
    },
    emitSubmit: {
      type: Boolean,
    },
    postEncode: {
      type: Boolean,
    },
    extendPostData: {
      type: Object,
    },
    emitInput: {
      type: Boolean,
    },
    value: {
      type: Object,
    },
    formatSubmitPostData: {
      type: Function,
    },
  },
  computed: {
    _value() {
      if (this.emitInput) {
        return this.value;
      } else {
        return this.state;
      }
    },
  },
};
</script>

<style>
</style>