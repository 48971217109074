<template>
  <WsMain>
    <WsReadSection
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      v-bind="crudSetting.read"
      :id="_id"
      :value="_value"
      @input="$_onInput($event)"
      @title-bar-custom-btn-click="$emit('title-bar-custom-btn-click',$event)"
      @title-custom-dropdown-menu-click="$emit('title-custom-dropdown-menu-click',$event)"
    >
    </WsReadSection>
  </WsMain>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `${this.label}`,
    };
  },
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_onInput($event) {
      this.state = $event;
      this.$emit("input", $event);
    },
  },
  computed: {
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _id() {
      if (this.id) {
        return this.id;
      } else {
        return this.$route.params.id;
      }
    },
  },
  props: {
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    id: {
      type: [Number, String],
    },
    crudSetting: {
      type: Object,
    },
  },
};
</script>