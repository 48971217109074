import axios from 'axios'
import config from '@/__config'
export default {
  async create(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('pocket_image', data)
        .then(res => {
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  async upload({
    file,
    fileName,
    signed = false,
    getUploadUrl,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .get(getUploadUrl, {
          params: {
            name: fileName,
          }
        })
        .then(res => {
          const uploadUrl = res.data
          axios
            .put(uploadUrl, file, {
              transformRequest: [(data, headers) => {
                delete headers.common.Authorization;
                return data
              }]
            })
            .finally(() => {
              const createData = {
                name: fileName,
                signed: signed,
              }
              let _url = uploadUrl.split("?")[0]
              if (config.general.postEncode) {
                _url = btoa(_url)
              }
              if (signed) {
                createData.signed_url = _url
              } else {
                createData.url = _url
              }
              this
                .create(createData)
                .then(res => {
                  if (!this.signed && config.storage.service == 'gcs') {
                    axios
                      .patch(`pocket_image/public_url/${res.id}`)
                      .then(() => {
                        resolve(res)
                      })
                  } else {
                    resolve(res)
                  }
                })
                .catch((err) => {
                  reject(err)
                })
            })
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getSrc(pocketImage) {
    if (!pocketImage) {
      return null;
    } else {
      if (pocketImage.signed) {
        return pocketImage.signed_url;
      } else {
        return pocketImage.url;
      }
    }
  }
}