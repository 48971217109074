<template>
  <WsMain
    v-if="modelData"
    :style="[{'padding-bottom':'120px'}]"
  >
    <WsBreadcrumbs :menu="_breadcrumbsItems"></WsBreadcrumbs>
    <WsText class="mt-20">{{_title}}</WsText>
    <WsBtnFlexGroup>
      <WsBtn
        color="textSecondary"
        outlined
        :to="`/${modelName}/${id}/update?redirect=${this.$route.fullPath}`"
      >
        <WsIcon
          class="mr-4"
          name="icon-md-mode-edit"
        /> {{$t('edit')}}
      </WsBtn>
      <WsBtn
        v-show="modeVersions"
        outlinvueed
        :to="`/${modelName}/${id}/version/create?redirect=${this.$route.fullPath}`"
      >
        <WsIcon
          class="mr-4"
          name="icon-md-update"
        /> 更新{{$t(label)}}版本
      </WsBtn>
      <WsBtn
        v-show="modeVersions"
        :to="`/${modelName}/${id}/version`"
        outlined
      >
        <WsIcon
          class="mr-4"
          name="icon-md-import-contacts"
        /> 查看版本列表
      </WsBtn>
      <WsBtn
        @click="$_onDelete(id)"
        alert
      >
        <WsIcon
          class="mr-4"
          name="icon-md-delete"
        /> {{$t('delete')}}
      </WsBtn>
    </WsBtnFlexGroup>
    <div class="ws-content-section pb-80">
      <div class="ws-content-section__primary">
        <WsReadContentBlock
          v-for="(item,itemKey) in primary"
          :key="itemKey"
          :type="item.type"
          :label="item.label"
          :title="item.title"
          :remark="item.remark"
          :floors="item.floors"
          :modedFields="fields"
          :fields="item.fields"
          :activeText="item.activeText"
          :inactiveText="item.inactiveText"
          :field="item.field"
          :locale="_selectingLocale"
          :modelName="item.modelName"
          :searchMode="item.searchMode"
          :batchMode="item.batchMode"
          :modeVersions="item.modeVersions"
          :modeParent="item.modeParent"
          :customButtons="item.customButtons"
          :modelData="modelData"
          :modelId="id"
          :titleKey="item.titleKey"
          :fieldsRead="item.fieldsRead"
          :orderBy="item.orderBy"
          :orderWay="item.orderWay"
          :parentModelName="$_getParentModelName(item)"
          :parentState="$_getParentState(item)"
        ></WsReadContentBlock>
      </div>
      <div class="ws-content-section__secondary">
        <WsReadContentBlock
          v-for="(item,itemKey) in secondary"
          :key="itemKey"
          :type="item.type"
          :label="item.label"
          :title="item.title"
          :remark="item.remark"
          :floors="item.floors"
          :modedFields="fields"
          :fields="item.fields"
          :activeText="item.activeText"
          :inactiveText="item.inactiveText"
          :field="item.field"
          :locale="_selectingLocale"
          :modelName="item.modelName"
          :searchMode="item.searchMode"
          :batchMode="item.batchMode"
          :modeVersions="item.modeVersions"
          :modeParent="item.modeParent"
          :customButtons="item.customButtons"
          :modelData="modelData"
          :modelId="id"
          :titleKey="item.titleKey"
          :fieldsRead="item.fieldsRead"
          :orderBy="item.orderBy"
          :orderWay="item.orderWay"
          :parentModelName="$_getParentModelName(item)"
          :parentState="$_getParentState(item)"
        ></WsReadContentBlock>
      </div>
    </div>
    <!-- Delete Dialog -->
    <WsPopupAlert
      ref="deleteDialog"
      :title="$t('sure_to_delete')"
      :description="$t('delete_cannot_be_recovered')"
      @submit="$_onDeleteSubmit($event)"
    />
  </WsMain>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
export default {
  data: () => ({
    modelData: null,
  }),
  props: {
    titleKey: {
      type: String,
      default: "text",
    },
    modeVersions: {
      type: Boolean,
      default: false,
    },
    backRouteText: {
      type: String,
      default: "返回",
    },
    backRoute: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
      default() {
        return [];
      },
    },
    secondary: {
      type: Array,
      default() {
        return [];
      },
    },
    id: {
      type: [String, Number],
      default: null,
    },
    localeMode: {
      type: Boolean,
      default: false,
    },
    backLabel: String,
    modelName: String,
    label: String,
    fields: Object,
  },
  computed: {
    _selectingLocale() {
      return this.$store.state.locale.selectingLocale;
    },
    _title() {
      if (!this.modelData) {
        return this.label;
      } else {
        return S_APP_General.getValueByFieldKey(this.titleKey, this.modelData);
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this.modelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._title,
        disabled: true,
      });
      return _breadcrumbsItems;
    },
    _backRouteText() {
      if (this.backRouteText) {
        return "返回";
      } else {
        return `${this.label}列表`;
      }
    },
    _backRoute() {
      if (this.backRoute) {
        return this.backRoute;
      } else {
        return `/${this.modelName}`;
      }
    },
  },
  methods: {
    $_onDelete($event) {
      this.$refs.deleteDialog.open($event);
    },
    async $_onDeleteSubmit($event) {
      this.$refs.deleteDialog.loadingStart();
      try {
        await this.$axios.delete(`/${this.modelName}/${$event}`);
        this.$router.push(`/${this.modelName}`);
      } catch (error) {
        alert(error);
      } finally {
        this.$refs.deleteDialog.close();
      }
    },
    $_getParentState(primaryItem) {
      if (primaryItem.modeParentVersions && this.modelData.last_version) {
        return this.modelData.last_version.id;
      } else {
        return this.modelData.id;
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeParentVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.modelName}/${this.id}`;
      if (this.localeMode) {
        getUrl += `?locale=${this._selectingLocale}`;
      }
      try {
        const res = await this.$axios.get(getUrl);
        this.modelData = res.data.data;
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
  },
  mounted() {
    this.$_fetchData();
  },
};
</script>

<style>
</style>