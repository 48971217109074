import serviceAuth from '@/__vue2stone_cms/service/api/v1/auth'
import axios from 'axios';
import moment from 'moment';
import numeral from 'numeral';
import config from '@/__config'
import message from '@/__vue2stone_cms/message'
import * as Case from "change-case";

export default (app) => {
  app.prototype.$auth = serviceAuth;
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
  app.prototype.$axios = axios;
  app.prototype.$moment = moment;
  app.prototype.$numeral = numeral;
  app.prototype.$config = config;
  app.prototype.$message = message[config.locale.lang];
  app.prototype.$case = Case;
}