<template>
  <WsText size="14">
    {{_switchText}}
  </WsText>
</template>

<script>
export default {
  computed: {
    _switchText() {
      if (this.value && this.value != "0") {
        return this.activeText;
      } else {
        return this.inactiveText;
      }
    },
  },
  props: {
    activeText: {
      type: String,
      default: "Y",
    },
    inactiveText: {
      type: String,
      default: "N",
    },
    value: {
      type: [Boolean, Number, String],
      default: false,
    },
  },
};
</script>

<style>
</style>