<template>
  <div class="ws-btn-color">
    <input
      type="color"
      @input="$emit('input',$event)"
      :value="value"
    >
  </div>
</template>

<script>
export default {
  props: {
    value: {},
  },
};
</script>

<style>
</style>