<template>
  <WsDialog
    ref="WsDialog"
    :title="_title"
    @close="$_onDialogClose()"
  >
    <template #content>
      <WsLoading v-if="loading.fetch"></WsLoading>
      <div v-else>
        <slot name="extend-content-pre"></slot>
        <WsFlex
          v-if="(customBtns&&customBtns.length)||(customDropdownMenu&&customDropdownMenu.length)"
          class="mb-20"
        >
          <WsBtn
            v-for="(customBtn, customBtnIndex) in customBtns"
            :key="`btn${customBtnIndex}`"
            class="mr-20"
            :to="customBtn.to"
            :disabled="customBtn.disabled"
            @click="$_onCustomBtnClick(customBtn)"
          >
            <WsIcon
              v-if="customBtn.icon&&customBtn.icon.left"
              :size="customBtn.icon.size||'24'"
              :name="customBtn.icon.name||'icon-md-menu'"
              class="mr-3"
            />
            {{customBtn.label}}
            <WsIcon
              v-if="customBtn.icon&&customBtn.icon.right"
              :size="customBtn.icon.size||'24'"
              :name="customBtn.icon.name||'icon-md-menu'"
              class="mr-3"
            />
          </WsBtn>
          <WsStateSelect
            class="mr-20"
            v-for="(dropdownItem,dropdownIndex) in customDropdownMenu"
            :key="`dropdown${dropdownIndex}`"
            isEmitItem
            :items="dropdownItem.items"
            :value="dropdownItem.value"
            :modelName="dropdownItem.modelName"
            :textKey="dropdownItem.textKey"
            :valueKey="dropdownItem.valueKey"
            :placeholder="dropdownItem.placeholder"
            @input="$_onCustomDropdownMenuClick($event,dropdownItem)"
          ></WsStateSelect>
        </WsFlex>
        <WsStateForm
          :fields="_fields"
          :value="state"
          info
        ></WsStateForm>
        <slot name="extend-content"></slot>
      </div>
      <!-- <slot name="content"></slot> -->
      <!-- <WsStateForm
        :fields="fields"
        :value="state"
      ></WsStateForm> -->
    </template>
    <template #rightActions>
      <WsBtn
        outlined
        @click="close()"
      >{{$t('關閉')}}</WsBtn>
    </template>
  </WsDialog>
</template>

<script>
export default {
  data() {
    return {
      id: null,
      state: {},
      loading: {
        fetch: true,
        submit: false,
      },
    };
  },
  methods: {
    $_onCustomBtnClick(btn) {
      if (btn.to) {
        return;
      } else {
        this.$emit("custom-btn-click", btn.event);
      }
    },
    $_onCustomDropdownMenuClick($event, dropdownItem) {
      const item = {
        id: dropdownItem.id,
        value: $event,
      };
      this.$emit("custom-dropdown-menu-click", item);
    },
    $_clear() {
      this.id = null;
      this.state = {};
    },
    $_onDialogClose() {
      this.$_clear();
      this.$emit("close");
    },
    close() {
      this.$refs.WsDialog.close();
    },
    open(id) {
      this.$_fetchData(id);
      this.$refs.WsDialog.open();
    },
    async $_fetchData(id) {
      this.id = id;
      this.loading.fetch = true;
      try {
        const res = await this.$axios.get(`/${this.modelName}/${this.id}`);
        if (res.data.data) {
          this.state = res.data.data;
          this.$emit("modelDataInit", res.data.data);
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        this.loading.fetch = false;
      }
    },
  },
  computed: {
    _title() {
      if (this.title) {
        return this.title;
      } else {
        return this.state[this.titleKey];
      }
    },
    _fields() {
      if (!this.showFields) {
        return {};
      }
      const _fields = {};
      this.showFields.forEach((fieldKey) => {
        if (this.fields[fieldKey]) {
          _fields[fieldKey] = this.fields[fieldKey];
        }
      });
      return _fields;
    },
  },
  props: {
    title: {
      type: String,
    },
    fields: {
      type: Object,
    },
    modelName: {
      type: String,
    },
    showFields: {
      type: Array,
    },
    titleKey: {
      type: String,
      default: "name",
    },
    customBtns: {
      type: Array,
    },
    customDropdownMenu: {
      type: Array,
    },
  },
};
</script>

<style>
</style>