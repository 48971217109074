import config from '@/__config';
import i18n from '@/i18n';
const model = {
  modelName: 'service_store_close',
  label: i18n.t('跨服務中心休息時間'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    start: {
      type: 'date',
      label: i18n.t('起始時間'),
      labelInLocale: true,
      rules: 'required',
    },
    end: {
      type: 'date',
      label: i18n.t('結束時間'),
      labelInLocale: true,
      rules: 'required',
    },
    service_stores: {
      type: 'belongsToMany',
      label: i18n.t('服務中心'),
      textKey: 'name',
      labelInLocale: true,
      modelName: 'service_store',
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    updated_admin: {
      type: 'belongsTo',
      label: i18n.t('最後更新人'),
      readonly: true,
      textKey: 'name',
      modelName: 'admin',
    },
  },
};

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: i18n.t('管理員分群'),
      modelName: 'cmser_group',
      labelInLocale: true,
    };
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: i18n.t('管理員分群'),
      modelName: 'admin_group',
      labelInLocale: true,
    };
  }
}

export default model;
