<template>
  <div
    class="ws-state-textarea"
    @click="$_inputBlockOnClick()"
    v-click-outside="$_inputBlockOnBlur"
  >
    <textarea-autosize
      ref="textarea"
      :placeholder="placeholder"
      :min-height="100"
      :max-height="500"
      :autofocus="autofocus"
      :value="value"
      @input="$emit('input',$event)"
      @keyup="$_onKeyup"
      :disabled="disabled"
    />
    <div
      class="ws-state-textarea__bg"
      :style="[
        {'background-color':_backgroundColor}
      ]"
    ></div>
  </div>
</template>

<script>
export default {
  data: () => ({
    isFocus: false,
  }),
  computed: {
    _backgroundColor() {
      if (this.backgroundColor) {
        return this.backgroundColor;
      } else {
        return null;
      }
    },
  },
  props: {
    autofocus: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: "...",
    },
    name: {
      type: String,
      default: null,
    },
    value: {},
    autoGrow: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    backgroundColor: {
      type: String,
    },
  },
  methods: {
    $_inputBlockOnClick() {
      if (this.disabled) {
        return;
      }
      this.isFocus = true;
    },
    $_inputBlockOnBlur() {
      this.isFocus = false;
    },
    $_onFocus() {
    },
    $_onBlur() {
    },
    $_onKeyup($event) {
      this.$emit("keyup", $event);
    },
  },
  watch: {
    isFocus: {
      handler() {
        if (this.isFocus) {
          this.$refs.textarea.$el.focus();
          this.$emit("onFocus");
        } else {
          this.$refs.textarea.$el.blur();
          this.$emit("onBlur");
        }
      },
    },
  },
};
</script>