<template>
  <WsStateInput
    ref="WsStateInput"
    @keyup="$emit('keyup', $event)"
    :autofocus="autofocus"
    :maxWidth="maxWidth"
    :rules="rules"
    :type="type"
    :name="name"
    :errors="errors"
    :placeholder="placeholder"
    :note="note"
    :isOk="isOk"
    :errorMessage="errorMessage"
    :disabled="disabled"
    :value="value"
    :maxlength="maxlength"
    :stateData="stateData"
    :suffixText="suffixText"
    @input="$emit('input',$event)"
    @enter="$emit('search',$event)"
    @after-btn-click="$emit('search',$event)"
    :beforeIcon="_beforeIcon"
  >
    <template v-slot:after-btn>
      <WsIcon
        v-if="searchOnClick"
        name="icon-md-search"
        class="mr-4"
      ></WsIcon>
      {{placeholder}}
    </template>
  </WsStateInput>
</template>

<script>
export default {
  computed: {
    _beforeIcon() {
      if (this.searchOnClick) {
        return undefined;
      } else {
        return this.beforeIcon;
      }
    },
  },
  props: {
    maxWidth: {
      type: String,
      default: null,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: String,
      default: null,
    },
    errors: {
      type: Array,
      default: null,
    },
    note: {
      type: String,
      default: null,
    },
    isOk: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [String, Array],
      default: null,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    maxlength: {
      type: [Number, String],
    },
    stateData: {
      type: Object,
    },
    suffixText: {
      type: String,
    },
    searchOnClick: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>