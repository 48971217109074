// import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_campaign',
  urlModelName: 'shop_product_campaign',
  label: i18n.t('商品促銷'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    is_active: {
      type: 'switch',
      label: 'is_active',
      defaultValue: true,
      labelInLocale: true,
    },
    name: {
      type: 'text',
      label: i18n.t('商品促銷名稱'),
      placeholder: i18n.t('輸入') + '...',
      rules: 'required',
    },
    type: {
      type: 'radio',
      label: i18n.t('類型'),
      rules: 'required',
      defaultValue: 'same_price',
      items: [
        {
          label: i18n.t('均一價'),
          value: 'same_price',
        },
        {
          label: i18n.t('均一折扣'),
          value: 'same_discount_percent',
        },
        {
          label: i18n.t('滿X件Y折'),
          value: 'pieces_to_discount_percent',
        },
        {
          label: i18n.t('滿X件幾元'),
          value: 'pieces_to_price',
        },
      ],
    },
    discount_percent: {
      type: 'number',
      label: '打折折數',
      placeholder: i18n.t('折'),
      rules: 'required|max_value:10|min_value:0|int_or_de:1',
      hint: '最多至小數點後一位，範圍：0 < 折數 < 10',
      col: 6,
    },
    discount_amount: {
      type: 'number',
    },
    discount_way: {
      type: 'radio-input',
      label: i18n.t('折扣方式'),
      items: [
        {
          value: 'discount_percent',
          text: i18n.t('打折折數'),
          fields: {
            discount_percent: {
              type: 'number',
              label: '',
              placeholder: i18n.t('折'),
              rules: 'required|max_value:10|min_value:0|int_or_de:1',
              hint: '最多至小數點後一位，範圍：0 < 折數 < 10',
              col: 6,
            },
          },
        },
        {
          value: 'discount_amount',
          text: i18n.t('折抵金額'),
          fields: {
            discount_amount: {
              type: 'number',
              label: '',
              rules: 'required|min_value:0',
              placeholder: `NT$ ${i18n.t('輸入')}...`,
              col: 6,
            },
          },
        },
      ],
    },
    start_date: {
      type: 'date',
      label: i18n.t('開始時間'),
      placeholder: i18n.t('選擇起日'),
      disabledDate: function (data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('結束時間'),
      placeholder: i18n.t('選擇迄日'),
      disabledDate: function (data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    status: {
      type: 'select',
      label: i18n.t('狀態'),
      labelInLocale: true,
      defaultValue: 'in-progress',
      items: [
        {
          value: 'in-progress',
          text: i18n.t('進行中'),
        },
        {
          value: 'not-started',
          text: i18n.t('未開始'),
        },
        {
          value: 'end',
          text: i18n.t('已結束'),
        },
      ],
    },
    show_countdown: {
      type: 'switch',
      label: '倒數顯示',
      defaultValue: 0
    },
    show_content: {
      type: 'switch',
      label: '前台顯示',
      defaultValue: 1
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      readonly: true,
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      readonly: true,
    },
    duration: {
      type: 'date-range-or-not',
      label: '使用期限',
    },
    // duration: {
    //   type: 'custom',
    //   label: i18n.t('使用期間'),
    //   getValue: function (data) {
    //     if (data.start_date && data.end_date) {
    //       return `${moment(data.start_date).format('YYYY.MM.DD')} - ${moment(
    //         data.end_date,
    //       ).format('YYYY.MM.DD')}`;
    //     } else {
    //       return '無期限';
    //     }
    //   },
    //   items: [
    //     {
    //       text: i18n.t('日期區間'),
    //       value: 'duration',
    //       fields: {
    //         start_date: {
    //           type: 'date',
    //           label: i18n.t('開始日期'),
    //           placeholder: i18n.t('選擇起日'),
    //           col: 4,
    //         },
    //         end_date: {
    //           type: 'date',
    //           label: i18n.t('結束日期'),
    //           placeholder: i18n.t('選擇迄日'),
    //           col: 4,
    //         },
    //       },
    //     },
    //     {
    //       text: i18n.t('無期限'),
    //       value: 'infinite',
    //     },
    //   ],
    // },
    custom_discount_way: {
      type: 'custom',
      label: i18n.t('折扣方式'),
      getValue: function (data) {
        if (data.discount_way === 'discount_amount') {
          return `${i18n.t('折抵金額')} | NT$ ${data.discount_amount || 0}`;
        } else if (data.discount_way === 'discount_percent') {
          return `${i18n.t('打折折數')} | ${i18n.t('{num} 折', {
            num: data.discount_percent || 0,
          })}`;
        } else {
          return '-';
        }
      },
    },
    price: {
      type: 'number',
      label: '價格',
      rules: 'required'
    },
    pieces_threshold: {
      type: 'number',
      label: '滿X件',
      rules: 'required'
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: ['name', 'type', 'duration'],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      params: {
        type: 'same_price,same_discount_percent,pieces_to_discount_percent,pieces_to_price',
      },
      customTableActions: [
        {
          icon: "icon-md-view-list",
          emit: "shop_product",
          tooltip: i18n.t("管理商品"),
        },
      ],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue)
              },
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'name',
      submitToIndex: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              getFields: (formValue) => {
                return model.getFieldsFromFormValue(formValue)
              },
            },
          ],
        },
      ],
    },
    read: {
      getFields: (formValue) => {
        return model.getFieldsFromFormValue(formValue)
      },
      titleKey: 'name'
    },
  },
  getFieldsFromFormValue(formValue) {
    if (!formValue.type) {
      return ['type', 'is_active', 'name', 'show_countdown', 'show_content', 'duration']
    } else if (formValue.type == 'same_price') {
      return ['type', 'price', 'is_active', 'name', 'show_countdown', 'show_content', 'duration',]
    } else if (formValue.type == 'same_discount_percent') {
      return ['type', 'discount_percent', 'is_active', 'name', 'show_countdown', 'show_content', 'duration']
    } else if (formValue.type == 'pieces_to_discount_percent') {
      return ['type', 'is_active', 'pieces_threshold', 'discount_percent', 'name', 'show_countdown', 'show_content', 'duration']
    } else if (formValue.type == 'pieces_to_price') {
      return ['type', 'is_active', 'pieces_threshold', 'price', 'name', 'show_countdown', 'show_content', 'duration']
    } else {
      return ['type', 'is_active', 'name', 'show_countdown', 'show_content', 'duration']
    }
  }
};

export default model;
