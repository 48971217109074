<template>
  <div>
    <WsUpdate
      :id="_id"
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :fields="fields"
      v-bind="crudSetting.update"
      :value="_value"
      @input="$_onInput($event)"
      @modelDataInit="$emit('modelDataInit',$event)"
      @submit="$emit('submit',$event)"
    >
    </WsUpdate>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `${this.$t("edit")}${this.label}`,
    };
  },
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_onInput($event) {
      this.state = $event;
      this.$emit("input", $event);
    },
  },
  computed: {
    _id() {
      return this.$route.params.id;
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
  },
  props: {
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    value: {
      type: Object,
    },
  },
};
</script>