<template>
  <div>
    <WsDialogCRUDUpdate
      ref="WsDialogCRUDUpdate"
      :title="$t('更改密碼')"
      :fields="fields"
      :modelName="_modelName"
      v-model="state"
    >
    </WsDialogCRUDUpdate>
  </div>
</template>
<script>
export default {
  name: "WsDialogAdminPasswordUpdate",
  data() {
    return {
      state: {},
      fields: {
        password: {
          type: "password",
          label: "新密碼",
          rules: "required",
          autofocus: true,
        },
      },
    };
  },
  computed: {
    _modelName() {
      return this.$config.general.postEncode ? "cmser" : "admin";
    },
  },
  methods: {
    $_clear() {
      this.state = {};
    },
    open(id) {
      this.$refs.WsDialogCRUDUpdate.open(id);
    },
  },
};
</script>