<template>
  <div>
    <WsDialog ref="popup">
      <template #content>
        <CrudRead
          v-bind="$store.state.stone_model.shop_order"
          :id="id"
          :value="state"
          @input="$emit('input',$event)"
          @title-bar-custom-btn-click="$emit('title-bar-custom-btn-click',$event)"
          @title-custom-dropdown-menu-click="$emit('title-custom-dropdown-menu-click',$event)"
        ></CrudRead>
        <WsCrud
          v-if="state"
          class="mt-40"
          :title="$t('商品資訊')"
          :modelName="subModelName"
          :label="subLabel"
          :fields="subfields"
          :showFields="shopOrderShopProductShowFields"
          :infiniteScroll="false"
          :params="_params"
          :creatable="false"
          inRowBtnRead
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
          :showExpand="false"
          :expandable="false"
          :hasFilter="false"
          :deletable="false"
        ></WsCrud>
        <div v-if="state && state.shop_return_records&&state.shop_return_records.length">
          <WsCard class="mt-20">
            <WsCrud
              v-if="state"
              class="mt-40"
              :title="$t('退貨資訊')"
              :modelName="returnModelName"
              :fields="returnFields"
              :showFields="returnShowFields"
              :titleBarCustomBtns="_returnTitleBarCustomBtns"
              :infiniteScroll="false"
              :params="_params"
              :creatable="false"
              inRowBtnRead
              :inRowBtnUpdate="false"
              :inRowBtnDelete="false"
              :showExpand="true"
              :expandable="true"
              :hasFilter="false"
              v-model="returnModelDatas"
              @title-bar-custom-btn-click="$_returnTitleBarCustomBtnClick($event)"
            ></WsCrud>
          </WsCard>
        </div>
      </template>
    </WsDialog>
    <WsPopupAlert
      ref="alertPopup"
      :title="alertPopup.title"
      :description="alertPopup.description"
      @submit="$_alertPopupSubmit($event)"
    >
    </WsPopupAlert>
  </div>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_order";
import subModel from "@/__vue2stone_cms/models/shop_order_shop_product";
import returnModel from "@/__vue2stone_cms/models/shop_return_record";
export default {
  metaInfo() {
    return {
      title: "訂單列表",
    };
  },
  data: () => ({
    modelName: model.modelName,
    subModelName: subModel.modelName,
    returnModelName: returnModel.modelName,
    label: model.label,
    subLabel: subModel.label,
    returnLabel: returnModel.label,
    fields: model.fields,
    subfields: subModel.fields,
    returnFields: returnModel.fields,
    returnLeftFields: returnModel.leftFields,
    returnModelDatas: null,
    returnShowFields: [
      "shop_order_shop_product__no",
      "shop_order_shop_product__name",
      "shop_order_shop_product__spec",
      "shop_order_shop_product__weight_capacity",
      "shop_order_shop_product__price",
      "count",
    ],
    shopOrderShopProductShowFields: [
      "name",
      "shop_product_no",
      "shop_product_weight_capacity",
      "shop_product_storage_space",
      "spec",
      "weight_capacity",
      "price",
      "original_count",
      "count",
    ],
    alertPopup: {},
  }),

  computed: {
    _params() {
      return {
        shop_order: this.id,
      };
    },
    _returnTitleBarCustomBtns() {
      let _titleBarCustomBtns = [];
      _titleBarCustomBtns.push({
        label: "取消退訂",
        event: "return-cancel",
      });
      _titleBarCustomBtns.push({
        label: "異動退訂",
        event: "return-update",
      });
      return _titleBarCustomBtns;
    },
  },
  methods: {
    async $_returnTitleBarCustomBtnClick($event) {
      if ($event == "return-cancel") {
        this.$_alertPopupOpen(this.returnModelDatas);
        this.alertPopup = {
          title: "",
          description: "是否取消退訂？",
          id: $event,
        };
      } else if ($event === "return-update") {
        this.$_dialogClose();
        this.$emit("return-update");
      }
    },
    $_alertPopupClose() {
      this.$refs.alertPopup.close();
    },
    $_alertPopupOpen($event) {
      this.$refs.alertPopup.open($event);
    },
    async $_returnCancel() {
      let postArr = [];
      try {
        this.returnModelDatas.forEach((item) => {
          postArr.push(
            this.$axios.delete(`/${this.returnModelName}/${item.id}`)
          );
        });
        await Promise.all(postArr);
        this.returnModelDatas = [];
        this.state.shop_return_records = [];
      } catch (err) {
        alert(err);
      }
    },
    async $_updateData(data) {
      try {
        const res = await this.$axios.patch(
          `/${this.modelName}/${this.id}`,
          data
        );
        this.$emit("input", res.data.data);
      } catch (err) {
        alert(err);
      }
    },
    $_alertPopupSubmit() {
      if (this.alertPopup.id === "return-cancel") {
        this.$_alertPopupClose();
        this.$_returnCancel();
      }
    },
    $_dialogClose() {
      this.$refs.popup.close();
    },
    $_dialogOpen() {
      this.$refs.popup.open();
    },
  },
  props: {
    id: {
      type: [String, Number],
    },
    state: {
      type: Object,
    },
  },
};
</script>

<style>
</style>