export default {
  getChangeReason(shop_order_shop_product) {
    let changeReasonArr = []
    if (shop_order_shop_product.shop_cart_product.count != shop_order_shop_product.count) {
      changeReasonArr.push('因系統庫存不足自動調整數量')
    }
    if (shop_order_shop_product.discount_price &&
      shop_order_shop_product.shop_cart_product.discount_price != shop_order_shop_product.discount_price
    ) {
      changeReasonArr.push('商品價格更新')
    } else if (shop_order_shop_product.shop_cart_product.price != shop_order_shop_product.price) {
      changeReasonArr.push('商品價格更新')
    }
    if (this.getReturnCount(shop_order_shop_product)) {
      changeReasonArr.push('因庫存不足調整數量')
    }
    return changeReasonArr.join('\n');
  },
  getReturnCount(shop_order_shop_product) {
    let returnCount = 0
    if (shop_order_shop_product.shop_return_records && shop_order_shop_product.shop_return_records.length) {
      shop_order_shop_product.shop_return_records.forEach(shop_return_record => {
        returnCount += shop_return_record.count
      });
    }
    return returnCount;
  }
}