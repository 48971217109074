const model = {
  modelName: 'invoice_job',
  label: '發票開立作業',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    invoice_date: {
      type: 'date',
      label: '開立日期'
    },
    status: {
      type: 'select',
      label: '狀態',
      items: [
        {
          value: 'waiting',
          text: '等待開立',
        },
        {
          value: 'invoiced',
          text: '已開立',
        },
      ]
    },
    shop_order: {
      type: 'belongsTo',
      label: '訂單',
      textKey: "no",
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: false,
      showFields: ['id', 'invoice_date', 'status', 'shop_order'],
      infiniteScroll: true,
      creatable: false,
    },
  },
};

export default model;
