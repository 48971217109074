export default {
  login: {
    url: '/auth/signin'
  },
  logout: {
    url: '/auth/signout'
  },
  scope: {
    list: [
      {
        text: '系統管理員',
        value: 'boss'
      },
      {
        text: '管理員',
        value: 'admin'
      },
    ]
  },
  admin_blur: true,
  admin_group: true,
  admin_role: true,
  log: true,
}