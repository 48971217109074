import config from '@/__config'
const model = {
  modelName: config.auth.admin_blur ? 'cmser' : "admin",
  label: "管理者帳號",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: '名稱',
      rules: 'required',
      autofocus: true
    },
    email: {
      type: 'email',
      label: 'Email',
      rules: 'required'
    },
    password: {
      type: 'password',
      label: '密碼',
      rules: {
        create: 'required',
        update: ''
      },
    },
    is_active: {
      type: 'switch',
      label: '啟用',
      defaultValue: true,
    },
    email_verified_at: {
      type: 'datetime',
      label: '信箱認證時間'
    },
    scopes: {
      type: 'multi-select',
      label: 'Scopes',
      items: config.auth.scope.list
    },
  },
}

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'cmser_group',
      labelInLocale: true,
    }
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'admin_group',
      labelInLocale: true,
    }
  }
}
if (config.auth.admin_role) {
  model.fields.roles = {
    type: 'belongsToMany',
    label: '角色',
    modelName: config.auth.admin_blur ? 'cmser_role' : "admin_role",
  }
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
}

export default model