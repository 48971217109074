<template>
  <WsText size="14">{{_content}}</WsText>
</template>

<script>
export default {
  methods: {
    $_getItemByValue(value) {
      const item = this.items.find((e) => {
        return e.value == value;
      });
      return item;
    },
  },
  computed: {
    _content() {
      if (this.value == undefined || !this.items.length) {
        return null;
      } else {
        return this.$_getItemByValue(this.value).label;
      }
    },
  },
  props: {
    value: {
      type: [Number, String, Array],
      default: null,
    },
    items: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>