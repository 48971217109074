<template>
  <div
    v-tooltip.top-center="tooltip"
    @click="$emit('click',$event)"
    class="ws-icon-btn"
    :style="[
      {width:`${size}px`},
      {height:`${size}px`},
      {'background-color':backgroundColor}
    ]"
    :class="[
      {disabled:disabled},
      {hide:hide},
    ]"
  >
    <RouterLink
      v-if="to"
      class="ws-icon-btn__link"
      :to="to"
    ></RouterLink>
    <p v-if="text">
      <slot></slot>
    </p>
    <WsIcon
      v-else
      :size="_iconSize"
      :name="name"
    />
  </div>
</template>

<script>
export default {
  computed: {
    _iconSize() {
      return this.size / 2;
    },
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
    to: {
      type: String,
      default: null,
    },
    tooltipPosition: {
      type: String,
      default: "bottom",
    },
    iconColor: {
      type: String,
      default: "textSecondary",
    },
    color: {
      type: String,
      default: "border",
    },
    size: {
      type: Number,
      default: 36,
    },
    backgroundColor: {
      type: String,
    },
    name: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    hide: {
      type: Boolean,
      default: false,
    },
  },
};
</script>