import moment from 'moment';
import i18n from '@/i18n';

const model = {
  modelName: 'shop_product_cover_frame',
  label: i18n.t('活動圖框管理'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: i18n.t('活動名稱'),
      placeholder: i18n.t('輸入') + '...',
    },
    url: {
      type: 'image',
      label: i18n.t('圖框'),
      signed: false,
      previewMaxWidth: '230px',
      previewMaxHeight: '230px',
      uploadable: true,
      linkable: false,
      pocketable: false,
    },
    start_date: {
      type: 'date',
      label: i18n.t('開始日期'),
      placeholder: i18n.t('選擇起日'),
      disabledDate: function(data, date) {
        if (data && data.end_date) {
          if (this.$moment(date).isAfter(data.end_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    end_date: {
      type: 'date',
      label: i18n.t('結束時間'),
      placeholder: i18n.t('選擇迄日'),
      disabledDate: function(data, date) {
        if (data && data.start_date) {
          if (this.$moment(date).isBefore(data.start_date)) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      },
    },
    order_type: {
      type: 'select',
      label: i18n.t('活動館別'),
      placeholder: i18n.t('請選擇活動管別'),
      items: [
        {
          text: i18n.t('隔日配'),
          value: 'next-day',
        },
        {
          text: i18n.t('預購'),
          value: 'pre-order',
        },
      ],
    },
    is_active: {
      type: 'switch',
      label: i18n.t('刊登狀態'),
      activeText: i18n.t('已上架'),
      inactiveText: i18n.t('下架'),
      updatable: {
        getAlertTitle: function() {
          return i18n.t('確認要下架此活動圖框嗎？');
        },
        getAlertContent: function() {
          //文案未定，no i18n
          return '解除刊登後...';
        },
      },
    },
    duration: {
      type: 'radio-input',
      label: i18n.t('活動起迄日期'),
      items: [
        {
          text: i18n.t('時間區間'),
          value: 'interval',
          fields: {
            start_date: {
              type: 'date',
              label: i18n.t('開始日期'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.end_date) {
                  if (this.$moment(date).isAfter(data.end_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
            end_date: {
              type: 'date',
              label: i18n.t('結束日期'),
              placeholder: i18n.t('選擇迄日'),
              col: 4,
              disabledDate: function(data, date) {
                if (data && data.start_date) {
                  if (this.$moment(date).isBefore(data.start_date)) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return false;
                }
              },
            },
          },
        },
        {
          text: i18n.t('永不下架'),
          value: 'infinite',
        },
      ],
    },
    custom_duration: {
      type: 'custom',
      label: i18n.t('活動起迄日期'),
      getValue: function(data) {
        let _duration = i18n.t('永不下架');
        if (data.start_date)
          _duration = `${moment(data.start_date).format('YYYY.MM.DD')} - `;
        if (data.end_date)
          _duration += moment(data.end_date).format('YYYY.MM.DD');
        return _duration;
      },
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ['url', 'name', 'is_active', 'custom_duration'],
      infiniteScroll: true,
      inRowBtnRead: true,
      inRowBtnUpdate: true,
      inRowBtbDelete: true,
      pageMode: true,
      filterUniqueSection: true,
      filterTabs: [
        {
          text: i18n.t('隔日配'),
          value: 'next-day',
          params: {
            order_type: 'next-day',
          },
        },
        {
          text: i18n.t('預購'),
          value: 'pre-order',
          params: {
            order_type: 'pre-order',
          },
        },
      ],
      customTableActions: [
        {
          icon: "icon-md-view-list",
          emit: "shop_product",
          tooltip: i18n.t("管理商品"),
        },
      ],
    },
    read: {
      leftFields: ['name', 'order_type', 'is_active', 'custom_duration', 'url'],
      rightFields: [],
      updatable: false,
      deletable: false,
      customBtns: [
        {
          label: i18n.t('編輯活動'),
          event: 'update-template',
          icon: {
            left: true,
            name: 'icon-md-edit',
          },
        },
        {
          label: i18n.t('刪除活動'),
          event: 'delete-template',
          icon: {
            left: true,
            name: 'icon-md-delete',
          },
        },
      ],
    },
  },
};

export default model;
