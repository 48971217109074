export default {
  modelName: "area_section",
  label: "子區域",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序",
      maxlength: 10
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
  },
}
