import store from '@/store'
export default {
  hasScope(scopes = []) {
    let hasScope = false
    const myScopes = store.state.auth.scopes
    myScopes.forEach(myScope => {
      if (scopes.includes(myScope)) {
        hasScope = true
      }
    });
    return hasScope
  },
  getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}