<template>
  <WsMain>
    <!-- <img
      v-if="url"
      v-lazy="url"
      alt=""
      @click="$_onUploadClick()"
    > -->
    <WsText
      v-if="file"
      class="mb-20"
    >{{file.name}}</WsText>
    <WsBtn @click="$_onUploadClick()">上傳檔案</WsBtn>
    <input
      v-show="false"
      @change="$_onChange($event)"
      type="file"
      ref="fileInput"
      accept="*/*"
    >
  </WsMain>
</template>

<script>
import Service_Api_V1_PocketFile from "@/__vue2stone_cms/service/api/v1/pocket_file";
export default {
  data: () => ({
    file: null,
  }),

  methods: {
    async $_onChange($event) {
      this.file = $event.target.files[0];
    },
    $_onUploadClick() {
      this.$refs.fileInput.click();
    },
    submit() {
      return new Promise((resolve, reject) => {
        const fileName = this.file.name;
        Service_Api_V1_PocketFile
          .upload({
            file: this.file,
            fileName: fileName,
            signed: this.signed,
            getUploadUrl: this.getUploadUrl,
          })
          .then((res) => {
            if (res.signed) {
              this.$emit("submit", res);
            } else {
              this.$emit("submit", res);
            }
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },

  props: {
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: "/pocket_file/upload_url",
    },
  },
};
</script>

<style>
</style>