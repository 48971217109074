<template>
  <div class="ws-info-has-many">
    <template v-if="relationPopup">
      <div
        v-for="(hasManyItem,hasManyItemKey) in value"
        :key="hasManyItemKey"
        class="ws-info-has-many__item"
      >
        <WsPopupModel
          :value="hasManyItem"
          :fields="_fields"
          :textKey="textKey"
          :getText="getText"
          :modelName="modelName"
          :id="hasManyItem.id"
        ></WsPopupModel>
      </div>
    </template>
    <template v-else>
      <WsText
        v-for="
          (hasManyItem,hasManyItemKey)
          in
          value"
        :key="hasManyItemKey"
        class="ws-info-has-many__item"
        size="14"
      >
        {{$_getValue(hasManyItem,textKey)}}
      </WsText>
    </template>
  </div>
</template>

<script>
export default {
  methods: {
    $_getValue(value, textKey) {
      if (!this.value) {
        return null;
      }
      if (this.getText) {
        return this.getText(value);
      } else {
        return value[textKey];
      }
    },
  },
  computed: {
    _fields() {
      if (this.fields) {
        return this.fields;
      } else if (this.$store.state.model[this.modelName]) {
        return this.$store.state.model[this.modelName].fields;
      } else {
        return null;
      }
    },
  },
  props: {
    getText: {
      type: Function,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    relationPopup: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: null,
    },
    value: {
      type: Array,
      default: null,
    },
    textKey: {
      type: String,
      default: "name",
    },
  },
};
</script>

<style>
</style>