<template>
  <div class="ws-info-list">
    <WsCrudTable
      v-if="value&&value.length"
      :headers="_tableHeaders"
      :displayFields="_displayFields"
      :fields="fields"
      :items="value"
      :paginate="false"
      :inRowBtnRead="false"
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :showExpand="false"
      :expandable="false"
    ></WsCrudTable>
  </div>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
export default {
  computed: {
    _displayFields() {
      return S_APP_General.getDisplayFields(this.fields);
    },
    _tableHeaders() {
      let _tableHeaders = [];
      this.showFields.forEach((showFieldKey) => {
        if (showFieldKey in this._displayFields) {
          const field = this._displayFields[showFieldKey];
          if (field.type == "list" || field.type == "evaluationStage") {
            return;
          }

          if (
            field.type == "image" ||
            field.type == "tags" ||
            field.type == "password" ||
            field.type == "link" ||
            field.type == "editor"
          ) {
            _tableHeaders.push({
              value: showFieldKey,
              text: field.label,
              width: field.width,
              sortable: false,
            });
            return;
          }

          _tableHeaders.push({
            value: showFieldKey,
            text: field.label,
            width: field.width,
          });
        }
      });
      return _tableHeaders;
    },
  },
  props: {
    value: {
      type: Array,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
  },
};
</script>

<style>
</style>