import Spare_Menu from '@/__vue2stone_cms/spare/menu'
// import S_App_Auth from '@/__vue2stone_cms/service/app/auth'
import S_APP_General from "@/__vue2stone_cms/service/app/general";
import H_Menu from '@/__vue2stone_cms/helpers/menu'
import config from '@/__config'
import store from '@/store'
export default {
  getScopeKeys() {
    const _scopeKeys = [];
    if (config.auth.scope.list && config.auth.scope.list.length) {
      config.auth.scope.list.forEach((scope) => {
        _scopeKeys.push(scope.value);
      });
    }
    return _scopeKeys;
  },
  getMainScope() {
    if (
      !store.state.auth.currentUser ||
      !store.state.auth.scopes
    ) {
      return null;
    }
    const _mainScope = S_APP_General.getPrimaryMatch(
      store.state.auth.scopes,
      this.getScopeKeys()
    );
    return _mainScope;
  },
  getMenu() {
    const mainScope = this.getMainScope()
    if (config.menu.custom && config.menu.custom[mainScope]) {
      return config.menu.custom[mainScope]
    }
    let _menu = []
    if (config.menu.extend) {
      for (const key in config.menu.extend) {
        const item = config.menu.extend[key]
        // _item =
          // if (S_App_Auth.hasScope(item.scopes)
          // ) {
          _menu.push(item)
        // }
      }
    }
    for (const key in Spare_Menu.wsmodules) {
      const item = Spare_Menu.wsmodules[key]
      // if (S_App_Auth.hasScope(item.scopes)
      // ) {
      _menu.push(item)
      // }
    }
    _menu = H_Menu.getScopesFilteredMenu(_menu)
    return _menu
  }
}