<template>
  <div
    class="layout-app"
    :class="[{'has-drawer':$store.state.app.hasDrawer},{'has-header':$store.state.app.hasHeader},{'bottom-nav':$store.state.app.hasBottomNav}]"
  >
    <WsNavigationDrawer
      v-if="$store.state.app.hasDrawer"
      :menu="_menu"
    ></WsNavigationDrawer>
    <WsAppHeader v-if="$store.state.app.hasHeader"></WsAppHeader>
    <WsAppWasajon></WsAppWasajon>
    <WsSnack></WsSnack>
    <div class="layout-app__container">
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import S_APP_Cms from "@/__vue2stone_cms/service/app/cms";
export default {
  computed: {
    _mainScope() {
      return S_APP_Cms.getMainScope();
    },
    _scopeKeys() {
      return S_APP_Cms.getScopeKeys();
    },
    _menu() {
      return S_APP_Cms.getMenu(this._mainScope);
    },
  },
};
</script>