<template>
  <div class="ws-state-table-list">
    <template v-if="_headers.length">
      <table
        cellspacing="0"
        cellpadding="0"
      >
        <tr>
          <th
            v-for="(headerItem,headerIndex) in _headers "
            :key="headerIndex"
          >
            <WsText size="14">
              {{headerItem}}
            </WsText>
          </th>
        </tr>
        <tr
          v-for="(rowItems,rowIndex) in _rowData"
          :key="rowIndex"
        >
          <td
            v-for="(settingItem,settingIndex) in rowItems.specs_setting_items"
            :key="settingIndex"
          >
            <WsText size="14">
              {{settingItem.name}}
            </WsText>
          </td>
          <td
            v-for="(fieldItem,fieldKey) in fields"
            :key="fieldKey"
          >
            <WsState
              :type="fields[fieldKey].type"
              :value="value&&value[rowIndex]&&value[rowIndex][fieldKey]?value[rowIndex][fieldKey]:null"
              @input="$_onInput($event,rowIndex,fieldKey)"
            ></WsState>
          </td>
        </tr>
      </table>
    </template>
  </div>
</template>
<script>
export default {
  name: "WsStateTableList",
  data() {
    return {
      rowData: [],
      isMount: true,
    };
  },
  methods: {
    $_onInput($event, index, fieldKey) {
      let _value = [];
      if (this.value) {
        _value = [...this.value];
      }
      _value[index] = { ..._value[index], [fieldKey]: $event };
      this.$emit("input", _value);
    },
    $_getShowCount(datas, index) {
      // computed datas[index][i] will show how many times in array
      if (datas[index + 1]) {
        return datas[index].length * this.$_getShowCount(datas, index + 1);
      } else {
        return datas[index].length;
      }
    },
    $_sortSettingValueIndex(datas, index, arr, startIndex) {
      // index : current datas's index;
      // arr : be transform array;
      // startIndex : this datas[index] of start;
      if (!datas.length || !datas[index] || !datas[index].length) return [];
      let _newArr = [...arr];
      let i = 0;
      for (i = 0; i < datas[index].length; i++) {
        if (index === 0) {
          // start item of datas
          let _showCount = 0;
          if (datas[index + 1]) {
            _showCount = this.$_getShowCount(datas, index + 1);
          } else {
            _showCount = 1;
          }
          for (let j = 0; j < _showCount; j++) {
            _newArr.push([i]);
          }
          if (datas[index + 1]) {
            _newArr = this.$_sortSettingValueIndex(
              datas,
              index + 1,
              _newArr,
              (i + 1) * _showCount - _showCount
            );
          }
        } else if (!datas[index + 1]) {
          // last item of datas
          const _showCount = 1;
          let _startIndex = (i + 1) * _showCount - _showCount + startIndex;
          _newArr[_startIndex] = [..._newArr[_startIndex], i];
        } else {
          // middle item of datas
          const _showCount = this.$_getShowCount(datas, index + 1);
          let _startIndex = (i + 1) * _showCount - _showCount + startIndex;
          for (let j = _startIndex; j < _startIndex + _showCount; j++) {
            _newArr[j] = [..._newArr[j], i];
          }
          _newArr = this.$_sortSettingValueIndex(
            datas,
            index + 1,
            _newArr,
            startIndex + i * _showCount
          );
        }
      }
      return _newArr;
    },
    $_formatValue() {
      let _value = [];
      if (!this.value || !this.value.length) {
        this._rowData.forEach((rowItem) => {
          _value.push({ specs_setting_items: rowItem.specs_setting_items });
        });
        this.$emit("input", _value);
        return;
      }
      if (this.isMount && this.value && this.value.length) {
        this._rowData.forEach((rowItem, rowIndex) => {
          _value.push({
            ...this.value[rowIndex],
            specs_setting_items: rowItem.specs_setting_items,
          });
        });
        this.$emit("input", _value);
        this.isMount = false;
        return;
      }
      this._rowData.forEach((rowItem) => {
        const hasValue = this.value.find((valueItem) => {
          if (valueItem.specs_setting_items && rowItem.specs_setting_items) {
            const valueSettingKeys = valueItem.specs_setting_items
              .map((item) => item._key)
              .toString();
            const rowSettingKeys = rowItem.specs_setting_items
              .map((item) => item._key)
              .toString();
            if (valueSettingKeys === rowSettingKeys) {
              return valueItem;
            }
          } else {
            return false;
          }
        });
        if (hasValue) {
          _value.push(hasValue);
        } else {
          _value.push({ specs_setting_items: rowItem.specs_setting_items });
        }
      });
      this.$emit("input", _value);
    },
  },
  watch: {
    _rowData: {
      handler() {
        this.$_formatValue();
      },
    },
  },
  computed: {
    _settingValue() {
      let arr = [];
      if (!this.specSettings || !this.specSettings.length) return [];
      arr = this.specSettings.map((item) => {
        return item.shop_product_spec_setting_items;
      });
      return arr;
    },
    _headers() {
      let _headersArr = [];
      if (this.specSettings) {
        let _arr = [];
        _arr = this.specSettings.map((item) => {
          return item.name || "-";
        });
        _headersArr = _arr;
      }
      if (this.fields) {
        for (let fieldKey in this.fields) {
          if (this.fields[fieldKey].label) {
            _headersArr.push(this.fields[fieldKey].label);
          } else {
            _headersArr.push("-");
          }
        }
      }
      return _headersArr;
    },
    _rowData() {
      if (this._settingValue && this._settingValue.length) {
        const _sortSettingValueIndex = this.$_sortSettingValueIndex(
          this._settingValue,
          0,
          [],
          0
        );
        return _sortSettingValueIndex.map((sortItem) => {
          if (!sortItem.length) return sortItem;
          let arr = [];
          sortItem.forEach((settingItem, settingIndex) => {
            const _text =
              this._settingValue[settingIndex][settingItem].name || "-";
            arr.push({
              _key: this._settingValue[settingIndex][settingItem]._key,
              name: _text,
            });
          });
          return {
            _key: (Math.random() + 1).toString(36).substring(6),
            specs_setting_items: arr,
          };
        });
      }
      return [];
    },
  },
  props: {
    value: {
      type: Array,
    },
    fields: {
      type: Object,
    },
    specSettings: {
      type: Array,
    },
  },
};
</script>