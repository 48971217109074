<template>
  <div class="ws-state-content-block">
    <div
      v-if="type=='stateCard'"
      class="state-card"
    >
      <WsCard
        v-for="(floor,floorKey) in floors"
        :key="floorKey"
        class="ws-content"
      >
        <WsText
          v-if="floor.title&&floor.titleInLocale"
          size="20"
          class="mb-10"
          bold
        >{{$t(floor.title)}}</WsText>
        <WsText
          v-else-if="floor.title"
          size="20"
          class="mb-10"
          bold
        >{{floor.title}}</WsText>
        <WsContentRemark
          v-if="floor.remark"
          class="mb-10"
        >{{floor.remark}}</WsContentRemark>
        <WsStateForm
          :value="value"
          @input="$onStateFormInput($event)"
          :fields="$_getFormFields(modedFields,floor)"
          :errorMessages="errorMessages"
          :isColumn="true"
          @error-popup-active="$emit('error-popup-active',$event)"
        ></WsStateForm>
      </WsCard>
    </div>
    <div v-else-if="type=='infoCard'">
      <WsCard
        v-for="(floor,floorKey) in floors"
        :key="floorKey"
        class="ws-content"
      >
        <WsText
          v-if="floor.title"
          size="20"
          bold
        >{{floor.title}}</WsText>
        <WsContentRemark v-if="floor.remark">{{floor.remark}}</WsContentRemark>
        <WsInfo
          v-for="(fieldKey,fieldIndex) in floor.fields"
          :key="fieldIndex"
          :label="$_getLabelByHideLabelFields(fieldKey,modedFields,floor.hideLabelFields)"
          :type="$_getFieldAttr(fieldKey,'type')"
          :signed="$_getFieldAttr(fieldKey,'signed')"
          :fields="$_getFieldAttr(fieldKey,'fields')"
          :showFields="$_getFieldAttr(fieldKey,'showFields')"
          :textKey="$_getFieldAttr(fieldKey,'textKey')"
          :getText="$_getFieldAttr(fieldKey,'getText')"
          :modelName="$_getFieldAttr(fieldKey,'modelName')"
          :placeholder="$_getFieldAttr(fieldKey,'placeholder')"
          :parent="$_getFieldAttr(fieldKey,'parentKey')"
          :requiredField="$_getFieldAttr(fieldKey,'requiredField')"
          :uploadUrl="$_getFieldAttr(fieldKey,'uploadUrl')"
          :getUploadUrl="$_getFieldAttr(fieldKey,'getUploadUrl')"
          :items="$_getFieldAttr(fieldKey,'items')"
          :displayLabel="$_getFieldAttr(fieldKey,'displayLabel')"
          :rules="$_getFieldAttr(fieldKey,'rules')"
          :relationPopup="$_getFieldAttr(fieldKey,'relationPopup')"
          :activeText="$_getFieldAttr(fieldKey,'activeText')"
          :inactiveText="$_getFieldAttr(fieldKey,'inactiveText')"
          :conditions="$_getConditions(fieldKey)"
          :parentState="$_getParentState(fieldKey,modedFields)"
          :requiredFieldState="$_getParentState(fieldKey,modedFields)"
          :originValue="$_getOriginValue(modelData,fieldKey)"
          v-model="modelData[fieldKey]"
        ></WsInfo>
      </WsCard>
    </div>
  </div>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
export default {
  computed: {},

  props: {
    errorMessages: {
      type: [Object, String],
    },
    value: {
      type: Object,
    },
    modelData: {
      type: Object,
      default: null,
    },
    updateData: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: null,
    },
    remark: {
      type: String,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
    fieldsRead: {
      type: Array,
      default: null,
    },
    modedFields: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      default: null,
    },
    fields: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: "stateCard",
    },
    floors: {
      type: Array,
      default() {
        return [];
      },
    },
    parentModelName: {
      type: String,
      default: null,
    },
    modelName: String,
    modeParent: {
      type: Boolean,
      default: false,
    },
    customButtons: {
      type: Array,
      default: null,
    },
  },
  methods: {
    $_getLabelByHideLabelFields(fieldKey, fields, hideLabelFields) {
      return S_APP_General.getLabelByHideLabelFields(
        fieldKey,
        fields,
        hideLabelFields
      );
    },
    $_getFormValue(value, showFields) {
      const _value = {};
      showFields.forEach((showField) => {
        if (value[showField] != undefined) {
          _value[showField] = value[showField];
        }
      });
      return _value;
    },
    $onStateFormInput($event) {
      setTimeout(() => {
        const _value = {
          ...this.value,
          ...$event,
        };
        this.$emit("input", _value);
      }, 0);
    },
    $_getFormFields(fields, floor) {
      const _fields = {};
      const showFields = floor.getFields
        ? floor.getFields(this.value)
        : floor.fields;
      showFields.forEach((showField) => {
        if (fields[showField] != undefined) {
          _fields[showField] = fields[showField];
        }
      });
      return _fields;
    },
    $_getOriginValue(modelData, fieldKey) {
      if (!modelData) {
        return null;
      } else {
        return modelData[fieldKey];
      }
    },
    $_getConditions(fieldKey) {
      const _conditions = [];
      const conditionKeys = this.$_getFieldAttr(fieldKey, "conditionKeys");
      if (!conditionKeys) {
        return [];
      }
      conditionKeys.forEach((conditionKey) => {
        _conditions.push({
          key: conditionKey.conditionName,
          value: this.updateData[conditionKey.fieldName],
          type: conditionKey.type,
        });
      });
      return _conditions;
    },
    $_getFieldAttr(fieldKey, keyName) {
      const field = S_APP_General.getFieldByFieldKey(
        fieldKey,
        this.modedFields
      );
      return field[keyName];
    },
    $_getParentState(fieldKey, modedFields) {
      const field = S_APP_General.getFieldByFieldKey(fieldKey, modedFields);
      if (field.parentKey) {
        return this.updateData[field.parentKey];
      } else if (field.requiredField) {
        return this.updateData[field.requiredField];
      } else {
        return null;
      }
    },
  },
};
</script>

<style>
</style>