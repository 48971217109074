export default {
  // admin: true,
  tulpa: {
    defaultContent: true,
    tulpa_page_template: {
      create: {
        requiredScopes: ['boss'],
      },
      update: {
        requiredScopes: ['boss'],
      },
    },
  },
  setting: {
    privacy_terms: true,
  },
  system: true
};
