export default {
  modelName: "tulpa_section",
  label: "Page Section",
  fields: {
    id: {
      type: "text",
      label: "ID",
      readonly: true
    },
    name: {
      type: 'text',
      label: 'name',
      rules: 'required',
      labelInLocale: true,
    },
    component_name: {
      type: "text",
      label: "name (coding)",
      rules: 'required',
    },
    fields: {
      type: 'fields',
      label: 'fields',
      labelInLocale: true,
    },
    content: {
      type: "payload",
      label: "content",
    },
    tags: {
      type: 'tags',
      label: 'Tags'
    },
    remark: {
      type: 'textarea',
      label: 'remark',
      labelInLocale: true,
    },
  },
}