import config from '@/__config';
const model = {
  modelName: 'service_store_noti',
  label: '服務中心公告',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    content: {
      type: 'textarea',
      labelInLocale: true,
      label: '公告內容',
    },
    start: {
      type: 'datetime',
      label: '起始時間',
      labelInLocale: true,
      rules: 'required',
    },
    end: {
      type: 'datetime',
      label: '結束時間',
      labelInLocale: true,
      rules: 'required',
    },
    service_stores: {
      type: 'belongsToMany',
      label: '服務中心',
      textKey: 'name',
      labelInLocale: true,
      modelName: 'service_store',
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    updated_admin: {
      type: 'belongsTo',
      label: '最後更新人',
      readonly: true,
      textKey: 'name',
      modelName: 'admin',
    },
  },
};

if (config.auth.admin_group) {
  if (config.auth.admin_blur) {
    model.fields.cmser_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'cmser_group',
      labelInLocale: true,
    };
  } else {
    model.fields.admin_groups = {
      type: 'belongsToMany',
      label: '管理員分群',
      modelName: 'admin_group',
      labelInLocale: true,
    };
  }
}

export default model;
