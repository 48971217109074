import config from '@/__config';
import i18n from '@/i18n';

const model = {
  modelName: 'service_plan',
  label: i18n.t('服務方案'),
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    name: {
      type: 'text',
      label: i18n.t('名稱'),
    },
    code: {
      type: 'text',
      label: i18n.t('方案代碼'),
    },
    remark: {
      type: 'textarea',
      label: i18n.t('說明'),
    },
    created_at: {
      type: 'datetime',
      label: i18n.t('建立時間'),
      labelInLocale: true,
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: i18n.t('最後更新時間'),
      labelInLocale: true,
      readonly: true,
    },
    updated_admin: {
      type: 'belongsTo',
      label: i18n.t('最後更新人'),
      labelInLocale: true,
      readonly: true,
      textKey: 'name',
      modelName: 'admin',
    },
  },
  crudSetting: {
    index: {
      modelName: 'service_plan',
      showFields: ['name', 'code'],
      liveSearching: true,
      pageMode: true,
      expandable: false,
      showExpand: false,
      labelInLocale: true,
      infiniteScroll: true,
      inRowBtnRead: false,
    },
    update: {
      titleKey: 'name',
      emitInput: true,
      emitSubmit: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'code',
                'remark',
                'monthly_price',
                'period_month',
              ],
            },
          ],
        },
      ],
    },
    create: {
      titleKey: 'name',
      emitInput: true,
      emitSubmit: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'name',
                'code',
                'remark',
                'monthly_price',
                'period_month',
              ],
            },
          ],
        },
      ],
    },
  },
};
if (config.wsmodule.service_plan) {
  if (config.wsmodule.service_plan.monthly_price) {
    model.fields.monthly_price = {
      type: 'number',
      label: i18n.t('每月費用'),
    };
    model.fields.period_month = {
      type: 'number',
      label: `${i18n.t('合約時間')}+ (${i18n.t('月')})`,
    };
    model.crudSetting.update.primary[0].floors[0].fields.push(
      ...['monthly_price', 'period_month'],
    );
    model.crudSetting.create.primary[0].floors[0].fields.push(
      ...['monthly_price', 'period_month'],
    );
  }
  if (config.wsmodule.service_plan.change_times_limit) {
    model.fields.change_times_limit = {
      type: 'number',
      label: i18n.t('裝置可異動次數'),
      rules: 'min_value:1',
    };
    model.fields.user_devices_count_limit = {
      type: 'number',
      label: i18n.t('裝置可綁定數量'),
      rules: 'min_value:1',
    };
    model.crudSetting.update.primary[0].floors[0].fields.push(
      ...['change_times_limit', 'user_devices_count_limit'],
    );
    model.crudSetting.create.primary[0].floors[0].fields.push(
      ...['change_times_limit', 'user_devices_count_limit'],
    );
  }
}

export default model;
