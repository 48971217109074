<template>
  <div
    class="ws-app-header"
    :class="[{'has-drawer':hasDrawer}]"
  >
    <div
      @click="$_onMenuBtnClick()"
      class="menu-btn"
    >
      <WsIcon
        size="24"
        name="icon-md-menu"
      />
    </div>
    <WsSpacer></WsSpacer>
    <WsLink to="/me">
      <WsFlex class="pa-8 cursor-pointer">
        <WsAvatar
          class="mr-4"
          size="32"
        ></WsAvatar>
        <WsText class="ml-8">{{$store.state.auth.currentUser.name}}</WsText>
      </WsFlex>
    </WsLink>
    <!-- <WsAppHeaderButton v-if="$config.locale.active">Language</WsAppHeaderButton> -->
  </div>
</template>

<script>
export default {
  data: () => ({
    hasDrawer: true,
  }),

  methods: {
    $_onMenuBtnClick() {
      this.$store.dispatch("app/menuToggle");
    },
  },

  computed: {
    _avatar() {
      if (!this._user) {
        return null;
      } else {
        return this._user.avatar;
      }
    },
    _user() {
      return this.$store.state.auth.currentUser;
    },
  },

  props: {
    userLink: {
      type: String,
      default: "/user",
    },
  },
};
</script>