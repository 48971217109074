<template>
  <div>
    <WsCreate
      :modelName="modelName"
      :urlModelName="urlModelName"
      :label="label"
      :extendPostData="_extendPostData"
      :fields="fields"
      :value="_value"
      :postUrl="postUrl"
      v-bind="crudSetting[bindingKey]"
      @input="$_onInput($event)"
      @error="$_onError($event)"
      @submit="$_onSubmit($event)"
    >
    </WsCreate>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: `新增${this.label}`,
    };
  },
  data() {
    return {
      state: null,
    };
  },
  methods: {
    $_onSubmit($event) {
      if (this.crudSetting[this.bindingKey].emitSubmit) {
        this.$emit("submit", $event);
      }
    },
    $_onInput($event) {
      this.state = $event;
      if (this.crudSetting[this.bindingKey].emitInput) {
        this.$emit("input", $event);
      }
    },
    $_onError($event) {
      if (
        $event.response &&
        $event.response.data &&
        $event.response.data.message
      ) {
        this.$store.dispatch("app/addSnack", `${this.$t("儲存錯誤！")}!`);
      }
    },
  },
  computed: {
    _extendPostData() {
      if (this.extendPostData) {
        return this.extendPostData;
      } else {
        return this.crudSetting[this.bindingKey].extendPostData;
      }
    },
    _value() {
      if (this.value) {
        return this.value;
      } else {
        return this.state;
      }
    },
  },
  props: {
    postUrl: {
      type: String,
    },
    bindingKey: {
      type: String,
      default: "create",
    },
    modelName: {
      type: String,
    },
    urlModelName: {
      type: String,
    },
    label: {
      type: String,
    },
    fields: {
      type: Object,
    },
    crudSetting: {
      type: Object,
    },
    value: {
      type: Object,
    },
    extendPostData: {
      type: Object,
    },
  },
};
</script>