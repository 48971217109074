export default {
  modelName: "app_developer",
  label: "開發人員",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    is_active: {
      type: 'switch',
      label: '啟用'
    },
    mobile: {
      type: 'text',
      label: '手機'
    },
    mobile_country_code: {
      type: 'text',
      label: '手機國碼'
    },
    otp: {
      type: 'text',
      label: '永久性 OTP'
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      readonly: true
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      pageMode: true,
      showFields: [
        'id',
        'is_active',
        'mobile',
        'mobile_country_code',
        'otp'
      ],
      infiniteScroll: true,
      inRowBtnRead: true,
      creatableScopes: ['app_developer-read'],
      updatableScopes: ['app_developer-read'],
      deletableScopes: ['app_developer-read'],
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'is_active',
                'mobile',
                'mobile_country_code',
                'otp'
              ],
            },
          ],
        },
      ],
    },
    update: {
      submitToIndex: true,
      titleKey: 'mobile',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'is_active',
                'mobile',
                'mobile_country_code',
                'otp'
              ],
            },
          ],
        },
      ],
    },
    read: {
      leftFields: [
        'mobile',
        'mobile_country_code',
        'otp'
      ],
      rightFields: [
        'id',
        'is_active',
        'updated_at',
        'created_at'
      ],
      titleKey: 'mobile',
    },
  }
}
