<template>
  <div class="ws-page-404">
    <img
      class="ws-page-404__image-pc"
      :src="_imagePc"
      alt=""
    >
    <img
      class="ws-page-404__image-mobile"
      :src="_imageMobile"
      alt=""
    >
    <WsText
      size="30"
      :color="$color.primary4d"
    >{{title}}</WsText>
    <WsText
      class="mt-16"
      size="20"
      :color="$color.primary4d"
    >{{des}}</WsText>
    <WsBtn
      :to="btnTo"
      class="mt-32"
    >{{btnText}}</WsBtn>
  </div>
</template>

<script>
export default {
  computed: {
    _imagePc() {
      if (this.imagePc) {
        return this.imagePc;
      } else {
        return require("@/__vue2stone_cms/assets/images/404-1440.png");
      }
    },
    _imageMobile() {
      if (this.imageMobile) {
        return this.imageMobile;
      } else {
        return require("@/__vue2stone_cms/assets/images/404-320.png");
      }
    },
  },
  props: {
    title: {
      type: String,
      default: "糟糕，迷路了！",
    },
    des: {
      type: String,
      default: "您已來到未知的座標，這裡很危險！",
    },
    btnTo: {
      type: String,
      default: "/",
    },
    btnText: {
      type: String,
      default: "返回首頁",
    },
    imagePc: {
      type: String,
    },
    imageMobile: {
      type: String,
    },
  },
};
</script>

<style>
</style>