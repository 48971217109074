<template>
  <div class="ws-info-wasa-intro">
    <WsCard
      color="#333"
      class="mt-20"
      v-for="(introItem, introIndex) in value"
      :key="introIndex"
    >
      <WsInfo
        label="區塊名稱"
        v-model="introItem.name"
      ></WsInfo>
      <WsText
        class="mt-20"
        size=6
      >{{$t('section_content')}}</WsText>
      <WsFlex class="mt-10">
        <WsFull
          v-for="(col, colIndex) in introItem.cols"
          :key="colIndex"
        >
          <WsCard v-if="col.type=='image'">
            <WsInfo
              type="image"
              label="圖片"
              :value="col.state.image"
            ></WsInfo>
          </WsCard>
          <WsCard v-else-if="col.type=='intro'">
            <WsInfo
              type="text"
              label="標題"
              :value="col.state.h2"
            ></WsInfo>
            <WsInfo
              type="text"
              label="副標題"
              :value="col.state.h3"
            ></WsInfo>
            <WsInfo
              type="textarea"
              label="說明"
              :value="col.state.text"
            ></WsInfo>
            <WsInfo
              type="link"
              label="外部連結"
              :value="col.state.link"
            ></WsInfo>
          </WsCard>
        </WsFull>
      </WsFlex>
    </WsCard>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: null,
    },
  },
};
</script>