<template>
  <div class="ws-state-config">
    <WsBtn
      v-if="exportable"
      @click="$_onExportClick()"
    >匯出JSON</WsBtn>
    <WsState
      v-if="checkable"
      type="checkbox"
      @input="$_onCheckBoxInput($event)"
      :value="_value"
      :text="label"
    ></WsState>
    <WsStateForm
      class="ml-20"
      v-if="_value"
      :fields="fields"
      :value="value"
      @input="$emit('input',$event)"
    >
    </WsStateForm>
  </div>
</template>

<script>
export default {
  methods: {
    $_onExportClick() {
      this.$copyText(JSON.stringify(this.value));
      alert("複製到剪貼簿囉:)");
    },
    $_onCheckBoxInput($event) {
      if ($event) {
        const _value = this.value ? { ...this.value } : {};
        this.$emit("input", _value);
      } else {
        this.$emit("input", false);
      }
    },
  },
  computed: {
    _value() {
      if (this.value) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    if (!this.checkable && !this.value) {
      this.$emit("input", {});
    }
  },
  props: {
    fields: {
      type: Object,
    },
    value: {
      type: Object,
    },
    checkable: {
      type: Boolean,
      default: true,
    },
    exportable: {
      type: Boolean,
    },
    label: {
      type: String,
    },
  },
};
</script>

<style>
</style>