<template>
  <div>
    <!-- <WsRow>
      <WsCol class="col-1">
      </WsCol>
      <WsCol
        v-for="n in 7"
        :key="`hour-${n}`"
        class="col-1"
      >
        <WsText size="14">
          {{$moment().locale($i18n.locale).startOf('week').add(n,'day').format('ddd')}}
        </WsText>
      </WsCol>
    </WsRow>
    <WsRow class="mt-10">
      <WsCol class="col-1">
        <WsText size="14">開始</WsText>
      </WsCol>
      <WsCol
        class="col-1"
        v-for="n in 7"
        :key="`start-${n}`"
      >
        <WsInfo
          type="text"
          maxlength="4"
          placeholder="0900"
          :value="$_getValue(n*2-2)"
        ></WsInfo>
      </WsCol>
    </WsRow>
    <WsRow>
      <WsCol class="col-1">
        <WsText size="14">結束</WsText>
      </WsCol>
      <WsCol
        class="col-1"
        v-for="n in 7"
        :key="`end-${n}`"
      >
        <WsInfo
          type="text"
          maxlength="4"
          placeholder="1800"
          :value="$_getValue(n*2-1)"
        ></WsInfo>
      </WsCol>
    </WsRow> -->
    <WsRow>
      <WsCol class="col-2">
      </WsCol>
      <WsCol class="col-5">
        <WsFlex alignItems="flex-end">
          <WsText>開始</WsText>
          <WsDes
            class="pb-2 pl-5"
            size="12"
          > 格式(0000)</WsDes>
        </WsFlex>
      </WsCol>
      <WsCol class="col-5">
        <WsFlex alignItems="flex-end">
          <WsText>結束</WsText>
          <WsDes
            class="pb-2 pl-5"
            size="12"
          > 格式(0000)</WsDes>
        </WsFlex>
      </WsCol>
    </WsRow>
    <WsRow
      v-for="n in 7"
      :key="n"
    >
      <WsCol class="col-2">
        {{$moment().locale($i18n.locale).startOf('week').add(n,'day').format('ddd')}}
      </WsCol>
      <WsCol class="col-5">
        <WsInfo
          type="text"
          maxlength="4"
          placeholder="0900"
          :value="$_getValue(n*2-2)"
        ></WsInfo>
      </WsCol>
      <WsCol class="col-5">
        <WsInfo
          type="text"
          maxlength="4"
          placeholder="1800"
          :value="$_getValue(n*2-1)"
        ></WsInfo>
      </WsCol>
    </WsRow>
  </div>
</template>

<script>
export default {
  methods: {
    $_getValue(index) {
      if (!this.value || !this.value[index]) {
        return null;
      } else {
        return this.value[index];
      }
    },
  },
  props: {
    value: {
      type: Array,
    },
  },
};
</script>

<style>
</style>