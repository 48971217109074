<template>
  <button type="button"
    @click.prevent="$_onClick()"
    class="ws-state-switch"
    :class="{active:_value}"
  >
    <div class="ws-state-switch__switch">
      <div class="ws-state-switch__switch-button"></div>
    </div>
    <div
      v-if="_value&&activeText"
      class="ws-state-switch__text"
    >{{activeText}}</div>
    <div
      v-else-if="!_value&&inactiveText"
      class="ws-state-switch__text"
    >{{inactiveText}}</div>
  </button>
</template>

<script>
export default {
  methods: {
    $_onClick() {
      let _value = 1;
      if (this.value && this.value != "0") {
        _value = 0;
      }
      this.$emit("input", _value);
    },
  },
  computed: {
    _value() {
      if (this.value && this.value != "0") {
        return true;
      } else {
        return false;
      }
    },
  },
  props: {
    value: {
      type: [Boolean, Number, String],
    },
    activeText: String,
    inactiveText: String,
  },
};
</script>