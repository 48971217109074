<template>
  <WsMain v-if="modelData">
    <WsBreadcrumbs
      v-if="hasBreadcrumbs"
      :menu="_breadcrumbsItems"
    ></WsBreadcrumbs>
    <slot name="beforeContent"></slot>
    <div class="ws-content-section pb-80">
      <ValidationObserver
        ref="form"
        class="ws-content-section-observer"
      >
        <form
          ref="updateForm"
          @submit.prevent
        >
          <div class="ws-content-section pb-80">
            <slot
              name="primaryContent"
              :value="_value"
              :fields="fields"
            >
              <div
                v-if="primary"
                class="ws-content-section__primary"
              >
                <WsStateContentBlock
                  v-for="(item,itemKey) in primary"
                  :key="itemKey"
                  :modelData="modelData"
                  :type="item.type"
                  :label="item.label"
                  :title="item.title"
                  :remark="item.remark"
                  :floors="item.floors"
                  :modedFields="fields"
                  :fields="item.fields"
                  :field="item.field"
                  :modelName="item.modelName"
                  :modeVersions="item.modeVersions"
                  :modeParent="item.modeParent"
                  :customButtons="item.customButtons"
                  :fieldsRead="item.fieldsRead"
                  :errorMessages="errorMessages"
                  :value="_value"
                  @input="$_onStateContentBlockInput($event)"
                  :parentModelName="$_getParentModelName(item)"
                  @error-popup-active="$_onErrorPopupActive($event)"
                ></WsStateContentBlock>
              </div>
            </slot>
            <slot name="secondaryContent">
              <div
                v-if="secondary"
                class="ws-content-section__secondary"
              >
                <WsStateContentBlock
                  v-for="(item,itemKey) in secondary"
                  :key="itemKey"
                  :modelData="modelData"
                  :type="item.type"
                  :label="item.label"
                  :title="item.title"
                  :remark="item.remark"
                  :floors="item.floors"
                  :modedFields="fields"
                  :fields="item.fields"
                  :field="item.field"
                  :modelName="item.modelName"
                  :modeVersions="item.modeVersions"
                  :modeParent="item.modeParent"
                  :customButtons="item.customButtons"
                  :fieldsRead="item.fieldsRead"
                  :errorMessages="errorMessages"
                  :value="_value"
                  @input="$_onStateContentBlockInput($event)"
                  :parentModelName="$_getParentModelName(item)"
                  @error-popup-active="$_onErrorPopupActive($event)"
                ></WsStateContentBlock>
              </div>
            </slot>
          </div>
        </form>
      </ValidationObserver>
    </div>
    <WsBottomNav>
      <template v-slot:rightActions>
        <WsBtn
          @click="$router.go(-1)"
          outlined
        >{{$t('cancel')}}</WsBtn>
        <WsBtn
          class="ml-8"
          :loading="loading.submit"
          @click="$_onSubmit()"
        >{{$t('submit')}}</WsBtn>
      </template>
    </WsBottomNav>
  </WsMain>
</template>

<script>
import S_APP_General from "@/__vue2stone_cms/service/app/general";
import S_App_State from "@/__vue2stone_cms/service/app/state";
export default {
  data: () => ({
    isMounted: false,
    modelData: null,
    loading: {
      submit: false,
    },
    state: {},
    errorMessages: {},
  }),
  methods: {
    $_onStateContentBlockInput($event) {
      this.$store.dispatch("app/startNeedSave");
      if (this.emitInput) {
        const _value = {
          ...this.value,
          ...$event,
        };
        this.$emit("input", _value);
      } else {
        const _value = {
          ...this.state,
          ...$event,
        };
        this.state = _value;
      }
    },
    loadingStart() {
      this.loading.submit = true;
    },
    loadingStop() {
      this.loading.submit = false;
    },
    async $_onSubmit() {
      const isValidate = await this.$refs.form.validate();
      if (!isValidate) {
        return;
      }
      if (this.emitSubmit) {
        if (this.emitInput) {
          this.$emit("submit", this.value);
        } else {
          this.$emit("submit", this.state);
        }
        return;
      }
      this.loading.submit = true;
      try {
        let _value;
        if (this.emitInput) {
          _value = this.value;
        } else {
          _value = this.state;
        }
        let postData = S_App_State.getFormatedStates(
          this.fields,
          _value,
          this.postEncode
        );
        if (this.extendPostData) {
          postData = {
            ...postData,
            ...this.extendPostData,
          };
        }
        const res = await this.$axios.patch(
          `/${this.modelName}/${this.id}`,
          postData
        );
        if (this.fields.last_version) {
          if (this.modelData.last_version) {
            try {
              await this.$axios.patch(
                `/${this._versionModelName}/${this.modelData.last_version.id}`,
                _value.last_version
              );
              this.loading.submit = false;
              this.$store.dispatch("app/stopNeedSave");
              if (this.$route.query && this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else if (this.submitToIndex) {
                this.$router.push(`/${this._urlModelName}`);
              } else {
                this.$router.push(`/${this._urlModelName}/${this.id}`);
              }
            } catch (error) {
              alert("更新發生錯誤");
              this.loading.submit = false;
            }
          } else {
            try {
              await this.$axios.post(
                `/${this.modelName}/${res.data.data.id}/${this._versionModelName}`,
                _value.last_version
              );
              this.loading.submit = false;
              this.$store.dispatch("app/stopNeedSave");
              if (this.$route.query && this.$route.query.redirect) {
                this.$router.push(this.$route.query.redirect);
              } else if (this.submitToIndex) {
                this.$router.push(`/${this._urlModelName}`);
              } else {
                this.$router.push(`/${this._urlModelName}/${this.id}`);
              }
            } catch (error) {
              alert("更新發生錯誤");
              this.loading.submit = false;
            }
          }
        } else {
          this.$store.dispatch("app/stopNeedSave");
          if (this.$route.query && this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (this.returnModelName) {
            this.$router.push(`/${this.returnModelName}/${this.id}`);
          } else if (this.submitToIndex) {
            this.$router.push(`/${this._urlModelName}`);
          } else {
            this.$router.push(`/${this._urlModelName}/${this.id}`);
          }
        }
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          this.$emit("error");
          this.errorMessages = this.$t(error.response.data.message);
        }
        this.loading.submit = false;
      }
    },
    $_getParentModelName(primaryItem) {
      if (primaryItem.modeVersions) {
        return `${this.modelName}_version`;
      } else {
        return this.modelName;
      }
    },
    async $_fetchData() {
      this.$store.dispatch("app/startPageLoading");
      let getUrl = `/${this.modelName}/${this.id}`;
      try {
        const res = await this.$axios.get(getUrl);
        this.modelData = res.data.data;
        if (this.emitInput) {
          this.$emit("input", {
            ...S_App_State.getValueFromFields(this.fields, this.modelData),
          });
        } else {
          this.state = {
            ...S_App_State.getValueFromFields(this.fields, this.modelData),
          };
        }
        this.$emit("modelDataInit", this.modelData);
        setTimeout(() => {
          this.isMounted = true;
        }, 500);
      } catch (error) {
        alert(error);
      } finally {
        this.$store.dispatch("app/stopPageLoading");
      }
    },
    $_onErrorPopupActive($event) {
      if ($event === false) {
        this.errorMessages = "";
      }
    },
  },
  computed: {
    _value() {
      if (this.emitInput) {
        return this.value;
      } else {
        return this.state;
      }
    },
    _title() {
      if (!this.modelData) {
        return this.label;
      } else {
        return S_APP_General.getValueByFieldKey(this.titleKey, this.modelData);
      }
    },
    _breadcrumbsItems() {
      const _breadcrumbsItems = [];
      _breadcrumbsItems.push({
        text: this.$t(this.label),
        to: `/${this._urlModelName}`,
        disabled: false,
      });
      _breadcrumbsItems.push({
        text: this._title,
        to: `/${this._urlModelName}/${this.id}`,
        disabled: false,
      });
      if (this.titleKey && this.modelData && this.modelData[this.titleKey]) {
        _breadcrumbsItems.push({
          text: `${this.$t("edit")} ${this.modelData[this.titleKey]}`,
          disabled: true,
        });
      } else {
        _breadcrumbsItems.push({
          text: this.$t("edit"),
          disabled: true,
        });
      }
      return _breadcrumbsItems;
    },
    _backRouteText() {
      if (this.backRouteText) {
        return "返回";
      } else {
        return `${this.label}列表`;
      }
    },
    _backRoute() {
      if (this.backRoute) {
        return this.backRoute;
      } else {
        return `/${this.modelName}`;
      }
    },
    _versionModelName() {
      return `${this.modelName}_version`;
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
  },
  props: {
    extendPostData: {
      type: Object,
    },
    hasBreadcrumbs: {
      type: Boolean,
      default: true,
    },
    postEncode: {
      type: Boolean,
      default: true,
    },
    emitInput: {
      type: Boolean,
      default: false,
    },
    emitSubmit: {
      type: Boolean,
      default: false,
    },
    titleKey: {
      type: String,
      default: "text",
    },
    backRouteText: {
      type: String,
      default: "返回",
    },
    backRoute: {
      type: String,
      default: null,
    },
    primary: {
      type: Array,
    },
    secondary: {
      type: Array,
    },
    modelName: String,
    label: String,
    fields: Object,
    id: {
      type: [String, Number],
      default: null,
    },
    value: {
      type: Object,
    },
    returnModelName: {
      type: String,
    },
    urlModelName: String,
    submitToIndex: Boolean,
  },
  mounted() {
    this.$_fetchData();
  },
};
</script>