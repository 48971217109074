import config from '@/__config';
import * as changeCase from 'change-case';
import H_Auth from '@/__vue2stone_cms/helpers/auth'

export default {
  getScopesFilteredMenu(menu) {
    const _menu = []
    menu.forEach(menuItem => {
      if (H_Auth.hasScope(menuItem.scopes)) {
        const _menuItem = { ...menuItem }
        if (menuItem.menu) {
          _menuItem.menu = this.getScopesFilteredMenu(menuItem.menu)
        }
        _menu.push(_menuItem)
      }
    });
    return _menu
  },
  getMenuOption(module) {
    const _option = this[`getMenuOption${changeCase.pascalCase(module)}`]()
    if (_option) {
      return _option
    } else {
      return {}
    }
  },
  getMenuOptionMy() {
    const _menu = []
    const _scopes = []
    if (config.wsmodule.xc_billing_statement) {
      _menu.push({
        title: '我的請款單',
        link: '/my/xc_billing_statement',
        scopes: [
          'xc_billing_statement-my',
        ],
      })
      _scopes.push('xc_billing_statement-my')
    }
    if (!_menu.length) {
      return {}
    } else {
      return {
        my: {
          icon: 'icon-ws-officialweb-about',
          title: '我的好東西',
          menu: _menu,
          scopes: _scopes
        }
      }
    }
  },
  getMenuOptionWsBlog() {
    if (!config.wsmodule.ws_blog) {
      return
    }
    const blogMenu = []
    const blogClassMenu = []
    if (config.wsmodule.ws_blog.types) {
      for (const typeKey in config.wsmodule.ws_blog.types) {
        const type = config.wsmodule.ws_blog.types[typeKey]
        blogMenu.push({
          title: type.title,
          link: `/${typeKey}`,
        })
        blogClassMenu.push({
          title: type.title + "分類",
          link: `/${typeKey}_class`,
        })
      }
    } else {
      blogMenu.push({
        title: '部落格',
        link: `/ws_blog`,
      })
      blogClassMenu.push({
        title: '部落格分類',
        link: `/ws_blog_class`,
      })
    }
    return {
      ws_blog: {
        icon: 'icon-md-library-books',
        title: '文章管理',
        scopes: ['admin'],
        menu: [
          ...blogMenu,
          ...blogClassMenu
        ]
      }
    }
  },
  getMenuOptionShowcase() {
    if (!config.wsmodule.showcase) {
      return
    }
    return {
      showcase: {
        icon: 'icon-md-nature',
        title: '案例展示',
        link: '/showcase',
        scopes: [
          'admin',
        ],
      }
    }
  },
  getMenuOptionInvoice() {
    if (!config.wsmodule.invoice) {
      return
    }
    return {
      invoice: {
        icon: 'icon-md-view-array',
        title: '發票',
        menu: [
          {
            title: '發票開立作業',
            link: '/invoice_job',
            scopes: [
              'admin',
            ],
          }
        ]
      },
    }
  },
  getMenuOptionLocale() {
    if (!config.wsmodule.locale) {
      return
    }
    return {
      locale: {
        icon: 'icon-md-language',
        title: '語言',
        link: '/locale',
        titleInLocale: true,
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionAppointment() {
    if (!config.wsmodule.appointment) {
      return
    }
    return {
      appointment: {
        icon: 'icon-md-timer',
        title: '預約',
        link: '/appointment',
        titleInLocale: true,
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionServiceStore() {
    if (!config.wsmodule.service_store) {
      return
    }
    return {
      service_store: {
        icon: 'icon-md-store',
        title: '服務中心',
        titleInLocale: true,
        menu: [
          {
            title: '休息時間',
            link: '/service_store_close',
            titleInLocale: true,
          },
          {
            title: '服務中心公告',
            link: '/service_store_noti',
            titleInLocale: true,
          },
          {
            title: '服務中心清單',
            link: '/service_store',
            titleInLocale: true,
          },
        ],
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionPinCard() {
    if (!config.wsmodule.pin_card) {
      return
    }
    return {
      pin_card: {
        icon: 'icon-md-fiber-pin',
        title: 'PIN Card',
        link: '/pin_card',
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionServicePlan() {
    if (!config.wsmodule.service_plan) {
      return
    }
    return {
      service_plan: {
        icon: 'icon-ws-officialweb-about',
        title: '服務方案',
        titleInLocale: true,
        menu: [
          {
            icon: 'icon-ws-officialweb-about',
            title: '服務方案',
            link: '/service_plan',
            titleInLocale: true,
          },
          {
            icon: 'icon-ws-officialweb-about',
            title: '會員綁定方案',
            link: '/user_service_plan',
            titleInLocale: true,
          },
        ],
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionSystemClass() {
    if (!config.wsmodule.system_class) {
      return
    }
    return {
      system_class: {
        icon: 'icon-md-widgets',
        title: '領域',
        link: '/system_class',
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionTag() {
    if (!config.wsmodule.tag) {
      return
    }
    return {
      tag: {
        icon: 'icon-md-label',
        title: '標籤',
        link: '/tag',
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionTulpa() {
    if (!config.wsmodule.tulpa) {
      return
    }
    return {
      tulpa: {
        icon: 'icon-md-view-compact',
        title: '網站頁面',
        titleInLocale: true,
        menu: [
          {
            title: '頁面',
            link: '/tulpa_page',
            titleInLocale: true,
          },
          {
            title: '跨網站項目',
            link: '/tulpa_cross_item',
            titleInLocale: true,
          },
          {
            title: 'Page Template',
            link: '/tulpa_page_template',
            titleInLocale: true,
          },
          {
            title: 'page_section',
            link: '/tulpa_section',
            titleInLocale: true,
          },
        ],
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionWebsite() {
    if (!config.wsmodule.website) {
      return
    }
    return {
      website: {
        icon: 'icon-md-settings',
        title: '網站設定',
        scopes: ['admin'],
        menu: [
          {
            title: '頁面設定',
            link: '/page_setting',
          },
        ],
      },
    }
  },
  getMenuOptionNews() {
    if (!config.wsmodule.news) {
      return
    }
    return {
      news: {
        icon: 'icon-md-library-books',
        title: '最新消息',
        link: '/news',
        titleInLocale: true,
        scopes: ['admin'],
        menu: [
          {
            title: '最新消息管理',
            link: '/news',
            titleInLocale: true,
          },
        ],
      },
    }
  },
  getMenuOptionShop() {
    if (!config.wsmodule.shop) {
      return
    }
    return {
      shop_order: {
        icon: 'icon-md-insert-drive-file',
        title: '訂單管理',
        link: '/shop_order',
        titleInLocale: true,
        scopes: ['admin'],
      },
      shop: {
        icon: 'icon-md-shopping-cart',
        title: '商品管理',
        titleInLocale: true,
        menu: [
          {
            title: '商品列表',
            link: '/shop_product',
            titleInLocale: true,
          },
          {
            title: '預購商品列表',
            link: '/shop_product_pre_order',
            titleInLocale: true,
          },
          {
            title: '商品分類管理',
            link: '/shop_class',
            titleInLocale: true,
          },
          {
            title: '精選分類管理',
            link: '/featured_class',
            titleInLocale: true,
          },
        ],
        scopes: ['admin'],
      },
      promotion_management: {
        icon: 'icon-md-library-books',
        title: '促銷管理',
        link: '/shop_free_shipping',
        scopes: ['admin'],
        titleInLocale: true,
        menu: [
          {
            title: '免運金額',
            link: '/shop_free_shipping',
            titleInLocale: true,
          },
          {
            title: '紅利點數回饋',
            link: '/bonus_point',
            titleInLocale: true,
          },
          {
            title: '折扣碼列表',
            link: '/shop_campaign',
            titleInLocale: true,
          },
          {
            title: '商品促銷',
            link: '/shop_product_campaign',
            titleInLocale: true,
          },
          {
            title: '會員邀請',
            link: '/general_user_invite',
            titleInLocale: true,
          },
        ],
      },
      shop_setting: {
        icon: 'icon-md-shopping-basket',
        title: '商城設定',
        link: '/shop_notice',
        titleInLocale: true,
        scopes: ['admin'],
        menu: [
          {
            title: '購物須知',
            link: '/shop_notice',
            titleInLocale: true,
          },
          {
            title: '購物須知類別',
            link: '/shop_notice_class',
            titleInLocale: true,
          },
          // News Banner
          {
            title: '橫幅管理',
            link: '/news_banner',
            titleInLocale: true,
          },
          {
            title: '活動圖框管理',
            link: '/shop_product_cover_frame',
            titleInLocale: true,
          },
          {
            title: '彈跳窗管理',
            link: '/page_cover',
            titleInLocale: true,
          },
          {
            title: '配送時段設定',
            link: '/shop_ship_time_setting',
            titleInLocale: true,
          },
          {
            title: '配送地區設定',
            link: '/shop_ship_area_setting',
            titleInLocale: true,
          },
        ],
      },
    }
  },
  getMenuOptionAcumatica() {
    if (!config.wsmodule.acumatica) {
      return
    }
    const menuAcumatica = [];
    if (config.wsmodule.acumatica.acumatica_app) {
      menuAcumatica.push({
        title: 'Acumatica App',
        link: '/acumatica_app',
      });
    }
    return {
      acumatica: {
        icon: 'icon-ws-filled-earth',
        title: 'Acumatica',
        titleInLocale: true,
        menu: menuAcumatica,
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionArea() {
    if (!config.wsmodule.area) {
      return
    }
    const areaSetting = {
      icon: 'icon-md-location-on',
      title: '地區',
      link: '/area',
      titleInLocale: true,
      scopes: ['admin'],
    };

    if (config.wsmodule.area) {
      if (config.wsmodule.area.label) {
        areaSetting.title = config.wsmodule.area.label;
      }
      if (config.wsmodule.area.urlModelName) {
        areaSetting.link = `/${config.wsmodule.area.urlModelName}`;
      }
    }
    return {
      area: areaSetting,
    }
  },
  getMenuOptionContactRequest() {
    if (!config.wsmodule.contact_request) {
      return
    }
    const menuContactRequest = [
      {
        title: '聯絡請求紀錄',
        link: '/contact_request',
        titleInLocale: true,
      },
      {
        title: '聯絡請求通知Email',
        link: '/contact_request_notify_mail',
        titleInLocale: true,
      },
    ];
    if (config.wsmodule.contact_request) {
      if (config.wsmodule.contact_request.auto_reply) {
        menuContactRequest.push({
          title: '自動回覆',
          link: '/contact_request_auto_reply',
          titleInLocale: true,
        });
      }
    }
    return {
      contact_request: {
        icon: 'icon-md-phone-missed',
        title: '聯絡請求',
        titleInLocale: true,
        scopes: ['admin'],
        menu: menuContactRequest,
      },
    }
  },
  getMenuOptionSetting() {
    if (!config.wsmodule.setting) {
      return
    }
    const menuSetting = [];
    if (config.wsmodule.setting.privacy_terms) {
      menuSetting.push({
        icon: 'icon-md-assignment-turned-in',
        title: '服務條款',
        link: '/privacy',
        titleInLocale: true,
      });
      menuSetting.push({
        icon: 'icon-md-assignment-turned-in',
        title: '使用者政策',
        link: '/terms',
        titleInLocale: true,
      });
    }
    if (config.wsmodule.setting.ez_about_us) {
      menuSetting.push({
        icon: 'icon-md-assignment-turned-in',
        title: '關於我們',
        link: '/about_us',
        titleInLocale: true,
      });
    }
    return {
      setting: {
        icon: 'icon-ws-filled-setting',
        title: '網站設定',
        scopes: ['admin'],
        menu: menuSetting,
      },
    }
  },
  getMenuOptionUser() {
    if (!config.wsmodule.user) {
      return
    }
    const menuUser = [
      {
        title: '會員列表',
        link: '/user',
        titleInLocale: true,
      },
    ];
    if (config.wsmodule.user.log) {
      menuUser.push({
        title: '會員使用紀錄',
        link: '/user_log',
        titleInLocale: true,
      });
    }
    if (config.wsmodule.user.device) {
      menuUser.push({
        title: '會員綁定裝置',
        link: '/user_device',
        titleInLocale: true,
      });
    }
    if (config.wsmodule.user_device_token) {
      menuUser.push({
        title: '會員裝置 Device Token',
        link: '/user_device_token',
      });
    }
    if (config.wsmodule.socialite) {
      if (config.wsmodule.socialite.google) {
        menuUser.push({
          title: 'Google會員綁定',
          link: '/socialite_google_account',
          titleInLocale: true,
        });
      }
      if (config.wsmodule.socialite.facebook) {
        menuUser.push({
          title: 'Facebook會員綁定',
          link: '/socialite_facebook_account',
          titleInLocale: true,
        });
      }
      if (config.wsmodule.socialite.line) {
        menuUser.push({
          title: 'Line會員綁定',
          link: '/socialite_line_account',
          titleInLocale: true,
        });
      }
    }
    if (config.wsmodule.user_location) {
      menuUser.push({
        title: '會員位置',
        link: '/user_location',
      });
    }
    if (config.wsmodule.user_like) {
      menuUser.push({
        title: '會員喜歡',
        link: '/user_like',
      });
    }
    if (config.wsmodule.user_friend) {
      menuUser.push({
        title: '會員交友關係',
        link: '/user_friend',
      });
    }
    return {
      user: {
        icon: 'icon-md-nature-people',
        title: '會員管理',
        menu: menuUser,
        scopes: ['admin'],
      },
    }
  },
  getMenuOptionAdmin() {
    if (!config.wsmodule.admin) {
      return
    }
    const menuAdmin = [
      {
        title: '後台使用者管理',
        link: config.auth.admin_blur ? '/cmser' : '/admin',
        titleInLocale: true,
      },
    ];
    if (config.auth) {
      if (config.auth.admin_group) {
        menuAdmin.push({
          title: '管理員分群',
          link: config.auth.admin_blur ? '/cmser_group' : '/admin_group',
          titleInLocale: true,
        });
      }
      if (config.auth.admin_role) {
        menuAdmin.push({
          title: '後台管理角色管理',
          link: config.auth.admin_blur ? '/cmser_role' : '/admin_role',
          titleInLocale: true,
        });
      }
      if (config.auth.log) {
        menuAdmin.push({
          title: '後台管理者使用紀錄',
          link: '/cms_log',
          titleInLocale: true,
        });
      }
    }
    return {
      admin: {
        icon: 'icon-md-groups',
        title: '管理員管理',
        titleInLocale: true,
        menu: menuAdmin,
        scopes: ['boss'],
      }
    }
  },
  getMenuOptionOtp() {
    if (!config.wsmodule.otp) {
      return
    }
    if (config.wsmodule.otp) {
      return {
        otp: {
          icon: 'icon-md-vpn-key',
          title: 'OTP',
          link: '/otp',
          scopes: [
            'otp-read',
          ],
        }
      }
    }
  },
  getMenuOptionAppDeveloper() {
    if (!config.wsmodule.app_developer) {
      return
    }
    if (config.wsmodule.app_developer) {
      const option = {
        icon: 'icon-md-keyboard',
        title: '開發人員',
        link: '/app_developer',
        scopes: [
          'app_developer-read',
          'app_developer-edit',
        ],
      }
      return {
        app_developer: option
      }
    }
  },
  getMenuOptionSystem() {
    if (!config.wsmodule.system) {
      return
    }
    if (config.wsmodule.system) {
      const option = {
        icon: 'icon-md-computer',
        title: '系統',
        menu: [
          {
            title: '系統資訊',
            link: '/system_info',
            scopes: [
              'system_info-read',
              'system_info-admin',
            ],
          },
          {
            title: '系統資訊編輯',
            link: '/system_info/update',
            scopes: [
              'system_info-update',
              'system_info-admin',
            ],
          },
        ],
        scopes: [
          'system_info-read',
          'system_info-update',
          'system_info-admin',
        ],
      }
      return {
        system_info: option
      }
    }
  },
  getMenuOptionXcBillingStatement() {
    if (!config.wsmodule.xc_billing_statement) {
      return
    }
    return {
      xc_billing_statement: {
        icon: 'icon-md-monetization-on',
        title: '請款單',
        link: '/xc_billing_statement',
        scopes: [
          'xc_billing_statement-review',
        ],
      },
    }
  },
  getMenuOptionXcTask() {
    if (!config.wsmodule.xc_task) {
      return
    }
    const option = {
      icon: 'icon-fa-score',
      title: 'Task',
      link: '/xc_task',
      titleInLocale: true,
      scopes: [
        'xc_task-edit',
      ],
    }
    return {
      xc_task: option
    }
  },
  getMenuOptionXcWorkType() {
    if (!config.wsmodule.xc_work_type) {
      return
    }
    const option = {
      icon: 'icon-md-change-history',
      title: '作業類型',
      link: '/xc_work_type',
      titleInLocale: true,
      scopes: [
        'xc_work_type-read',
        'xc_work_type-edit',
      ],
    }
    return {
      xc_work_type: option
    }
  },
  getMenuOptionXcProject() {
    if (!config.wsmodule.xc_project) {
      return
    }
    const option = {
      icon: 'icon-md-videogame-asset',
      title: '專案',
      link: '/xc_project',
      scopes: [
        'boss',
        'xc_project-read',
        'xc_project-edit',
      ],
    }
    return {
      xc_project: option
    }
  },
  getMenuOptionDownloadInfo() {
    if (!config.wsmodule.download_info) {
      return
    }
    const option = {
      icon: 'icon-md-cloud-download',
      title: '下載資訊',
      titleInLocale: true,
    }
    if (config.wsmodule.download_info.clone_types) {
      option.menu = this.getMenuFromCloneTypes(config.wsmodule.download_info.clone_types)
    }
    return {
      download_info: option
    }
  },
  getMenuFromCloneTypes(cloneTypes) {
    const menu = []
    for (const key in cloneTypes) {
      menu.push({
        title: cloneTypes[key].title,
        link: `/${key}`,
      })
    }
    return menu
  }
}