<template>
  <div class="ws-state-image">
    <WsImg
      v-if="value"
      :url="value"
      alt=""
      class="mb-10"
      :wireBackground="true"
      :previewMaxWidth="previewMaxWidth"
      :previewMaxHeight="previewMaxHeight"
    />
    <WsPocketImagePicker
      @submit="$_onPocketImageSubmit($event)"
      v-model="dialog"
      :signed="signed"
      :getUploadUrl="getUploadUrl"
      :pocketable="pocketable"
      :uploadable="uploadable"
      :linkable="linkable"
    ></WsPocketImagePicker>
    <WsBtn @click="$_onPickDialogOpen()">{{$t('pick_image')}}</WsBtn>
    <WsIconBtn
      @click="$_onClear()"
      v-if="value"
      class="remove-btn"
      backgroundColor="#000"
      name="icon-md-delete"
    />
  </div>
</template>

<script>
import PocketImageService from "@/__vue2stone_cms/service/api/v1/pocket_image";
export default {
  data: () => ({
    dialog: false,
    fetchedItems: [],
  }),

  computed: {},

  methods: {
    $_onClear() {
      this.$emit("input", null);
    },
    $_onPickDialogOpen() {
      this.dialog = true;
    },
    $_onPocketImageSubmit($event) {
      this.$emit("input", PocketImageService.getSrc($event));
    },
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    signed: {
      type: Boolean,
      default: false,
    },
    getUploadUrl: {
      type: String,
      default: undefined,
    },
    previewMaxWidth: {
      type: String,
      default: "100%",
    },
    previewMaxHeight: {
      type: String,
      default: "100%",
    },
    pocketable: {
      type: Boolean,
    },
    uploadable: {
      type: Boolean,
    },
    linkable: {
      type: Boolean,
    },
  },
};
</script>

<style>
</style>