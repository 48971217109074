// import config from '@/__config'

const model = {
  modelName: 'appointment',
  label: '預約',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    date: {
      type: 'date',
      label: '日期',
    },
    start_time: {
      type: 'time',
      label: '起始時間 (0000)',
      maxlength: 4,
    },
    end_time: {
      type: 'time',
      label: '結束時間 (0000)',
      maxlength: 4,
    },
    user: {
      type: 'belongsTo',
      label: '會員',
      relationPopup: true,
      textKey: 'name',
      modelName: 'user',
    },
    service_store: {
      type: 'belongsTo',
      label: '服務中心',
      relationPopup: true,
      textKey: 'name',
      modelName: 'service_store',
    },
    tel: {
      type: 'text',
      label: '電話',
      labelInLocale: true,
    },
    email: {
      type: 'text',
      label: 'Email',
    },
    remark: {
      type: 'textarea',
      label: '備註',
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    status: {
      type: 'select',
      label: '狀態',
      items: [
        {
          text: '已預約',
          value: 'reserved'
        },
        {
          text: '完成',
          value: 'complete'
        },
        {
          text: '取消',
          value: 'cancel'
        },
      ]
    }
  },
  crudSetting: {
    index: {
      liveSearching: true,
      exportable: true,
      infiniteScroll: true,
      filterUniqueSection: true,
      showFields: [
        "status",
        "date",
        "start_time",
        "end_time",
        "user",
        "service_store"
      ],
      filterSelects: {
        service_store: {
          label: '維修據點',
          type: 'belongsTo',
          modelName: 'service_store',
          textKey: 'name',
          col: 3,
        },
        date: {
          label: '日期',
          type: 'date',
          col: 3,
        }
      },
      inRowBtnRead: true,
      deletable: false,
      pageMode: true
    },
    read: {
      leftFields: [
        'id',
        'date',
        'start_time',
        'end_time',
        'user',
        'service_store',
        'tel',
        'email',
        'remark',
      ],
      rightFields: [
        'status',
        'created_at',
      ],
      deletable: false,
      titleKey: 'id'
    },
    update: {
      titleKey: 'id',
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'date',
                'start_time',
                'end_time',
                'tel',
                'email',
                'remark',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'status',
                'user',
                'service_store',
              ],
            },
          ],
        },
      ],
    },
  }
}

export default model