<template>
  <WsModelPicker
    ref="indexDialog"
    :label="label"
    :modelName="belongsToManyModelName"
    :fields="belongsToManyFields"
    :showFields="belongsToManyPickShowFields"
    valueSync
    :filterSelects="belongsToManyPickFilterSelects"
    :value="modelDatas"
    :alert="alert"
    :params="_modelPickerParams"
    @submit="$_onSubmitPicker($event)"
  ></WsModelPicker>
</template>

<script>
export default {
  methods: {
    async $_onSubmitPicker($event) {
      this.modelDatas = $event;
      if (!this.modelDatas || !this.modelDatas.length) {
        return;
      }
      const _ids = this.modelDatas.map((item) => item.id);
      await this.$axios.patch(`/${this.modelName}/${this.id}`, {
        [this._belongsToManyKey]: _ids,
      });
      this.$refs.indexDialog.close();
      this.modelDatas = [];
    },
    async open() {
      await this.$_fetchData();
      setTimeout(() => {
        this.$refs.indexDialog.open();
      }, 0);
    },
    async $_fetchData() {
      const getUrl = `/${this.belongsToManyModelName}`;
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
      }
      this.cancelTokenSource = this.$axios.CancelToken.source();
      const res = await this.$axios.get(getUrl, {
        params: this._params,
        cancelToken: this.cancelTokenSource.token,
      });
      this.modelDatas = res.data.data;
    },
  },
  data() {
    return {
      cancelTokenSource: null,
      loading: {
        modelPicker: false,
      },
      modelDatas: [],
    };
  },
  computed: {
    _modelPickerParams() {
      return {
        // getall: 1,
      };
    },
    _paramsKey() {
      if (this.paramsKey) {
        return this.paramsKey;
      } else {
        return `${this.modelName}s`;
      }
    },
    _belongsToManyKey() {
      if (this.belongsToManyKey) {
        return this.belongsToManyKey;
      } else {
        return `${this.belongsToManyModelName}s`;
      }
    },
    _params() {
      return {
        [this._paramsKey]: this.id,
        getall: 1,
      };
    },
  },
  props: {
    belongsToManyModelName: {},
    belongsToManyFields: {},
    belongsToManyShowFields: {},
    belongsToManyPickShowFields: {},
    belongsToManyPickFilterSelects: {},
    belongsToManyKey: {},
    modelName: {
      type: String,
    },
    paramsKey: {
      type: String,
    },
    id: {},
    updateBtnLabel: {
      type: String,
    },
    label: {
      type: String,
    },
    alert: Object,
  },
};
</script>

<style>
</style>