import config from '@/__config'

const model = {
  modelName: "area",
  label: "地區",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    sequence: {
      type: "text",
      label: "排序",
      maxlength: 10,
    },
    name: {
      type: "text",
      label: "名稱",
      autofocus: true,
      rules: "required",
      maxlength: 30,
      labelInLocale: true,
    },
    updated_at: {
      type: "date",
      label: "更新時間",
      readonly: true,
      labelInLocale: true,
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      readonly: true,
      labelInLocale: true,
    },
  },
  crudSetting: {
    index: {
      liveSearching: true,
      showFields: ["id", "name", "country_code"],
      infiniteScroll: true,
      pageMode: true,
    },
  },
}

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: "select",
    label: "國家/地區代碼",
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  }
}
if (config.wsmodule.area) {
  if (config.wsmodule.area.label) {
    model.label = config.wsmodule.area.label
  }
  if (config.wsmodule.area.urlModelName) {
    model.urlModelName = config.wsmodule.area.urlModelName
  }
  if (config.wsmodule.area.area_section) {
    model.crudSetting.index.inRowBtnRead = true
  }
}

export default model