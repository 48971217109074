import config from '@/__config';

const model = {
  modelName: 'user_device',
  label: '使用者裝置',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    uuid: {
      type: 'text',
      label: 'UUID',
      readonly: true,
    },
    type: {
      type: 'text',
      label: 'Type',
    },
    is_diy: {
      type: 'switch',
      label: 'DIY',
    },
    brand: {
      type: 'text',
      label: 'Brand',
    },
    model_number: {
      type: 'text',
      label: 'Model Number',
    },
    serial_number: {
      type: 'text',
      label: 'Serial Number',
    },
    user: {
      type: 'belongsTo',
      label: '會員',
      textKey: 'name',
      modelName: 'user',
      width: '100px',
      labelInLocale: true,
    },
    status: {
      type: 'select',
      label: '狀態',
      labelInLocale: true,
      defaultValue: 'active',
      items: [
        {
          value: 'active',
          text: 'Active',
        },
        {
          value: 'deactive',
          text: 'Deactive',
        },
      ],
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: [
        'uuid',
        'type',
        'is_diy',
        'brand',
        'model_number',
        'serial_number',
        'user',
        'status',
      ],
      infiniteScroll: true,
      expandable: false,
      updatable: false,
      deletable: false,
      inRowBtnRead: true,
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'type',
                'is_diy',
                'brand',
                'model_number',
                'serial_number',
                'user',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: '設定',
              fields: ['status'],
            },
          ],
        },
      ],
    },
    update: {
      titleKey: 'uuid',
      submitToIndex: true,
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'type',
                'is_diy',
                'brand',
                'model_number',
                'serial_number',
                'user',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              title: '設定',
              fields: ['status'],
            },
          ],
        },
      ],
    },
  },
};

if (config.wsmodule.country_code) {
  model.fields['country_code'] = {
    type: 'select',
    label: '國家/地區代碼',
    items: config.wsmodule.country_code.items,
    labelInLocale: true,
  };
}

if (config.wsmodule.user) {
  if (config.wsmodule.user.device) {
    if (config.wsmodule.user.device.type) {
      if (config.wsmodule.user.device.type.items) {
        model.fields.type.type = 'select';
        model.fields.type.items = config.wsmodule.user.device.type.items;
      }
    }
    if (config.wsmodule.user.device.brand) {
      if (config.wsmodule.user.device.brand.items) {
        model.fields.brand.type = 'select';
        model.fields.brand.items = config.wsmodule.user.device.brand.items;
      }
    }
  }
}

export default model;
