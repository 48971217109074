export default {
  modelName: "pin_card",
  label: "PIN Card",
  fields: {
    id: {
      type: "text",
      label: 'ID',
      readonly: true
    },
    pin: {
      type: 'text',
      label: 'PIN',
    },
    status: {
      type: 'select',
      label: '狀態',
      labelInLocale: true,
      defaultValue: 0,
      items: [
        {
          value: 0,
          text: '未啟用'
        },
        {
          value: 1,
          text: '啟用'
        },
      ]
    },
    user: {
      type: "belongsTo",
      label: "會員",
      labelInLocale: true,
      textKey: "name",
      modelName: "user"
    },
    service_plan: {
      type: "belongsTo",
      label: "服務方案",
      labelInLocale: true,
      textKey: "name",
      rules: 'required',
      modelName: "service_plan"
    },
    expired_at: {
      type: "date",
      label: "開卡期限"
    },
    created_at: {
      type: "datetime",
      label: "建立時間",
      labelInLocale: true,
      readonly: true
    },
    updated_at: {
      type: "datetime",
      label: "最後更新時間",
      labelInLocale: true,
      readonly: true
    },
    updated_admin: {
      type: "belongsTo",
      label: "最後更新人",
      labelInLocale: true,
      readonly: true,
      textKey: "name",
      modelName: "admin"
    },
  },
}
