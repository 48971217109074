import config from '@/__config';
import moment from 'moment';
const model = {
  modelName: 'news',
  label: '最新消息',
  fields: {
    id: {
      type: 'text',
      label: 'ID',
      readonly: true,
    },
    title: {
      type: 'text',
      label: '標題',
      rules: 'required',
    },
    content: {
      type: 'editor',
      label: '內容',
      signed: false,
    },
    description: {
      type: 'text',
      label: '說明',
    },
    publish_at: {
      type: 'datetime',
      label: '發布時間',
      defaultValue: moment().format('YYYY-MM-DD hh:00:00'),
    },
    publish_status: {
      type: 'switch',
      label: '發布',
    },
    tags: {
      type: 'tags',
      label: '標籤',
    },
    created_at: {
      type: 'datetime',
      label: '建立時間',
      readonly: true,
    },
    updated_at: {
      type: 'datetime',
      label: '最後更新時間',
      readonly: true,
    },
  },
  crudSetting: {
    index: {
      pageMode: true,
      liveSearching: true,
      showFields: [
        "title",
        "id",
        "tags",
        "publish_status"
      ],
      infiniteScroll: true,
      expandable: false,
      inRowBtnRead: true,
      orderBySq: true,
    },
    read: {
      leftFields: [
        'content',
      ],
      rightFields: [
        'publish_status',
        'id',
        'title',
        'description',
        'publish_at',
        'tags',
        'created_at',
        'updated_at',
      ],
      titleKey: 'title'
    },
    create: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'content',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'publish_status',
                'title',
                'description',
                'publish_at',
                'tags',
              ],
            },
          ],
        },
      ],
    },
    update: {
      primary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'content',
              ],
            },
          ],
        },
      ],
      secondary: [
        {
          type: 'stateCard',
          floors: [
            {
              fields: [
                'publish_status',
                'title',
                'description',
                'publish_at',
                'tags',
              ],
            },
          ],
        },
      ],
      titleKey: 'title'
    },
  }
};


if (config.wsmodule.news) {
  if (config.wsmodule.news.cover_image) {
    model.fields.cover_image = {
      type: 'pocket_image',
      label: '封面圖片 (建議500k以下)',
      signed: false,
    }
  }
}


export default model;
