// import tulpa_page from '@/__vue2stone_cms/models/tulpa_page'
// import tulpa_section from '@/__vue2stone_cms/models/tulpa_section'
// import tulpa_page_template from '@/__vue2stone_cms/models/tulpa_page_template'
// import area from '@/__vue2stone_cms/models/area'
// import area_section from '@/__vue2stone_cms/models/area_section'
// import system_class from '@/__vue2stone_cms/models/system_class'
// import system_subclass from '@/__vue2stone_cms/models/system_subclass'
// import contact_request from '@/__vue2stone_cms/models/contact_request'
// import admin_group from '@/__vue2stone_cms/models/admin_group'
// import admin_role from '@/__vue2stone_cms/models/admin_role'
// import admin from '@/__vue2stone_cms/models/admin'
// import ws_blog from '@/__vue2stone_cms/models/ws_blog'
// import locale from '@/__vue2stone_cms/models/locale'
// import user_device_token from '@/__vue2stone_cms/models/user_device_token'
// import notification from '@/__vue2stone_cms/models/notification'
// import user from '@/__vue2stone_cms/models/user'
// import service_store from '@/__vue2stone_cms/models/service_store'
// import service_store_close from '@/__vue2stone_cms/models/service_store_close'
// import service_store_noti from '@/__vue2stone_cms/models/service_store_noti'
import penguin from '@/models/penguin'
import ice from '@/models/ice'
export default {
  namespaced: true,
  state: {
    // tulpa_page,
    // tulpa_section,
    // tulpa_page_template,
    penguin,
    ice,
    // area,
    // area_section,
    // system_class,
    // system_subclass,
    // contact_request,
    // admin_group,
    // admin_role,
    // admin,
    // ws_blog,
    // locale,
    // user_device_token,
    // notification,
    // user,
    // service_store,
    // service_store_close,
    // service_store_noti,
  },
  mutations: {
  },
  actions: {
  }
}