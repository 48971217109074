<template>
  <div class="ws-crud-table">
    <WsNoDataMessage v-if="(!_items||!_items.length)&&!loading">{{$t('no_data')}} :(</WsNoDataMessage>
    <template v-else>
      <WsDataTablePagenateTypeB
        v-if="paginate && showTopPaginate"
        class="ws-data-table-pagenate-top"
        @pageto="$emit('pageto',$event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
      <WsText
        v-if="!paginate&&showDataTotalCount"
        class="mr-10 pl-16 mb-20"
      >
        共 {{dataTotalCount}} 筆</WsText>
      <WsFlex
        class="mb-20"
        align-items="center"
        v-if="value&&value.length"
      >
        <WsText class="mr-10"> 已選取 {{value.length}} 個項目</WsText>
        <WsBtn @click="$_cancelAllSelected()">取消選取</WsBtn>
      </WsFlex>
      <div class="ws-crud-table__table-container">
        <SlickList
          tag="table"
          cellspacing="0"
          cellpadding="0"
          helperClass="helper"
          :useDragHandle="true"
          :value="items"
          @input="$_onSlickInput($event)"
        >
          <tr :style="[{'top':_scrollTop + 'px'}]">
            <th v-if="sortOption"></th>
            <th
              v-if="selectable"
              class="check"
            >
              <WsState
                type="checkbox"
                :value="_tableCheckValue"
                @input="$_onTableCheckInput($event)"
              ></WsState>
            </th>
            <th
              v-if="expandable"
              class="expand"
            ></th>
            <th
              @click="$_orderSet(headersItem)"
              v-for="(headersItem,headersIndex) in _headers"
              :key="headersIndex"
              :class="[{orderable:$_orderableCheck(headersItem)},C_orderWay]"
              :style="[{width:$_getThWidth(headersItem.width)},{'max-width':$_getThWidth(headersItem.width)},{'min-width':$_getThWidth(headersItem.width)}]"
            >
              <WsText size="12">{{headersItem.text}}</WsText>
              <WsIcon
                v-if="$_orderableCheck(headersItem)&&C_orderBy==headersItem.value"
                name="icon-md-arrow-drop-up"
              />
            </th>
            <th
              v-if="inRowBtnRead||inRowBtnUpdate||inRowBtnDelete||inRowBtnComplete||(customTableActions&&customTableActions.length)"
              class="actions"
              :style="[{width:`${_actionWidth}px`},{'min-width':`${_actionWidth}px`},{'max-width':`${_actionWidth}px`},]"
            >
            </th>
          </tr>
          <SlickItem
            class="ws-crud-table-item"
            tag="tbody"
            v-for="(item,itemIndex) in _items"
            :key="itemIndex"
            :index="itemIndex"
          >
            <tr
              :class="[{expandable:expandable},{selectedBackground:$_getRowCheckValue(item)}]"
              @click="$_onRowClick(item,itemIndex)"
            >
              <td v-if="sortOption">
                <WsIcon
                  size="20"
                  name="icon-md-drag-handle"
                  v-handle
                />
              </td>
              <td
                v-if="selectable"
                class="check"
              >
                <WsState
                  type="checkbox"
                  :value="$_getRowCheckValue(item)"
                  @input="$_onRowCheckInput($event,item)"
                ></WsState>
              </td>
              <td
                v-if="expandable"
                class="expand"
              >
                <WsIcon
                  size="20"
                  name="icon-md-chevron-down"
                />
              </td>
              <td
                v-for="(headersItem,headersIndex) in _headers"
                :key="headersIndex"
              >
                <WsState
                  v-if="fields[headersItem.value]&&fields[headersItem.value].updatable"
                  :value="$_getValue(item,headersItem.value,fields[headersItem.value])"
                  v-bind="fields[headersItem.value]"
                  :label="undefined"
                  :modelData="item"
                  @input="$_onUpdateAlert($event,headersItem.value,itemIndex,fields[headersItem.value],item)
                "
                  @submit="$_onUpdateAlert($event,headersItem.value,itemIndex,fields[headersItem.value],item);$_updateAlertOpen(item)"
                >
                </WsState>
                <WsInfo
                  v-else
                  :value="$_getValue(item,headersItem.value,fields[headersItem.value])"
                  v-bind="fields[headersItem.value]"
                  :label="undefined"
                  :modelData="item"
                ></WsInfo>
              </td>
              <td
                class="actions"
                :class="[{selectedBackground:$_getRowCheckValue(item)}]"
              >
                <template v-if="customTableActions">
                  <dir
                    v-for="(customTableAction, customTableActionIndex) in customTableActions"
                    :key="customTableActionIndex"
                  >
                    <WsIconBtn
                      v-if="$_customActionDisplayChecek(customTableAction,item)"
                      @click.stop="$emit('custom-table-action',{emit:customTableAction.emit,data:{item,itemIndex}})"
                      :name="$_getCustomActionIcon(customTableAction,item)"
                      :to="$_getCustomActionUrl(customTableAction,item)"
                      :tooltip="$_getCustomActionTooltip(customTableAction,item)"
                      :disabled="$_getCustomActionDisabled(customTableAction,item)"
                    />
                  </dir>
                </template>
                <template v-if="inRowBtnRead">
                  <WsIconBtn
                    tooltip="Preview"
                    :to="$_getReadUrl(item)"
                    v-if="pageMode&&!dialogRead"
                    name="icon-ws-outline-eye-open"
                  />
                  <WsIconBtn
                    tooltip="Preview"
                    v-else
                    @click.stop="$emit('read',{item,itemIndex})"
                    name="icon-ws-outline-eye-open"
                  />
                </template>
                <template v-if="inRowBtnUpdate">
                  <WsIconBtn
                    tooltip="Edit"
                    :to="$_getUpdateUrl(item)"
                    v-if="pageMode&&!dialogUpdate"
                    name="icon-md-edit"
                  />
                  <WsIconBtn
                    tooltip="Edit"
                    v-else
                    @click.stop="$emit('update',{item,itemIndex})"
                    name="icon-md-edit"
                  />
                </template>
                <WsIconBtn
                  tooltip="Delete"
                  @click.stop="$emit('delete',{item,itemIndex})"
                  v-if="inRowBtnDelete"
                  name="icon-md-delete"
                />
                <WsIconBtn
                  tooltip="Delete"
                  @click.stop="$emit('complete',{item,itemIndex})"
                  v-if="inRowBtnComplete"
                  name="icon-md-done"
                />
              </td>
            </tr>
            <tr
              v-if="$_expandedCheck(itemIndex)"
              :key="`${itemIndex}-expand`"
              class="expand-content"
            >
              <td
                v-if="expandable"
                class="expand"
              >
              </td>
              <td :colspan="_expandColspan">
                <WsEasyTable
                  :fields="displayFields"
                  :modelData="items[itemIndex]"
                />
              </td>
            </tr>
          </SlickItem>
        </SlickList>
      </div>
      <WsDataTablePagenateTypeB
        v-if="paginate"
        @pageto="$emit('pageto',$event)"
        :currentPage="currentPage"
        :dataTotalCount="dataTotalCount"
        :itemsPerPage="itemsPerPage"
        :lastPage="lastPage"
      ></WsDataTablePagenateTypeB>
    </template>
    <WsPopup
      ref="updateAlert"
      @enter="$_updateAlertSubmit($refs.updateAlert.data)"
    >
      <template
        #title
        v-if="updateAlert.title"
      >
        <WsText>{{updateAlert.title}}</WsText>
      </template>
      <template #content>
        {{updateAlert.content}}
      </template>
      <template #rightActions>
        <WsBtn @click="$_updateAlertClose()">{{$t('取消')}}</WsBtn>
        <WsBtn @click="$_updateAlertSubmit($refs.updateAlert.data)">{{$t('確認')}}</WsBtn>
      </template>
    </WsPopup>
  </div>
</template>

<script>
import S_APP_State from "@/__vue2stone_cms/service/app/state";
import H_State from "@/__vue2stone_cms/helpers/state";
export default {
  data: () => ({
    C_orderBy: null,
    C_orderWay: "desc",
    expandedRows: [],
    updateAlert: {
      title: "",
      content: "",
      data: null,
    },
  }),
  methods: {
    $_onSlickInput($event) {
      this.$emit("update:order", $event);
    },
    $_getValue(item, key, field) {
      if (field.type == "date-range-or-not") {
        return H_State.getValueFromFieldAndFormValue(field, item, key);
      } else {
        return S_APP_State.getValueByFieldKey(key, item);
      }
    },
    $_onTableCheckInput($event) {
      let _value;
      if ($event) {
        _value = [...this.items];
      } else {
        _value = [];
      }
      this.$emit("input", _value);
    },
    $_onRowCheckInput($event, item) {
      let _value = this.value ? [...this.value] : [];
      const exist = _value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if ($event) {
        if (!exist) {
          const _item = this.items.find((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.push(_item);
        }
      } else {
        if (exist) {
          const _valueIndex = this.value.findIndex((e) => {
            return e[this.modelDataKey] == item[this.modelDataKey];
          });
          _value.splice(_valueIndex, 1);
        }
      }
      this.$emit("input", _value);
    },
    $_getRowCheckValue(item) {
      if (!this.value) {
        return false;
      }
      const _item = this.value.find((e) => {
        return e[this.modelDataKey] == item[this.modelDataKey];
      });
      if (_item) {
        return true;
      } else {
        return false;
      }
    },
    $_getCustomActionDisabled(customTableAction, item) {
      if (customTableAction.getDisabled) {
        return customTableAction.getDisabled(item);
      } else {
        return customTableAction.disabled;
      }
    },
    $_customActionDisplayChecek(customTableAction, item) {
      if (customTableAction.displayCheck) {
        return customTableAction.displayCheck(item);
      } else {
        return true;
      }
    },
    $_getCustomActionTooltip(customTableAction, item) {
      if (customTableAction.getTooltip) {
        return customTableAction.getTooltip(item);
      } else {
        return customTableAction.tooltip;
      }
    },
    $_getCustomActionUrl(customTableAction, item) {
      if (customTableAction.getUrl) {
        return customTableAction.getUrl(item);
      } else {
        return null;
      }
    },
    $_getCustomActionIcon(customTableAction, item) {
      if (customTableAction.getIcon) {
        return customTableAction.getIcon(item);
      } else {
        return customTableAction.icon;
      }
    },
    $_getThWidth(width) {
      if (width) {
        return width;
      } else {
        return "100px";
      }
    },
    $_getReadUrl(item) {
      if (this.getReadUrl) {
        return this.getReadUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}`;
      }
    },
    $_getUpdateUrl(item) {
      if (this.getUpdateUrl) {
        return this.getUpdateUrl(item);
      } else {
        return `/${this._urlModelName}/${item.id}/update`;
      }
    },
    $_expandedCheck(index) {
      return this.expandedRows.includes(index);
    },
    $_onRowClick(item, itemIndex) {
      if (!this.inRowBtnRead && this.rowClickRead) {
        this.$emit("read", { item, itemIndex });
      }
      if (!this.expandable) {
        return;
      }
      const tarIndex = this.expandedRows.findIndex((e) => {
        return e == itemIndex;
      });
      if (tarIndex >= 0) {
        this.expandedRows.splice(tarIndex, 1);
      } else {
        this.expandedRows.push(itemIndex);
      }
    },
    $_orderSet(item) {
      if (!this.$_orderableCheck(item)) {
        return;
      }
      const value = item.value;
      if (this.C_orderBy != value) {
        this.C_orderBy = value;
        this.C_orderWay = this.orderWayDefault;
      } else {
        if (this.C_orderWay == this.orderWayDefault) {
          this.C_orderWay = this.orderWayDefault == "desc" ? "asc" : "desc";
        } else {
          this.C_orderWay = this.orderWayDefault;
          this.C_orderBy = this.orderByDefault;
        }
      }
    },
    $_orderableCheck(headersItem) {
      if (!headersItem.sortable) {
        return false;
      } else if (headersItem.value == "actions") {
        return false;
      } else {
        return true;
      }
    },
    expandReset() {
      this.expandedRows = [];
    },
    $_cancelAllSelected() {
      this.$emit("input", []);
    },
    $_onUpdateAlert($event, fieldKey, fieldIndex, field, state) {
      this.updateAlert.data = {};
      this.updateAlert.data[fieldKey] = $event;
      if (this.fields[fieldKey].updatable.getAlertTitle) {
        this.updateAlert.title =
          this.fields[fieldKey].updatable.getAlertTitle(state);
      }
      if (this.fields[fieldKey].updatable.getAlertContent) {
        this.updateAlert.content =
          this.fields[fieldKey].updatable.getAlertContent(state);
      }
      this.updateAlert.index = fieldIndex;
      if (field.type === "switch") {
        this.$_updateAlertOpen(state);
      }
    },
    $_updateAlertClose() {
      this.$refs.updateAlert.close();
    },
    $_updateAlertOpen(data) {
      this.$refs.updateAlert.open(data);
    },
    async $_updateAlertSubmit($event) {
      this.$_updateAlertClose();
      try {
        await this.$axios.patch(
          `/${this.modelName}/${$event.id}`,
          this.updateAlert.data
        );
        let _items = [...this._items];
        _items[this.updateAlert.index] = this.updateAlert.data;
        this.$emit("updateAlert", _items);
      } catch (err) {
        alert(err);
      }
    },
    $_formatItemOrderProduct(item, itemPre, orderProductFields, keyPre = "") {
      for (let orderProductFieldKey in orderProductFields) {
        const shopOrderShopProductField =
          orderProductFields[orderProductFieldKey];
        this.$_formatItem(
          item,
          itemPre.shop_order_shop_product,
          shopOrderShopProductField,
          orderProductFieldKey,
          `${keyPre}shop_order_shop_product__`
        );
      }
    },
    $_formatItemOther(item, itemPre, fieldKey, keyPre) {
      if (!itemPre || !itemPre[fieldKey]) {
        return;
      }
      item[`${keyPre}${fieldKey}`] = itemPre ? itemPre[fieldKey] : null;
    },
    $_formatItem(item, itemPre, field, fieldKey, keyPre = "") {
      if (field.type == "shop_order_shop_product") {
        this.$_formatItemOrderProduct(item, itemPre, field.fields, keyPre);
      } else {
        this.$_formatItemOther(item, itemPre, fieldKey, keyPre);
      }
    },
  },
  computed: {
    _displayFields() {
      if (this.displayFields) {
        return this.displayFields;
      } else {
        return this.fields;
      }
    },
    _tableCheckValue() {
      if (this.value && this.value.length) {
        if (this.value.length == this.items.length) {
          return true;
        } else {
          return "-";
        }
      } else {
        return false;
      }
    },
    _tdBorderColor() {
      if (this.tdBorderColor) {
        return this.tdBorderColor;
      } else {
        return this.$color.gray;
      }
    },
    _expandColspan() {
      return this._headers.length + 1;
    },
    _items() {
      if (!this.items) {
        return [];
      }
      const _items = [...this.items];
      _items.forEach((item) => {
        for (let fieldKey in this.fields) {
          // 判斷顯示/隱藏
          const field = this.fields[fieldKey];
          this.$_formatItem(item, item, field, fieldKey);
        }
      });
      return _items;
    },
    _actionWidth() {
      let _actionWidth = 10;
      let count = 0;

      if (this.inRowBtnRead) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnUpdate) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnDelete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.inRowBtnComplete) {
        _actionWidth += 40.1;
        count++;
      }
      if (this.customTableActions) {
        this.customTableActions.forEach(() => {
          _actionWidth += 40.1;
          count++;
        });
      }
      _actionWidth += count * 8;
      return _actionWidth;
    },
    _headers() {
      if (this.headers) {
        return this.headers;
      } else if (this.showFields) {
        const _headers = [];
        this.showFields.forEach((showFieldKey) => {
          if (showFieldKey in this._displayFields) {
            const field = this._displayFields[showFieldKey];
            const _label = field.labelInLocale
              ? this.$t(field.label)
              : field.label;
            if (field.type == "list" || field.type == "evaluationStage") {
              return;
            }
            if (
              field.type == "image" ||
              field.type == "tags" ||
              field.type == "password" ||
              field.type == "link" ||
              field.type == "editor"
            ) {
              _headers.push({
                value: showFieldKey,
                text: _label,
                width: field.width,
                sortable: false,
              });
              return;
            }
            _headers.push({
              value: showFieldKey,
              text: _label,
              width: field.width,
              sortable: field.sortable,
            });
          }
        });
        return _headers;
      } else {
        return [];
      }
    },
    _urlModelName() {
      return this.urlModelName ? this.urlModelName : this.modelName;
    },
    _scrollTop() {
      return 0;
      // if (!this.headerSticky) return 0;
      // return this.scrollTop;
    },
  },

  props: {
    showDataTotalCount: {
      type: Boolean,
      default: true,
    },
    modelDataKey: {
      type: String,
      default: "id",
    },
    value: {
      type: Array,
    },
    selectable: {
      type: Boolean,
      default: false,
    },
    tdBorderColor: {
      type: String,
    },
    customTableActions: {
      type: Array,
      default: null,
    },
    showFields: {
      type: Array,
      default: null,
    },
    dialogRead: {
      type: Boolean,
      default: false,
    },
    dialogUpdate: {
      type: Boolean,
      default: false,
    },
    dialogDelete: {
      type: Boolean,
      default: false,
    },
    getUpdateUrl: {
      type: Function,
      default: null,
    },
    getReadUrl: {
      type: Function,
      default: null,
    },
    paginate: {
      type: Boolean,
      default: true,
    },
    headers: {
      type: Array,
      default: null,
    },
    lastPage: Number,
    itemsPerPage: Number,
    currentPage: [Number, String],
    dataTotalCount: {
      type: Number,
      default: 0,
    },
    orderBy: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWay: {
      type: [String, Array],
      default: "desc",
    },
    orderByDefault: {
      type: [String, Array],
      default: "updated_at",
    },
    orderWayDefault: {
      type: [String, Array],
      default: "desc",
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rowClickRead: {
      type: Boolean,
      default: false,
    },
    inRowBtnRead: {
      type: Boolean,
      default: true,
    },
    inRowBtnUpdate: {
      type: Boolean,
      default: true,
    },
    inRowBtnDelete: {
      type: Boolean,
      default: true,
    },
    inRowBtnComplete: {
      type: Boolean,
      default: false,
    },
    expandable: {
      type: Boolean,
      default: true,
    },
    modelName: String,
    pageMode: Boolean,
    displayFields: Object,
    fields: Object,
    items: {
      type: Array,
      default: null,
    },
    showTopPaginate: {
      type: Boolean,
      default: false,
    },
    urlModelName: String,
    scrollTop: {
      type: Number,
      default: 0,
    },
    headerSticky: {
      type: Boolean,
    },
    sortOption: {
      type: Object,
    },
  },

  watch: {
    C_orderBy: {
      handler() {
        this.$emit("update:orderBy", this.C_orderBy);
      },
    },
    C_orderWay: {
      handler() {
        this.$emit("update:orderWay", this.C_orderWay);
      },
    },
    orderBy: {
      handler() {
        this.C_orderBy = this.orderBy;
      },
    },
    orderWay: {
      handler() {
        this.C_orderWay = this.orderWay;
      },
    },
  },
};
</script>